import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ClickAwayListener,
} from "@material-ui/core";
import { IntlContext } from "src/App";
import Select, { components } from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { ReactComponent as Order } from "../../../../../assets/images/icons/order.svg";
import { ReactComponent as BookIcon } from "../../../../../assets/images/icons/bookicon.svg";
import { ReactComponent as AddBookIcon } from "../../../../../assets/images/add-blackbook.svg";
import { ReactComponent as TooltipArrow } from "../../../../../assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { fetchInterval } from "../../../../../helpers/store/Actions/interval";
import { Config } from "../../../../../helpers/context/config";
import "react-lazy-load-image-component/src/effects/blur.css";
import _ from "lodash";
import BlackBookModal from "src/views/component/UI/blackBookModal";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchFromStorage } from "src/helpers/context";
import HighChart from "src/views/component/UI/graph";
import "./fluctuationGraph.scss";
import NoDataComp from "src/views/component/UI/NoData";

const options = [
  { value: "", label: "ODDS TYPE", disabled: true },
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" },
  { value: "3", label: "Win Tote" },
  { value: "4", label: "Place Tote" },
  // { value: "4", label: "Starting Price" },
];

const FluctuationGraphPage = ({
  runnerInfo,
  isrunnnerLoading,
  raceId,
  isResult,
}) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData
  );
  const [selectedOption, setSelectedOption] = useState("1");
  const [runnerInfoData, setrunnerInfoData] = useState([]);
  const [timeinterval, setTimeInterval] = useState();
  const [apiTimeout, setAPiTimeout] = useState();
  const [isOrder, setIsOrder] = useState(false);
  const [open, setOpen] = useState(-1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [oddsType, setOddsType] = useState({ marketId: 1, oddKey: 1 });
  const [oddsLoader, setOddsLoader] = useState(false);
  const [oddsApiCount, setOddsAPiCount] = useState(0);
  const [ProviderData, setProviderData] = useState([]);
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [BBopen, setBBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedGraphId, setSelectedGraphId] = useState(-1);
  const [providerOption, setProviderOption] = useState([]);
  const [isPageHeaderLoading, setIsPageHeaderLoading] = useState(false);
  const handleModal = () => {
    setBBOpen(true);
  };

  const handleClose = () => {
    setBBOpen(false);
  };

  const handleBlackBookModal = (title, id, type) => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      setSelectedTitle(title);
      setSelectedType(type);
      setSelectedId(id);
      handleModal();
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  let sportId = params.sportId;
  const dispatch = useDispatch();

  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
  }, [raceId]);

  useEffect(() => {
    // fetchTableHeading()
    fetchBookKeeper();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    return () => clearInterval(timeinterval);
  }, []);

  const fetchTableHeading = async (Provider) => {
    setIsPageHeaderLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders?SportId=1,2,3`
      );
      if (status === 200) {
        let filteredHeading = data?.result?.filter((item) =>
          Provider?.includes(item?.BookKeeperId)
        );
        setPageHeadingData(filteredHeading);
        let providerList = [];
        const list = filteredHeading?.map((item) => {
          return providerList?.push({
            label: item?.BookKeeper?.name,
            value: item?.BookKeeperId,
          });
        });
        let alldatas = providerList?.unshift(
          {
            label: "Current Best",
            value: -1,
          },
          {
            label: "Best At Open",
            value: -2,
          }
        );
        setProviderOption(providerList);
        setIsPageHeaderLoading(false);
      } else {
        setIsPageHeaderLoading(false);
      }
    } catch (err) {
      setIsPageHeaderLoading(false);
    }
  };
  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (raceId) {
      fetchOddsData(raceId, true);
      fetchLatestOdds(raceId);
    }
    let timeout = setTimeout(() => {
      if (raceId) {
        fetchOddsData(raceId, false);
      }
      let Interval = setInterval(() => {
        if (raceId) {
          fetchOddsData(raceId, false);
        }
      }, 30000);
      setTimeInterval(Interval);
      dispatch(fetchInterval(Interval));
    }, 15000);
    setAPiTimeout(timeout);
    dispatch(fetchInterval(timeout));
    setOpen(-1);
    return () => clearInterval(timeinterval);
  }, [runnerInfo]);

  const oddsTypeChange = (e) => {
    setSelectedOption(e?.value);
    switch (e?.value) {
      case "1":
        setOddsType({ marketId: 1, oddKey: 1 });
        break;
      case "2":
        setOddsType({ marketId: 1, oddKey: 2 });
        break;
      case "3":
        setOddsType({ marketId: 2, oddKey: 1 });
        break;
      case "4":
        setOddsType({ marketId: 2, oddKey: 2 });
        break;
      default:
        setOddsType({ marketId: 1, oddKey: 1 });
    }
  };
  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
    if (raceId) {
      fetchOddsData(raceId, true);
      fetchLatestOdds(raceId);
    }
    let timeout = setTimeout(() => {
      if (raceId) {
        fetchOddsData(raceId, false);
      }
      let Interval = setInterval(() => {
        if (raceId) {
          fetchOddsData(raceId, false);
        }
      }, 30000);
      setTimeInterval(Interval);
      dispatch(fetchInterval(Interval));
    }, 15000);
    setAPiTimeout(timeout);
    dispatch(fetchInterval(timeout));
  }, [selectedOption]);

  const fetchOddsData = async (raceId, isFirstTime) => {
    if (isFirstTime) {
      setOddsLoader(true);
    }
    let runnerInfoData = runnerInfo?.map((obj) => {
      return {
        ...obj,
        RunnerInfos: obj?.RunnerInfos?.map((item) => {
          return {
            ...item,
            info: item?.info ? JSON.parse(item?.info) : "",
          };
        }),
      };
    });
    try {
      const { status, data } = await axiosInstance.get(
        `events/getOddsByrace/${raceId}?marketId=${oddsType?.marketId}&oddKey=${oddsType?.oddKey}`
      );
      if (status === 200) {
        setOddsLoader(false);
        setOddsAPiCount(oddsApiCount + 1);

        let Provider = [];
        let ProviderListfirstRunner = data?.marketRelation?.[0]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        let ProviderListsecondRunner = data?.marketRelation?.[1]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        let ProviderListthirdRunner = data?.marketRelation?.[2]?.data?.map(
          (element) => {
            return Provider?.push(element?.BookKeeperId);
          }
        );
        const uniqueArray = _.uniq(Provider);
        setProviderData(uniqueArray);
        fetchTableHeading(uniqueArray);
        // hideSeeAllOddsColumn(data?.marketRelation)
        let newData = runnerInfoData?.map((obj) => {
          return {
            ...obj,
            oddsData: data?.marketRelation
              ?.map((item) => {
                return item?.RacingParticipantId == obj?.id ? item : [];
              })
              ?.filter((x) => {
                return x?.data?.length > 0;
              }),
          };
        });
        setrunnerInfoData(newData);
      }
    } catch (err) {}
  };

  const fetchLatestOdds = async (raceId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `sync/oddOnDemand/${raceId}`
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleRunnerOrder = () => {
    setIsOrder(!isOrder);
    if (isOrder) {
      const runnerDescending = [...runnerInfoData].sort((a, b) => a.id - b.id);
      setrunnerInfoData(runnerDescending);
    } else {
      const runnerDescending = [...runnerInfoData].sort((a, b) => b.id - a.id);
      setrunnerInfoData(runnerDescending);
    }
  };

  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(sportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="Odds Icon"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      ) : (
        <></>
      );
    } else {
      return (
        <a
          href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="Odds Icon"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      );
    }
  };
  const fetchClickableOdds = (odds, BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          {" "}
          {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
        </a>
      ) : (
        <></>
      );
    } else {
      return (
        <a
          href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <span onClick={() => handleBookkeeperCounter(BookKeeperId, type)}>
            {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
          </span>
        </a>
      );
    }
  };

  const fetchFluctuationGraph = (data) => {
    let selectedId = null;
    if (selectedGraphId === -2) {
      let maxno = data?.reduce((max, obj) => {
        obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
          ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
          : (max = max);
        return max;
      }, -1);
      if (maxno !== -1) {
        if (maxno == 0) {
          let newmaxno = data?.reduce((max, obj) => {
            obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
              ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
              : (max = max);
            return max;
          }, -1);
          if (newmaxno !== -1) {
            let providerid = data
              ?.map((obj) => {
                if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                  return obj?.BookKeeperId;
                }
              })
              ?.filter((x) => x !== undefined);
            selectedId = providerid?.[0];
          } else {
            selectedId = null;
          }
        } else {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          selectedId = providerid?.[0];
        }
        // return maxno;
      } else {
        selectedId = null;
      }
    } else if (selectedGraphId === -1) {
      let maxno = data?.reduce((max, obj) => {
        obj?.RaceOdds?.[0]?.intValue > max
          ? (max = obj?.RaceOdds?.[0]?.intValue)
          : (max = max);
        return max;
      }, -1);
      if (maxno !== -1) {
        if (maxno !== 0) {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            .filter((x) => x !== undefined);
          selectedId = providerid?.[0];
        } else {
          selectedId = null;
        }
      } else {
        selectedId = null;
      }
    } else {
      selectedId = selectedGraphId;
    }
    if (selectedId) {
      const selectedFilterData = data?.filter((item) => {
        return item?.BookKeeperId === selectedId;
      });
      let FlucData = [];
      const selectedGraphIdData = selectedFilterData?.[0]?.RaceOdds?.[0]?.RaceOddFlucs?.map(
        (odds) => {
          return FlucData?.push({
            point: odds?.intValue,
          });
        }
      );
      return <HighChart FlucData={FlucData} />;
    } else {
      return <Box>No Data Available</Box>;
    }
  };

  const fetchCurrentBestValue = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  const fetchCurrentBestIcon = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  // const handleMouseEnter = (index) => {
  //   setTooltipOpen(true);
  //   setHoveredIndex(index);
  // };

  // const handleMouseLeave = () => {
  //   setTooltipOpen(false);
  // };

  const handleTooltipContentClick = (event, index) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(index);
  };

  const handleOutsideClick = (item, index) => {
    if (hoveredIndex === index) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, index) => {
    return (
      <Box
        className="custom-tooltip custom-tooltip-runnertable"
        // onMouseEnter={() => handleMouseEnter(index)}
        // onMouseLeave={handleMouseLeave}
        onClick={(e) => handleTooltipContentClick(e, index)}
      >
        <BookIcon className="book-icon" />
        {hoveredIndex === index ? (
          tooltipOpen && (
            <Box
              // className={
              //   sportId === "3" ? `tooltip greyhound-tooltip` : `tooltip`
              // }
              className="tooltip"
            >
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-blackbook">
                <AddBookIcon /> {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
              </Typography>
              <Box className="black-details">
                {item?.animal ? (
                  <Typography
                    className="details"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.animal?.name,
                        item?.animal?.id,
                        "Runner"
                      )
                    }
                  >
                    Runner: {item?.animal?.name}
                  </Typography>
                ) : (
                  ""
                )}
                {item?.Jockey ? (
                  <Typography
                    className="details"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.Jockey?.name,
                        item?.Jockey?.id,
                        "Jockey"
                      )
                    }
                  >
                    {sportId === "2"
                      ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                      : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                    {item?.Jockey?.name}
                  </Typography>
                ) : (
                  ""
                )}
                {item?.Trainer ? (
                  <Typography
                    className="details"
                    onClick={() =>
                      handleBlackBookModal(
                        item?.Trainer?.name,
                        item?.Trainer?.id,
                        "Trainer"
                      )
                    }
                  >
                    Trainer: {item?.Trainer?.name}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <Box className="race-player-detail-wrap side-space flutuation-graph-details-wrap">
        <Box className="runner-detail-wrap">
          <Box className="odds-table-head">
            <Box className="select-wrap">
              <Select
                className="React"
                value={options?.find((item) => {
                  return item?.value == selectedOption;
                })}
                onChange={(e) => oddsTypeChange(e)}
                options={options}
                isOptionDisabled={(option) => option.disabled}
                classNamePrefix="select"
                isSearchable={false}
              />
            </Box>
            {reduxSubscriptionData?.isPremiumUser && (
              <Box className="select-odds-wrap">
                <Select
                  className="React odds-select"
                  value={providerOption?.find((item) => {
                    return item?.value === selectedGraphId;
                  })}
                  onChange={(e) => {
                    setSelectedGraphId(e?.value);
                  }}
                  isLoading={isPageHeaderLoading}
                  options={providerOption}
                  classNamePrefix="select"
                  placeholder="odds"
                  // isSearchable={false}
                  components={{ DropdownIndicator }}
                />
              </Box>
            )}
          </Box>
          {screenWidth > 799 ? (
            <TableContainer className="race-bookmakersodd">
              {isrunnnerLoading || oddsLoader ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : (
                <Table
                  className="racing-data flutuation-graph-table"
                  aria-label="customized table"
                  style={{ minWidth: 700, borderRadius: 5 }}
                >
                  <TableHead className="racing-track-table-head">
                    <TableRow style={{ fontSize: 12 }} className="head-row">
                      <TableCell
                        className="track-table-th"
                        style={{ textAlign: "left", width: "50%" }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            columnGap: "18px",
                          }}
                        >
                          <span>
                            {localesData?.RUNNER_TABLE?.RUNNER}
                            <Order
                              className="player-order"
                              onClick={handleRunnerOrder}
                            />
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell style={{ width: "30%" }}></TableCell>
                      <TableCell
                        className="track-tabel-odds-th table-head current-best"
                        style={{ width: "20%", textAlign: "center" }}
                      >
                        {localesData?.RUNNER_TABLE?.CURRENT_BEST}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {runnerInfoData?.length > 0 ? (
                      <>
                        {runnerInfoData?.map((item, index) => {
                          return (
                            <>
                              {item?.isScratched === "false" ||
                              item?.isScratched === null ||
                              item?.isScratched === "0" ? (
                                <TableRow className="racing-track-data-row">
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    // className='border-right'
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Box
                                      className="racer-detail-wrap"
                                      onClick={() => {
                                        setOpen(open === index ? -1 : index);
                                        // if (open === -1 || open !== index)
                                        //   fetchRunnerExtraInfor(item?.id);
                                      }}
                                    >
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          handleOutsideClick(item, index)
                                        }
                                      >
                                        <Box className="blackbook-icon">
                                          <Typography variant="h6">
                                            {item?.runnerNumber}
                                            {"."} {item?.animal?.name} (
                                            {item?.barrierNumber})
                                          </Typography>
                                          {handleOpenTooltip(item, index)}{" "}
                                        </Box>
                                      </ClickAwayListener>
                                      <Box className="player-wrap">
                                        {sportId === "1" ? (
                                          <Box className="player-detail">
                                            <span>
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.WEIGHT
                                              }
                                              :
                                            </span>
                                            <span>
                                              {Number(
                                                item?.JockeyWeight
                                              ).toFixed(2) + "Kg"}
                                            </span>
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                        <Box className="player-detail">
                                          {item?.Jockey ? (
                                            <>
                                              <span>
                                                {sportId === "2"
                                                  ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                                                  : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                                              </span>
                                              <span>{item?.Jockey?.name}</span>
                                            </>
                                          ) : item?.Trainer ? (
                                            <>
                                              <span>
                                                {
                                                  localesData?.RUNNER_TABLE
                                                    ?.TRAINER
                                                }
                                                :{" "}
                                              </span>
                                              <span>{item?.Trainer?.name}</span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Box>
                                      </Box>
                                      <Box className="player-wrap">
                                        {sportId === "1" || sportId === "2" ? (
                                          <Box className="player-detail">
                                            {item?.Trainer ? (
                                              <>
                                                <span>
                                                  {
                                                    localesData?.RUNNER_TABLE
                                                      ?.TRAINER
                                                  }
                                                  :{" "}
                                                </span>
                                                <span>
                                                  {item?.Trainer?.name}
                                                </span>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell className="chart-cell">
                                    {fetchFluctuationGraph(
                                      item?.oddsData?.[0]?.data
                                    )}
                                  </TableCell>
                                  <TableCell className="border-right current-best-odds-column">
                                    {item?.oddsData?.[0]?.data ? (
                                      <>
                                        <Box>
                                          {fetchCurrentBestValue(
                                            item?.oddsData?.[0]?.data
                                          )}
                                        </Box>
                                        {fetchCurrentBestIcon(
                                          item?.oddsData?.[0]?.data
                                        )}
                                      </>
                                    ) : (
                                      <Tooltip
                                        title="No odds available"
                                        className="odds-tooltip"
                                        placement="top"
                                      >
                                        <span className="no-odds">NOA</span>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell className="border-left border-bottom"></TableCell>
                                </TableRow>
                              ) : (
                                <TableRow className="racing-track-data-row">
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    className="racing-data"
                                    colSpan={3}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Box
                                      className="racer-detail-wrap"
                                      onClick={() => {
                                        setOpen(open === index ? -1 : index);
                                      }}
                                    >
                                      <Typography variant="h6">
                                        <Box className="scratched">
                                          <span className="scratched-runner-name">
                                            {item?.runnerNumber}
                                            {"."} {item?.animal?.name} (
                                            {item?.barrierNumber})
                                          </span>
                                        </Box>
                                        <Box className="scratched-data">
                                          <span className="scratched-runner-text">
                                            {
                                              localesData?.RUNNER_TABLE
                                                ?.SCRATCHED
                                            }{" "}
                                          </span>{" "}
                                          @{" "}
                                          <span className="scratched-runner-time">
                                            {" "}
                                            {moment(item?.updatedAt).format(
                                              "hh:mm A"
                                            )}
                                          </span>
                                          <span className="scratched-runner-date">
                                            {" "}
                                            {moment(item?.updatedAt).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </Box>
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell className="border-left border-bottom"></TableCell>
                                </TableRow>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <TableRow style={{ border: "transparent" }}>
                        <TableCell
                          style={{
                            textAlign: "center",
                            border: "transparent",
                          }}
                          colSpan={21}
                        >
                          {/* {localesData?.sport?.NO_DATA} */}
                          <NoDataComp />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          ) : (
            <Box className="race-bookmakersodd">
              {isrunnnerLoading || oddsLoader ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <Box className="racing-data">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        columnGap: "18px",
                        alignItems: "center",
                      }}
                      className="runnertable-header"
                    >
                      <span>
                        {localesData?.RUNNER_TABLE?.RUNNER}
                        <Order
                          className="player-order"
                          onClick={handleRunnerOrder}
                        />
                      </span>
                    </Box>
                    {runnerInfoData?.length > 0 ? (
                      <>
                        {runnerInfoData?.map((item, index) => {
                          return (
                            <>
                              <Box
                                className="racer-detail-wrap"
                                onClick={() => {
                                  setOpen(open === index ? -1 : index);
                                }}
                              >
                                <ClickAwayListener
                                  onClickAway={() =>
                                    handleOutsideClick(item, index)
                                  }
                                >
                                  <Box className="mobile-blackbook">
                                    <Typography
                                      variant="h6"
                                      className="mobile-racer-info"
                                    >
                                      {item?.runnerNumber}
                                      {"."} {item?.animal?.name} (
                                      {item?.barrierNumber})
                                    </Typography>
                                    {handleOpenTooltip(item, index)}
                                  </Box>
                                </ClickAwayListener>
                                <Box className="player-detail-container">
                                  <Box className="player-wrap">
                                    {sportId === "1" ? (
                                      <Box className="player-detail">
                                        <span>
                                          {localesData?.RUNNER_TABLE?.WEIGHT}:
                                        </span>
                                        <span>
                                          {Number(item?.JockeyWeight).toFixed(
                                            2
                                          ) + "Kg"}{" "}
                                        </span>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                    <Box className="player-detail">
                                      {item?.Jockey ? (
                                        <>
                                          <span>
                                            {sportId === "2"
                                              ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                                              : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                                          </span>
                                          <span>{item?.Jockey?.name}</span>
                                        </>
                                      ) : item?.Trainer ? (
                                        <>
                                          <span>
                                            {localesData?.RUNNER_TABLE?.TRAINER}
                                            :{" "}
                                          </span>
                                          <span>{item?.Trainer?.name}</span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  </Box>
                                  <Box className="player-wrap">
                                    {sportId === "1" || sportId === "2" ? (
                                      <Box className="player-detail">
                                        {item?.Trainer ? (
                                          <>
                                            <span>
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.TRAINER
                                              }
                                              :{" "}
                                            </span>
                                            <span>{item?.Trainer?.name}</span>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                              </Box>

                              <Box className="mobile-table-container">
                                <TableContainer>
                                  <Table className="mobile-graph-table">
                                    <TableHead className="mobile-data-head">
                                      <TableCell
                                        className="mobile-table-data current-best"
                                        width="33.33%"
                                      >
                                        {" "}
                                        {
                                          localesData?.RUNNER_TABLE
                                            ?.CURRENT_BEST
                                        }
                                      </TableCell>
                                      <TableCell className="mobile-graph-cell"></TableCell>
                                    </TableHead>
                                    <TableBody className="mobile-table-body">
                                      <TableCell className="mobile-table-data current-best-odds-column">
                                        {item?.oddsData?.[0]?.data ? (
                                          <>
                                            <Box>
                                              {fetchCurrentBestValue(
                                                item?.oddsData?.[0]?.data
                                              )}
                                            </Box>
                                            {fetchCurrentBestIcon(
                                              item?.oddsData?.[0]?.data
                                            )}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {fetchFluctuationGraph(
                                          item?.oddsData?.[0]?.data
                                        )}
                                      </TableCell>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Box
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        {/* {localesData?.sport?.NO_DATA} */}
                        <NoDataComp />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <BlackBookModal
        open={BBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
      />
    </>
  );
};

export default FluctuationGraphPage;
