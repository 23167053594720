import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "./ui.scss";

const BlackBookModal = ({
  handleClose,
  open,
  title,
  closeIcon,
  type,
  id,
  typeId,
  editComment,
  isEdit,
  tabType,
  listingFunction
}) => {
  const location = useLocation();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const GetBlackBook = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `blackbook/popUpData/${typeId}?type=${type}`
      );
      if (status === 200) {
        setPopUpData(data?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const handleAddBlackBook = async () => {
    try {
      const payload = {
        type: type,
        id: typeId,
        comment: comment
      };
      const { status, data } = await axiosInstance.post(
        `blackbook/favoriteData`,
        payload
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        listingFunction();
        setTimeout(() => {
          handleClose();
          setPopUpData([]);
          setComment("");
        }, 1000);
      } else {
      }
    } catch (error) {
      toast.error("An Error Occured".data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
      handleClose();
    }
  };
  const handleUpdateBlackBook = async () => {
    try {
      const payload = {
        type: type,
        comment: comment
      };
      const { status, data } = await axiosInstance.put(
        `blackbook/favoriteData/${id}`,
        payload
      );
      if (status === 200) {
        toast.success("Blackbook Updated", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        setTimeout(() => {
          handleClose();
          setPopUpData([]);
          setComment("");
        }, 3000);

        listingFunction();
      } else {
      }
    } catch (error) {
      toast.error("An Error Occured".data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };
  useEffect(() => {
    if (open) {
      GetBlackBook();
      const comment = isEdit ? editComment : "";
      setComment(comment);
    }
  }, [open]);
  return (
    <Dialog
      className="blackBook-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {title && closeIcon && (
          <DialogTitle>
            <Box className="custom-dialog-title">
              <Typography variant="h3" className="dialog-title">
                {title}
                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      handleClose();
                      setPopUpData([]);
                      setComment("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
          </DialogTitle>
        )}
        <Box className="blackBook-content-wrap">
          {isLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : type === "Runner" ? (
            <Box className="blackbook-type-wrap">
              <Box className="blackbook-content">
                <span className="content-label">Last Race -</span>{" "}
                <span className="content-des">
                  {" "}
                  {moment
                    .utc(popUpData?.startTimeDate)
                    .local()
                    .format("DD/MM/YYYY")}{" "}
                  ,{popUpData?.raceNumber ? "R" + popUpData?.raceNumber : ""}{" "}
                  {popUpData?.raceName}{" "}
                </span>
              </Box>
              <Box className="blackbook-content details">
                <span className="des-wrap">
                  <span className="content-label">Trainer -</span>{" "}
                  <span className="content-des">
                    {popUpData?.runner?.[0]?.Trainer?.name
                      ? popUpData?.runner?.[0]?.Trainer?.name
                      : "N/A"}
                    ,
                  </span>
                </span>

                <span className="des-wrap">
                  <span className="content-label"> Jockey -</span>{" "}
                  <span className="content-des">
                    {popUpData?.runner?.[0]?.Jockey?.name
                      ? popUpData?.runner?.[0]?.Jockey?.name
                      : "N/A"}{" "}
                  </span>{" "}
                </span>
              </Box>
            </Box>
          ) : type === "Jockey" ? (
            <Box className="blackbook-type-wrap">
              <Box className="blackbook-content">
                <span className="content-label">Last Race -</span>{" "}
                <span className="content-des">
                  {" "}
                  {moment
                    .utc(popUpData?.startTimeDate)
                    .local()
                    .format("DD/MM/YYYY")}{" "}
                  ,{popUpData?.raceNumber ? "R" + popUpData?.raceNumber : ""}{" "}
                  {popUpData?.raceName}{" "}
                </span>
              </Box>
              <Box className="blackbook-content details">
                <span className="des-wrap">
                  <span className="content-label">LY -</span>{" "}
                  <span className="content-des">
                    {popUpData?.LY?.starts ? popUpData?.LY?.starts : "0"}{" "}
                    {popUpData?.LY?.wins ? popUpData?.LY?.wins : "0"}-
                    {popUpData?.LY?.second ? popUpData?.LY?.second : "0"}-
                    {popUpData?.LY?.thirds ? popUpData?.LY?.thirds + "," : "0,"}
                  </span>
                </span>

                <span className="des-wrap">
                  <span className="content-label">ROI -</span>{" "}
                  <span className="content-des">N/A</span>{" "}
                </span>
              </Box>
            </Box>
          ) : type === "Trainer" ? (
            <Box className="blackbook-type-wrap">
              <Box className="blackbook-content">
                <span className="content-label">Last Race -</span>{" "}
                <span className="content-des">
                  {" "}
                  {moment
                    .utc(popUpData?.startTimeDate)
                    .local()
                    .format("DD/MM/YYYY")}{" "}
                  ,{popUpData?.raceNumber ? "R" + popUpData?.raceNumber : ""}{" "}
                  {popUpData?.raceName}{" "}
                </span>
              </Box>
              <Box className="blackbook-content details">
                <span className="des-wrap">
                  <span className="content-label">LY -</span>{" "}
                  <span className="content-des">
                    {" "}
                    {popUpData?.LY?.starts ? popUpData?.LY?.starts : "0"}{" "}
                    {popUpData?.LY?.wins ? popUpData?.LY?.wins : "0"}-
                    {popUpData?.LY?.second ? popUpData?.LY?.second : "0"}-
                    {popUpData?.LY?.thirds ? popUpData?.LY?.thirds + "," : "0,"}
                  </span>
                </span>

                <span className="des-wrap">
                  <span className="content-label">ROI -</span>{" "}
                  <span className="content-des">N/A</span>{" "}
                </span>
              </Box>
            </Box>
          ) : (
            <Box className="blackbook-type-wrap">
              <Box className="blackbook-content">
                <span className="content-label">Last Race -</span>{" "}
                <span className="content-des">
                  {" "}
                  {moment
                    .utc(popUpData?.startTimeDate)
                    .local()
                    .format("DD/MM/YYYY")}{" "}
                  ,{popUpData?.raceNumber ? "R" + popUpData?.raceNumber : ""}{" "}
                  {popUpData?.raceName}{" "}
                </span>
              </Box>
              <Box className="blackbook-content details">
                <span className="des-wrap">
                  <span className="content-label">Distance -</span>{" "}
                  <span className="content-des">
                    {popUpData?.Distance?.name
                      ? popUpData?.Distance?.name + "m,"
                      : "N/A,"}
                  </span>
                </span>

                <span className="des-wrap">
                  <span className="content-label">Track -</span>{" "}
                  <span className="content-des">
                    {popUpData?.trackCondition ? popUpData?.trackCondition : ""}
                  </span>{" "}
                </span>
              </Box>
            </Box>
          )}
          <TextareaAutosize
            id="outlined-basic"
            variant="outlined"
            className="details-textarea-field"
            placeholder="Comment(optional)..."
            multiline
            maxRows={6}
            name="message"
            style={{ width: "97%", height: "241px" }}
            onChange={e => setComment(e.target.value)}
            value={comment}
          />
          <Box className="blackbook-btn-wrap">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              className="blackbook-button"
              onClick={() => {
                isEdit ? handleUpdateBlackBook() : handleAddBlackBook();
              }}
            >
              {isEdit ? "Update Blackbook" : "Add to my Blackbook"}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
    </Dialog>
  );
};

export default BlackBookModal;
