import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import banner from "../../../assets/images/banner/smart-b-banner-secodary-white.webp";
import AdvertisingBannner from "../advertisingBanner";
import "./subscriptionandpayment.scss";
import { Link } from "react-router-dom";
import { ReactComponent as SubscriptionActive } from "../../../assets/images/icons/sub_plan_active.svg";
import { ReactComponent as Checked } from "src/assets/images/billedChecked.svg";
import { ReactComponent as UnChecked } from "src/assets/images/billedUnChecked.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
// import { ReactComponent as Cvvimage } from "src/assets/images/cvvimage.svg";
import Cvvimage from "../../../assets/images/cvvimage.png";
import MasterCard from "../../../assets/images/mastercard.png";
import Visa from "../../../assets/images/visa.png";
import AmericanExpress from "../../../assets/images/americanExpress.png";
import PayPal from "../../../assets/images/payPal.png";
import { ToastContainer, toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import OurPartner from "../ourPartners";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";

const subData = [
  {
    id: 0,
    type: "Freemium",
    price: "$10",
    mostPopular: true,
    features: [
      {
        id: 0,
        des:
          "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
        disabled: false
      },
      {
        id: 1,
        des: "Etiam ac hendrerit lacus, vel vulputate libero.",
        disabled: false
      },
      {
        id: 2,
        des: "Etiam ac hendrerit lacus, vel vulputate libero.",
        disabled: false
      },
      {
        id: 3,
        des: "Etiam ac hendrerit lacus, vel vulputate libero.",
        disabled: true
      },
      {
        id: 4,
        des: "Etiam ac hendrerit lacus, vel vulputate libero.",
        disabled: true
      }
    ],
    btn: "Subscribe Now!",
    loginBtn: "Sign Up Now"
  }
  // {
  //   id: 1,
  //   type: "Most Popular",
  //   price: "$120",
  //   mostPopular: true,
  //   features: [
  //     {
  //       id: 0,
  //       des:
  //         "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
  //       disabled: false
  //     },
  //     {
  //       id: 1,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 2,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 3,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 4,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     }
  //   ],
  //   btn: "Subscribe Now!"
  // },
  // {
  //   id: 2,
  //   type: "Premium",
  //   price: "$120",
  //   features: [
  //     {
  //       id: 0,
  //       des:
  //         "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
  //       disabled: false
  //     },
  //     {
  //       id: 1,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 2,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 3,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 4,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     }
  //   ],
  //   btn: "Try Premium Now!"
  // },
  // {
  //   id: 3,
  //   type: "Liftime Basic",
  //   price: "$120",
  //   features: [
  //     {
  //       id: 0,
  //       des:
  //         "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
  //       disabled: false
  //     },
  //     {
  //       id: 1,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 2,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 3,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     },
  //     {
  //       id: 4,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     }
  //   ],
  //   btn: "Try Now!"
  // },
  // {
  //   id: 4,
  //   type: "Freemium",
  //   price: "$0",
  //   features: [
  //     {
  //       id: 0,
  //       des:
  //         "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
  //       disabled: false
  //     },
  //     {
  //       id: 1,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: false
  //     },
  //     {
  //       id: 2,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     },
  //     {
  //       id: 3,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     },
  //     {
  //       id: 4,
  //       des: "Etiam ac hendrerit lacus, vel vulputate libero.",
  //       disabled: true
  //     }
  //   ],
  //   btn: "Sign Up for Free!"
  // }
];
const planData = [
  {
    id: 0,
    des:
      "Etiam ac hendrerit lacus, vel vulputate libero. Ut hendrerit sed augue id finibus.",
    disabled: false
  },
  {
    id: 1,
    des: "Etiam ac hendrerit lacus, vel vulputate libero.",
    disabled: false
  },
  {
    id: 2,
    des: "Etiam ac hendrerit lacus, vel vulputate libero.",
    disabled: false
  },
  {
    id: 3,
    des: "Etiam ac hendrerit lacus, vel vulputate libero.",
    disabled: true
  },
  {
    id: 4,
    des: "Etiam ac hendrerit lacus, vel vulputate libero.",
    disabled: true
  }
];
const SubscriptionAndPaymentPage = () => {
  const dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  // const [value, setValue] = useState("Monthly");
  const [paymentMode, setPaymentMode] = useState("");
  // const [formvalues, setformvalues] = useState();
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [cardsave, setCardSave] = useState(false);
  // const [CardSaveLoading, setCardSaveLoading] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [planId, setPlanId] = useState("");
  const [planPrice, setPlanPrice] = useState("");
  const [cardList, setCardList] = useState([]);
  const [cardLoader, setCardLoader] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");

  const handleCardSave = event => {
    setCardSave(event?.target?.checked);
  };

  // const handleRadioChange = e => {
  //   setValue(e?.target?.value);
  // };

  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const handleModal = data => {
    if (handleRestrictedUser()) {
      setPaymentModalOpen(true);
      setPlanId(data?.id);
      setPlanPrice(data?.amount);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  // const scrollToBottomSection = () => {
  //   const targetSection = document.getElementById("details-section");

  //   if (targetSection) {
  //     targetSection.scrollIntoView({
  //       behavior: "smooth"
  //     });
  //   }
  // };
  // const scrollToTopSection = () => {
  //   const targetSection = document.getElementById("subscription-plan");

  //   if (targetSection) {
  //     targetSection.scrollIntoView({
  //       behavior: "smooth"
  //     });
  //   }
  // };
  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleClose = () => {
    setPaymentModalOpen(false);
  };

  useEffect(() => {
    fetchGetPlanDetails();
  }, []);

  const fetchGetPlanDetails = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/plan-list`
      );
      if (status === 200) {
        setLoading(false);
        const planDetails = data?.getPlans;
        setPlanDetails(planDetails);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetCardDetails();
  }, [paymentModalOpen]);

  const fetchGetCardDetails = async () => {
    setCardLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/subscription/get-card`
      );
      if (status === 200) {
        setCardLoader(false);
        const cardDetails = data?.card;
        setCardList(cardDetails);
      }
    } catch (error) {
      setCardLoader(false);
    }
  };

  const handlePaymentModeChange = (e, data) => {
    setSelectedCard(data);
    setPaymentMode(e?.target?.value);
  };

  const handlecardRemove = async id => {
    try {
      const { status, data } = await axiosInstance.delete(
        `/subscription/delete-card/${id}`
      );
      if (status === 200) {
        fetchGetCardDetails();
        setSelectedCard("");
      } else {
        setSelectedCard("");
      }
    } catch (error) {
      setCardLoader(false);
      setSelectedCard("");
    }
  };

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          <AdvertisingBannner src={banner} className="banner" />
        </Box>

        <Box className="subscriptionandpayment-data-header">
          <Box className="bredcrumn-details">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  Home
                </Link>
                <Typography>Subscription</Typography>
              </Breadcrumbs>
            </Box>
          </Box>
          <Typography variant="h1">Subscription</Typography>
        </Box>
        <Box className="sub-card-wrap" id="subscription-plan">
          {loading && (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          )}
          {!loading &&
            (planDetails?.length > 0 ? (
              planDetails?.map(card => {
                return (
                  <Box
                    className={
                      card?.mostPopular
                        ? `subscriptionandpayment-data`
                        : `subscriptionandpayment-data most-popular-plan-data`
                    }
                  >
                    <Box
                      className={
                        card?.mostPopular
                          ? `subscription-plan most-popular-plan`
                          : `subscription-plan`
                      }
                    >
                      <Box className="plan-title-box">
                        <Typography className="plan-title">
                          {card?.name ? card?.name : "Dummy"}
                        </Typography>
                        {card?.purchasedPlan ? (
                          <Button variant="contained" className="current-plan">
                            Your Current Plan
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Box className="plan-devider"></Box>

                      <Box className="plan-info">
                        <Box className="price-box">
                          <Typography className="price">
                            {"$" + card?.amount}
                            <span className="suffix">/month</span>
                          </Typography>
                        </Box>
                        <Box className="des-wrap">
                          {planData?.map(item => {
                            return (
                              <Box className="des-box">
                                <Box className="tic-box">
                                  <span
                                    className={
                                      item?.disabled ? `sub-disable` : ``
                                    }
                                  >
                                    <SubscriptionActive />
                                  </span>
                                </Box>
                                <Box className="des">{item?.des}</Box>
                              </Box>
                            );
                          })}
                        </Box>
                        {card?.purchasedPlan ? (
                          <Button className="blank-btn"></Button>
                        ) : (
                          <Button
                            disableElevation
                            disableFocusRipple
                            disableRipple
                            // type="submit"
                            className="sub-btn"
                            // onClick={() => scrollToBottomSection()}
                            onClick={() => handleModal(card)}
                          >
                            {handleRestrictedUser()
                              ? "Subscribe Now!"
                              : "Sign Up Now"}
                            {/* {card?.btn} */}
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box> No data available</Box>
            ))}
        </Box>
        {/* <Box className="your-detalis-wrap" id="details-section">
          <Box className="details-wrap">
            <Box className="your-selection-wrap">
              <Box className="selection-header">
                <Typography className="header ">Your Selection</Typography>
              </Box>
              <Box className="subscription-type-details">
                <Typography className="subscription-type subscription-text">
                  Subscription type
                </Typography>
                <Box className="plan-deatils">
                  <Typography className="plan-name subscription-text">
                    Most Popular
                  </Typography>
                  <Typography className="plan-price subscription-text">
                    $120/month
                  </Typography>
                </Box>
                <Box className="plan-select">
                  <RadioGroup
                    aria-label="plan"
                    name="plan"
                    className="plan-radioGroup"
                    value={value}
                    onChange={e => handleRadioChange(e)}
                  >
                    <FormControlLabel
                      value="Monthly"
                      control={
                        <Radio
                          size="small"
                          icon={<UnChecked className="radio-icon" />}
                          checkedIcon={<Checked className="radio-icon" />}
                          disableRipple
                        />
                      }
                      label="Billed Monthly"
                    />
                    <FormControlLabel
                      value="Annually"
                      control={
                        <Radio
                          size="small"
                          icon={<UnChecked className="radio-icon" />}
                          checkedIcon={<Checked className="radio-icon" />}
                          disableRipple
                        />
                      }
                      label="Billed Annually (Save 25%)"
                    />
                  </RadioGroup>
                </Box>
                <Box className="total-details ">
                  <Typography className="total-name subscription-text">
                    Today's total
                  </Typography>
                  <Typography className="total-price subscription-text">
                    $120
                  </Typography>
                </Box>
              </Box>
              <Box className="update-subscription">
                <Typography
                  className="update-subscription-type"
                  onClick={() => scrollToTopSection()}
                >
                  Update subscription type
                </Typography>
              </Box>
            </Box>
            <Box className="your-details-wrap">
              <Typography className="details">Enter your details</Typography>
              <Box className="personal-details">
                <Typography className="your-account">
                  If already have an account. Subscribe with your{" "}
                  <span className="existing-account">existing account</span>.
                </Typography>
                <Formik
                  initialValues={{
                    email: "",
                    firstname: "",
                    lastname: "",
                    password: "",
                    phonenumber: "",
                    documentsRead: false
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .nullable()
                      .email(localesData?.validation?.valid_email)
                      .max(255)
                      .required(localesData?.validation?.required_message),
                    firstname: Yup.string()
                      .nullable()
                      .required(localesData?.validation?.required_message),
                    lastname: Yup.string()
                      .nullable()
                      .required(localesData?.validation?.required_message),
                    password: Yup.string()
                      .required(localesData?.validation?.required_message)
                      .min(6, localesData?.validation?.passowrd_length),
                    phonenumber: Yup.string()
                      .nullable()
                      .matches(
                        phoneRegExp,
                        localesData?.validation?.number_valid
                      )
                      .required(localesData?.validation?.required_message)
                      .min(10, localesData?.validation?.NUMBER_MIN_MAX_LENGTH)
                      .max(12, localesData?.validation?.NUMBER_MIN_MAX_LENGTH),
                    documentsRead: Yup.boolean().oneOf(
                      [true],
                      "Checkbox is required"
                    )
                  })}
                  onSubmit={() => handleModal()}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            {localesData?.register?.email}
                            <span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="text"
                            name="email"
                            error={Boolean(touched?.email && errors?.email)}
                            helperText={touched?.email ? errors?.email : ""}
                            onChange={handleChange}
                            value={values?.email}
                          />
                        </Box>
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            {localesData?.register?.first_name}
                            <span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="text"
                            name="firstname"
                            error={Boolean(
                              touched?.firstname && errors?.firstname
                            )}
                            helperText={
                              touched?.firstname ? errors?.firstname : ""
                            }
                            onChange={handleChange}
                            value={values?.firstname}
                          />
                        </Box>
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            {localesData?.register?.last_name}
                            <span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="text"
                            name="lastname"
                            error={Boolean(
                              touched?.lastname && errors?.lastname
                            )}
                            helperText={
                              touched?.lastname ? errors?.lastname : ""
                            }
                            onChange={handleChange}
                            value={values?.lastname}
                          />
                        </Box>
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            Create password<span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="text"
                            name="password"
                            error={Boolean(
                              touched?.password && errors?.password
                            )}
                            helperText={
                              touched?.password ? errors?.password : ""
                            }
                            onChange={handleChange}
                            value={values?.password}
                          />
                        </Box>
                        <Box className="personal-info">
                          <Typography className="textfield-text">
                            {localesData?.register?.phonenumber}
                            <span className="star">*</span>:
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            className="details-textfield"
                            type="number"
                            name="phonenumber"
                            error={Boolean(
                              touched?.phonenumber && errors?.phonenumber
                            )}
                            helperText={
                              touched?.phonenumber ? errors?.phonenumber : ""
                            }
                            onChange={handleChange}
                            value={values?.phonenumber}
                          />
                        </Box>
                        <Box className="checkBox-wrap">
                          <FormControlLabel
                            className="documentsRead-check"
                            control={
                              <Checkbox
                                className="documentsRead-checkbox"
                                checked={values?.documentsRead}
                                onChange={() =>
                                  setFieldValue(
                                    "documentsRead",
                                    !values?.documentsRead
                                  )
                                }
                                color="primary"
                              />
                            }
                            label={
                              <Typography>
                                I have read and agree to the{" "}
                                <span>Privacy Policy</span> &{" "}
                                <span>Terms and Conditions</span>
                              </Typography>
                            }
                            error={
                              touched?.documentsRead && errors?.documentsRead
                            }
                          />
                          {touched?.documentsRead && errors?.documentsRead && (
                            <Typography variant="body2" color="error">
                              {errors?.documentsRead}
                            </Typography>
                          )}
                        </Box>
                        <Box className="payment-btn-wrap">
                          <Button
                            variant="contained"
                            type="submit"
                            className="payment-btn"
                          >
                            Continue for payment
                          </Button>
                        </Box>
                      </Box>
                      {setformvalues(values)}
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Box>
        </Box> */}
        <OurPartner />
      </Box>
      {/* <DialogBox
        open={paymentModalOpen}
        handleClose={handleClose}
        title={false}
        className="payment-modal"
        content={<>payment</>}
        closeIcon={true}
      /> */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={paymentModalOpen}
        className="payment-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            Payment
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            className="close-icon"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="payment-details">
          <Box className="payment-select">
            <RadioGroup
              aria-label="payment"
              name="payment"
              className="payment-radioGroup"
              value={Number(paymentMode)}
            >
              {cardLoader && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!cardLoader &&
                (cardList?.length > 0 ? (
                  cardList?.map(item => {
                    return (
                      <>
                        <FormControlLabel
                          key={item.id}
                          className="save-card"
                          value={Number(item.id)}
                          onChange={e => handlePaymentModeChange(e, item)}
                          control={
                            <Radio
                              size="small"
                              icon={<UnChecked className="radio-icon" />}
                              checkedIcon={<Checked className="radio-icon" />}
                              disableRipple
                            />
                          }
                          label=<Box className="save-payment-card-label">
                            <Box>
                              <Typography className="bold">
                                {item?.brand}
                                <span>****{item?.last4}</span>
                              </Typography>
                              <Typography>{item?.cardHolderName}</Typography>
                              <Typography>Expiry - {item?.cardExp}</Typography>
                            </Box>
                            <Box>
                              <span onClick={() => handlecardRemove(item?.id)}>
                                Remove
                              </span>
                            </Box>
                          </Box>
                        />
                      </>
                    );
                  })
                ) : (
                  <></>
                ))}
              {/* <FormControlLabel
                value="Credit"
                control={
                  <Radio
                    size="small"
                    icon={<UnChecked className="radio-icon" />}
                    checkedIcon={<Checked className="radio-icon" />}
                    disableRipple
                  />
                }
                label={
                  <Box className="payment-label">
                    <Typography className="label">
                      Credit Or Debit Card
                    </Typography>
                    <Box className="card-section">
                      <img src={MasterCard} alt="card" />
                      <img src={Visa} alt="card" />
                      <img src={AmericanExpress} alt="card" />
                    </Box>
                  </Box>
                }
              /> */}
              {/* <FormControlLabel
                value="PayPal"
                control={
                  <Radio
                    size="small"
                    icon={<UnChecked className="radio-icon" />}
                    checkedIcon={<Checked className="radio-icon" />}
                    disableRipple
                  />
                }
                label=<Box className="payment-label">
                  <Typography className="label">PayPal</Typography>
                  <Box className="card-section">
                    <img src={PayPal} alt="card" />
                  </Box>
                </Box>
              /> */}
            </RadioGroup>
          </Box>
          <Box className="card-details">
            <Formik
              initialValues={{
                cardname: selectedCard ? selectedCard?.cardHolderName : "",
                cardNumber: "",
                expirationDate: selectedCard
                  ? moment(selectedCard?.cardExp).format("MM/YYYY")
                  : "",
                cvv: ""
              }}
              enableReinitialize={true}
              validationSchema={Yup.object().shape({
                cardname: Yup.string().required(
                  localesData?.validation?.required_message
                ),
                cardNumber: Yup.string()
                  .min(19, "Card number must be at most 16 characters")
                  .max(19, "Card number must be at most 16 characters")
                  .required("Card number is required"),
                expirationDate: Yup.string()
                  .required("Expiration date is required")
                  .typeError("Not a valid expiration date. Example: MM/YYYY")
                  .max(7, "Not a valid expiration date. Example: MM/YYYY")
                  .matches(
                    /([0-9]{2})\/([0-9]{4})/,
                    "Not a valid expiration date. Example: MM/YYYY"
                  )
                  .test(
                    "is-future",
                    "Card expiration date should be in the future",
                    function(value) {
                      if (!value) return false;
                      const currentDate = new Date();
                      const [month, year] = value.split("/");
                      const cardExpirationDate = new Date(
                        parseInt(year),
                        parseInt(month) - 1
                      );
                      return cardExpirationDate > currentDate;
                    }
                  )
                  .test(
                    "not-over-100",
                    "Card expiration date should not exceed 100 years in the future",
                    function(value) {
                      if (!value) return false;
                      const currentDate = new Date();
                      const [month, year] = value.split("/");
                      const cardExpirationDate = new Date(
                        parseInt(year),
                        parseInt(month) - 1
                      );
                      return (
                        cardExpirationDate.getFullYear() <=
                        currentDate.getFullYear() + 100
                      );
                    }
                  ),
                cvv: Yup.string()
                  .min(3)
                  .max(3)
                  .required("CVV number is required")
              })}
              // onSubmit={async requestData => {
              //   console.log("requestData", requestData);

              // }}
              onSubmit={async (requestData, { resetForm }) => {
                setSubscribeLoading(true);

                const expirationDate = requestData?.expirationDate;
                const parts = expirationDate.split("/");
                let formattedDate = "";
                if (parts.length === 2) {
                  const month = parts[0];
                  const year = parts[1];
                  formattedDate = `${year}-${month}`;
                }
                // let cardPayload = {
                //   card: {
                //     card_holder_name: requestData?.cardname,
                //     card_number: requestData?.cardNumber?.replace(/\s/g, ""),
                //     card_exp: formattedDate,
                //     cvv: requestData?.cvv
                //   }
                // };
                let payload = {
                  plan_id: planId,
                  plateform: "web",
                  card: {
                    card_holder_name: requestData?.cardname,
                    card_number: requestData?.cardNumber?.replace(/\s/g, ""),
                    card_exp: formattedDate,
                    cvv: requestData?.cvv
                  }
                };
                const isUpgrade = planDetails?.some(
                  item => item?.purchasedPlan === true
                );

                try {
                  let passApi = isUpgrade
                    ? "/subscription/upgrade-plan"
                    : "/subscription/subscribe-plan";
                  let method = isUpgrade ? "put" : "post";
                  const { status, data } = await axiosInstance[method](
                    passApi,
                    payload
                  );
                  if (status === 200) {
                    // if (cardsave) {
                    //   setCardSaveLoading(true);
                    //   try {
                    //     const { status, data } = await axiosInstance.post(
                    //       "/subscription/create-card",
                    //       cardPayload
                    //     );
                    //     if (status === 200) {
                    //       setCardSaveLoading(false);
                    //       setCardSave(false);
                    //     } else {
                    //       setCardSaveLoading(false);
                    //       setCardSave(false);
                    //     }
                    //   } catch (error) {
                    //     setCardSaveLoading(false);
                    //     setCardSave(false);
                    //   }
                    // }
                    setSubscribeLoading(false);
                    toast.success(data?.message, {
                      position: "bottom-center",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      theme: "colored"
                    });
                    setCardSave(false);
                    setPaymentModalOpen(false);
                    setSelectedCard("");
                    fetchGetPlanDetails();
                    fetchSubscriptionPaymentData();
                    let timer = setTimeout(
                      () => resetForm(requestData),
                      // setInterestedData([]),

                      3000
                    );
                  } else {
                    setSubscribeLoading(false);
                    setPaymentModalOpen(false);
                    setCardSave(false);
                    setSelectedCard("");
                  }
                } catch (error) {
                  setSubscribeLoading(false);
                  setCardSave(false);
                  setSelectedCard("");
                  toast.error(error?.response?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored"
                  });
                  setPaymentModalOpen(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box className="card-form">
                    <Box className="card-info mb-19">
                      <Typography className="textfield-text">
                        Card Number<span className="star">*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield"
                        name="cardNumber"
                        error={Boolean(
                          touched?.cardNumber && errors?.cardNumber
                        )}
                        helperText={touched?.cardNumber && errors?.cardNumber}
                        inputProps={{ maxLength: 19 }}
                        onChange={e => {
                          handleChange(e);
                          // setCardUpdate({
                          //   ...cardUpdate,
                          //   card_token: e.target.value
                          // });
                          setFieldValue(
                            "cardNumber",
                            e?.target?.value
                              .replace(/[^\dA-Z*]/g, "")
                              .replace(/(.{4})/g, "$1 ")
                              .trim()
                          );
                        }}
                        value={values?.cardNumber}
                        onKeyPress={e => {
                          if (!/[0-9 ]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onBlur={e => {
                          handleBlur(e);
                          // updateCardDetails(
                          //   "cardNumber",
                          //   values?.cardNumber
                          //     .replace(/[^\dA-Z*]/g, "")
                          //     .replace(/(.{4})/g, "$1 ")
                          //     .trim()
                          // );
                        }}
                      />
                    </Box>
                    <Box className="date-cvv-wrap mb-19">
                      <Box className="card-info date-card-info">
                        <Typography className="textfield-text">
                          Expiry Date<span className="star">*</span>
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          className="details-textfield"
                          placeholder="mm/yyyy"
                          error={Boolean(
                            touched?.expirationDate && errors?.expirationDate
                          )}
                          helperText={
                            touched?.expirationDate && errors?.expirationDate
                          }
                          name="expirationDate"
                          value={values?.expirationDate}
                          onChange={e => {
                            setFieldValue(
                              "expirationDate",
                              e?.target?.value
                                .replace(/[^0-9]/g, "") // To allow only numbers
                                .replace(/^([2-9])$/g, "0$1") // To handle 3 > 03
                                .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2") // 13 > 01/3
                                .replace(/^0{1,}/g, "0") // To handle 00 > 0
                                .replace(
                                  /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
                                  "$1/$2"
                                ) // To handle 113 > 11/3
                            );
                            // setCardUpdate({
                            //   ...cardUpdate,
                            //   card_exp: e.target.value
                            // });
                          }}
                          inputProps={{ maxLength: 7 }}
                          onBlur={e => {
                            handleBlur(e);
                            // updateCardDetails(
                            //   "expirationDate",
                            //   values?.expirationDate
                            // );
                          }}
                        />
                      </Box>
                      <Box className="card-info cvv-card-info">
                        <Typography className="textfield-text cvv-text">
                          CVV <span className="star">*</span>
                          <img src={Cvvimage} alt="cvv" />
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          className="details-textfield"
                          name="cvv"
                          type="password"
                          error={Boolean(touched?.cvv && errors?.cvv)}
                          helperText={touched?.cvv && errors?.cvv}
                          value={values.cvv}
                          onChange={e => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                            // setCardUpdate({
                            //   ...cardUpdate,
                            //   cvv: e.target.value
                            // });
                          }}
                          inputProps={{ maxLength: 3 }}
                          onBlur={e => {
                            handleBlur(e);
                            // updateCardDetails("cvv", values?.cvv);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className="card-info mb-19">
                      <Typography className="textfield-text">
                        Name on Card<span className="star">*</span>
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textfield"
                        error={Boolean(touched.cardname && errors.cardname)}
                        helperText={touched.cardname && errors.cardname}
                        name="cardname"
                        value={values.cardname}
                        onChange={e => {
                          handleChange(e);
                          // setCardUpdate({
                          //   ...cardUpdate,
                          //   card_holder_name: e.target.value
                          // });
                        }}
                        onBlur={e => {
                          handleBlur(e);
                          // updateCardDetails("cardname", values?.cardname);
                        }}
                      />
                    </Box>
                    <Box className="checkBox-wrap">
                      <FormControlLabel
                        className="documentsRead-check"
                        control={
                          <Checkbox
                            className="documentsRead-checkbox"
                            checked={cardsave}
                            icon={<CheckboxUnChecked className="radio-icon" />}
                            checkedIcon={
                              <CheckBoxChecked className="radio-icon" />
                            }
                            onChange={e => handleCardSave(e)}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>Save the card for future use</Typography>
                        }
                      />
                    </Box>
                    <Box className="total-amount">
                      <Typography className="bold">Total Cost:</Typography>
                      <Typography>
                        {"$" + Number(planPrice)?.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box className="subscribe-wrap-btn">
                      <Button
                        variant="contained"
                        type="submit"
                        className="subscribe-btn"
                        disabled={subscribeLoading}
                      >
                        {subscribeLoading ? "Loading..." : "Subscribe"}
                      </Button>
                    </Box>
                    <Box className="subscribe-note">
                      <Typography>
                        Plan renews automatically. You can change or cancel
                        anytime
                      </Typography>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default SubscriptionAndPaymentPage;
