import React, { useState, useEffect, useContext } from "react";
import { IntlContext } from "src/App";
import { useLocation } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import Horse from "../../../assets/images/dark_horse.svg";
import Greys from "../../../assets/images/dar-greyhound.svg";
import Harnes from "../../../assets/images/dark_harness.svg";
import CancelIcon from "@material-ui/icons/Cancel";
import _ from "lodash";
import "./blackbook.scss";
const newTabData = [
  {
    id: 0,
    name: "Runner"
  },
  { id: 1, name: "Jockey" },
  {
    id: 2,
    name: "Trainer"
  },
  {
    id: 3,
    name: "Track"
  }
];
const BBSearch = ({ handleClose, open, closeIcon, handleBlackBookModal }) => {
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [isLoading, setisLoading] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [TrackListData, setTrackListData] = useState([]);
  const [EventPage, setEventPage] = useState(0);
  const [EventCount, setEventCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const fetchTrackList = async inputValue => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrackList?name=${inputValue}&limit=50&offset=0`
      );
      if (status === 200) {
        let count = data?.count / 50;
        setEventCount(Math.ceil(count));
        const sortedData = data?.result?.sort((a, b) => {
          return a?.name?.trim().localeCompare(b?.name?.trim());
        });
        setTrackListData(sortedData);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };
  const handleOnScrollBottomEvents = async EventPage => {
    if (EventCount !== (EventCount == 1 ? 1 : Math.ceil(EventPage / 50))) {
      try {
        const passApi =
          tabvalue === 3
            ? `statistics/getTrackList?name=${searchInput}&limit=50&offset=${EventPage}`
            : tabvalue === 1
            ? `statistics/getJockeyList?name=${searchInput}&limit=50&offset=${EventPage}`
            : tabvalue === 2
            ? `statistics/getTrainerList?name=${searchInput}&limit=50&offset=${EventPage}`
            : `statistics/getRunnerProfile?name=${searchInput}&limit=50&offset=${EventPage}`;
        const { status, data } = await axiosInstance.get(passApi);
        if (status === 200) {
          setEventPage(EventPage);
          let uniqbydata = _.uniqBy(
            [...TrackListData, ...data?.result],
            function(e) {
              return e?.id;
            }
          );
          const sortedData = uniqbydata?.sort((a, b) => {
            return a?.name?.trim().localeCompare(b?.name?.trim());
          });
          setTrackListData(sortedData);
        } else {
        }
      } catch (err) {}
    }
  };
  const fetchJockeyList = async inputValue => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getJockeyList?name=${inputValue}&limit=50&offset=0`
      );
      if (status === 200) {
        const sortedData = data?.result?.sort((a, b) => {
          return a?.name?.trim().localeCompare(b?.name?.trim());
        });
        setTrackListData(sortedData);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };
  const fetchTrainerList = async inputValue => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrainerList?name=${inputValue}&limit=50&offset=0`
      );
      if (status === 200) {
        const sortedData = data?.result?.sort((a, b) => {
          return a?.name?.trim()?.localeCompare(b?.name?.trim());
        });
        setTrackListData(sortedData);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };
  const fetchRunnerList = async inputValue => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getRunnerProfile?name=${inputValue}&limit=50&offset=0`
      );
      if (status === 200) {
        const sortedData = data?.result?.sort((a, b) => {
          return a?.name?.trim()?.localeCompare(b?.name?.trim());
        });
        setTrackListData(sortedData);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };
  const fetchSportIcon = sportId => {
    const sportIcon = sportId === 1 ? Horse : sportId === 3 ? Greys : Harnes;
    return (
      <span style={{ paddingLeft: "5px" }}>
        <img
          src={sportIcon}
          alt={
            sportId === 1 ? "Horse" : sportId === 3 ? "Greyhound" : "Harness"
          }
        />
      </span>
    );
  };
  useEffect(() => {
    if (open) {
      fetchRunnerList("");
    }
  }, [open]);

  useEffect(() => {
    setEventPage(0);
    setSearchInput("");
    if (tabvalue === 0) {
      fetchRunnerList("");
    } else if (tabvalue === 1) {
      fetchJockeyList("");
    } else if (tabvalue === 2) {
      fetchTrainerList("");
    } else if (tabvalue === 3) {
      fetchTrackList("");
    }
  }, [tabvalue]);

  const handleClearClick = () => {
    setSearchInput("");
    if (tabvalue === 0) {
      fetchRunnerList("");
    } else if (tabvalue === 1) {
      fetchJockeyList("");
    } else if (tabvalue === 2) {
      fetchTrainerList("");
    } else if (tabvalue === 3) {
      fetchTrackList("");
    }
  };
  return (
    <Dialog
      className="blackBook-dialog blackbook-wrap"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {closeIcon && (
          <IconButton className="dialog-close" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        )}
        <Box className="blackBook-content-wrap">
          <h1>Search To Add Blackbook</h1>
          <Box className="tab-search-section modal-section">
            <Box className="team-sports-tab">
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons="off"
                indicatorColor="primary"
                textColor="primary"
                className="teamsport-tab-detail"
              >
                {newTabData?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={item?.name}
                        value={item?.id}
                        className={item?.id === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>
            <Box>
              <TextField
                placeholder={localesData?.BLACKBOOK?.SEARCH_PLACEHOLDER}
                variant="outlined"
                type="text"
                name="search"
                className="search_input"
                value={searchInput}
                onChange={e => {
                  setSearchInput(e.target.value);
                  if (tabvalue === 3) {
                    fetchTrackList(e.target.value);
                  } else if (tabvalue === 1) {
                    fetchJockeyList(e.target.value);
                  } else if (tabvalue === 2) {
                    fetchTrainerList(e.target.value);
                  } else if (tabvalue === 0) {
                    fetchRunnerList(e.target.value);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchInput && (
                        <IconButton
                          onClick={() => handleClearClick()}
                          edge="end"
                          style={{ minWidth: "unset" }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Box>
          {isLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <Box className="tracklist-container">
              <ul className="trackprofilelist-wrap">
                {TrackListData?.length > 0 ? (
                  <div
                    id="scrollableDiv"
                    style={{ height: "100vh", overflow: "auto" }}
                  >
                    <InfiniteScroll
                      dataLength={TrackListData?.length}
                      next={() => handleOnScrollBottomEvents(EventPage + 50)}
                      hasMore={EventCount !== Math.ceil(EventPage / 50 + 1)}
                      loader={
                        <div className="allsport-loader-center ">
                          <Loader />
                        </div>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {TrackListData?.map(obj => {
                        return (
                          <>
                            <li
                              className="trackprofile-list"
                              style={{
                                paddingBottom: tabvalue !== 3 ? "10px" : "4px",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <span
                                onClick={() =>
                                  handleBlackBookModal(
                                    obj?.name,
                                    obj?.id,
                                    tabvalue === 0
                                      ? "Runner"
                                      : tabvalue === 1
                                      ? "Jockey"
                                      : tabvalue === 2
                                      ? "Trainer"
                                      : "Track"
                                  )
                                }
                              >
                                {obj?.name}
                                {obj?.State ? `, ${obj?.State?.state}` : ""}
                                {obj?.Country
                                  ? `, ${obj?.Country?.country}`
                                  : ""}
                              </span>
                              {tabvalue === 3
                                ? fetchSportIcon(obj?.sportId)
                                : ""}
                            </li>
                          </>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                ) : (
                  <>
                    <Box style={{ border: "transparent" }}>
                      <Box
                        style={{
                          textAlign: "center",
                          border: "transparent"
                        }}
                      >
                        No Data Available
                      </Box>
                    </Box>
                  </>
                )}
              </ul>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
    </Dialog>
  );
};

export default BBSearch;
