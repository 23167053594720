import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  withStyles,
  makeStyles,
  Tooltip,
  Collapse
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Config } from "../../../../../helpers/context/config";
import { IntlContext } from "src/App";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useParams } from "react-router";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    minWidth: "457px",
    // boxShadow: "inset -2px 2px 6px 0px #ebebeb",
    backgroundColor: "#ffffff",
    "@media(max-width: 1090px)": {
      minWidth: "300px"
      // boxShadow: "inset -2px 2px 6px 0px #ebebeb"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px"
      // boxShadow: "inset -2px 2px 6px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    minWidth: "457px",
    // boxShadow: "inset -2px 2px 6px 0px #ebebeb",
    backgroundColor: "#ffffff",
    "@media(max-width: 1090px)": {
      minWidth: "300px"
      // boxShadow: "inset -2px 2px 6px 0px #ebebeb"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px"
      // boxShadow: "inset -2px 2px 6px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const InfoOddsComparison = ({
  pageHeadingData,
  individualTeamData,
  MarketName,
  BookkeeperData
}) => {
  const classes = useStyles();
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const BetslipData = useSelector(state => state?.reduxData?.BetSlipData);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [MobileBestOpenHome, setMobileBestOpenHome] = useState(-1);
  const [MobileBestOpenAway, setMobileBestOpenAway] = useState(-1);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const renderTableCell = (BookKeeperId, fullData) => {
    return (
      <>
        <TableCell className={classes.head}>
          {oddsicon(BookKeeperId, "header", null, fullData)}
        </TableCell>
      </>
    );
  };

  const oddsicon = (BookKeeperId, type, item, fullData) => {
    const Identifiers = fullData?.SoccerIdentifiers;
    const newData = Identifiers?.map(obj => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        item => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });
    const filteredData = newData
      ?.filter?.(obj => obj?.BookKeeperId === BookKeeperId)
      ?.filter(obj => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.filter(
        ele => ele?.providerMarketId && ele?.providerParticipantId
      );
      return (
        <a
          href={
            Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link bookmaker-wrap"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            // onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
            alt="Odds Icon"
          />
        </a>
      );
    } else {
      return (
        <a
          href={
            filteredData?.length > 0 && filteredData?.[0]?.url
              ? filteredData?.[0]?.url + `?Referrer=SmartB`
              : iconData?.affiliate_link
          }
          target="_blank"
          rel="noopener noreferrer"
          className="bookmaker-wrap"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            // onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
            alt="Odds Icon"
          />
        </a>
      );
    }
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const fetchSeeAllTeamOddsvalue = (data, ProviderId, team, type) => {
    let allTeamOdds = data?.SoccerBetOffers?.[0]?.SoccerOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return item?.SoccerTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return item?.SoccerTeamId == data?.awayTeamId;
    });
    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;

    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter(ele => {
        let LabelName = ele?.SoccerOddLabel?.displayName
          ? ele?.SoccerOddLabel?.displayName
          : ele?.SoccerOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("over") ||
          name?.toLowerCase()?.includes("totalover")
        );
      });
      let UnderData = allTeamOdds?.filter(ele => {
        let LabelName = ele?.SoccerOddLabel?.displayName
          ? ele?.SoccerOddLabel?.displayName
          : ele?.SoccerOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("under") ||
          name?.toLowerCase()?.includes("totalunder")
        );
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    if (type === "odds") {
      if (teamData) {
        let odds = teamData
          ?.map(obj => {
            if (obj?.BookKeeperId === ProviderId) {
              return { odds: obj?.odd, bookkeeper: obj?.BookKeeperId };
            }
          })
          ?.filter(x => x !== undefined);
        if (odds?.length > 0) {
          return fetchClickableOdds(
            odds?.[0]?.odds,
            odds?.[0]?.bookkeeper,
            "header",
            teamData,
            data,
            "betslip"
          );
          // return odds?.[0]?.odds;
        } else {
          return (
            <Tooltip
              title="No odds available"
              className="odds-tooltip"
              placement="top"
            >
              <span className="odds-btn">
                <span className="no-odds">NOA</span>
              </span>
            </Tooltip>
          );
        }
      } else {
        return (
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="odds-btn">
              <span className="no-odds">NOA</span>
            </span>
          </Tooltip>
        );
      }
    } else if (type === "spreadpoints") {
      if (teamData) {
        let odds = teamData
          ?.map(obj => {
            if (obj?.BookKeeperId === ProviderId) {
              let LabelName = obj?.SoccerOddLabel?.displayName
                ? obj?.SoccerOddLabel?.displayName
                : obj?.SoccerOddLabel?.name;
              let name = LabelName ? LabelName : obj?.label;
              return obj?.point
                ? MarketName?.toLowerCase()?.includes("total")
                  ? name + " " + obj?.point
                  : obj?.point
                : "";
            }
          })
          ?.filter(x => x !== undefined);
        if (odds?.length > 0) {
          return odds?.[0];
        } else {
        }
      } else {
      }
    }
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    const oddsInfo = BookKeeperData?.[0];
    let isAdded = BetslipData?.some(el => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

    //   const Odds = Bet365Data?.filter(
    //     ele => ele?.providerMarketId && ele?.providerParticipantId
    //   );
    //   return (
    //     <a
    //       href={
    //         Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //           ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //           : `${iconData?.affiliate_link}`
    //       }
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="odds-link"
    //     >
    //       {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
    //     </a>
    //   );
    // } else {
    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      <>
        {odds ? (
          <span
            className="odds-btn cursor-pointer"
            onClick={() => {
              IsBetslip === "nobetslip"
                ? handleBookkeeperCounter(BookKeeperId, type)
                : isAdded
                ? handleDeleteBetslip(BookKeeperId, item, fulldata)
                : handleAddToBetSlip(
                    BookKeeperId,
                    type,
                    item,
                    fulldata,
                    Isscore
                  );
            }}
          >
            <span
              className={
                isAdded && IsBetslip === "betslip"
                  ? "betslip-added odds-link"
                  : "odds-link"
              }
            >
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : (
          <span className="odds-btn">NOA</span>
        )}
      </>
      // {/* <span
      //   className="odds-link"
      //   onClick={() => handleAddToBetSlip(BookKeeperId, type, item, fulldata)}
      //   //  onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
      // >
      //   {Number(odds).toFixed(2)}
      // </span> */}

      // </a>
    );
    // }
  };

  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: 8
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleDeleteBetslip = async (BookKeeperId, item, fulldata) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    const oddsInfo = BookKeeperData?.[0];
    let delslip = BetslipData?.filter(el => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = BetslipData?.filter(obj => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };

  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    item,
    fulldata,
    Isscore
  ) => {
    handleBookkeeperCounter(BookKeeperId, type);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      if (!Isscore) {
        const BookKeeperData = item?.filter(
          obj => obj?.BookKeeperId === BookKeeperId
        );
        const oddsInfo = BookKeeperData?.[0];

        let passTeamId = oddsInfo?.SoccerTeamId
          ? oddsInfo?.SoccerTeamId
          : oddsInfo?.SoccerOddLabelId
          ? oddsInfo?.SoccerOddLabelId
          : oddsInfo?.SoccerPlayerId;
        let payload = {
          sportId: fulldata?.SportId,
          eventId: fulldata?.id,
          teamId: passTeamId,
          bookKeeperId: BookKeeperId,
          betOfferId: oddsInfo?.id
        };
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored"
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  return (
    <>
      {screenWidth >= 1023 ? (
        <Box className="teamsports-seeall-tablewrap">
          <TableContainer
            className="table-seeall-container"
            id="individual-team-table"
          >
            <Table style={{ minWidth: 700 }} className="individual-table">
              <TableHead className="individual-team-head">
                <TableRow>
                  <StickyTableCell className={classes.head}>
                    <Box className="d-flex align-center individual-head-wrap">
                      {individualTeamData?.SoccerTournament ? (
                        <>
                          <Box className="table-indicator-wrap">
                            <span>
                              {individualTeamData?.SoccerTournament?.name}
                            </span>
                          </Box>{" "}
                          |
                        </>
                      ) : (
                        <></>
                      )}
                      <Box>
                        {`${
                          individualTeamData?.marketLength
                            ? individualTeamData?.marketLength
                            : 0
                        } Markets`}
                      </Box>
                    </Box>
                  </StickyTableCell>
                  {pageHeadingData?.map(id =>
                    renderTableCell(id?.BookKeeperId, individualTeamData)
                  )}
                  <TableCell className={classes.head}></TableCell>
                </TableRow>
              </TableHead>
              {individualTeamData ? (
                <TableBody className="individual-team-body">
                  <TableRow>
                    <StickyTableCell className="time-market">
                      {fetchDayName(individualTeamData?.startTime)}{" "}
                      {moment
                        .utc(individualTeamData?.startTime)
                        .local()
                        .format("DD/MM/YYYY")}{" "}
                      |{" "}
                      {moment
                        .utc(individualTeamData?.startTime)
                        .local()
                        .format("hh:mm A")}{" "}
                    </StickyTableCell>
                  </TableRow>
                  {/* {!isMultipleTeam ? (
                    <> */}
                  <TableRow className="individual-team-row">
                    <StickyTableCell
                      className={`${classes.cell} body-runner-name`}
                    >
                      <Box className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(individualTeamData, "hometeam")}
                        </span>
                        <span className="team-name">
                          {" "}
                          {individualTeamData?.homeTeam?.name
                            ? individualTeamData?.homeTeam?.name
                            : ""}
                        </span>
                      </Box>
                    </StickyTableCell>
                    {pageHeadingData?.length > 0 ? (
                      pageHeadingData?.map((id, index) => (
                        <TableCell
                          className={`${classes.cell} individual-tablecell`}
                          key={index}
                        >
                          <Box className="odds-point">
                            {fetchSeeAllTeamOddsvalue(
                              individualTeamData,
                              id?.BookKeeperId,
                              MarketName?.toLowerCase()?.includes("total")
                                ? "over"
                                : "hometeam",
                              "spreadpoints"
                            )}
                          </Box>
                          <span className="odds">
                            {fetchSeeAllTeamOddsvalue(
                              individualTeamData,
                              id?.BookKeeperId,
                              MarketName?.toLowerCase()?.includes("total")
                                ? "over"
                                : "hometeam",
                              "odds"
                            )}
                          </span>
                        </TableCell>
                      ))
                    ) : (
                      <TableCell className={classes.cell}>
                        <span className="odds">
                          <span className="odds-btn">NOA</span>
                        </span>
                      </TableCell>
                    )}
                    <TableCell className={classes.cell}></TableCell>
                  </TableRow>
                  <TableRow className="individual-team-row">
                    <StickyTableCell
                      className={`${classes.cell} body-runner-name`}
                    >
                      <Box className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(individualTeamData, "awayteam")}
                        </span>
                        <span className="team-name">
                          {" "}
                          {individualTeamData?.awayTeam?.name
                            ? individualTeamData?.awayTeam?.name
                            : ""}
                        </span>
                      </Box>
                    </StickyTableCell>
                    {pageHeadingData?.length > 0 ? (
                      pageHeadingData?.map((id, index) => (
                        <TableCell
                          className={`${classes.cell} individual-tablecell`}
                          key={index}
                        >
                          <Box className="odds-point">
                            {fetchSeeAllTeamOddsvalue(
                              individualTeamData,
                              id?.BookKeeperId,
                              MarketName?.toLowerCase()?.includes("total")
                                ? "under"
                                : "awayteam",
                              "spreadpoints"
                            )}
                          </Box>
                          <span className="odds">
                            {fetchSeeAllTeamOddsvalue(
                              individualTeamData,
                              id?.BookKeeperId,
                              MarketName?.toLowerCase()?.includes("total")
                                ? "under"
                                : "awayteam",
                              "odds"
                            )}
                          </span>
                        </TableCell>
                      ))
                    ) : (
                      <TableCell
                        className={`${classes.cell} individual-tablecell`}
                      >
                        <span className="odds">
                          <span className="odds-btn">NOA</span>
                        </span>
                      </TableCell>
                    )}
                    <TableCell className={classes.cell}></TableCell>
                  </TableRow>
                  {/* </>
                  ) : (
                    fetchSeeAllMultipleTeamData(individualTeamData)
                  )} */}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Box style={{ textAlign: "center" }}>
                        {" "}
                        No Data Available
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box className="individual-table-mobileview">
          <Box className="d-flex align-center individual-head-wrap">
            {individualTeamData?.SoccerTournament && (
              <Box className="table-indicator-wrap">
                <span> {individualTeamData?.SoccerTournament?.name}</span>
              </Box>
            )}
          </Box>

          {individualTeamData ? (
            <>
              <Box className="info-date-markets-section">
                {fetchDayName(individualTeamData?.startTime)}{" "}
                {moment
                  .utc(individualTeamData?.startTime)
                  .local()
                  .format("DD/MM/YYYY")}{" "}
                |{" "}
                {moment
                  .utc(individualTeamData?.startTime)
                  .local()
                  .format("hh:mm A")}{" "}
                |{" "}
                {`${
                  individualTeamData?.marketLength
                    ? individualTeamData?.marketLength
                    : 0
                } Markets`}
              </Box>
              {/* {!isMultipleTeam ? (
                      <> */}
              <Box className="home-team-wrap">
                <Box className="team-wrap">
                  <span className="team-img-wrap">
                    {fetchTeamlogo(individualTeamData, "hometeam")}
                  </span>
                  <span className="team-name mr-6">
                    {" "}
                    {individualTeamData?.homeTeam?.name
                      ? individualTeamData?.homeTeam?.name
                      : ""}
                  </span>
                </Box>
                <Box className="mobile-best5odds-wrap">
                  <Box className="mobile-best5odds-select">
                    <Typography>{localesData?.sport?.SEE_ALL_ODDS}</Typography>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setMobileBestOpenHome(MobileBestOpenHome === 0 ? -1 : 0)
                      }
                    >
                      {MobileBestOpenHome === 0 ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Box>
                    <Collapse
                      in={MobileBestOpenHome === 0}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableContainer>
                        <Table className="individual-team-mobile-table">
                          <TableHead></TableHead>
                          <TableBody>
                            <TableRow className="individual-team-mobile-row">
                              {pageHeadingData?.length > 0 ? (
                                pageHeadingData?.map((id, index) => (
                                  <TableCell
                                    className={`${classes.cell} individual-tablecell`}
                                    key={index}
                                  >
                                    <Box className="odds-point">
                                      {fetchSeeAllTeamOddsvalue(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "over"
                                          : "hometeam",
                                        "spreadpoints"
                                      )}
                                    </Box>
                                    <span className="odds">
                                      {fetchSeeAllTeamOddsvalue(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "over"
                                          : "hometeam",
                                        "odds"
                                      )}
                                    </span>
                                  </TableCell>
                                ))
                              ) : (
                                <TableCell
                                  className={`${classes.cell} individual-tablecell`}
                                >
                                  <span className="odds">
                                    <span className="odds-btn">NOA</span>
                                  </span>
                                </TableCell>
                              )}
                            </TableRow>
                            <TableRow className="individual-team-mobile-row odds-icon-seeall">
                              {pageHeadingData?.map(id =>
                                renderTableCell(
                                  id?.BookKeeperId,
                                  individualTeamData
                                )
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </Box>
                </Box>
              </Box>
              <Box className="away-team-wrap">
                <Box className="team-wrap">
                  <span className="team-img-wrap">
                    {fetchTeamlogo(individualTeamData, "awayteam")}
                  </span>
                  <span className="team-name mr-6">
                    {" "}
                    {individualTeamData?.awayTeam?.name
                      ? individualTeamData?.awayTeam?.name
                      : ""}
                  </span>
                </Box>
                <Box className="mobile-best5odds-wrap">
                  <Box className="mobile-best5odds-select">
                    <Typography>{localesData?.sport?.SEE_ALL_ODDS}</Typography>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setMobileBestOpenAway(MobileBestOpenAway === 0 ? -1 : 0)
                      }
                    >
                      {MobileBestOpenAway === 0 ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Box>
                    <Collapse
                      in={MobileBestOpenAway === 0}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableContainer>
                        <Table className="individual-team-mobile-table">
                          <TableHead></TableHead>
                          <TableBody>
                            <TableRow className="individual-team-mobile-row">
                              {pageHeadingData?.length > 0 ? (
                                pageHeadingData?.map((id, index) => (
                                  <TableCell
                                    className={`${classes.cell} individual-tablecell`}
                                    key={index}
                                  >
                                    <Box className="odds-point">
                                      {fetchSeeAllTeamOddsvalue(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "under"
                                          : "awayteam",
                                        "spreadpoints"
                                      )}
                                    </Box>
                                    <span className="odds">
                                      {fetchSeeAllTeamOddsvalue(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "under"
                                          : "awayteam",
                                        "odds"
                                      )}
                                    </span>
                                  </TableCell>
                                ))
                              ) : (
                                <TableCell
                                  className={`${classes.cell} individual-tablecell`}
                                >
                                  <span className="odds">
                                    <span className="odds-btn">NOA</span>
                                  </span>
                                </TableCell>
                              )}
                            </TableRow>
                            <TableRow className="individual-team-mobile-row odds-icon-seeall">
                              {pageHeadingData?.map(id =>
                                renderTableCell(
                                  id?.BookKeeperId,
                                  individualTeamData
                                )
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </Box>
                </Box>
              </Box>
              {/* </>
                    ) : (
                      <>
                        {fetchMobileViewSeeAllMultipleTeamData(
                          individualTeamData
                        )}
                      </>
                    )} */}
            </>
          ) : (
            <Box style={{ textAlign: "center", padding: "10px" }}>
              {" "}
              No Data Available{" "}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default InfoOddsComparison;
