import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Tabs,
  Tab,
  Collapse,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../../../components/Loader";
import TrackListCountdown from "./trackListCountdown";
import TrackRaceResult from "./trackRaceResult";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment-timezone";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import TabHorses from "../../../../../assets/images/Sport/tab_horse.svg";
import TabGreyhounds from "../../../../../assets/images/Sport/tab_greyhounds.svg";
import TabHarness from "../../../../../assets/images/Sport/tab_harness.svg";
import { Routes } from "../../../../../helpers/constants/routeConstants";
import { Config } from "../../../../../helpers/context/config";
import "./racingList.scss";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import Banner1 from "../../../../../assets/images/ad-placeholder/sports1.webp";
import Banner2 from "../../../../../assets/images/ad-placeholder/sports2.webp";
import Banner3 from "../../../../../assets/images/ad-placeholder/sports3.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import FixedMark from "../../../../../assets/images/Sport/fixed_mark.svg";
import Close from "../../../../../assets/images/Sport/close.svg";
import CloseSecondary from "../../../../../assets/images/Sport/close_secondary_mark.svg";
import Interim from "../../../../../assets/images/Sport/interim_mark.svg";
import Paying from "../../../../../assets/images/Sport/paying_mark.svg";
import { ReactComponent as BookIcon } from "../../../../../assets/images/icons/bookicon.svg";
import { ReactComponent as AddBookIcon } from "../../../../../assets/images/add-blackbook.svg";
import { ReactComponent as TooltipArrow } from "../../../../../assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as BlackbookRight } from "../../../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../../../assets/images/icons/blackbookPlus.svg";
import { IntlContext } from "src/App";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { clearConfigCache } from "prettier";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchFromStorage } from "src/helpers/context";
import BlackBookModal from "src/views/component/UI/blackBookModal";
import NoDataComp from "src/views/component/UI/NoData";

// mobile view tabs
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className="tabs-wrap">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const RacingListeView = ({
  selectedDate,
  filterRaceType,
  filterCountry,
  fetchAds,
}) => {
  const intlContext = useContext(IntlContext);
  const dispatch = useDispatch();
  const localesData = intlContext?.messages;
  const [RaceType, setRaceType] = useState([
    {
      id: 1,
      title: "Horses",
      icon: TabHorses,
    },
    {
      id: 3,
      title: "Greyhounds",
      icon: TabGreyhounds,
    },
    {
      id: 2,
      title: "Harness",
      icon: TabHarness,
    },
  ]);
  const [HorseRaceData, setHorseRaceData] = useState([]);
  const [GreyhoundsRaceData, setGreyhoundsRaceData] = useState([]);
  const [HarnessRaceData, setHarnessRaceData] = useState([]);
  const [isThreeRaceLoading, setisThreeRaceLoading] = useState(false);
  const [acordianLoader, setisacordianLoader] = useState(false);
  const [RaceLoader, setisRaceLoader] = useState(true);
  const [isYesterday, setisYesterday] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [value, setValue] = useState(0);
  const [AdData, setAdData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [raceMenuCollapse, setRaceMenuCollapse] = useState(-1);
  const [dropDownSportId, setDropDownSportId] = useState(0);
  const [isintl, setIsIntl] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [BBopen, setBBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [getTipsModal, setGetTipsModal] = useState(false);
  const [getTipsDetails, setGetTipsDetails] = useState([]);
  const [getTipsEventName, setGetTipsEventName] = useState({});
  const [getTipsLoader, setGetTipsLoader] = useState(false);

  const handleModal = () => {
    setBBOpen(true);
  };

  const handleClose = () => {
    setBBOpen(false);
  };

  const handleBlackBookModal = (title, id, type) => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      setSelectedTitle(title);
      setSelectedType(type);
      setSelectedId(id);
      handleModal();
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  let searchParamsType = query.get("type");
  // Fetch Racing Track Detail
  const fetchThreeRacing = async () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    setisThreeRaceLoading(true);
    setisacordianLoader(true);
    let date_to_pass = selectedDate;

    let country_to_pass = "";
    let state_to_pass = "";

    // Get Sport Id  and Country From Sport FIlter
    let SelectedSportId = filterRaceType;
    let SelectedState = filterCountry;
    try {
      const { status, data } = await axiosInstance.get(
        // `events/trackList/?todate=${date_to_pass}&sportId=${SelectedSportId}&MeetingState=${SelectedState}&countryId=${country_to_pass // isAustralianRacing ? 13 :
        // }&stateId=${state_to_pass}&timezone=${timezone}`
        `v2/events/trackList/?todate=${date_to_pass}&sportId=${SelectedSportId}&MeetingState=${SelectedState}&countryId=${
          country_to_pass // isAustralianRacing ? 13 :
        }&stateId=${state_to_pass}&timezone=${timezone}`
      );
      if (status === 200) {
        let data_pass =
          data?.events?.length > 0
            ? // Remove raceNumber === 0 and duplicate raceNumber
              data?.events?.map((obj) => {
                return {
                  ...obj,
                  race: Array.from(new Set(obj?.race.map((a) => a?.raceNumber)))
                    .map((id) => {
                      return obj?.race.find((a) => a?.raceNumber === id);
                    })
                    .filter((race) => race?.raceNumber !== 0),
                };
              })
            : [];

        // setRaceData(data_pass);

        // Filter For Horse Aus / intl racing
        let horsesrace = data_pass.filter((item) => {
          return item.sportId == 1;
        });
        let greyhounds = data_pass.filter((item) => {
          return item.sportId == 3;
        });
        let harness = data_pass.filter((item) => {
          return item.sportId == 2;
        });
        // Set horsesrace
        let singlehorsesraceData = horsesrace?.map((obj) => {
          let data = obj?.race;
          let a = data.map((a) => a.raceNumber) || [];

          for (var i = 1; i <= 12; i++) {
            if (a.indexOf(i) == -1) {
              let dummy_obj = { startTimeDate: null, raceNumber: i };
              data.push(dummy_obj);
            }
          }

          obj["race"] = data.sort((a, b) => {
            return a?.raceNumber - b?.raceNumber;
          });
          return { ...obj };
        });
        setHorseRaceData(singlehorsesraceData);

        // Set greyhounds

        let singlegreyhoundsraceData = greyhounds?.map((obj) => {
          let data = obj?.race;
          let a = data.map((a) => a.raceNumber) || [];

          for (var i = 1; i <= 12; i++) {
            if (a.indexOf(i) == -1) {
              let dummy_obj = { startTimeDate: null, raceNumber: i };
              data.push(dummy_obj);
            }
          }

          obj["race"] = data.sort((a, b) => {
            return a?.raceNumber - b?.raceNumber;
          });
          return { ...obj };
        });
        setGreyhoundsRaceData(singlegreyhoundsraceData);

        // Set harness

        let singleharnessraceData = harness?.map((obj) => {
          let data = obj?.race;
          let a = data.map((a) => a.raceNumber) || [];

          for (var i = 1; i <= 12; i++) {
            if (a.indexOf(i) == -1) {
              let dummy_obj = { startTimeDate: null, raceNumber: i };
              data.push(dummy_obj);
            }
          }

          obj["race"] = data.sort((a, b) => {
            return a?.raceNumber - b?.raceNumber;
          });
          return { ...obj };
        });
        setHarnessRaceData(singleharnessraceData);
        setisThreeRaceLoading(false);
        setisacordianLoader(false);
        setisRaceLoader(false);
      }
    } catch (err) {
      setisThreeRaceLoading(false);
      setisacordianLoader(false);
      setisRaceLoader(false);
    }
  };
  //  Filter For Horse Aus/Nz and Intl Racing
  let newHorseRaceData = {
    ausData: HorseRaceData?.filter((item) => {
      return item?.track?.Country?.id == 13 || item?.track?.Country?.id == 157;
    }),
    intlData: HorseRaceData?.filter((item) => {
      return item?.track?.Country?.id != 13 && item?.track?.Country?.id != 157;
    }),
  };

  //  Filter For GreyhoundsRaceData Aus/Nz and Intl Racing
  let newGreyhoundsRaceData = {
    ausData: GreyhoundsRaceData?.filter((item) => {
      return item?.track?.Country?.id == 13 || item?.track?.Country?.id == 157;
    }),
    intlData: GreyhoundsRaceData?.filter((item) => {
      return item?.track?.Country?.id != 13 && item?.track?.Country?.id != 157;
    }),
  };
  //  Filter For HarnessRaceData Aus/Nz and Intl Racing
  let newHarnessRaceData = {
    ausData: HarnessRaceData?.filter((item) => {
      return item?.track?.Country?.id == 13 || item?.track?.Country?.id == 157;
    }),
    intlData: HarnessRaceData?.filter((item) => {
      return item?.track?.Country?.id != 13 && item?.track?.Country?.id != 157;
    }),
  };
  // push All Racing Filterd Data in RaceType Object
  RaceType[0]["RaceData"] = newHorseRaceData;
  RaceType[1]["RaceData"] = newGreyhoundsRaceData;
  RaceType[2]["RaceData"] = newHarnessRaceData;

  useEffect(() => {
    fetchThreeRacing();
    if (
      selectedDate ==
      moment
        .tz(moment(), timezone)
        .subtract(1, "days")
        .format("YYYY-MM-DD")
    ) {
      setisYesterday(true);
    } else {
      setisYesterday(false);
    }
  }, [selectedDate, filterRaceType, filterCountry]);

  //  check race is upcoming or close
  const checkRaceMinCell = (time, type) => {
    let current_time_string = moment().format("YYYY/MM/DD HH:mm:ss");
    let end_time_string = moment(time).format("YYYY/MM/DD HH:mm:ss");
    let diff_sec = moment(end_time_string).diff(current_time_string, "second");
    let class_to_pass = "";
    if (type === "desktop") {
      class_to_pass = !isNaN(diff_sec)
        ? // ? diff_sec > 1800
          //   ? "interim"
          diff_sec <= 1800 && diff_sec > 300
          ? "close-secondary"
          : diff_sec <= 300 && diff_sec > 0
          ? "close"
          : diff_sec < 0
          ? "upcoming_race_cell_close"
          : "white"
        : "";
    } else {
      class_to_pass = !isNaN(diff_sec)
        ? diff_sec <= 600 && diff_sec > 0
          ? "upcoming_race_mobile"
          : ""
        : "";
    }
    return class_to_pass;
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchMobileMenuTrackList = (data, race, obj, racetrack, intl) => {
    if (
      obj?.startTimeDate !== null &&
      moment(new Date(obj?.startTimeDate)).isBefore(new Date())
    ) {
      return (
        <TrackRaceResult // if race is close
          race={race}
          race_obj={obj}
          key={racetrack}
          raceData={data}
          intl={intl}
          isMobile={false}
        />
      );
    } else {
      return (
        <TrackListCountdown // if race is upcoming
          race={race}
          race_obj={obj}
          key={racetrack}
          intl={intl}
          raceData={data}
          expiryTimestamp={
            obj?.startTimeDate !== null
              ? new Date(new Date(obj?.startTimeDate).toUTCString()).getTime()
              : new Date().getTime()
          }
          checkRaceMinCell={checkRaceMinCell}
          isMobile={false}
        />
      );
    }
  };

  const fetchFixedOdds = (race_obj) => {
    if (race_obj?.id) {
      if (race_obj?.isOdd) {
        return "fixed";
      } else {
        return "";
      }
    }
  };

  const fetchNewMobileViewRacingData = (data, intl) => {
    return (
      <>
        <Box className="Mobile-racing-data">
          {!isThreeRaceLoading &&
            (data?.length > 0 ? (
              data?.map((race, index) => {
                let haveRace = race?.race?.filter(
                  (data) => data?.startTimeDate !== null
                );
                let upnextRaces = race?.race?.filter(
                  (item) =>
                    item?.startTimeDate !== null &&
                    moment(new Date()).isBefore(new Date(item?.startTimeDate))
                );
                let completedRaces = race?.race?.filter(
                  (item) =>
                    item?.startTimeDate !== null &&
                    moment(new Date(item?.startTimeDate)).isBefore(new Date())
                );
                return (
                  <React.Fragment key={index}>
                    <Box
                      className={`${
                        raceMenuCollapse !== index ? "" : "racing-menu-collapse"
                      } racing-data-row`}
                    >
                      <Box>
                        <ClickAwayListener
                          onClickAway={() =>
                            handleOutsideClick(race, index, intl)
                          }
                        >
                          <Typography
                            className="track-title"
                            variant="subtitle1"
                          >
                            <Box className="track-name blackbook-tooltip">
                              <span
                                onClick={
                                  upnextRaces?.length > 0
                                    ? () => {
                                        navigate(
                                          // Redirect to Runner Details
                                          Routes.RunnerDetails(
                                            race?.sportId === 1
                                              ? "horse"
                                              : race?.sportId === 2
                                              ? "harness"
                                              : "greyhounds",
                                            race?.sportId,
                                            upnextRaces[0].id,
                                            race?.track?.id,
                                            race?.id,
                                            selectedDate,
                                            intl
                                          ),
                                          {
                                            state: {
                                              raceData: data,
                                              CurrentData: race,
                                              SelectedRaceid: haveRace[0].id,
                                            },
                                          }
                                        );
                                      }
                                    : haveRace[0]?.raceNumber !== undefined
                                    ? () => {
                                        navigate(
                                          Routes.RunnerDetails(
                                            race?.sportId === 1
                                              ? "horse"
                                              : race?.sportId === 2
                                              ? "harness"
                                              : "greyhounds",
                                            race?.sportId,
                                            haveRace[0].id,
                                            race?.track?.id,
                                            race?.id,
                                            selectedDate,
                                            intl
                                          ),
                                          {
                                            state: {
                                              raceData: data,
                                              CurrentData: race,
                                              SelectedRaceid: haveRace[0].id,
                                            },
                                          }
                                        );
                                      }
                                    : () => {}
                                }
                              >
                                {" "}
                                {race?.eventName}{" "}
                              </span>
                              {handleOpenTooltip(race, index, intl)}
                              {handleopenGetTips(race, index, intl)}
                            </Box>
                            <Box className="track-country">
                              {race?.track?.Country?.country}
                            </Box>
                          </Typography>
                        </ClickAwayListener>
                      </Box>
                      <Box className="upcoming-list">
                        {upnextRaces?.length > 0 ? (
                          <Box className="collapse-menu">
                            {fetchMobileMenuTrackList(
                              data,
                              race,
                              upnextRaces?.[0],
                              upnextRaces?.[0]?.raceNumber,
                              intl
                            )}
                            <span
                              className={
                                index === 0
                                  ? `race-tag race-tag-upcoming`
                                  : `race-tag race-tag-upcoming `
                              }
                            >
                              Race {upnextRaces?.[0]?.raceNumber}
                            </span>
                          </Box>
                        ) : (
                          <></>
                        )}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            return (
                              setRaceMenuCollapse(
                                raceMenuCollapse === index ? -1 : index
                              ),
                              setDropDownSportId(race?.sportId),
                              setIsIntl(intl)
                            );
                          }}
                        >
                          {raceMenuCollapse === index &&
                          dropDownSportId === race?.sportId &&
                          isintl === intl ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                    <Collapse
                      in={
                        raceMenuCollapse === index &&
                        dropDownSportId === race?.sportId &&
                        isintl === intl
                      }
                      timeout="auto"
                      unmountOnExit
                      className={`${
                        raceMenuCollapse !== index ? "" : "collapse-section"
                      }`}
                    >
                      {upnextRaces?.length > 0 ? (
                        upnextRaces?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Box
                                className={`collapse-menu collapse-td ${fetchFixedOdds(
                                  item
                                )}`}
                              >
                                {fetchMobileMenuTrackList(
                                  data,
                                  race,
                                  item,
                                  item?.raceNumber,
                                  intl
                                )}
                                <span
                                  className={
                                    index === 0
                                      ? `race-tag race-tag-close`
                                      : `race-tag race-tag-secondary-close`
                                  }
                                >
                                  Race {item?.raceNumber}
                                </span>
                              </Box>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      {completedRaces?.length > 0 ? (
                        completedRaces?.map((item, index) => {
                          return (
                            <Box
                              className={`collapse-menu collapse-td ${fetchFixedOdds(
                                item
                              )}`}
                              key={index}
                            >
                              {fetchMobileMenuTrackList(
                                data,
                                race,
                                item,
                                item?.raceNumber,
                                intl
                              )}
                              <span className="race-tag race-tag-result">
                                Race {item?.raceNumber}
                              </span>
                            </Box>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Collapse>
                  </React.Fragment>
                );
              })
            ) : (
              <Box
                style={{
                  textAlign: "center",
                }}
              >
                {/* No Race Available */}
                <NoDataComp />
              </Box>
            ))}
        </Box>
      </>
    );
  };

  // const handleMouseEnter = (sportId, index, intl) => {
  //   setTooltipOpen(true);
  //   setHoveredIndex(index);
  //   setDropDownSportId(sportId);
  //   setIsIntl(intl);
  // };

  // const handleMouseLeave = () => {
  //   setTooltipOpen(false);
  // };

  const handleTooltipContentClick = (event, sportId, index, intl) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(index);
    setDropDownSportId(sportId);
    setIsIntl(intl);
  };
  const handleOutsideClick = (item, index, intl) => {
    if (
      hoveredIndex === index &&
      dropDownSportId === item?.sportId &&
      isintl === intl
    ) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };
  const handleOpenTooltip = (item, index, intl) => {
    return (
      <Box
        className="custom-tooltip"
        onClick={(e) =>
          handleTooltipContentClick(e, item?.sportId, index, intl)
        }
      >
        {item?.isBlackBook > 0 ? (
          <BlackbookRight className="book-icon" />
        ) : (
          <BlackbookPlus className="book-icon" />
        )}
        {hoveredIndex === index &&
        dropDownSportId === item?.sportId &&
        isintl === intl ? (
          tooltipOpen && (
            <Box className="tooltip">
              <TooltipArrow className="tooltip-bottom-arrow" />
              <Typography
                className="add-blackbook"
                onClick={() =>
                  handleBlackBookModal(item?.eventName, item?.trackId, "Track")
                }
              >
                <AddBookIcon /> {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
              </Typography>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const handleGetTipsModal = (event, item, index, intl) => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      if (item?.isExpertTip > 1) {
        setGetTipsModal(true);
        handleGetTipsDetails(item?.sportId, item?.id);
      } else {
        navigate(`/gettips/${item?.sportId}/${item?.tipId}`);
      }
      setGetTipsEventName(item);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
    // event.stopPropagation();
    // setHoveredIndex(index);
    // setDropDownSportId(item?.sportId);
    // setIsIntl(intl);
  };

  const handleGetTipsDetails = async (sportId, eventId) => {
    setGetTipsLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/expertTips/web/viewMore?SportId=${sportId}&EventId=${eventId}`
      );
      if (status === 200) {
        setGetTipsLoader(false);
        setGetTipsDetails(data?.result?.rows);
      }
    } catch (err) {
      setGetTipsLoader(false);
    }
  };

  const handleTipsModalClose = () => {
    setGetTipsModal(false);
  };
  const handleopenGetTips = (item, index, intl) => {
    return (
      <>
        {item?.isExpertTip > 0 ? (
          <Box
            className="get-tips"
            onClick={(e) => handleGetTipsModal(e, item, index, intl)}
          >
            <span>Get Tips</span>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };
  return (
    <>
      {fetchAds(1, "102px", Banner1)}
      <Box className="track-info">
        <ul>
          <li>
            <img src={FixedMark} alt="fixed-indicator" />{" "}
            {localesData?.RACING?.FIXED_OODS_OFFERED}
          </li>
          <li>
            <img src={Close} alt="close-icon" /> -5{" "}
            {localesData?.RACING?.MINS_TO_JUMP}
          </li>
          <li>
            <img src={CloseSecondary} alt="close-icon" /> -30{" "}
            {localesData?.RACING?.MINS_TO_JUMP}
          </li>
          <li>
            <img src={Interim} alt="interim" /> {localesData?.RACING?.INTERIM}
          </li>
          <li>
            <img src={Paying} alt="paying" /> {localesData?.RACING?.PAYING}
          </li>
        </ul>
      </Box>
      {RaceLoader ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : screenWidth > 799 ? (
        RaceType?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Box className="racing-colleps" key={index}>
                {item?.RaceData?.ausData.length > 0 ||
                item?.RaceData?.intlData.length > 0 ? (
                  <>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <img src={item?.icon} alt="" />
                        <Typography>{item?.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {acordianLoader ? (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        ) : (
                          <Box>
                            {item.RaceData.ausData?.length > 0 ? (
                              <Box className="race-detail">
                                <Typography className="country-title">
                                  Australia
                                </Typography>

                                <TableContainer className="racing-table-container tracklist-container">
                                  <Table
                                    className="racing-data"
                                    aria-label="customized table"
                                    style={{ minWidth: 700 }}
                                  >
                                    <TableHead className="racing-table-head">
                                      <TableRow>
                                        <TableCell className="rtable-hc1"></TableCell>
                                        <TableCell className="rt-thead">
                                          R1
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R2
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R3
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R4
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R5
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R6
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R7
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R8
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R9
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R10
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R11
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R12
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody className="table-odd-even">
                                      {!isThreeRaceLoading &&
                                        (item?.RaceData?.ausData?.length > 0 ? (
                                          item?.RaceData?.ausData?.map(
                                            (race, index) => {
                                              let haveRace = race?.race?.filter(
                                                (data) =>
                                                  data?.startTimeDate !== null
                                              );
                                              let upnextRaces = race?.race?.filter(
                                                (item) =>
                                                  item?.startTimeDate !==
                                                    null &&
                                                  moment(new Date()).isBefore(
                                                    new Date(
                                                      item?.startTimeDate
                                                    )
                                                  )
                                              );
                                              return (
                                                <>
                                                  <TableRow
                                                    className="racing-data-row"
                                                    key={index}
                                                  >
                                                    <ClickAwayListener
                                                      onClickAway={() =>
                                                        handleOutsideClick(
                                                          race,
                                                          index,
                                                          false
                                                        )
                                                      }
                                                    >
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                        className="racing-data racing-data-th blackbook-tooltip"
                                                        style={{
                                                          cursor: "auto",
                                                        }}
                                                      >
                                                        <Box
                                                          className="box-cilck"
                                                          onClick={
                                                            upnextRaces?.length >
                                                            0
                                                              ? () => {
                                                                  navigate(
                                                                    // Redirect to Runner Details
                                                                    Routes.RunnerDetails(
                                                                      race?.sportId ===
                                                                        1
                                                                        ? "horse"
                                                                        : race?.sportId ===
                                                                          2
                                                                        ? "harness"
                                                                        : "greyhounds",
                                                                      race?.sportId,
                                                                      upnextRaces[0]
                                                                        .id,
                                                                      race
                                                                        ?.track
                                                                        ?.id,
                                                                      race?.id,
                                                                      selectedDate,
                                                                      false
                                                                    ),
                                                                    {
                                                                      state: {
                                                                        raceData:
                                                                          item
                                                                            ?.RaceData
                                                                            ?.ausData,
                                                                        CurrentData: race,
                                                                        SelectedRaceid:
                                                                          haveRace[0]
                                                                            .id,
                                                                      },
                                                                    }
                                                                  );
                                                                }
                                                              : haveRace[0]
                                                                  ?.raceNumber !==
                                                                undefined
                                                              ? () => {
                                                                  navigate(
                                                                    Routes.RunnerDetails(
                                                                      race?.sportId ===
                                                                        1
                                                                        ? "horse"
                                                                        : race?.sportId ===
                                                                          2
                                                                        ? "harness"
                                                                        : "greyhounds",
                                                                      race?.sportId,
                                                                      haveRace[0]
                                                                        .id,
                                                                      race
                                                                        ?.track
                                                                        ?.id,
                                                                      race?.id,
                                                                      selectedDate,
                                                                      false
                                                                    ),
                                                                    {
                                                                      state: {
                                                                        raceData:
                                                                          item
                                                                            ?.RaceData
                                                                            ?.ausData,
                                                                        CurrentData: race,
                                                                        SelectedRaceid:
                                                                          haveRace[0]
                                                                            .id,
                                                                      },
                                                                    }
                                                                  );
                                                                }
                                                              : () => {}
                                                          }
                                                        >
                                                          {race?.track?.Country?.country_flag?.includes(
                                                            "uploads"
                                                          ) ? (
                                                            <img
                                                              src={
                                                                Config.mediaURL +
                                                                race?.track
                                                                  ?.Country
                                                                  ?.country_flag
                                                              }
                                                              alt="Race Contry"
                                                              className="flag-icon"
                                                            />
                                                          ) : (
                                                            <img
                                                              src={
                                                                race?.track
                                                                  ?.Country
                                                                  ?.country_flag
                                                              }
                                                              alt="Race Contry"
                                                              className="flag-icon"
                                                            />
                                                          )}
                                                          {/* <img
                                                      src={
                                                        race?.track?.Country
                                                          ?.country_flag
                                                      }
                                                      alt="Race Contry"
                                                      className="flag-icon"
                                                    /> */}
                                                          <Typography
                                                            className="track-title"
                                                            variant="subtitle1"
                                                          >
                                                            {race?.eventName}
                                                            {race?.track
                                                              ?.Country
                                                              ?.countryCode &&
                                                            race?.track?.Country
                                                              ?.id === 157 ? (
                                                              <span className="coutry-code">
                                                                {""}(
                                                                {
                                                                  race?.track
                                                                    ?.Country
                                                                    ?.countryCode
                                                                }
                                                                )
                                                              </span>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </Typography>
                                                        </Box>
                                                        {handleOpenTooltip(
                                                          race,
                                                          index,
                                                          false
                                                        )}

                                                        {handleopenGetTips(
                                                          race,
                                                          index,
                                                          false
                                                        )}
                                                      </TableCell>
                                                    </ClickAwayListener>
                                                    {race?.race
                                                      ?.slice(0, 12)
                                                      .map((obj, i) => {
                                                        if (
                                                          obj?.startTimeDate !==
                                                            null &&
                                                          moment(
                                                            new Date(
                                                              obj?.startTimeDate
                                                            )
                                                          ).isBefore(new Date())
                                                        ) {
                                                          return (
                                                            <TrackRaceResult // if race is close
                                                              race={race}
                                                              race_obj={obj}
                                                              key={i}
                                                              raceData={
                                                                item?.RaceData
                                                                  ?.ausData
                                                              }
                                                              selectedDate={
                                                                selectedDate
                                                              }
                                                              intl={false}
                                                              isMobile={false}
                                                            />
                                                          );
                                                        } else {
                                                          return (
                                                            <TrackListCountdown // if race is upcoming
                                                              race={race}
                                                              race_obj={obj}
                                                              key={i}
                                                              intl={false}
                                                              raceData={
                                                                item?.RaceData
                                                                  ?.ausData
                                                              }
                                                              selectedDate={
                                                                selectedDate
                                                              }
                                                              expiryTimestamp={
                                                                obj?.startTimeDate !==
                                                                null
                                                                  ? new Date(
                                                                      new Date(
                                                                        obj?.startTimeDate
                                                                      ).toUTCString()
                                                                    ).getTime()
                                                                  : new Date().getTime()
                                                              }
                                                              checkRaceMinCell={
                                                                checkRaceMinCell
                                                              }
                                                              isMobile={false}
                                                            />
                                                          );
                                                        }
                                                      })}
                                                  </TableRow>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <TableRow
                                            style={{
                                              border: "transparent",
                                              height: "1px",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                textAlign: "center",
                                                border: "none",
                                              }}
                                              colSpan={21}
                                            >
                                              {/* No Race Available */}
                                              <NoDataComp />
                                            </TableCell>
                                          </TableRow>
                                        ))}

                                      {/* 
                          {HorseRaceData.map((race, index) => {
                            return (
                              <>
                                {" "}
                                <TableRow className="racing-data-row">
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    className="racing-data racing-data-th"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={Ausflag} alt="Race Contry" />
                                    <Typography
                                      className="track-title"
                                      variant="subtitle1"
                                    >
                                      {race?.eventName}
                                    </Typography>
                                  </TableCell>
                                  {race?.race?.slice(0, 12).map(
                                    (obj, i) => {
                                      // if (
                                      //   obj?.startTimeDate !== null &&
                                      //   moment(
                                      //     new Date(obj?.startTimeDate)
                                      //   ).isBefore(new Date())
                                      // ) {
                                      //   return (
                                      //     <TrackRaceResult // if race is close
                                      //       race={race}
                                      //       race_obj={obj}
                                      //       key={i}
                                      //       isMobile={false}
                                      //     />
                                      //   );
                                      // } else {
                                      return (
                                        <TrackListCountdown // if race is upcoming
                                          race={race}
                                          race_obj={obj}
                                          key={i}
                                          expiryTimestamp={new Date(
                                            new Date(
                                              obj?.startTimeDate
                                            ).toUTCString()
                                          ).getTime()}
                                          checkRaceMinCell={checkRaceMinCell}
                                          isMobile={false}
                                        />
                                      );
                                    }
                                    // }
                                  )}
                                </TableRow>
                              </>
                            );
                          })} */}

                                      <TableRow
                                        style={{
                                          borderBottom: "transparent",
                                          borderBottomLeftRadius: 5,
                                          borderBottomRightRadius: 5,
                                          backgroundColor: "#fff",
                                          height: "1px",
                                        }}
                                      ></TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            ) : (
                              ""
                            )}
                            {item.RaceData.intlData?.length > 0 ? (
                              <Box className="race-detail">
                                <Typography className="country-title">
                                  International
                                </Typography>
                                <TableContainer className="racing-table-container tracklist-container">
                                  <Table
                                    className="racing-data"
                                    aria-label="customized table"
                                    style={{ minWidth: 700 }}
                                  >
                                    <TableHead className="racing-table-head">
                                      <TableRow>
                                        <TableCell className="rtable-hc1"></TableCell>
                                        <TableCell className="rt-thead">
                                          R1
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R2
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R3
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R4
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R5
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R6
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R7
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R8
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R9
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R10
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R11
                                        </TableCell>
                                        <TableCell className="rt-thead">
                                          R12
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody className="table-odd-even">
                                      {!isThreeRaceLoading &&
                                        (item.RaceData.intlData?.length > 0 ? (
                                          item.RaceData.intlData?.map(
                                            (race, index) => {
                                              let haveRace = race?.race?.filter(
                                                (data) =>
                                                  data?.startTimeDate !== null
                                              );
                                              let upnextRaces = race?.race?.filter(
                                                (item) =>
                                                  item?.startTimeDate !==
                                                    null &&
                                                  moment(new Date()).isBefore(
                                                    new Date(
                                                      item?.startTimeDate
                                                    )
                                                  )
                                              );
                                              return (
                                                <>
                                                  <TableRow
                                                    className="racing-data-row"
                                                    key={index}
                                                  >
                                                    <ClickAwayListener
                                                      onClickAway={() =>
                                                        handleOutsideClick(
                                                          race,
                                                          index,
                                                          true
                                                        )
                                                      }
                                                    >
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                        className="racing-data racing-data-th blackbook-tooltip"
                                                        style={{
                                                          cursor: "auto",
                                                        }}
                                                      >
                                                        {/* <img
                                                      className="flag-icon"
                                                      src={
                                                        race?.track?.Country
                                                          ?.country_flag
                                                      }
                                                      alt="Race Contry"
                                                    /> */}
                                                        <Box
                                                          className="box-cilck"
                                                          onClick={
                                                            upnextRaces?.length >
                                                            0
                                                              ? () => {
                                                                  navigate(
                                                                    // Redirect to Runner Details
                                                                    Routes.RunnerDetails(
                                                                      race?.sportId ===
                                                                        1
                                                                        ? "horse"
                                                                        : race?.sportId ===
                                                                          2
                                                                        ? "harness"
                                                                        : "greyhounds",
                                                                      race?.sportId,
                                                                      upnextRaces[0]
                                                                        .id,
                                                                      race
                                                                        ?.track
                                                                        ?.id,
                                                                      race?.id,
                                                                      selectedDate,
                                                                      true
                                                                    ),
                                                                    {
                                                                      state: {
                                                                        raceData:
                                                                          item
                                                                            ?.RaceData
                                                                            ?.intlData,
                                                                        CurrentData: race,
                                                                        SelectedRaceid:
                                                                          haveRace[0]
                                                                            .id,
                                                                      },
                                                                    }
                                                                  );
                                                                }
                                                              : haveRace[0]
                                                                  ?.raceNumber !==
                                                                undefined
                                                              ? () => {
                                                                  navigate(
                                                                    Routes.RunnerDetails(
                                                                      race?.sportId ===
                                                                        1
                                                                        ? "horse"
                                                                        : race?.sportId ===
                                                                          2
                                                                        ? "harness"
                                                                        : "greyhounds",
                                                                      race?.sportId,
                                                                      haveRace[0]
                                                                        .id,
                                                                      race
                                                                        ?.track
                                                                        ?.id,
                                                                      race?.id,
                                                                      selectedDate,
                                                                      true
                                                                    ),
                                                                    {
                                                                      state: {
                                                                        raceData:
                                                                          item
                                                                            ?.RaceData
                                                                            ?.intlData,
                                                                        CurrentData: race,
                                                                        SelectedRaceid:
                                                                          haveRace[0]
                                                                            .id,
                                                                      },
                                                                    }
                                                                  );
                                                                }
                                                              : () => {}
                                                          }
                                                        >
                                                          {race?.track?.Country?.country_flag?.includes(
                                                            "uploads"
                                                          ) ? (
                                                            <img
                                                              src={
                                                                Config.mediaURL +
                                                                race?.track
                                                                  ?.Country
                                                                  ?.country_flag
                                                              }
                                                              alt="Race Contry"
                                                              className="flag-icon"
                                                            />
                                                          ) : (
                                                            <img
                                                              src={
                                                                race?.track
                                                                  ?.Country
                                                                  ?.country_flag
                                                              }
                                                              alt="Race Contry"
                                                              className="flag-icon"
                                                            />
                                                          )}

                                                          <Typography
                                                            className="track-title"
                                                            variant="subtitle1"
                                                          >
                                                            <span>
                                                              {race?.eventName}
                                                              {race?.track
                                                                ?.Country
                                                                ?.countryCode ? (
                                                                <span className="coutry-code">
                                                                  {""}(
                                                                  {
                                                                    race?.track
                                                                      ?.Country
                                                                      ?.countryCode
                                                                  }
                                                                  )
                                                                </span>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </span>
                                                          </Typography>
                                                        </Box>
                                                        {handleOpenTooltip(
                                                          race,
                                                          index,
                                                          true
                                                        )}
                                                        {handleopenGetTips(
                                                          race,
                                                          index,
                                                          true
                                                        )}
                                                      </TableCell>
                                                    </ClickAwayListener>
                                                    {race?.race
                                                      ?.slice(0, 12)
                                                      .map((obj, i) => {
                                                        if (
                                                          obj?.startTimeDate !==
                                                            null &&
                                                          moment(
                                                            new Date(
                                                              obj?.startTimeDate
                                                            )
                                                          ).isBefore(new Date())
                                                        ) {
                                                          return (
                                                            <TrackRaceResult // if race is close
                                                              race={race}
                                                              race_obj={obj}
                                                              key={i}
                                                              intl={true}
                                                              selectedDate={
                                                                selectedDate
                                                              }
                                                              raceData={
                                                                item?.RaceData
                                                                  ?.intlData
                                                              }
                                                              isMobile={false}
                                                            />
                                                          );
                                                        } else {
                                                          return (
                                                            <TrackListCountdown // if race is upcoming
                                                              race={race}
                                                              race_obj={obj}
                                                              key={i}
                                                              intl={true}
                                                              selectedDate={
                                                                selectedDate
                                                              }
                                                              raceData={
                                                                item?.RaceData
                                                                  ?.intlData
                                                              }
                                                              expiryTimestamp={
                                                                obj?.startTimeDate !==
                                                                null
                                                                  ? new Date(
                                                                      new Date(
                                                                        obj?.startTimeDate
                                                                      ).toUTCString()
                                                                    ).getTime()
                                                                  : new Date().getTime()
                                                              }
                                                              checkRaceMinCell={
                                                                checkRaceMinCell
                                                              }
                                                              isMobile={false}
                                                            />
                                                          );
                                                        }
                                                      })}
                                                  </TableRow>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <TableRow
                                            style={{ border: "transparent" }}
                                          >
                                            <TableCell
                                              style={{
                                                textAlign: "center",
                                                border: "none",
                                              }}
                                              colSpan={21}
                                            >
                                              {/* No Race Available */}
                                              <NoDataComp />
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      <TableRow
                                        style={{
                                          borderBottom: "transparent",
                                          borderBottomLeftRadius: 5,
                                          borderBottomRightRadius: 5,
                                          backgroundColor: "#fff",
                                          height: "1px",
                                        }}
                                      ></TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    {index === 0 ? (
                      fetchAds(2, "102px", Banner2)
                    ) : index === 1 ? (
                      fetchAds(3, "102px", Banner3)
                    ) : (
                      <></>
                    )}
                  </>
                ) : !acordianLoader && searchParamsType ? (
                  searchParamsType == item?.id &&
                  searchParamsType?.length > 1 ? (
                    <p style={{ textAlign: "center" }} className="no-race">
                      {/* No Race Available */}
                      <NoDataComp />
                    </p>
                  ) : index == 0 &&
                    newHorseRaceData?.ausData?.length <= 0 &&
                    newGreyhoundsRaceData?.ausData?.length <= 0 &&
                    newHarnessRaceData?.ausData?.length <= 0 &&
                    newHorseRaceData?.intlData?.length <= 0 &&
                    newGreyhoundsRaceData?.intlData?.length <= 0 &&
                    newHarnessRaceData?.intlData?.length <= 0 ? (
                    <>
                      <NoDataComp />
                    </>
                  ) : (
                    ""
                  )
                ) : index == 0 &&
                  newHorseRaceData?.ausData?.length <= 0 &&
                  newGreyhoundsRaceData?.ausData?.length <= 0 &&
                  newHarnessRaceData?.ausData?.length <= 0 &&
                  newHorseRaceData?.intlData?.length <= 0 &&
                  newGreyhoundsRaceData?.intlData?.length <= 0 &&
                  newHarnessRaceData?.intlData?.length <= 0 ? (
                  <>
                    <NoDataComp />
                  </>
                ) : (
                  ""
                )}
              </Box>
            </React.Fragment>
          );
        })
      ) : (
        RaceType?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Box className="racing-colleps" key={index}>
                {item?.RaceData?.ausData.length > 0 ||
                item?.RaceData?.intlData.length > 0 ? (
                  <>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <img src={item?.icon} alt="race-icon" />
                        <Typography>{item?.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {acordianLoader ? (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        ) : (
                          <Box>
                            {item.RaceData.ausData?.length > 0 ? (
                              <Box className="race-detail">
                                <Typography className="country-title">
                                  Australia
                                </Typography>
                                <Box className="Mobile-view-tab-container tracklist-container">
                                  <Box>
                                    {fetchNewMobileViewRacingData(
                                      item?.RaceData?.ausData,
                                      false
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )}
                            {item.RaceData.intlData?.length > 0 ? (
                              <Box className="race-detail">
                                <Typography className="country-title">
                                  International
                                </Typography>
                                <Box className="Mobile-view-tab-container tracklist-container ">
                                  <Box>
                                    {fetchNewMobileViewRacingData(
                                      item?.RaceData?.intlData,
                                      true
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    {index === 0 ? (
                      fetchAds(2, "102px", Banner2)
                    ) : index === 1 ? (
                      fetchAds(3, "102px", Banner3)
                    ) : (
                      <></>
                    )}
                  </>
                ) : !acordianLoader && searchParamsType ? (
                  searchParamsType == item?.id &&
                  searchParamsType?.length > 1 ? (
                    <p style={{ textAlign: "center" }} className="no-race">
                      {/* No Race Available */}
                      <NoDataComp />
                    </p>
                  ) : index == 0 &&
                    newHorseRaceData?.ausData?.length <= 0 &&
                    newGreyhoundsRaceData?.ausData?.length <= 0 &&
                    newHarnessRaceData?.ausData?.length <= 0 &&
                    newHorseRaceData?.intlData?.length <= 0 &&
                    newGreyhoundsRaceData?.intlData?.length <= 0 &&
                    newHarnessRaceData?.intlData?.length <= 0 ? (
                    <NoDataComp />
                  ) : (
                    ""
                  )
                ) : index == 0 &&
                  newHorseRaceData?.ausData?.length <= 0 &&
                  newGreyhoundsRaceData?.ausData?.length <= 0 &&
                  newHarnessRaceData?.ausData?.length <= 0 &&
                  newHorseRaceData?.intlData?.length <= 0 &&
                  newGreyhoundsRaceData?.intlData?.length <= 0 &&
                  newHarnessRaceData?.intlData?.length <= 0 ? (
                  <NoDataComp />
                ) : (
                  ""
                )}
              </Box>
            </React.Fragment>
          );
        })
      )}
      <BlackBookModal
        open={BBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
      />
      <Dialog
        onClose={handleTipsModalClose}
        aria-labelledby="customized-dialog-title"
        open={getTipsModal}
        className="payment-modal get-tips-modal"
      >
        <DialogTitle className="modal-title">
          <Typography variant="h6" className="title">
            {getTipsEventName?.eventName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => handleTipsModalClose()}
            className="close-icon"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="payment-details">
          {getTipsLoader ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : (
            getTipsDetails?.map((item, index) => {
              return (
                <>
                  <Box className="modal-details">
                    <Typography
                      className="user-name"
                      onClick={() =>
                        navigate(`/gettips/${item?.SportId}/${item?.id}`)
                      }
                    >
                      <span>{item?.User?.firstName}</span>
                      <span> {item?.User?.lastName}</span>
                    </Typography>
                  </Box>
                </>
              );
            })
          )}
          {/* <Box>
            <Button
              variant="contained"
              onClick={() => handleTipsModalClose()}
              className="back-btn"
            >
              Back
            </Button>
          </Box> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RacingListeView;
