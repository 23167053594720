import React, { useState, useContext } from "react";
import { Box, Button, Typography, TextField } from "@material-ui/core";
import "../profile.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import { ReactComponent as BackArrow } from "../../../../assets/images/Back_Arrow.svg";

function PasswordEdit(data) {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [newData, setnewData] = useState(data?.data);
  const [currentPassword, setcurrentPassword] = useState(false);
  const [newPassword, setnewPassword] = useState(false);
  const [showRepeatPassword, setshowRepeatPassword] = useState(false);
  const [formvalues, setformvalues] = useState();
  const [isLoading, setLoading] = useState(false);
  const [error, seterror] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const payload = {
      currentPassword: formvalues?.currentPassword,
      password: formvalues?.newPassword,
      bookMaker: newData?.bookMaker,
      bookMakersOther: newData?.bookMakerOther,
      sportOrEvent: newData?.sportOrEvent,
      sportOrEventOther: newData?.sportOrEventOther,
      offerings: newData?.offerings,
      offeringsOther: newData?.offeringsOther
    };

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload
      );
      if (status === 200) {
        // setuserData(data);
        setLoading(false);
        toast.success(localesData?.register?.PASSWORD_CHANGED_SUCCESSFULLY, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let timer = setTimeout(() => navigate("/profile"), 4000);
        seterror("");
        // navigate("/profile");
      } else if (status === 403) {
        setLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      seterror(error?.response?.data?.message);
    }
  };
  const handleBack = () => {
    navigate("/profile");
  };
  return (
    <>
      <Formik
        initialValues={{
          // email: ""
          currentPassword: "",
          newPassword: "",
          repeatpassword: ""
        }}
        validationSchema={Yup.object({
          // email: Yup.string()
          //   .email(localesData?.validation?.valid_email)
          //   .max(255)
          //   .required(localesData?.validation?.required_message),
          currentPassword: Yup.string()
            .required(localesData?.validation?.required_message)
            .min(6, localesData?.validation?.passowrd_length),
          newPassword: Yup.string()
            .required(localesData?.validation?.required_message)
            .min(6, localesData?.validation?.passowrd_length),
          repeatpassword: Yup.string()
            .required(localesData?.validation?.required_message)
            .min(6, localesData?.validation?.passowrd_length)
            .when("newPassword", {
              is: val => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("newPassword")],
                localesData?.validation?.password_confpass_not_match
              )
            })
        })}
        onSubmit={() => handleSave()}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Box className="useredit-details" onClick={() => handleBack()}>
                <span>
                  <BackArrow className="back-arrow" />
                </span>
                <h4>{localesData?.EDITPROFILE?.EDIT_PASSWORD}</h4>
              </Box>
              {isLoading && (
                <div className="loader_center">
                  <Loader />
                </div>
              )}

              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.CURRENT_PASSWORD}:
                </Typography>
                <Box>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    name="currentPassword"
                    type={currentPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: currentPassword ? (
                        <UnVisiblePassword
                          onClick={() => setcurrentPassword(!currentPassword)}
                        />
                      ) : (
                        <VisiblePassword
                          onClick={() => setcurrentPassword(!currentPassword)}
                        />
                      )
                    }}
                    error={Boolean(
                      touched?.currentPassword && errors?.currentPassword
                    )}
                    helperText={
                      touched?.currentPassword ? errors?.currentPassword : ""
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values?.currentPassword}
                  />
                  <span className="text-danger">{error}</span>
                </Box>
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.new_password}:
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="details-textfield"
                  type={newPassword ? "text" : "password"}
                  name="newPassword"
                  InputProps={{
                    endAdornment: newPassword ? (
                      <UnVisiblePassword
                        onClick={() => setnewPassword(!newPassword)}
                      />
                    ) : (
                      <VisiblePassword
                        onClick={() => setnewPassword(!newPassword)}
                      />
                    )
                  }}
                  error={Boolean(touched?.newPassword && errors?.newPassword)}
                  helperText={touched?.newPassword ? errors?.newPassword : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values?.newPassword}
                />
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.repeat_new_password}:
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="details-textfield"
                  type={showRepeatPassword ? "text" : "password"}
                  name="repeatpassword"
                  InputProps={{
                    endAdornment: showRepeatPassword ? (
                      <UnVisiblePassword
                        onClick={() =>
                          setshowRepeatPassword(!showRepeatPassword)
                        }
                      />
                    ) : (
                      <VisiblePassword
                        onClick={() =>
                          setshowRepeatPassword(!showRepeatPassword)
                        }
                      />
                    )
                  }}
                  error={Boolean(
                    touched?.repeatpassword && errors?.repeatpassword
                  )}
                  helperText={
                    touched?.repeatpassword ? errors?.repeatpassword : ""
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values?.repeatpassword}
                />
              </Box>
              <Box className="profile-button">
                <Box className="profile-btn">
                  <Button
                    variant="outlined"
                    className="btn-cancel"
                    onClick={() => handleBack()}
                  >
                    {localesData?.EDITPROFILE?.CANCEL}
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-save"
                    type="submit"
                  >
                    {localesData?.EDITPROFILE?.SAVE}
                  </Button>
                </Box>
              </Box>
            </Box>
            {setformvalues(values)}
          </form>
        )}
      </Formik>
      <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
    </>
  );
}

export default PasswordEdit;
