import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableSortLabel
} from "@material-ui/core";
import playerAvtar from "../../../assets/images/player-avatar.png";
import { ReactComponent as AccordionExpand } from "../../../assets/images/icons/accordion-arrow-down.svg";
import { ReactComponent as Print } from "../../../assets/images/icons/print.svg";
import NoDataComp from "../UI/NoData";
import Loader from "src/components/Loader";
import { useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "../AdBanner/AdBanner";
import tippingAbove from "../../../assets/images/ad-placeholder/tipping1.png";
import tippingBelow from "../../../assets/images/ad-placeholder/tipping2.png";
import BannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.png";
import { Config } from "src/helpers/context";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const LadderTab = ({
  stepperCount,
  tabvalue,
  redux_userData,
  eventByIdData,
  compsId
}) => {
  const params = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [ladderList, setLadderList] = useState([]);
  const [ladderCount, setLadderCount] = useState(0);
  const [ladderOffset, setLadderOffset] = useState(0);
  const [currentUser, setCurrentUser] = useState({});
  const [AdData, setAdData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [sortType, setSortType] = useState("roundPoints");
  const [sortRound, setSortRound] = useState(false);
  const [sortOverallRound, setSortOverallRound] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
  }, []);

  useEffect(() => {
    if (tabvalue === 1) {
      getLadderList(0, [], stepperCount, sortType, false);
    }
  }, [params, stepperCount]);

  const fetchAdsData = async () => {
    try {
      const passPageId =
        params?.SportId == 4 ? 41 : params?.SportId == 12 ? 42 : 43;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${passPageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.page_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const getLadderList = async (
    offset,
    ladderLists,
    stepperCount,
    type,
    order
  ) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/ladderBoard?competitionId=${Number(
          params?.compsId
        )}&limit=10&offset=${offset}&round=${stepperCount}&sort=${
          type ? type : ""
        }&orderBy=${order ? "asc" : "desc"}`
      );
      if (status === 200) {
        setLoading(false);
        setLadderCount(data?.result?.length);
        setCurrentUser(data?.currentUser);
        const LadderDatas = [...ladderLists, ...data?.result];
        setLadderList(LadderDatas);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleMoreData = () => {
    let sortData = sortType === "roundPoints" ? sortRound : sortOverallRound;
    setLadderOffset(ladderOffset + 10);
    getLadderList(ladderOffset + 10, ladderList, stepperCount, sortType, false);
  };

  const handleLessData = () => {
    // scrollToTopSection();
    setLadderOffset(ladderOffset - 10);
    if (ladderList?.length % 10 === 0) {
      setLadderList(prevArray => prevArray.slice(0, -10));
    } else {
      setLadderList(prevArray => prevArray.slice(0, -ladderCount));
    }
  };

  const sortLabelHandler = type => {
    setSortType(type);
    if (type === "roundPoints") {
      getLadderList(ladderOffset, [], stepperCount, type, !sortRound);
      setSortRound(!sortRound);
      setSortOverallRound(false);
    } else {
      getLadderList(ladderOffset, [], stepperCount, type, !sortOverallRound);
      setSortRound(false);
      setSortOverallRound(!sortOverallRound);
    }
  };

  const printLadderPDF = async () => {
    // setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/generatePdf?competitionId=${compsId}&round=${stepperCount}`
      );
      if (status === 200) {
        // setLoading(false);

        // Example download
        download(data?.pdfFilePath, data?.fileName);
      } else {
        // setLoading(false);
      }
    } catch (err) {
      // setLoading(false);
    }
  };

  const download = (path, filename) => {
    // Create a new link
    const anchor = document.createElement("a");
    anchor.href = path;
    anchor.download = filename;
    anchor.target = "_blank";

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  };
  return (
    <>
      <Box className="ladder-wrapper">
        {fetchAds(
          1,
          "100%",
          Config?.release == "IN" ? BannerIndia : tippingAbove
        )}
      </Box>

      {/* new Table Design  */}

      {loading ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : (
        <>
          {redux_userData?.id === eventByIdData?.User?.id &&
            currentUser &&
            ladderCount > 0 && (
              <Box className="print-btn-box">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="print-btn"
                  onClick={() => {
                    printLadderPDF();
                  }}
                  endIcon={<Print />}
                >
                  <span className="btn-txt">Print Ladder</span>
                </Button>
              </Box>
            )}

          {ladderList && ladderList?.length > 0 ? (
            <>
              <Box className="new-ladder-sec">
                <Table style={{ minWidth: 940 }} className="new-ladder-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#Pos</TableCell>
                      <TableCell>Tipper</TableCell>
                      <TableCell
                        onClick={() => sortLabelHandler("roundPoints")}
                        className="primary-font-color table-cell-center"
                      >
                        Round Points{" "}
                        <TableSortLabel
                          active={true}
                          direction={
                            sortType === "roundPoints"
                              ? sortRound
                                ? "asc"
                                : "desc"
                              : "desc"
                          }
                        />
                      </TableCell>
                      <TableCell className="primary-font-color table-cell-center">
                        Round Margin
                      </TableCell>
                      <TableCell
                        className="primary-font-color table-cell-center"
                        onClick={() => sortLabelHandler("totalPoints")}
                      >
                        Overall Points{" "}
                        <TableSortLabel
                          active={true}
                          direction={
                            sortType === "totalPoints"
                              ? sortOverallRound
                                ? "asc"
                                : "desc"
                              : "desc"
                          }
                        />
                      </TableCell>
                      <TableCell className="primary-font-color table-cell-center">
                        Overall Margin
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUser && (
                      <TableRow className="current-user-row">
                        <TableCell className="highlight-border">
                          {currentUser && currentUser?.rank}
                        </TableCell>
                        <TableCell className="highlight-border">
                          <Box className="tipper-img-wrap">
                            <Box>
                              <img src={playerAvtar} alt="avtar" />
                            </Box>
                            <span>
                              {currentUser?.firstName} {currentUser?.lastName}
                            </span>
                          </Box>
                        </TableCell>
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.roundPoints ?? "-"}
                        </TableCell>
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.margin ?? "-"}
                        </TableCell>
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.totalPoints ?? "-"}
                        </TableCell>
                        <TableCell className="table-cell-center highlight-border">
                          {currentUser?.roundData?.totalMargin ?? "-"}
                        </TableCell>
                      </TableRow>
                    )}
                    {ladderList?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {item?.rank ? item?.rank : index + 1}
                          </TableCell>
                          <TableCell>
                            <Box className="tipper-img-wrap">
                              <Box>
                                <img src={playerAvtar} alt="avtar" />
                              </Box>
                              <span>
                                {item?.firstName} {item?.lastName}
                              </span>
                            </Box>
                          </TableCell>
                          <TableCell className="table-cell-center">
                            {item?.roundData?.roundPoints ?? "-"}
                          </TableCell>
                          <TableCell className="table-cell-center">
                            {item?.roundData?.margin ?? "-"}
                          </TableCell>
                          <TableCell className="table-cell-center">
                            {item?.roundData?.totalPoints ?? "-"}
                          </TableCell>
                          <TableCell className="table-cell-center">
                            {item?.roundData?.totalMargin ?? "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                {ladderList?.length > 0 ? (
                  <Box className="view-more">
                    {ladderOffset + 10 === ladderList?.length ? (
                      <span
                        className="view-more-text"
                        onClick={() => handleMoreData()}
                      >
                        View More
                      </span>
                    ) : (
                      <></>
                    )}
                    {ladderOffset >= 10 && ladderList?.length > 10 ? (
                      <span
                        className="view-more-text view-lese-text"
                        onClick={() => handleLessData()}
                      >
                        View Less
                      </span>
                    ) : (
                      <></>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </>
          ) : (
            <Box
              style={{
                textAlign: "center"
              }}
            >
              <NoDataComp />
            </Box>
          )}
        </>
      )}

      {/* Old table design  */}

      {/* <Box className="ladder-sec">
        {loading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <>
            {ladderList && ladderList?.length > 0 ? (
              <>
                {screenWidth > 940 ? (
                  <>
                    <Table className="table-content">
                      <TableHead>
                        <TableRow>
                          <TableCell className="table-title">#Pos</TableCell>
                          <TableCell
                            className="table-title txt-width"
                            style={{ width: "32%" }}
                          >
                            Tipper
                          </TableCell>
                          <TableCell
                            className="table-title txt-center"
                            onClick={() => sortLabelHandler("roundPoints")}
                          >
                            Round Points{" "}
                            <TableSortLabel
                              active={true}
                              direction={
                                sortType === "roundPoints"
                                  ? sortRound
                                    ? "asc"
                                    : "desc"
                                  : "desc"
                              }
                            />
                          </TableCell>
                          <TableCell className="table-title txt-center">
                            Round Margin
                          </TableCell>
                          <TableCell
                            className="table-title txt-center"
                            onClick={() => sortLabelHandler("overallRound")}
                          >
                            Overall Points{" "}
                            <TableSortLabel
                              active={true}
                              direction={
                                sortType === "overallRound"
                                  ? sortOverallRound
                                    ? "asc"
                                    : "desc"
                                  : "desc"
                              }
                            />
                          </TableCell>
                          <TableCell className="table-title txt-center">
                            Overall Margin
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentUser && (
                          <TableRow className="table-row">
                            <TableCell className="tablecell-highlight table-detail table-title-left table-position">
                              {currentUser && currentUser?.rank}
                            </TableCell>

                            <TableCell className="tablecell-highlight table-detail txt-width detail-flex table-title-left row-height">
                              <Box className="team-img-wrap team-wrap">
                                <img
                                  src={playerAvtar}
                                  alt="avtar"
                                  className="flag team-img"
                                />
                              </Box>
                              <span className="tipper-name">
                                {currentUser?.firstName} {currentUser?.lastName}
                              </span>
                            </TableCell>
                            <TableCell
                              className={
                                "tablecell-highlight table-detail table-detail-cell txt-center"
                              }
                            >
                              {currentUser?.roundData?.roundPoints ?? "-"}
                            </TableCell>
                            <TableCell
                              className={
                                "tablecell-highlight table-detail table-detail-cell txt-center"
                              }
                            >
                              {currentUser?.roundData?.margin ?? "-"}
                            </TableCell>
                            <TableCell
                              className={
                                "tablecell-highlight table-detail table-detail-cell txt-center"
                              }
                            >
                              {currentUser?.roundData?.totalPoints ?? "-"}
                            </TableCell>
                            <TableCell
                              className={
                                "tablecell-highlight table-detail table-detail-cell txt-center"
                              }
                            >
                              {currentUser?.roundData?.totalMargin ?? "-"}
                            </TableCell>
                          </TableRow>
                        )}
                        {ladderList?.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className={"table-detail"}>
                                {item?.rank ? item?.rank : index + 1}
                              </TableCell>
                              <TableCell
                                className={"table-detail detail-flex txt-width"}
                              >
                                <Box className="team-img-wrap">
                                  <img
                                    src={playerAvtar}
                                    alt="avtar"
                                    className="flag team-img"
                                  />
                                </Box>
                                <span className="tipper-name">
                                  {item?.firstName} {item?.lastName}
                                </span>
                              </TableCell>
                              <TableCell className={"table-detail txt-center"}>
                                {item?.roundData?.roundPoints ?? "-"}
                              </TableCell>
                              <TableCell className={"table-detail txt-center"}>
                                {item?.roundData?.margin ?? "-"}
                              </TableCell>
                              <TableCell className={"table-detail txt-center"}>
                                {item?.roundData?.totalPoints ?? "-"}
                              </TableCell>
                              <TableCell className={"table-detail txt-center"}>
                                {item?.roundData?.totalMargin ?? "-"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>

                    {ladderList?.length > 0 ? (
                      <Box className="view-more">
                        {ladderOffset + 10 === ladderList?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {ladderOffset >= 10 && ladderList?.length > 10 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <Box className="accordion-header">
                      <Typography className="header">#</Typography>
                      <Typography className="header">Tipper</Typography>
                    </Box>
                    {currentUser && (
                      <>
                        <Box className="left-section-details">
                          <Accordion className={"user-ranking"}>
                            <AccordionSummary
                              expandIcon={<AccordionExpand />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>
                                <Box
                                  className={`team-img-wrap detail-flex user-info
                                  `}
                                >
                                  {currentUser && currentUser?.rank}
                                  <img
                                    src={
                                      // item?.Favorite?.flag &&
                                      // item?.Favorite?.flag?.includes("uploads")
                                      //   ? Config.baseURL + item?.Favorite?.flag
                                      //   : item?.SportId === 5 ||
                                      //     item?.SportId === 6 ||
                                      //     item?.SportId === 7 ||
                                      //     item?.SportId === 16
                                      //   ?
                                      playerAvtar
                                      // : brisbane
                                    }
                                    style={{
                                      marginLeft: "34px"
                                    }}
                                    // src={
                                    //   item?.flag?.includes("uploads")
                                    //     ? Config.baseURL + item?.flag
                                    //     : item?.flag
                                    // }
                                    alt="avtar"
                                    className="flag team-img"
                                  />
                                  <span
                                    style={{
                                      marginLeft: "6px"
                                    }}
                                  >
                                    {currentUser?.firstName}{" "}
                                    {currentUser?.lastName}
                                  </span>
                                </Box>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Box className="accordion-details">
                                <Box className="score-sec border-bottom">
                                  <Typography className="score">
                                    Round Points
                                  </Typography>
                                  <Typography className="score black-txt">
                                    {currentUser?.roundData?.roundPoints
                                      ? currentUser?.roundData?.roundPoints
                                      : "-"}
                                  </Typography>
                                </Box>
                                <Box className="score-sec border-bottom">
                                  <Typography className="score">
                                    Round Margin
                                  </Typography>
                                  <Typography className="score black-txt">
                                    {currentUser?.roundData?.margin
                                      ? currentUser?.roundData?.margin
                                      : "-"}
                                  </Typography>
                                </Box>
                                <Box className="score-sec border-bottom">
                                  <Typography className="score">
                                    Overall Points
                                  </Typography>
                                  <Typography className="score black-txt">
                                    {currentUser?.roundData?.totalPoints
                                      ? currentUser?.roundData?.totalPoints
                                      : "-"}
                                  </Typography>
                                </Box>
                                <Box className="score-sec">
                                  <Typography className="score">
                                    Overall Margin
                                  </Typography>
                                  <Typography className="score black-txt">
                                    {currentUser?.roundData?.totalMargin
                                      ? currentUser?.roundData?.totalMargin
                                      : "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </>
                    )}
                    {ladderList?.map((item, index) => {
                      return (
                        <>
                          <Box className="left-section-details" key={index}>
                            <Accordion
                            // className={index == 0 ? "user-ranking" : ""}
                            >
                              <AccordionSummary
                                expandIcon={<AccordionExpand />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>
                                  <Box className={`team-img-wrap detail-flex`}>
                                    {item?.rank ? item?.rank : index + 1}
                                    <img
                                      src={
                                        // item?.Favorite?.flag &&
                                        // item?.Favorite?.flag?.includes("uploads")
                                        //   ? Config.baseURL + item?.Favorite?.flag
                                        //   : item?.SportId === 5 ||
                                        //     item?.SportId === 6 ||
                                        //     item?.SportId === 7 ||
                                        //     item?.SportId === 16
                                        //   ?
                                        playerAvtar
                                        // : brisbane
                                      }
                                      style={{
                                        marginLeft: "34px"
                                      }}
                                      // src={
                                      //   item?.flag?.includes("uploads")
                                      //     ? Config.baseURL + item?.flag
                                      //     : item?.flag
                                      // }
                                      alt="avtar"
                                      className="flag team-img"
                                    />
                                    <span
                                      style={{
                                        marginLeft: "6px"
                                      }}
                                    >
                                      {item?.firstName} {item?.lastName}
                                    </span>
                                  </Box>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="accordion-details ">
                                  <Box className="score-sec border-bottom">
                                    <Typography className="score">
                                      Round Points
                                    </Typography>
                                    <Typography className="score black-txt">
                                      {item?.roundData?.roundPoints
                                        ? item?.roundData?.roundPoints
                                        : "-"}
                                    </Typography>
                                  </Box>
                                  <Box className="score-sec border-bottom">
                                    <Typography className="score">
                                      Round Margin
                                    </Typography>
                                    <Typography className="score black-txt">
                                      {item?.roundData?.margin
                                        ? item?.roundData?.margin
                                        : "-"}
                                    </Typography>
                                  </Box>
                                  <Box className="score-sec border-bottom">
                                    <Typography className="score">
                                      Overall Point
                                    </Typography>
                                    <Typography className="score black-txt">
                                      {item?.roundData?.totalPoints
                                        ? item?.roundData?.totalPoints
                                        : "-"}
                                    </Typography>
                                  </Box>
                                  <Box className="score-sec">
                                    <Typography className="score">
                                      Overall Margin
                                    </Typography>
                                    <Typography className="score black-txt">
                                      {item?.roundData?.totalMargin
                                        ? item?.roundData?.totalMargin
                                        : "-"}
                                    </Typography>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </>
                      );
                    })}
                    {ladderList?.length > 0 ? (
                      <Box className="view-more">
                        {ladderOffset + 10 === ladderList?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {ladderOffset >= 10 && ladderList?.length > 10 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ) : (
              <Box
                style={{
                  textAlign: "center"
                }}
              >
                <NoDataComp />
              </Box>
            )}
          </>
        )}
      </Box> */}
      <Box className="ladder-wrapper">
        {fetchAds(
          2,
          "100%",
          Config?.release == "IN" ? BannerIndia : tippingBelow
        )}
      </Box>
    </>
  );
};

export default LadderTab;
