import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import { useParams } from "react-router";
import { IntlContext } from "src/App";
import AboveTable from "../../../../../../../assets/images/ad-placeholder/sports1.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment";
import NoDataComp from "src/views/component/UI/NoData";

const formData = [
  {
    id: 1,
    place: "8",
    total: "12",
    bookmaker: "bet365",
    otherinfo: "Echuca 11-Oct-22 1000m",
    trackCondition: "Heavy 8",
    racenumber: "R7",
    position: "0-64",
    winningprize: "$675 (of $27,000)",
    barrierno: "7",
    winningtime: "58.66",
    sp: "$31.00",
    running: " 800m 9th, 400m 11th,"
  },
  {
    id: 2,
    place: "1",
    total: "11",
    bookmaker: "bet365",
    otherinfo: "Echuca 11-Oct-22 1000m",
    trackCondition: "Good 4",
    racenumber: "R7",
    position: "0-64",
    winningprize: "$675 (of $27,000)",
    barrierno: "7",
    winningtime: "58.66",
    sp: "$31.00",
    running: " 800m 9th, 400m 11th,"
  },
  {
    id: 3,
    place: "8",
    total: "12",
    bookmaker: "bet365",
    otherinfo: "Echuca 11-Oct-22 1000m",
    trackCondition: "Soft 6",
    racenumber: "R7",
    position: "0-64",
    winningprize: "$675 (of $27,000)",
    barrierno: "7",
    winningtime: "58.66",
    sp: "$31.00",
    running: " 800m 9th, 400m 11th,"
  },
  {
    id: 4,
    place: "5",
    total: "14",
    bookmaker: "bet365",
    otherinfo: "Echuca 11-Oct-22 1000m",
    trackCondition: "Heavy 8",
    racenumber: "R7",
    position: "0-64",
    winningprize: "$675 (of $27,000)",
    barrierno: "7",
    winningtime: "58.66",
    sp: "$31.00",
    running: " 800m 9th, 400m 11th,"
  },
  {
    id: 5,
    place: "4",
    total: "11",
    bookmaker: "bet365",
    otherinfo: "Echuca 11-Oct-22 1000m",
    trackCondition: "Good 4",
    racenumber: "R7",
    position: "0-64",
    winningprize: "$675 (of $27,000)",
    barrierno: "7",
    winningtime: "58.66",
    sp: "$31.00",
    running: " 800m 9th, 400m 11th,"
  }
];
function HorsesProfile({ fetchAds, headerData }) {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const params = useParams();
  const [formLoading, setformLoading] = useState(false);
  const [formDetailsData, setformDetailsData] = useState([]);
  const [FormOffset, setFormOffset] = useState(0);
  const [FormAddedDataCount, setFormAddedDataCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [horseName, setHorseName] = useState("");
  const [raceInfo, setRaceInfo] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const checktrackcondition = data => {
    if (data.toLowerCase().includes("heavy")) {
      return "heavy";
    } else if (data.toLowerCase().includes("soft")) {
      return "soft";
    } else {
      return "good";
    }
  };
  const checkplayerplace = place => {
    if (place === "1") {
      return "first-place";
    }
  };
  const fetchFormDetails = async (offset, info) => {
    setformLoading(true);
    try {
      let passApi = "getHorseData";
      const { status, data } = await axiosInstance.get(
        `statistics/${passApi}/form/${params?.horseId}?limit=20&offset=${offset}`
      );
      if (status === 200) {
        setformLoading(false);

        const FormData = data?.result;

        let FormInfoData = FormData?.map(obj => {
          return {
            ...obj,
            Runner: obj?.runner?.map(runner => {
              return {
                ...runner,
                RunnerInfos: runner?.RunnerInfos?.map(item => {
                  return {
                    ...item,
                    info: item?.info ? JSON.parse(item?.info) : ""
                  };
                })
              };
            }),
            RaceResultSummary: obj?.RaceResultSummary?.summary
              ? JSON.parse(obj?.RaceResultSummary?.summary)
              : []
          };
        });
        setFormAddedDataCount(FormInfoData?.length);
        const formData = [...info, ...FormInfoData];
        setformDetailsData(formData);
        setRaceInfo(data?.info);
        setFormCount(data?.count);
      } else {
        setformLoading(false);
      }
    } catch (error) {
      setformLoading(false);
    }
  };
  const handleFormMoreData = () => {
    setFormOffset(FormOffset + 20);
    fetchFormDetails(FormOffset + 20, formDetailsData);
  };
  const handleFormLessData = () => {
    setFormOffset(FormOffset - 20);
    if (formDetailsData?.length % 20 === 0) {
      setformDetailsData(prevArray => prevArray.slice(0, -20));
    } else {
      setformDetailsData(prevArray => prevArray.slice(0, -FormAddedDataCount));
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchFormDetails(0, []);
  }, []);
  useEffect(() => {
    if (headerData) {
      setHorseName(headerData?.horse?.name);
    }
  }, [headerData]);
  return (
    <>
      {fetchAds(1, "102px", AboveTable)}
      <Box className="horseprofile-wrap">
        <Box className="career-stats-wrap">
          <Box className="horse-profile-header">
            <Typography variant="h1">
              {horseName} - {localesData?.sport?.CAREER}{" "}
              {localesData?.STATISTICS?.STATS}
            </Typography>
            {screenWidth > 1024 ? (
              <Box className="horse-performance">
                <Box>
                  <span className="detail-label">
                    {localesData?.sport?.EARNINGS} :{" "}
                  </span>
                  <span className="detail-info">
                    {" "}
                    {raceInfo?.runner_info?.prize_money
                      ? "$" + raceInfo?.runner_info?.prize_money
                      : "N/A"}
                  </span>
                </Box>
                <Box>
                  <span className="detail-label">
                    {localesData?.sport?.AVG_ODDS} :{" "}
                  </span>
                  <span className="detail-info">N/A</span>
                </Box>
                <Box>
                  <span className="detail-label">
                    {localesData?.sport?.RATINGS} :{" "}
                  </span>
                  <span className="detail-info">N/A</span>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          {screenWidth < 1025 ? (
            <Box className="horse-performance">
              <Box>
                <span className="detail-label">
                  {localesData?.sport?.EARNINGS} :{" "}
                </span>
                <span className="detail-info">
                  {raceInfo?.runner_info?.prize_money
                    ? "$" + raceInfo?.runner_info?.prize_money
                    : "N/A"}
                </span>
              </Box>
              <Box>
                <span className="detail-label">
                  {localesData?.sport?.AVG_ODDS} :{" "}
                </span>
                <span className="detail-info">N/A</span>
              </Box>
              <Box>
                <span className="detail-label">
                  {localesData?.sport?.RATINGS} :{" "}
                </span>
                <span className="detail-info">N/A</span>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          <Box className="career-stats">
            <Box>
              <span className="detail-label">
                {localesData?.sport?.CAREER} :{" "}
              </span>
              <span className="detail-info">
                {" "}
                {raceInfo?.past_runner_performances?.overall?.starts
                  ? raceInfo?.past_runner_performances?.overall?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.overall?.wins
                  ? raceInfo?.past_runner_performances?.overall?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.overall?.seconds
                  ? raceInfo?.past_runner_performances?.overall?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.overall?.thirds
                  ? raceInfo?.past_runner_performances?.overall?.thirds
                  : "0"}
              </span>
            </Box>
            <Box>
              <span className="detail-label">
                {localesData?.RUNNER_TABLE?.TRACK} :{" "}
              </span>
              <span className="detail-info">
                {raceInfo?.past_runner_performances?.track?.starts
                  ? raceInfo?.past_runner_performances?.track?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.track?.wins
                  ? raceInfo?.past_runner_performances?.track?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.track?.seconds
                  ? raceInfo?.past_runner_performances?.track?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.track?.thirds
                  ? raceInfo?.past_runner_performances?.track?.thirds
                  : "0"}
              </span>
            </Box>
            <Box>
              <span className="detail-label">
                {localesData?.RUNNER_TABLE?.DISTANCE} :{" "}
              </span>
              <span className="detail-info">
                {raceInfo?.past_runner_performances?.distance?.starts
                  ? raceInfo?.past_runner_performances?.distance?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.distance?.wins
                  ? raceInfo?.past_runner_performances?.distance?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.distance?.seconds
                  ? raceInfo?.past_runner_performances?.distance?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.distance?.thirds
                  ? raceInfo?.past_runner_performances?.distance?.thirds
                  : "0"}
              </span>
            </Box>
            <Box>
              <span className="detail-label">Trk/Dst:</span>
              <span className="detail-info">
                {raceInfo?.past_runner_performances?.track_distance?.starts
                  ? raceInfo?.past_runner_performances?.track_distance?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.track_distance?.wins
                  ? raceInfo?.past_runner_performances?.track_distance?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.track_distance?.seconds
                  ? raceInfo?.past_runner_performances?.track_distance?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.track_distance?.thirds
                  ? raceInfo?.past_runner_performances?.track_distance?.thirds
                  : "0"}
              </span>
            </Box>
            {/* <Box>
              <span className="detail-label">
                {localesData?.sport?.PLACES} :{" "}
              </span>
              <span className="detail-info">67%</span>
            </Box> */}
            <Box>
              <span className="detail-label">
                {localesData?.sport?.FIRST_UP} :{" "}
              </span>
              <span className="detail-info">
                {" "}
                {raceInfo?.past_runner_performances?.first_up?.starts
                  ? raceInfo?.past_runner_performances?.first_up?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.first_up?.wins
                  ? raceInfo?.past_runner_performances?.first_up?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.first_up?.seconds
                  ? raceInfo?.past_runner_performances?.first_up?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.first_up?.thirds
                  ? raceInfo?.past_runner_performances?.first_up?.thirds
                  : "0"}
              </span>
            </Box>
            <Box>
              <span className="detail-label">
                {localesData?.sport?.SECOND_UP} :{" "}
              </span>
              <span className="detail-info">
                {" "}
                {raceInfo?.past_runner_performances?.second_up?.starts
                  ? raceInfo?.past_runner_performances?.second_up?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.second_up?.wins
                  ? raceInfo?.past_runner_performances?.second_up?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.second_up?.seconds
                  ? raceInfo?.past_runner_performances?.second_up?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.second_up?.thirds
                  ? raceInfo?.past_runner_performances?.second_up?.thirds
                  : "0"}
              </span>
            </Box>
            {/* <Box>
              <span className="detail-label">
                {localesData?.sport?.TURF} :{" "}
              </span>
              <span className="detail-info"></span>
            </Box> */}
            <Box>
              <span className="detail-label">
                {localesData?.sport?.SYNTHETIC} :{" "}
              </span>
              <span className="detail-info">
                {raceInfo?.past_runner_performances?.synthetic?.starts
                  ? raceInfo?.past_runner_performances?.synthetic?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.synthetic?.wins
                  ? raceInfo?.past_runner_performances?.synthetic?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.synthetic?.seconds
                  ? raceInfo?.past_runner_performances?.synthetic?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.synthetic?.thirds
                  ? raceInfo?.past_runner_performances?.synthetic?.thirds
                  : "0"}
              </span>
            </Box>
            {/* <Box>
              <span className="detail-label">{localesData?.sport?.ROI} : </span>
              <span className="detail-info">-58%</span>
            </Box> */}
            <Box>
              <span className="detail-label">
                {localesData?.sport?.FIRM} :{" "}
              </span>
              <span className="detail-info">
                {raceInfo?.past_runner_performances?.firm?.starts
                  ? raceInfo?.past_runner_performances?.firm?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.firm?.wins
                  ? raceInfo?.past_runner_performances?.firm?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.firm?.seconds
                  ? raceInfo?.past_runner_performances?.firm?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.firm?.thirds
                  ? raceInfo?.past_runner_performances?.firm?.thirds
                  : "0"}
              </span>
            </Box>
            <Box>
              <span className="detail-label">
                {localesData?.sport?.GOOD} :{" "}
              </span>
              <span className="detail-info">
                {raceInfo?.past_runner_performances?.good?.starts
                  ? raceInfo?.past_runner_performances?.good?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.good?.wins
                  ? raceInfo?.past_runner_performances?.good?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.good?.seconds
                  ? raceInfo?.past_runner_performances?.good?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.good?.thirds
                  ? raceInfo?.past_runner_performances?.good?.thirds
                  : "0"}
              </span>
            </Box>
            <Box>
              <span className="detail-label">
                {localesData?.sport?.SOFT} :{" "}
              </span>
              <span className="detail-info">
                {raceInfo?.past_runner_performances?.soft?.starts
                  ? raceInfo?.past_runner_performances?.soft?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.soft?.wins
                  ? raceInfo?.past_runner_performances?.soft?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.soft?.seconds
                  ? raceInfo?.past_runner_performances?.soft?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.soft?.thirds
                  ? raceInfo?.past_runner_performances?.soft?.thirds
                  : "0"}
              </span>
            </Box>
            <Box>
              <span className="detail-label">
                {localesData?.sport?.HEAVY} :{" "}
              </span>
              <span className="detail-info">
                {raceInfo?.past_runner_performances?.heavy?.starts
                  ? raceInfo?.past_runner_performances?.heavy?.starts
                  : "0"}{" "}
                {raceInfo?.past_runner_performances?.heavy?.wins
                  ? raceInfo?.past_runner_performances?.heavy?.wins
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.heavy?.seconds
                  ? raceInfo?.past_runner_performances?.heavy?.seconds
                  : "0"}
                -
                {raceInfo?.past_runner_performances?.heavy?.thirds
                  ? raceInfo?.past_runner_performances?.heavy?.thirds
                  : "0"}
              </span>
            </Box>
            {/* <Box>
              <span className="detail-label">
                {localesData?.sport?.WIN_RANGE} :{" "}
              </span>
              <span className="detail-info">2000m - 2000m</span>
            </Box> */}
          </Box>
          <Typography variant="h1" className="horses-header">
            {moment()
              .subtract(1, "month")
              .format("MMM YYYY")}
          </Typography>
          {/* <Typography className="stats-des">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            fermentum consequat est, rhoncus scelerisque urna cursus ut. Morbi
            pharetra mi ac ex mollis tristique.Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </Typography> */}
        </Box>
        <Box className="player-form-wrap">
          {screenWidth > 799 ? (
            <>
              {formLoading && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!formLoading && (
                <>
                  <Table className="form-table">
                    <TableBody>
                      {formDetailsData?.length > 0 ? (
                        formDetailsData?.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="place-wrap">
                              <span
                                className={`player-rank ${checkplayerplace(
                                  item?.place
                                )}`}
                              >
                                {/* <span className="place">8 </span> of
                                <span>12</span> */}
                                {item?.runnerLength}
                              </span>
                            </TableCell>
                            <TableCell className="form-extra-details">
                              <span> {item?.raceName}</span>{" "}
                              <span>
                                {item.startDate
                                  ? moment(item.startDate).format("DD-MMM-YY")
                                  : ""}{" "}
                                {item?.Distance
                                  ? item?.Distance?.name + "m"
                                  : ""}
                              </span>{" "}
                              {item?.trackCondition ? (
                                <span
                                  className={`track-condition ${checktrackcondition(
                                    item?.trackCondition
                                  )}`}
                                >
                                  {item?.trackCondition}
                                </span>
                              ) : (
                                <></>
                              )}
                              <span>
                                {" "}
                                {item?.raceNumber
                                  ? "R" + item?.raceNumber
                                  : ""}{" "}
                              </span>
                              {/* <span>{item?.position}</span> */}
                              <span>
                                {item?.prizeMoney ? item?.prizeMoney : ""},{" "}
                              </span>
                              {item?.runner?.[0]?.barrierNumber ? (
                                <span>
                                  {" "}
                                  Barrier {
                                    item?.runner?.[0]?.barrierNumber
                                  },{" "}
                                </span>
                              ) : (
                                <></>
                              )}
                              {/* <span>Winning Time: {item?.winningtime}, </span>
                      <span> SP : {item?.sp}</span> */}
                              {item?.status ? (
                                <span>Status {item?.status}</span>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                            <TableCell>
                              <ul className="race-position-wrap">
                                {item?.RaceResultSummary?.slice(0, 4)?.map(
                                  obj => {
                                    return (
                                      <>
                                        <li>
                                          <span>
                                            {" "}
                                            {obj?.position
                                              ? Number(obj?.position) === 1
                                                ? "1st"
                                                : Number(obj?.position) === 2
                                                ? "2nd"
                                                : Number(obj?.position) === 3
                                                ? "3rd"
                                                : `${Number(obj?.position)}th`
                                              : Number(obj?.Position) === 1
                                              ? "1st"
                                              : Number(obj?.Position) === 2
                                              ? "2nd"
                                              : Number(obj?.Position) === 3
                                              ? "3rd"
                                              : `${Number(obj?.Position)}th`}
                                          </span>{" "}
                                          <span>
                                            {obj?.name ? obj?.name : obj?.Name}
                                          </span>
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </ul>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={12}
                            className="no-data-td"
                            style={{ textAlign: "center" }}
                          >
                            <NoDataComp />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {formDetailsData?.length > 0 &&
                  formCount > FormOffset + 20 ? (
                    <Box className="view-more">
                      {FormOffset + 20 === formDetailsData?.length ? (
                        <span
                          className="view-more-text red"
                          onClick={() => handleFormMoreData()}
                        >
                          Show more
                        </span>
                      ) : (
                        <></>
                      )}
                      {FormOffset >= 20 && formDetailsData?.length > 20 ? (
                        <span
                          className="view-more-text view-lese-text red"
                          onClick={() => handleFormLessData()}
                        >
                          View Less
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {/* <Box className="show-more">Show more</Box> */}
            </>
          ) : (
            <Box className="mobileview-forms-wrap">
              {formLoading && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}

              {!formLoading &&
                (formDetailsData?.length > 0 ? (
                  <>
                    {formDetailsData?.map((item, index) => (
                      <Box className="form-detail">
                        <Box
                          className={`place-wrap ${checkplayerplace(
                            item?.place
                          )}`}
                        >
                          {/* <span>
                          <span>8 </span> of
                          <span> 12</span>
                        </span> */}
                          {item?.runnerLength}
                        </Box>
                        <Box className="form-extra-details">
                          <span> {item?.raceName}</span>
                          <span>
                            {item.startDate
                              ? moment(item.startDate).format("DD-MMM-YY")
                              : ""}{" "}
                            {item?.Distance ? item?.Distance?.name + "m" : ""}{" "}
                          </span>{" "}
                          {item?.trackCondition ? (
                            <span
                              className={`track-condition ${checktrackcondition(
                                item?.trackCondition
                              )}`}
                            >
                              {item?.trackCondition}
                            </span>
                          ) : (
                            <></>
                          )}{" "}
                          <span>
                            {" "}
                            {item?.raceNumber ? "R" + item?.raceNumber : ""}
                          </span>
                          {/* <span>{item?.position}</span> */}
                          <span>
                            {item?.prizeMoney ? item?.prizeMoney : ""},{" "}
                          </span>
                          {item?.runner?.[0]?.barrierNumber ? (
                            <span>
                              {" "}
                              Barrier {item?.runner?.[0]?.barrierNumber},{" "}
                            </span>
                          ) : (
                            <></>
                          )}
                          {/* <span>Winning Time: {item?.winningtime}, </span>
                  <span> SP : {item?.sp}</span> */}
                          {item?.status ? (
                            <span>Status {item?.status}</span>
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Box>
                          <ul className="race-position-wrap">
                            {item?.RaceResultSummary?.slice(0, 4)?.map(obj => {
                              return (
                                <>
                                  <li>
                                    <span>
                                      {" "}
                                      {obj?.position
                                        ? Number(obj?.position) === 1
                                          ? "1st"
                                          : Number(obj?.position) === 2
                                          ? "2nd"
                                          : Number(obj?.position) === 3
                                          ? "3rd"
                                          : `${Number(obj?.position)}th`
                                        : Number(obj?.Position) === 1
                                        ? "1st"
                                        : Number(obj?.Position) === 2
                                        ? "2nd"
                                        : Number(obj?.Position) === 3
                                        ? "3rd"
                                        : `${Number(obj?.Position)}th`}
                                    </span>{" "}
                                    <span>
                                      {obj?.name ? obj?.name : obj?.Name}
                                    </span>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </Box>
                      </Box>
                    ))}
                    {formDetailsData?.length > 0 &&
                    formCount > FormOffset + 20 ? (
                      <Box className="view-more">
                        {FormOffset + 20 === formDetailsData?.length ? (
                          <span
                            className="view-more-text red"
                            onClick={() => handleFormMoreData()}
                          >
                            Show more
                          </span>
                        ) : (
                          <></>
                        )}
                        {FormOffset >= 20 && formDetailsData?.length > 20 ? (
                          <span
                            className="view-more-text view-lese-text red"
                            onClick={() => handleFormLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Box
                    className="no-data-mobile"
                    style={{ textAlign: "center" }}
                  >
                    {/* No Data Available */}
                    <NoDataComp />
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default HorsesProfile;
