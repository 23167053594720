import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IntlContext } from "src/App";
import PropTypes from "prop-types";
import {
  AppBar,
  Grid,
  // TextField,
  Button,
  SwipeableDrawer,
  Box,
  IconButton,
  Typography
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonIcon from "@material-ui/icons/Person";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import InputAdornment from "@material-ui/core/InputAdornment";
import { Link, useNavigate } from "react-router-dom";
import LeftSideBar from "../../views/component/leftsidebar/leftsidebar";
// import { ReactComponent as Search } from "../../assets/images/icons/search.svg";
import { ReactComponent as FaBars } from "../../assets/images/icons/menu.svg";
import logo from "../../assets/images/logo.svg";
import DialogBox from "src/views/component/UI/dialogBox";
import HeaderTopBar from "./headertopbar/headertopbar";
import CloseIcon from "@material-ui/icons/Close";
import SignUp from "src/views/component/auth/SignUp/signup";
import SignIn from "src/views/component/auth/SignIn/signin";
import ForgotPassword from "src/views/component/auth/ForgotPassword/forgotpassword";
import EnterOtp from "src/views/component/auth/EnterOtp/enterOtp";
// import { loginUser } from "src/helpers/store/Actions/auth";
import { useDispatch, useSelector } from "react-redux";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import HeaderTopBarV2 from "./headertopbar/headertopbarV2";
import { fetchBetSlipToggle } from "src/helpers/store/Actions/BetSlipToggle";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { Config, fetchFromStorage } from "src/helpers/context";
import { release } from "src/helpers/context/release";

const TopBar = () => {
  let dispatch = useDispatch();
  const location = useLocation();
  const BetSlipToggleData = useSelector(
    state => state?.reduxData?.BetSlipToggle
  );
  const BetSlipCount = useSelector(state => state?.reduxData?.BetSlipCount);
  const reduxSubscriptionData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const [showMenu, setshowMenu] = useState(false);
  const [modal, setModal] = useState();
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [betCount, setBetCount] = useState(0);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  let token = localStorage.getItem("auth_token");

  const toggleMenu = () => {
    setshowMenu(!showMenu);
  };
  const handleModal = mName => {
    setModal(mName);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    setshowMenu(false);
  }, [location?.pathname, location?.search]);

  useEffect(() => {
    if (BetSlipCount) {
      setBetCount(BetSlipCount);
    } else if (BetSlipCount === 0) {
      setBetCount(BetSlipCount);
    }
  }, [BetSlipCount]);

  const handleCloseMenu = () => {
    setshowMenu(false);
  };

  // console.log("showmenu", showMenu, location);

  const handleBetSlipToggle = () => {
    if (!token || token == "null") {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    } else {
      dispatch(
        fetchBetSlipToggle(BetSlipToggleData ? !BetSlipToggleData : true)
      );
      if (!BetSlipToggleData) {
        document.body.classList.add("no-scroll");
        if (screenWidth < 799) {
          window.scrollTo(0, 0);
        }
      } else {
        document.body.classList.remove("no-scroll");
      }
    }
  };
  return (
    <>
      {/* <HeaderTopBar /> */}
      <HeaderTopBarV2 />
      <AppBar
        position="static"
        elevation={0}
        className="main-menu-wrap"
        style={{ backgroundColor: "#FFF" }}
      >
        {/* Mobile memnu Before Login */}
        <div className="mobileMenu">
          <SwipeableDrawer
            anchor={"left"}
            open={showMenu}
            onClose={toggleMenu}
            onOpen={toggleMenu}
            className="mobileMenuDrawer"
            swipeAreaWidth={0}
          >
            <div className="mobileMenuDrawerClose">
              <CloseIcon onClick={toggleMenu} />
            </div>
            <LeftSideBar handleCloseMenu={handleCloseMenu} />
          </SwipeableDrawer>
        </div>
        <Grid container spacing={0} alignItems="center" className="header-wrap">
          <Grid item xs={1} sm={1} className="mobileMenu">
            <FaBars onClick={toggleMenu} className="MenuTopBar-button" />
          </Grid>
          <Grid
            item
            md={6}
            sm={5}
            xs={5}
            lg={6}
            className="text-left logo-container"
          >
            <NavLink to="/">
              <img
                src={logo}
                style={{ maxWidth: " 240.56px" }}
                className="App-logo-main"
                alt="logo"
                // loading="lazy"
              />
            </NavLink>
          </Grid>
          {/* <Grid
            item
            md={6}
            sm={3}
            xs={6}
            className="text-right search-wrap"
            style={{ justifyContent: "end" }}
          >
            <TextField
              placeholder={localesData?.search_placeholder}
              variant="outlined"
              type="text"
              name="search"
              className="search_input hide-sx"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
            <Box
              sx={{ display: { xs: "block", sm: "none" } }}
              className="search-box"
            >
              <TextField
                placeholder={localesData?.search_placeholder}
                variant="outlined"
                type="text"
                name="search"
                className="search_input search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Grid> */}

          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            className="MuiGrid-spacing-xs-0 login-buttons"
          >
            {!token || token == "null" ? (
              <Box className="signup-login-wrap">
                <Link
                  to="/sign-up"
                  // onClick={() => handleModal("signup")}
                >
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signup"
                  >
                    {localesData?.auth_btn?.signup}
                  </Button>
                </Link>
                <Link
                  to="/sign-in"
                  // onClick={() => handleModal("signin")}
                >
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signin"
                  >
                    {localesData?.auth_btn?.signin}
                  </Button>
                </Link>
              </Box>
            ) : (
              <Box className="profile-logout-wrap">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  color="inherit"
                  className="profile-icon"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  {/* <AccountCircle /> */}
                  <PersonIcon />
                </IconButton>
                <Typography className="user-name">
                  {reduxSubscriptionData?.firstName
                    ? reduxSubscriptionData?.firstName
                    : ""}
                </Typography>
                {/* bet slip button */}

                {/* <Box
                  className="bet-slip-button"
                  onClick={() => handleBetSlipToggle()}
                >
                  <Typography className="bet-slip">Bet Slip</Typography>
                  <Box className="bet-slip-count">{betCount}</Box>
                </Box> */}

                {/* {screenWidth > 799 ? (
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="auth-btn signup logout"
                    onClick={() => {
                      localStorage.removeItem("auth_token");
                      navigate("/");
                      dispatch(loginUser(""));
                    }}
                  >
                    {localesData?.auth_btn?.logout}
                  </Button>
                ) : (
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    className="profile-icon"
                    onClick={() => {
                      localStorage.removeItem("auth_token");
                      navigate("/");
                      dispatch(loginUser(""));
                    }}
                  >
                    <ExitToAppIcon />
                  </IconButton>
                )} */}
              </Box>
            )}
            {release[Config.release]?.betSlip && (
              <Box
                className="bet-slip-button"
                onClick={() => handleBetSlipToggle()}
              >
                <Typography className="bet-slip">Bet Slip</Typography>
                <Box className="bet-slip-count">{betCount}</Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </AppBar>
      <DialogBox
        open={open}
        handleClose={handleClose}
        title={false}
        content={
          modal === "signup" ? (
            <SignUp handleModal={handleModal} />
          ) : modal === "signin" ? (
            <SignIn handleModal={handleModal} />
          ) : modal === "forgotpassword" ? (
            <ForgotPassword handleModal={handleModal} />
          ) : modal === "enterotp" ? (
            <EnterOtp />
          ) : (
            <></>
          )
        }
        closeIcon={false}
      />
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
