// import banner1 from "../../assets/images/banner/banner-bg1.png";
// import banner2 from "../../assets/images/banner/banner-bg2.png";
// import banner3 from "../../assets/images/banner/banner-bg3.png";
// import banner4 from "../../assets/images/banner/banner-bg4.png";
// import banner5 from "../../assets/images/banner/banner-bg5.png";
// import banner6 from "../../assets/images/banner/banner-bg6.png";
import { Config } from "../context/config";
import { release } from "../context/release";

export const identifiers = {
  token: "authData",
  active: "active",
  dropDown: "Drop down",
  visitorUserId: "visitorUserId",
  authData: "authData",
  welcomePageSlider: [
    { id: 1, url: "banner1" },
    { id: 2, url: "banner2" },
    { id: 3, url: "banner3" },
    { id: 4, url: "banner4" },
    { id: 5, url: "banner5" },
    { id: 6, url: "banner6" }
  ],
  Menu: release[Config.release]?.menu
};
