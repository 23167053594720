import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import Avatar from "src/assets/images/user-avatar.png";
import { ReactComponent as OrangeDelete } from "src/assets/images/orange-delete.svg";
import "./wall.scss";
import DialogBox from "../../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import { MentionsInput, Mention } from "react-mentions";
import moment from "moment-timezone";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function ReplyComp({ postList, handlePostDelete }) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleDelete = id => {
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedId("");
  };

  return (
    <>
      {postList?.map((item, index) => {
        return (
          <Box className="wall-sec">
            <Box className="post-message reply-padding">
              <Box className="user-img">
                <img src={Avatar} alt="avatar" />
              </Box>
              <Box className="post-details">
                <Box className="name-delete-sec">
                  <Typography className="name">
                    {item?.fromUser?.firstName + " " + item?.fromUser?.lastName}
                  </Typography>
                  <Box
                    className="delete-box"
                    onClick={() => {
                      handleDelete(item?.post_id);
                    }}
                  >
                    <OrangeDelete />
                  </Box>
                </Box>

                <Typography
                  className="comment"
                  dangerouslySetInnerHTML={{
                    __html: item?.text
                  }}
                ></Typography>
                <Typography className="time">
                  {moment
                    .utc(item?.createdAt)
                    .tz(timezone)
                    .format("dddd")}
                  ,{" "}
                  {moment
                    .utc(item?.createdAt)
                    .tz(timezone)
                    .format("DD MMM")}{" "}
                  at{" "}
                  {moment
                    .utc(item?.createdAt)
                    .tz(timezone)
                    .format("hh:mm A")}
                </Typography>
                {/* {isPost === true ? (
                  <>
                    <Box className="bottom-comp">
                      <Box className="user-img">
                        <img src={Avatar} alt="avatar" />
                      </Box>
                      <TextareaAutosize
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textarea-field"
                    placeholder="Reply..."
                    multiline
                    maxRows={6}
                    name="message"
                    style={{ width: "97%", height: "18px" }}
                    // onChange={e => setComment(e.target.value)}
                    // value={comment}
                  />
                      <MentionsInput
                        value={userCommentBox}
                        onChange={e => {
                          onInputChange(e);
                        }}
                        style={defaultStyle}
                        id="outlined-basic"
                        variant="outlined"
                        className="details-textarea-field mention-input"
                        placeholder={
                          // isReply
                          // ?
                          `Reply...`
                          // : `Write on Chloe Royle's wall...`
                        }
                        forceSuggestionsAboveCursor={true}
                        rows={5}
                        cols={1}
                      >
                        <Mention
                          // markup="<a data-value='__id__' href='#'>@__display__</a>"
                          markup="<a href=__id__ class=`wysiwyg-mention` data-mention>__display__</a>"
                          // displayTransform={(id) => `@${id}`}
                          data={mentionUsersList}
                          // trigger="@"
                          // onAdd={this.onAdd}
                          // style={defaultMentionStyle}
                        />
                      </MentionsInput>
                      {screenWidth > 530 ? (
                        <Button
                          className="post-btn"
                          onClick={() => {
                            console.log(
                              "item, item?.parentPostId",
                              item,
                              item?.parentPostId
                            );
                            handlePostSubmit(true, item?.parentPostId);
                            setIsPost(false);
                          }}
                          disabled={!userCommentBox}
                        >
                          Post
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Box>
                    {screenWidth < 530 ? (
                      <Box className="right-btn">
                        <Button
                          className="post-btn"
                          onClick={() => {
                            handlePostSubmit(true, item?.parentPostId);
                            setIsPost(false);
                          }}
                          disabled={!userCommentBox}
                        >
                          Post
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Box className="reply-btn-sec">
                    <Button
                      className="post-btn reply-btn"
                      onClick={() => setIsPost(true)}
                    >
                      Reply
                    </Button>
                  </Box>
                )} */}
              </Box>
            </Box>
          </Box>
        );
      })}
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handlePostDelete(selectedId);
                    handleDeleteClose();
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default ReplyComp;
