import React, { useContext, useEffect, useState } from "react";
import { IntlContext } from "src/App";
import {
  Box,
  Breadcrumbs,
  Typography,
  Button,
  ClickAwayListener,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../helpers/context/config";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import { ReactComponent as BlackBookIcon } from "../../../assets/images/icons/blackbookicon.svg";
import { ReactComponent as BlackbookRight } from "../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../assets/images/icons/blackbookPlus.svg";
import { ReactComponent as RightBlack } from "../../../assets/images/icons/rightblack.svg";
import { ReactComponent as PlusBlack } from "../../../assets/images/icons/plusblack.svg";
import { ReactComponent as AddBookIcon } from "../../../assets/images/add-blackbook.svg";
import { ReactComponent as TooltipArrow } from "../../../assets/images/icons/tooltip_arrow.svg";
import Bet365 from "../../../assets/images/bookmakers/bet365-thumb.svg";
import BlackBookModal from "../UI/blackBookModal";
import { fetchFromStorage } from "src/helpers/context";
import Loader from "src/components/Loader";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import "../allsports/racing/runnerTable/runnerTable.scss";
import "./getTips.scss";
import Tooltip from "@material-ui/core/Tooltip";
import GetTipsHighChart from "./getTipsGraph";
import OurPartner from "../ourPartners";
import { event } from "jquery";
import GetRuunerodds from "./getRuunerodds";

const tipsType = [
  {
    name: "Best Bet",
    Participant: "betParticipant",
    Price: "betPrice",
    Race: "betRace",
    Comment: "betComment",
    odds: "betParticipantOdds",
    noComment: "No Best Bet",
  },
  {
    name: "Best Each Way",
    Participant: "wayParticipant",
    Price: "wayPrice",
    Race: "wayRace",
    Comment: "wayComment",
    odds: "wayParticipantOdds",
    noComment: "No Each Way Bet",
  },
  {
    name: "Best Lay",
    Participant: "layParticipant",
    Price: "layPrice",
    Race: "layRace",
    Comment: "layComment",
    odds: "layParticipantOdds",
    noComment: "No Lay Bet",
  },
];
const GetTips = () => {
  const params = useParams();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const dispatch = useDispatch();
  const [raceTrackdata, setraceTrackdata] = useState({});
  const [runnerData, setRunnerData] = useState([]);
  const [isrunnnerLoading, setisrunnnerLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [eventLocation, setEventLocation] = useState([]);
  const [isEventLoading, setisEventLoading] = useState(false);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [isTipsLoading, setIsTipsLoading] = useState(false);
  const [tipsData, setTipsData] = useState([]);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [tipsViewMoreData, setTipsViewMoreData] = useState([]);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipRunnerOpen, setTooltipRunnerOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredRaceIndex, setHoveredRaceIndex] = useState(null);
  const [hoveredRaceObjIndex, setHoveredRaceObjIndex] = useState(null);
  const [tipsRaceData, setTipsRaceData] = useState([]);
  const [tipsRaceLoading, setTipsRaceLoading] = useState(false);
  const [tipsViewMoreCount, setTipsViewMoreCount] = useState(0);
  const [tipsViewMorePage, setTipsViewMorePage] = useState(1);

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleBlackBookModal = (title, id, type) => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      setSelectedTitle(title);
      setSelectedType(type);
      setSelectedId(id);
      handleModal();
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const fetchRaceRunner = async (id) => {
    setisrunnnerLoading(true);
    try {
      const { status, data } = await axiosInstance.get(`/events/runner/${id}`);
      if (status === 200) {
        setraceTrackdata(data?.data?.race);
        setRunnerData(data?.data?.runners);
        setisrunnnerLoading(false);

        let RunnerExtraInfoData = data?.data?.runners?.map((item) => {
          return item?.RunnerInfos?.[0];
        });

        let finalData = RunnerExtraInfoData?.map((item) => {
          return JSON.parse(item?.info);
        });
        // let runnerExtraInfoDatas =JSON.parse(finalData)
      }
    } catch (err) {
      setisrunnnerLoading(false);
    }
  };
  const fetchTipsById = async (id) => {
    setIsTipsLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `expertTips/web/get/${params?.id}`
      );
      if (status === 200) {
        // setTipsData(data?.result);
        fetchRaceData(data?.result?.tips?.EventId, data?.result?.tips?.UserId);
        const tipsOddsid = [
          data?.result?.tips?.betParticipantId,
          data?.result?.tips?.wayParticipantId,
          data?.result?.tips?.layParticipantId,
        ];
        tipsOddsData(tipsOddsid, data?.result);
      }
    } catch (err) {
      setIsTipsLoading(false);
    }
  };

  const tipsOddsData = async (id, expertTipsData) => {
    const payload = id;
    try {
      const { status, data } = await axiosInstance.post(
        `expertTips/odds`,
        payload
      );
      if (status === 200) {
        let betParticipantOdds = data?.odds?.filter(
          (item) => item?.RacingParticipantId === id?.[0]
        );
        let wayParticipantOdds = data?.odds?.filter(
          (item) => item?.RacingParticipantId === id?.[1]
        );
        let layParticipantOdds = data?.odds?.filter(
          (item) => item?.RacingParticipantId === id?.[2]
        );
        const mergeData = {
          tips: {
            ...expertTipsData?.tips,
            betParticipantOdds,
            wayParticipantOdds,
            layParticipantOdds,
          },
        };
        setTipsData(mergeData);
        setIsTipsLoading(false);
      }
    } catch (err) {
      setIsTipsLoading(false);
    }
  };
  const fetchRaceData = async (eventId, UserId) => {
    setTipsRaceLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `expertTips/web/win/get/${eventId}/${UserId}`
      );
      if (status === 200) {
        setTipsRaceLoading(false);
        setTipsRaceData(data?.result?.races);
      }
    } catch (err) {
      setTipsRaceLoading(false);
    }
  };
  const fetchViewMoreTips = async (page) => {
    setViewMoreLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `expertTips/web/viewMore?SportId=${params?.sportId}&id=${params?.id}&limit=3&page=${page}`
      );
      if (status === 200) {
        setViewMoreLoading(false);
        setTipsViewMoreData(data?.result?.rows);
        setTipsViewMoreCount(data?.result?.count);
      }
    } catch (err) {
      setViewMoreLoading(false);
    }
  };

  const handleAllBBPaginationClick = (event, page) => {
    setTipsViewMorePage(Number(page));
    fetchViewMoreTips(Number(page));
  };

  const allBBpageNumbers = [];

  if (tipsViewMoreCount > 0) {
    for (let i = 1; i <= Math.ceil(tipsViewMoreCount / 3); i++) {
      allBBpageNumbers.push(i);
    }
  }

  let currentDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchBookKeeper();
  }, []);

  useEffect(() => {
    fetchTipsById();
    fetchViewMoreTips(1);
  }, [params]);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };
  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(tipsInfo?.SportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const oddsicon = (BookKeeperId, type, item, itemRace) => {
    const newData = tipsInfo?.[itemRace]?.ApiRaceIdentifiers?.map((obj) => {
      const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter(
        (item) => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null,
      };
    });
    const filteredData = newData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="bookmaker"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      ) : (
        <></>
      );
    } else {
      return (
        <a
          href={
            filteredData?.length > 0 && filteredData?.[0]?.url
              ? filteredData?.[0]?.url + `?Referrer=SmartB`
              : iconData?.affiliate_link
          }
          // href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="bookmaker"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      );
    }
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip
  ) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    // let isAdded = BetslipData?.some((el) => {
    //   if (
    //     el?.BookKeeperId == BookKeeperId &&
    //     el?.betOfferId == BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId
    //     //  &&
    //     // el?.eventId == Number(params?.raceId)
    //   )
    //     return true;
    //   else return false;
    // });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8)

    //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0]
    //   return (
    //     Bet365Data && Bet365Data?.length > 0 ?
    //       (<a href={Odds?.providerMarketId && Odds?.providerParticipantId ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
    //         }~${Odds?.intValue}~1&bet=1` : `${iconData?.affiliate_link}`} target="_blank" rel="noopener noreferrer" className="odds-link" >  {odds === "SP" ? "SP" : Number(odds).toFixed(2)} </a>
    //       ) : (<></>)
    //   )
    // } else {

    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      <span
        // className={isAdded && IsBetslip === "betslip" ? "betslip-added odds-link" : "odds-link"}
        // onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : isAdded ? handleDeleteBetslip(BookKeeperId, item) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
        onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
        className="odds-link"
      >
        {/* {IsBetslip === "betslip" ? (
          // && !isResult
          <Tooltip
            title="Bet"
            className="bet-tooltip"
            placement="top"
          >
            <span className="bet-now">
              {" "}
              {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
            </span>
          </Tooltip>
        ) : (
          <> */}
        {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
        {/* </>
        )} */}
      </span>
      // </a>
    );
    // }
  };

  const fetchBestOpenValue = (data) => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno == 0) {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          return fetchClickableOdds(
            maxno,
            providerid?.[0],
            "header",
            data,
            [],
            "nobetslip"
          );
        } else {
          return "-";
        }
      } else {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);
        return fetchClickableOdds(
          maxno,
          providerid?.[0],
          "header",
          data,
          [],
          "nobetslip"
        );
      }
      // return maxno;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };

  const fetchBestOpenIcon = (data, item) => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header", data, item);
      } else {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (
                obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === newmaxno
              ) {
                return obj?.BookKeeperId;
              }
            })
            .filter((x) => x !== undefined);
          return oddsicon(providerid?.[0], "header", data, item);
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };

  const fetchCurrentBestValue = (data, fulldata) => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return fetchClickableOdds(
          maxno,
          providerid?.[0],
          "header",
          data,
          fulldata,
          "betslip"
        );
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  const fetchCurrentBestIcon = (data, item) => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header", data, item);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const fetchFluctuationGraph = (data) => {
    let selectedId = null;

    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        selectedId = providerid?.[0];
      } else {
        selectedId = null;
      }
    } else {
      selectedId = null;
    }

    if (selectedId) {
      const selectedFilterData = data?.filter((item) => {
        return item?.BookKeeperId === selectedId;
      });
      let FlucData = [];
      const selectedGraphIdData = selectedFilterData?.[0]?.RaceOdds?.[0]?.RaceOddFlucs?.map(
        (odds) => {
          return FlucData?.push({
            point: odds?.intValue,
          });
        }
      );
      return <GetTipsHighChart FlucData={FlucData} />;
    } else {
      return <Box className="no-data-tipsGarph">No Data Available</Box>;
    }
  };
  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const eventName = (tipsData && tipsData?.tips?.Event?.eventName) || "";
  const dayName = fetchDayName(
    (tipsData && tipsData?.tips?.Event?.eventDate) || null
  );
  const tipsInfo = (tipsData && tipsData?.tips) || {};
  const raceList = (tipsData && tipsData?.races) || [];

  const handleTooltipContentClick = (event, index) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(index);
  };

  const handleTooltipRaceContentClick = (event, index, objindex) => {
    event.stopPropagation();
    setTooltipRunnerOpen(!tooltipRunnerOpen);
    setHoveredRaceIndex(index);
    setHoveredRaceObjIndex(objindex);
  };

  const handleOutsideClick = (item, index) => {
    if (hoveredIndex === index) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleRaceOutsideClick = (item, index, obj, objindex) => {
    if (hoveredRaceIndex === index && hoveredRaceObjIndex === objindex) {
      if (tooltipRunnerOpen) {
        setTooltipRunnerOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, index) => {
    return (
      <Box
        className="custom-tooltip blackbook-icon"
        onClick={(e) => handleTooltipContentClick(e, index)}
      >
        {tipsInfo?.[item?.Participant]?.animal?.isBlackBook ||
        tipsInfo?.[item?.Participant]?.Jockey?.isBlackBook ||
        tipsInfo?.[item?.Participant]?.Trainer?.isBlackBook > 0 ? (
          <BlackbookRight className="book-icon" />
        ) : (
          <BlackbookPlus className="book-icon" />
        )}
        {hoveredIndex === index ? (
          tooltipOpen && (
            <Box
              // className={sportId === "3" ? `tooltip greyhound-tooltip` : `tooltip`}
              className="tooltip"
            >
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-blackbook">
                <AddBookIcon /> {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
              </Typography>
              <Box className="black-details">
                {tipsInfo?.[item?.Participant]?.animal ? (
                  <>
                    <Box className="tooltip-details">
                      <Typography
                        className="details"
                        onClick={() =>
                          handleBlackBookModal(
                            tipsInfo?.[item?.Participant]?.animal?.name,
                            tipsInfo?.[item?.Participant]?.animal?.id,
                            "Runner"
                          )
                        }
                      >
                        Runner: {tipsInfo?.[item?.Participant]?.animal?.name}
                      </Typography>
                      {tipsInfo?.[item?.Participant]?.animal?.isBlackBook >
                      0 ? (
                        <RightBlack className="icon-blackbook" />
                      ) : (
                        <PlusBlack className="icon-blacbook" />
                      )}
                    </Box>
                  </>
                ) : (
                  ""
                )}
                {tipsInfo?.[item?.Participant]?.Jockey ? (
                  <>
                    <Box className="tooltip-details">
                      <Typography
                        className="details"
                        onClick={() =>
                          handleBlackBookModal(
                            tipsInfo?.[item?.Participant]?.Jockey?.name,
                            tipsInfo?.[item?.Participant]?.Jockey?.id,
                            "Jockey"
                          )
                        }
                      >
                        {params?.sportId === "2"
                          ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                          : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                        {tipsInfo?.[item?.Participant]?.Jockey?.name}
                      </Typography>
                      {tipsInfo?.[item?.Participant]?.Jockey?.isBlackBook >
                      0 ? (
                        <RightBlack className="icon-blackbook" />
                      ) : (
                        <PlusBlack className="icon-blacbook" />
                      )}
                    </Box>
                  </>
                ) : (
                  ""
                )}
                {tipsInfo?.[item?.Participant]?.Trainer ? (
                  <>
                    <Box className="tooltip-details">
                      <Typography
                        className="details"
                        onClick={() =>
                          handleBlackBookModal(
                            tipsInfo?.[item?.Participant]?.Trainer?.name,
                            tipsInfo?.[item?.Participant]?.Trainer?.id,
                            "Trainer"
                          )
                        }
                      >
                        Trainer: {tipsInfo?.[item?.Participant]?.Trainer?.name}
                      </Typography>
                      {tipsInfo?.[item?.Participant]?.Trainer?.isBlackBook >
                      0 ? (
                        <RightBlack className="icon-blackbook" />
                      ) : (
                        <PlusBlack className="icon-blacbook" />
                      )}
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const handleRaceOpenTooltip = (item, index, obj, objindex) => {
    return (
      <Box
        className="custom-tooltip blackbook-icon"
        onClick={(e) => handleTooltipRaceContentClick(e, index, objindex)}
      >
        {obj?.animal?.isBlackBook ||
        obj?.Jockey?.isBlackBook ||
        obj?.Trainer?.isBlackBook > 0 ? (
          <BlackbookRight className="book-icon" />
        ) : (
          <BlackbookPlus className="book-icon" />
        )}
        {hoveredRaceIndex === index && hoveredRaceObjIndex === objindex ? (
          tooltipRunnerOpen && (
            <Box
              // className={sportId === "3" ? `tooltip greyhound-tooltip` : `tooltip`}
              className="tooltip"
            >
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-blackbook">
                <AddBookIcon /> {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}
              </Typography>
              <Box className="black-details">
                {obj?.animal ? (
                  <>
                    <Box className="tooltip-details">
                      <Typography
                        className="details"
                        onClick={() =>
                          handleBlackBookModal(
                            obj?.animal?.name,
                            obj?.animal?.id,
                            "Runner"
                          )
                        }
                      >
                        Runner: {obj?.animal?.name}
                      </Typography>
                      {obj?.animal?.isBlackBook > 0 ? (
                        <RightBlack className="icon-blackbook" />
                      ) : (
                        <PlusBlack className="icon-blacbook" />
                      )}
                    </Box>
                  </>
                ) : (
                  ""
                )}
                {obj?.Jockey ? (
                  <>
                    <Box className="tooltip-details">
                      <Typography
                        className="details"
                        onClick={() =>
                          handleBlackBookModal(
                            obj?.Jockey?.name,
                            obj?.Jockey?.id,
                            "Jockey"
                          )
                        }
                      >
                        {params?.sportId === "2"
                          ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                          : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                        {obj?.Jockey?.name}
                      </Typography>
                      {obj?.Jockey?.isBlackBook > 0 ? (
                        <RightBlack className="icon-blackbook" />
                      ) : (
                        <PlusBlack className="icon-blacbook" />
                      )}
                    </Box>
                  </>
                ) : (
                  ""
                )}
                {obj?.Trainer ? (
                  <>
                    <Box className="tooltip-details">
                      <Typography
                        className="details"
                        onClick={() =>
                          handleBlackBookModal(
                            obj?.Trainer?.name,
                            obj?.Trainer?.id,
                            "Trainer"
                          )
                        }
                      >
                        Trainer: {obj?.Trainer?.name}
                      </Typography>
                      {obj?.Trainer?.isBlackBook > 0 ? (
                        <RightBlack className="icon-blackbook" />
                      ) : (
                        <PlusBlack className="icon-blacbook" />
                      )}
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const handlemarrketOdds = (item) => {
    return (
      <>
        <Box className="right-odds-details">
          <Box className="best-current-wrap">
            <Box className="odds-wrap">
              <Box className="odds-header">
                <Typography className="header-name">Best at Open</Typography>
              </Box>
              <Box className="odds-value-wrap">
                <Box className="mb-10 tip-odds-detail">
                  <span className="odds-value">
                    {" "}
                    {fetchBestOpenValue(tipsInfo?.[item?.odds])}
                  </span>
                </Box>
                {fetchBestOpenIcon(tipsInfo?.[item?.odds], item?.Race)}
              </Box>
            </Box>
            <Box className="odds-wrap">
              <Box className="odds-header odds-header-dark">
                <Typography className="header-name">Current Best</Typography>
              </Box>
              <Box className="odds-value-wrap odds-value-wrap-dark">
                <Box className="mb-10">
                  <span className="odds-value">
                    {" "}
                    {fetchCurrentBestValue(tipsInfo?.[item?.odds], [])}
                  </span>
                </Box>
                {fetchCurrentBestIcon(tipsInfo?.[item?.odds], item?.Race)}
              </Box>
            </Box>
          </Box>
          <Box className="odds-wrap odds-fluctuation">
            <Box className="odds-header">
              <Typography className="header-name">Fluctuation Graph</Typography>
            </Box>
            <Box>{fetchFluctuationGraph(tipsInfo?.[item?.odds])}</Box>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <>
      {/* <AdvertisingBannner src={banner} /> */}
      {/* {fetchAds(1, "218px", banner)} */}
      {fetchPageHeaderAds("218px", banner)}
      <Box className="allracing-wrap allsport-tips-wrap">
        <Box className="side-space">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                {localesData?.MENU?.HOME}
              </Link>
              <Link underline="hover" color="inherit" to="/racing">
                {localesData?.MENU?.RACING}
              </Link>
              <Typography>{eventName}</Typography>
            </Breadcrumbs>
          </Box>
        </Box>
        {isTipsLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <Box className="sport-tab sport-racing-tips">
            <Box className="racing-tab">
              <Box className="tips-mainheader">{eventName}</Box>
              {/* Racing Location */}

              <Box className="racing-location">
                <ul>
                  {tipsInfo?.Event?.track?.Country ? (
                    <li>
                      {tipsInfo?.Event?.track?.Country?.country_flag?.includes(
                        "uploads"
                      ) ? (
                        <img
                          src={
                            Config.mediaURL +
                            tipsInfo?.Event?.track?.Country?.country_flag
                          }
                          width="22px"
                          alt="Race Country"
                        />
                      ) : (
                        <img
                          src={tipsInfo?.Event?.track?.Country?.country_flag}
                          width="22px"
                          alt="Race Country"
                        />
                      )}

                      <span className="country-name">
                        {tipsInfo?.Event?.track?.Country?.country}
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                  {tipsInfo?.Event?.track?.State ? (
                    <li>
                      <span>{tipsInfo?.Event?.track?.State?.state}</span>
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <span>{eventName}</span>
                  </li>
                </ul>
              </Box>
            </Box>

            {/* Tracklist TabBar */}
            <Box className="race-track-blackbook">
              <Box className="blackbook-button-wrap">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="blackbook-button"
                  onClick={() =>
                    handleBlackBookModal(
                      tipsInfo?.Event?.track?.name,
                      tipsInfo?.Event?.track?.id,
                      "Track"
                    )
                  }
                >
                  <span style={{ display: "flex" }}>
                    <BlackBookIcon />{" "}
                  </span>{" "}
                  <span style={{ paddingLeft: "8px" }}>
                    {" "}
                    {localesData?.BLACKBOOK?.ADD_TO_MY_BLACKBOOK}{" "}
                  </span>
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        <Box className="tips-details-wrap">
          {isTipsLoading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : (
            <>
              <Box className="tips-key-details-wrap mb-18">
                <Typography variant="h6" className="tips-heading">
                  Key Comments
                </Typography>
                <Typography className="tips-para">
                  {eventName} Tips for {dayName}'s meeting
                </Typography>
                <Typography className="tips-comments">
                  {tipsInfo?.keyComment}
                </Typography>
              </Box>
              {tipsType?.map((item, index) => {
                return (
                  <>
                    <Box className="tips-key-details-wrap mb-18">
                      <Typography variant="h6" className="tips-heading">
                        {item?.name}
                      </Typography>
                      {tipsInfo?.[item?.Comment] ? (
                        <Typography
                          className="tips-para cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/racing/${
                                tipsInfo?.SportId === 1
                                  ? "horse"
                                  : tipsInfo?.SportId === 2
                                  ? "harness"
                                  : "greyhounds"
                              }/${tipsInfo?.SportId}/${
                                tipsInfo?.[item?.Race]?.id
                              }/${tipsInfo?.Event?.trackId}/${
                                tipsInfo?.EventId
                              }?date=${moment
                                .utc(tipsInfo?.Event?.eventDate)
                                .format("YYYY-MM-DD")}&intl=${
                                tipsInfo?.Event?.track?.Country?.id === 13 ||
                                tipsInfo?.Event?.track?.Country?.id === 157
                                  ? false
                                  : true
                              }`
                            )
                          }
                        >
                          {/* {tipsInfo?.[item?.Participant]?.animal?.name} (
                          {eventName} Race {tipsInfo?.[item?.Race]?.raceNumber}{" "}
                          No.
                          {tipsInfo?.[item?.Participant]?.runnerNumber} ) */}
                          {tipsInfo?.[item?.Race]?.raceName}
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Typography className="tips-comments">
                        {tipsInfo?.[item?.Comment] ? (
                          <>
                            <span className="bold">Comment :</span>{" "}
                            {tipsInfo?.[item?.Comment]}
                          </>
                        ) : (
                          item?.noComment
                        )}
                      </Typography>
                      {tipsInfo?.[item?.Comment] ? (
                        <Box className="analyst-price-wrap">
                          <Typography className="price-text">
                            {/* {"$" + Number(tipsInfo?.[item?.Price]).toFixed(2)} */}
                          </Typography>
                          <Typography className="analyst-text">
                            <span className="analyst-bold">Analyst: </span>
                            {tipsInfo?.User?.firstName +
                              " " +
                              tipsInfo?.User?.lastName}
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                      {tipsInfo?.[item?.Comment] ? (
                        <Box className="tips-runner-details-wrap">
                          <Box className="left-runner-details">
                            <ClickAwayListener
                              onClickAway={() =>
                                handleOutsideClick(item, index)
                              }
                            >
                              <Box className="runner-name-wrap">
                                <Typography
                                  className="runner-name cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/racing/${
                                        tipsInfo?.SportId === 1
                                          ? "horse"
                                          : tipsInfo?.SportId === 2
                                          ? "harness"
                                          : "greyhounds"
                                      }/${tipsInfo?.SportId}/${
                                        tipsInfo?.[item?.Race]?.id
                                      }/${tipsInfo?.Event?.trackId}/${
                                        tipsInfo?.EventId
                                      }?date=${moment
                                        .utc(tipsInfo?.Event?.eventDate)
                                        .format("YYYY-MM-DD")}&intl=${
                                        tipsInfo?.Event?.track?.Country?.id ===
                                          13 ||
                                        tipsInfo?.Event?.track?.Country?.id ===
                                          157
                                          ? false
                                          : true
                                      }`
                                    )
                                  }
                                >
                                  {tipsInfo?.[item?.Participant]?.runnerNumber}.
                                  {tipsInfo?.[item?.Participant]?.animal?.name}{" "}
                                  (
                                  {tipsInfo?.[item?.Participant]?.barrierNumber}
                                  )
                                </Typography>
                                {handleOpenTooltip(item, index)}
                              </Box>
                            </ClickAwayListener>
                            <Box className="runner-info">
                              {tipsInfo?.SportId === 1 ? (
                                <Typography className="runner-info-details light">
                                  Weight:{" "}
                                  {Number(
                                    tipsInfo?.[item?.Participant]?.JockeyWeight
                                  ).toFixed(2) + "Kg"}
                                </Typography>
                              ) : (
                                ""
                              )}

                              <Typography className="runner-info-details dark">
                                {tipsInfo?.SportId === 2
                                  ? tipsInfo?.[item?.Participant]?.Jockey?.name
                                    ? `Driver: ${
                                        tipsInfo?.[item?.Participant]?.Jockey
                                          ?.name
                                      }`
                                    : ""
                                  : tipsInfo?.[item?.Participant]?.Jockey?.name
                                  ? `Jockey: ${
                                      tipsInfo?.[item?.Participant]?.Jockey
                                        ?.name
                                    }`
                                  : ""}
                              </Typography>
                            </Box>
                            <Box className="runner-info">
                              {tipsInfo?.[item?.Participant]?.Trainer?.name ? (
                                <Typography className="runner-info-details dark">
                                  Trainer:{" "}
                                  {tipsInfo?.[item?.Participant]?.Trainer?.name}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Box>
                          {handlemarrketOdds(item)}
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </>
                );
              })}
              <Box className="tip-key-details-grid mb-18">
                {tipsRaceData?.map((item, index) => {
                  return (
                    <>
                      <Box className="tips-key-details-wrap" key={index}>
                        <Typography variant="h6" className="tips-heading">
                          Race {index + 1}
                        </Typography>
                        <Typography
                          className="tips-para cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/racing/${
                                item?.sportId === 1
                                  ? "horse"
                                  : item?.sportId === 2
                                  ? "harness"
                                  : "greyhounds"
                              }/${item?.sportId}/${item?.RaceId}/${
                                item?.trackId
                              }/${item?.EventId}?date=${moment
                                .utc(item?.eventDate)
                                .format("YYYY-MM-DD")}&intl=${
                                item?.Track?.countryId === 13 ||
                                item?.Track?.countryId === 157
                                  ? false
                                  : true
                              }`
                            )
                          }
                        >
                          {/* {item?.runners?.[0]?.animal?.name} ({eventName} Race{" "}
                          {index + 1} No. {item?.runners?.[0]?.runnerNumber}) */}
                          {item?.raceName}
                        </Typography>
                        {item?.runners?.map((obj, objindex) => {
                          return (
                            <>
                              {obj?.isScratched === "false" ||
                              obj?.isScratched === null ||
                              obj?.isScratched === "0" ? (
                                <Box
                                  className="race-runner-details"
                                  key={objindex}
                                >
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      handleRaceOutsideClick(
                                        item,
                                        index,
                                        obj,
                                        objindex
                                      )
                                    }
                                  >
                                    <Box className="runner-name-wrap-grid">
                                      <Typography
                                        className="runner-name cursor-pointer"
                                        onClick={() =>
                                          navigate(
                                            `/racing/${
                                              item?.sportId === 1
                                                ? "horse"
                                                : item?.sportId === 2
                                                ? "harness"
                                                : "greyhounds"
                                            }/${item?.sportId}/${
                                              item?.RaceId
                                            }/${item?.trackId}/${
                                              item?.EventId
                                            }?date=${moment
                                              .utc(item?.eventDate)
                                              .format("YYYY-MM-DD")}&intl=${
                                              item?.Track?.countryId === 13 ||
                                              item?.Track?.countryId === 157
                                                ? false
                                                : true
                                            }`
                                          )
                                        }
                                      >
                                        {obj?.runnerNumber}.{obj?.animal?.name}{" "}
                                        ({obj?.barrierNumber})
                                      </Typography>
                                      {/* <Box className="blackbook-icon">
                                    <BlackbookPlus />
                                  </Box> */}
                                      {handleRaceOpenTooltip(
                                        item,
                                        index,
                                        obj,
                                        objindex
                                      )}
                                    </Box>
                                  </ClickAwayListener>
                                  <GetRuunerodds
                                    itemApiRaceIdentifier={item}
                                    obj={obj}
                                    BookkeeperData={BookkeeperData}
                                    raceTrackdata={raceTrackdata}
                                    tipsInfo={tipsInfo}
                                  />
                                  {/* {fecthRaceRunnerOdds(obj)} */}
                                </Box>
                              ) : (
                                <Box
                                  className="race-runner-details"
                                  key={objindex}
                                >
                                  <Box className="runner-name-wrap-grid">
                                    <Typography
                                      className="runner-name cursor-pointer is-scratched"
                                      onClick={() =>
                                        navigate(
                                          `/racing/${
                                            item?.sportId === 1
                                              ? "horse"
                                              : item?.sportId === 2
                                              ? "harness"
                                              : "greyhounds"
                                          }/${item?.sportId}/${item?.RaceId}/${
                                            item?.trackId
                                          }/${item?.EventId}?date=${moment
                                            .utc(item?.eventDate)
                                            .format("YYYY-MM-DD")}&intl=${
                                            item?.Track?.countryId === 13 ||
                                            item?.Track?.countryId === 157
                                              ? false
                                              : true
                                          }`
                                        )
                                      }
                                    >
                                      {obj?.runnerNumber}.{obj?.animal?.name} (
                                      {obj?.barrierNumber})
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                            </>
                          );
                        })}
                      </Box>
                    </>
                  );
                })}
              </Box>
            </>
          )}
          {tipsViewMoreData?.length > 0 ? (
            <Box className="tips-key-details-wrap">
              <Typography
                variant="h6"
                className="tips-heading view-more-tips-heading"
              >
                View more tips
              </Typography>
              {viewMoreLoading ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : (
                tipsViewMoreData?.map((item, index) => {
                  return (
                    <>
                      <Box className="view-more-tips-wrap" key={index}>
                        <Typography className="tips-para view-tips-para">
                          {item?.Sport?.sportName} Tips : SA{" "}
                          {fetchDayName(item?.Event?.eventDate)}{" "}
                          {moment(item?.Event?.eventDate).format(
                            "Do MMMM YYYY"
                          )}
                        </Typography>
                        <Typography className="tips-comments view-tips-comments">
                          Our {item?.Event?.eventName} Racing Tips for{" "}
                          {fetchDayName(item?.Event?.eventDate)}'s meeting. Key
                          Comments {item?.keyComment} Best Bet{" "}
                          {item?.betComment}
                        </Typography>
                        <Box className="view-tips-button">
                          <Typography
                            className="view-tips"
                            onClick={() =>
                              navigate(`/gettips/${item?.SportId}/${item?.id}`)
                            }
                          >
                            View tips
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })
              )}
              <Box className="get-tips-Pagination">
                <Pagination
                  // hideNextButton
                  // hidePrevButton
                  disabled={tipsViewMoreCount / 3 > 1 ? false : true}
                  page={tipsViewMorePage}
                  onChange={(e, value) => handleAllBBPaginationClick(e, value)}
                  count={allBBpageNumbers[allBBpageNumbers?.length - 1]}
                  siblingCount={2}
                  boundaryCount={1}
                  size="small"
                />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <OurPartner />
      </Box>
      <BlackBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        listingFunction={fetchTipsById}
      />
    </>
  );
};

export default GetTips;
