import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { Box, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import RaceGreyhounds from "../../../assets/images/Sport/races_greyhounds.svg";
import { ReactComponent as AmericanFootball } from "../../../assets/images/SportIcon/blueSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../../assets/images/SportIcon/blueSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../../assets/images/SportIcon/blueSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../../assets/images/SportIcon/blueSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../../assets/images/SportIcon/blueSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../../assets/images/SportIcon/blueSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../../assets/images/SportIcon/blueSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../../assets/images/SportIcon/blueSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../../assets/images/SportIcon/blueSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../../assets/images/SportIcon/blueSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../../assets/images/SportIcon/blueSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../../assets/images/SportIcon/blueSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../../assets/images/SportIcon/blueSportIcon/Tennis.svg";
import { ReactComponent as Batting } from "../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Bowling } from "../../../assets/images/SportIcon/ball.svg";
import { IntlContext } from "src/App";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosInstance from "../../../helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import { release } from "src/helpers/context/release";

const FeaturedSport = ({ data }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const [featureSportData, setFeatureSportData] = useState([]);
  const [featureSportLoader, setFeatureSportLoader] = useState(false);
  const [dragging, setDragging] = useState(false);
  const maxDots = 10;

  const settingsSport = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: false,
    // adaptiveHeight: true,
    beforeChange: (current, next) => {
      handleBeforeChange();
    },
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 610,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // if (featureSportData?.length > maxDots) {
  //   settingsSport.dots = false; // If you have more than 10 slides, hide the dots
  // }

  useEffect(() => {
    fetchFeaturedSport();
  }, [data]);

  const fetchFeaturedSport = async () => {
    setFeatureSportLoader(true);
    let SportId =
      data?.type === "cricket"
        ? 4
        : data?.type === "rugbyleague"
        ? 12
        : data?.type === "rugbyunion"
        ? 13
        : data?.type === "basketball"
        ? 10
        : data?.type === "americanfootball"
        ? 15
        : data?.type === "australianrules"
        ? 9
        : data?.type === "baseball"
        ? 11
        : data?.type === "boxing"
        ? 6
        : data?.type === "icehockey"
        ? 17
        : data?.type === "mma"
        ? 5
        : data?.type === "soccer"
        ? 8
        : data?.type === "tennis"
        ? 7
        : data?.type === "golf"
        ? 16
        : "";
    let passApi = data
      ? `/sportFeatured?SportId=${SportId ? SportId : ""}`
      : `/sportFeatured?SportId=${
          release[Config.release]?.sportId
            ? release[Config.release]?.sportId?.toString()
            : ""
        }`;
    try {
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setFeatureSportLoader(false);
        setFeatureSportData(data?.result);
      }
    } catch (error) {
      setFeatureSportLoader(false);
    }
  };

  const raceIcon = name => {
    if (name === "American Football") {
      return <AmericanFootball />;
    } else if (name === "Australian Rules") {
      return <AustralianRules />;
    } else if (name === "Baseball") {
      return <Baseball />;
    } else if (name === "Basketball") {
      return <Basketball />;
    } else if (name === "Boxing") {
      return <Boxing />;
    } else if (name === "Cricket") {
      return <Cricket />;
    } else if (name === "Golf") {
      return <Golf />;
    } else if (name === "Ice Hockey") {
      return <IceHockey />;
    } else if (name === "Mixed Martial Arts") {
      return <MixedMartialArts />;
    } else if (name === "Rugby League") {
      return <RugbyLeague />;
    } else if (name === "Rugby Union") {
      return <RugbyUnion />;
    } else if (name === "Soccer") {
      return <Soccer />;
    } else if (name === "Tennis") {
      return <Tennis />;
    }
  };

  const fetchCurrentBestOdds = (data, team) => {
    let allTeamOdds =
      data?.Sport?.sportName === "Cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.Sport?.sportName === "Basketball"
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : data?.Sport?.sportName === "American Football"
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : data?.Sport?.sportName === "Australian Rules"
        ? data?.ARBetOffers?.[0]?.AROdds
        : data?.Sport?.sportName === "Golf"
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : data?.Sport?.sportName === "Tennis"
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : data?.Sport?.sportName === "Baseball"
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : data?.Sport?.sportName === "Ice Hockey"
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : data?.Sport?.sportName === "Boxing"
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : data?.Sport?.sportName === "Mixed Martial Arts"
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : data?.Sport?.sportName === "Soccer"
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return data?.Sport?.sportName === "Cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Basketball"
        ? item?.NBATeamId == data?.homeTeamId
        : data?.Sport?.sportName === "American Football"
        ? item?.AFLTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Australian Rules"
        ? item?.ARTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Golf"
        ? item?.GolfTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Tennis"
        ? item?.TennisTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Baseball"
        ? item?.BaseballTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Ice Hockey"
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Boxing"
        ? item?.BoxingTeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Mixed Martial Arts"
        ? item?.MMATeamId == data?.homeTeamId
        : data?.Sport?.sportName === "Soccer"
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return data?.Sport?.sportName === "Cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Basketball"
        ? item?.NBATeamId == data?.awayTeamId
        : data?.Sport?.sportName === "American Football"
        ? item?.AFLTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Australian Rules"
        ? item?.ARTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Golf"
        ? item?.GolfTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Tennis"
        ? item?.TennisTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Baseball"
        ? item?.BaseballTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Ice Hockey"
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Boxing"
        ? item?.BoxingTeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Mixed Martial Arts"
        ? item?.MMATeamId == data?.awayTeamId
        : data?.Sport?.sportName === "Soccer"
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;

    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);

    if (maxno !== -1) {
      if (maxno !== 0) {
        let bookkeeperid = teamData
          ?.map(obj => {
            if (obj?.odd === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);
        return (
          <Box className="best-value">
            <span className="best">Best</span>
            <span className="best-odds">{maxno ? maxno : "NOA"}</span>
          </Box>
        );

        // fetchClickableOdds(maxno, bookkeeperid?.[0], "header", teamData);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const handleNavigate = useCallback(
    data => {
      if (!dragging) {
        let sportName =
          data?.Sport?.sportName === "Cricket"
            ? "cricket"
            : data?.Sport?.sportName === "Basketball"
            ? "basketball"
            : data?.Sport?.sportName === "American Football"
            ? "americanfootball"
            : data?.Sport?.sportName === "Australian Rules"
            ? "australianrules"
            : data?.Sport?.sportName === "Golf"
            ? "golf"
            : data?.Sport?.sportName === "Tennis"
            ? "tennis"
            : data?.Sport?.sportName === "Baseball"
            ? "baseball"
            : data?.Sport?.sportName === "Ice Hockey"
            ? "icehockey"
            : data?.Sport?.sportName === "Boxing"
            ? "boxing"
            : data?.Sport?.sportName === "Mixed Martial Arts"
            ? "mma"
            : data?.Sport?.sportName === "Soccer"
            ? "soccer"
            : data?.Sport?.sportName === "Rugby League"
            ? "rugbyleague"
            : data?.Sport?.sportName === "Rugby Union"
            ? "rugbyunion"
            : "rugbyunionsevens";
        if (Config?.release == "IN") {
          navigate(`/teamsports/${sportName}/odds/0/false`);
        } else {
          navigate(`/teamsports/${sportName}/odds/${data?.id}/true`);
        }
      }
    },
    [dragging]
  );

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const getTeamScores = (data, teamId, teamType) => {
    let teamScore =
      data?.SportId === 4
        ? data?.ScoreBoard
        : data?.SportId === 10
        ? data?.NBAScores
        : data?.SportId === 15
        ? data?.AFLScores
        : data?.SportId === 9
        ? data?.ARScores
        : data?.SportId === 16
        ? data?.GolfScores
        : data?.SportId === 7
        ? data?.TennisScores
        : data?.SportId === 11
        ? data?.BaseballScores
        : data?.SportId === 17
        ? data?.IceHockeyScores
        : data?.SportId === 6
        ? data?.BoxingScores
        : data?.SportId === 5
        ? data?.MMAScores
        : data?.SportId === 8
        ? data?.ScoreBoard
        : data?.RLScores;
    const team =
      data?.SportId !== 4 &&
      data?.SportId !== 8 &&
      teamScore?.filter(item => item?.teamId === teamId);
    const teamDataScore = team?.[0]?.score;
    const scores = teamDataScore?.current;
    return (
      <>
        {data?.SportId === 4 ? (
          <>
            {(teamType === "hometeam" && teamScore?.Ebat == 1) ||
            (teamType === "awayteam" && teamScore?.Ebat == 2) ? (
              <span className="score-icon">
                <Batting />
              </span>
            ) : (
              <span className="score-icon">
                <Bowling />
              </span>
            )}
            {teamType === "hometeam" ? (
              teamScore?.Exd > "1" ? (
                <Typography className="team-score">
                  {teamScore?.Tr1C1 ?? ""}
                  {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                    ? ""
                    : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
                      `/${teamScore?.Tr1CW1}${
                        teamScore?.Tr1CD1 && teamScore?.Tr1CD1 === 1 ? "d" : ""
                      }`}{" "}
                  {(teamScore?.Tr1C2 || teamScore?.Tr1C2 === 0) &&
                    `& ${teamScore?.Tr1C2}`}
                  {teamScore?.Tr1CW2 && teamScore?.Tr1CW2 === 10
                    ? ""
                    : (teamScore?.Tr1CW2 || teamScore?.Tr1CW2 === 0) &&
                      `/${teamScore?.Tr1CW2}${
                        teamScore?.Tr1CD2 && teamScore?.Tr1CD2 === 1 ? "d" : ""
                      }`}{" "}
                </Typography>
              ) : (
                <Typography className="team-score">
                  {teamScore?.Tr1C1 ?? ""}
                  {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                    ? ""
                    : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
                      `/ ${teamScore?.Tr1CW1}`}{" "}
                  <span>
                    {" "}
                    {teamScore?.Tr1CO1 ? `(${teamScore?.Tr1CO1})` : ""}
                  </span>{" "}
                </Typography>
              )
            ) : teamScore?.Exd > "1" ? (
              <Typography className="team-score">
                {teamScore?.Tr2C1 ?? ""}
                {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                  ? ""
                  : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
                    `/${teamScore?.Tr2CW1}${
                      teamScore?.Tr2CD1 && teamScore?.Tr2CD1 === 1 ? "d" : ""
                    }`}{" "}
                {(teamScore?.Tr2C2 || teamScore?.Tr2C2 === 0) &&
                  `& ${teamScore?.Tr2C2}`}
                {teamScore?.Tr2CW2 && teamScore?.Tr2CW2 === 10
                  ? ""
                  : (teamScore?.Tr2CW2 || teamScore?.Tr2CW2 === 0) &&
                    `/${teamScore?.Tr2CW2}${
                      teamScore?.Tr2CD2 && teamScore?.Tr2CD2 === 1 ? "d" : ""
                    }`}{" "}
              </Typography>
            ) : (
              <Typography className="team-score">
                {teamScore?.Tr2C1 ?? ""}
                {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                  ? ""
                  : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
                    `/ ${teamScore?.Tr2CW1}`}{" "}
                <span>{teamScore?.Tr2CO1 ? `(${teamScore?.Tr2CO1})` : ""}</span>
              </Typography>
            )}
          </>
        ) : data?.SportId === 8 ? (
          <>
            {teamType === "hometeam" ? (
              <span>{teamScore?.Tr1 ?? "-"}</span>
            ) : (
              <sapn>{teamScore?.Tr2 ?? "-"}</sapn>
            )}{" "}
          </>
        ) : (
          <span>{scores}</span>
        )}
      </>
    );
  };
  return (
    <>
      {featureSportLoader ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : featureSportData?.length > 0 ? (
        <Box className="racing-slider-wrap">
          <Box>
            <Typography variant="h3" className="home-slider-header">
              {localesData?.HOME?.FEATURED_FIXTURES}
            </Typography>

            <Slider
              {...settingsSport}
              afterChange={handleAfterChange}
              className="racing-slick-slider"
            >
              {featureSportData?.map(sport => {
                return (
                  <>
                    <Box
                      className={
                        sport?.status === "inprogress" ||
                        sport?.ScoreBoard?.Epr === 1 ||
                        sport?.ScoreBoard?.Epr === 2
                          ? "racing-slider-details cursor-pointer"
                          : " racing-slider-details sport-slider-details  cursor-pointer"
                      }
                      onClick={() => {
                        handleNavigate(sport);
                      }}
                    >
                      <Box className="slider-header-wrap d-flex text-align content-space-betwen">
                        <Box className="event-name-deatils d-flex text-align">
                          <Box className="d-flex text-align">
                            <span className="sport-icon">
                              {raceIcon(sport?.Sport?.sportName)}
                            </span>
                            <span className="event-name">
                              {sport?.Sport?.sportName}
                            </span>
                          </Box>
                          <span className="tournament-name">
                            {sport?.Sport?.sportName === "Cricket"
                              ? sport?.CricketTournament?.name
                              : sport?.Sport?.sportName === "Rugby League" ||
                                sport?.Sport?.sportName === "Rugby Union" ||
                                sport?.Sport?.sportName === "Rugby Union Sevens"
                              ? sport?.RLTournament?.name
                              : sport?.Sport?.sportName === "American Football"
                              ? sport?.AFLTournament?.name
                              : sport?.Sport?.sportName === "Australian Rules"
                              ? sport?.ARTournament?.name
                              : sport?.Sport?.sportName === "Golf"
                              ? sport?.GolfTournament?.name
                              : sport?.Sport?.sportName === "Tennis"
                              ? sport?.TennisTournament?.name
                              : sport?.Sport?.sportName === "Baseball"
                              ? sport?.BaseballTournament?.name
                              : sport?.Sport?.sportName === "Ice Hockey"
                              ? sport?.IceHockeyTournament?.name
                              : sport?.Sport?.sportName === "Boxing"
                              ? sport?.BoxingTournament?.name
                              : sport?.Sport?.sportName === "Mixed Martial Arts"
                              ? sport?.MMATournament?.name
                              : sport?.Sport?.sportName === "Soccer"
                              ? sport?.SoccerTournament?.name
                              : sport?.Sport?.sportName === "Basketball"
                              ? sport?.NBATournament?.NBACategory === null
                                ? sport?.NBATournament?.name
                                : sport?.NBATournament?.name +
                                  " " +
                                  sport?.NBATournament?.NBACategory?.name
                              : ""}
                          </span>
                        </Box>
                        <Box className="event-time-distance">
                          <Box className="sport-time">
                            <span className="time-distance">
                              {moment
                                .utc(sport?.startTime)
                                .local()
                                .format("DD/MM/YYYY")}
                            </span>{" "}
                            |{" "}
                            <span className="time-distance">
                              {" "}
                              {moment
                                .utc(sport?.startTime)
                                .local()
                                .format("hh:mm A")}
                            </span>{" "}
                          </Box>
                          {release[Config.release]?.oddsCheck && (
                            <>
                              <span className="divider">|</span>
                              <span className="time-distance">
                                {sport?.marketLength ? sport?.marketLength : 0}{" "}
                                Markets
                              </span>
                            </>
                          )}
                        </Box>
                      </Box>
                      {sport?.status === "inprogress" ||
                      sport?.ScoreBoard?.Epr === 1 ? (
                        <Box className="live-wrap">
                          <span className="live-indicator">LIVE</span>
                        </Box>
                      ) : (
                        <></>
                      )}
                      <Box className="slider-event-race-deatils d-flex text-align content-space-betwen sport-deatils">
                        <Box className="d-flex text-align sport-team-wrap sport-team-home">
                          <Box className="flag-wrap">
                            <Box className="flag-img">
                              {fetchTeamlogo(sport, "hometeam")}
                            </Box>
                            <Typography className="team-name">
                              {sport?.homeTeam?.name}
                            </Typography>
                          </Box>
                          {release[Config.release]?.oddsCheck &&
                            fetchCurrentBestOdds(sport, "hometeam")}
                        </Box>
                        <Typography className="team-vs">Vs</Typography>
                        <Box className="d-flex text-align sport-team-wrap">
                          {release[Config.release]?.oddsCheck &&
                            fetchCurrentBestOdds(sport, "awayteam")}
                          <Box className="flag-wrap away-flag-wrap">
                            <Box className="flag-img">
                              {fetchTeamlogo(sport, "awayTeam")}
                            </Box>
                            <Typography className="team-name awayteam-name">
                              {sport?.awayTeam?.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {sport?.status === "inprogress" ||
                      sport?.ScoreBoard?.Epr === 1 ||
                      sport?.ScoreBoard?.Epr === 2 ? (
                        <Box className="featured-sport-score">
                          <Box className="score-details">
                            <span className="score">
                              {getTeamScores(
                                sport,
                                sport?.homeTeamId,
                                "hometeam"
                              )}
                            </span>
                            <span className="score-team">
                              {" "}
                              {sport?.homeTeam?.name}
                            </span>
                          </Box>
                          <Box className="score-details">
                            <span className="desh">-</span>
                          </Box>
                          <Box className="score-details">
                            <span className="score">
                              {getTeamScores(
                                sport,
                                sport?.awayTeamId,
                                "awayteam"
                              )}
                            </span>
                            <span className="score-team ">
                              {sport?.awayTeam?.name}
                            </span>
                          </Box>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                );
              })}
            </Slider>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default FeaturedSport;
