import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { IntlContext } from "src/App";
// import { ReactComponent as FooterLogo } from "../../../assets/images/logo-footer.svg";
import FooterLogo from "../../../assets/images/footer-log.webp";
import { ReactComponent as Location } from "../../../assets/images/icons/location.svg";
import { ReactComponent as Call } from "../../../assets/images/icons/call.svg";
import { ReactComponent as EighteenPlus } from "../../../assets/images/icons/18.svg";
import { ReactComponent as GamblingHelp } from "../../../assets/images/icons/gambling-help.svg";
// import { ReactComponent as PlaystoreImg } from "../../../assets/images/playstorelogo.svg";
import playstore from "../../../assets/images/playstore.webp";
import AppleStore from "../../../assets/images/appstore.webp";

import "./footer.scss";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Config } from "src/helpers/context";

export default function Footer() {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const version = process.env.REACT_APP_VERSION;
  const [tagLine, setTagLine] = useState(
    JSON.parse(window.localStorage.getItem("tagline"))
  );

  useEffect(() => {
    let taglineOption = JSON.parse(window.localStorage.getItem("tagline"));
    taglineOption === 6 ? setTagLine(1) : setTagLine(tagLine + 1);
  }, []);

  useEffect(() => {
    window.localStorage.setItem("tagline", tagLine);
  }, [tagLine]);
  const handleNavigate = () => {
    navigate("/advertisingenquiries");
  };
  const fetTagline = () => {
    switch (tagLine) {
      case 1:
        return "Chances are you’re about to lose.";
      case 2:
        return "Think. Is this a bet you really want to place?";
      case 3:
        return "What’s gambling really costing you?";
      case 4:
        return "Imagine what you could be buying instead.";
      case 5:
        return "You win some. You lose more.";
      case 6:
        return "What are you really gambling with?";
      default:
        return "Chances are you’re about to lose.";
    }
  };

  return (
    <>
      <Box className="footer-wrap">
        <Box className="upper-footer">
          <img src={FooterLogo} alt="logo" />
        </Box>

        <Box className="footer-content">
          {/* <Link to="/" style={{ textAlign: "center", display: "block" }}>
          <FooterLogo />
        </Link> */}
          <Grid container>
            {/* <Grid item xl={4} lg={4} md={6} sm={6} xs={6}> */}
            <Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
              <h4>{localesData?.footer?.connet}</h4>
              <Box className="contact">
                <Location />
                <span>
                  <a
                    href={
                      Config?.release == "IN"
                        ? "mailto:info@smartb.co.in"
                        : "mailto:info@smartb.com.au"
                    }
                  >
                    {Config?.release == "IN"
                      ? "info@smartb.co.in"
                      : "info@smartb.com.au"}
                  </a>
                </span>
              </Box>
              {/* <Box className="contact">
                <Call />
                <span>
                  <a href="tel:1300065591">1300 065 591</a>
                </span>
              </Box> */}
              <Box className="contact">
                {/* <Call /> */}
                <span
                  onClick={() => handleNavigate()}
                  style={{ cursor: "pointer" }}
                >
                  Advertise With Us
                </span>
              </Box>
            </Grid>
            {/* <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
            <h4>{localesData?.footer?.navigate}</h4>
            <Box className="link-wrap">
              <Link to="/">{localesData?.MENU?.HOME}</Link>
              <Link to="/racing">{localesData?.MENU?.RACING}</Link>
              <Link to="/comingsoon">{localesData?.MENU?.SPORTS}</Link> */}
            {/* <Link to="/comingsoon">{localesData?.MENU?.BOOKMAKER_SPECIALS}</Link> */}
            {/* <Link to="/comingsoon">{localesData?.MENU?.TIPS}</Link>
            </Box>
          </Grid> */}
            <Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
              <h4>{localesData?.MENU?.POLICIES}</h4>
              <Box className="link-wrap">
                <Link to="/responsible-gambling">
                  {localesData?.MENU?.RESPONSIBLE_GAMBLING}
                </Link>
                <Link to="/privacy-policy">
                  {localesData?.MENU?.PRIVACY_POLICY}
                </Link>
                <Link to="/terms-and-conditions">
                  {localesData?.MENU?.TERMS_AND_CONDITIONS}
                </Link>
                {Config.release == "AU" && (
                  <a
                    href={`${Config?.baseURL?.replace(
                      "/api/",
                      "/"
                    )}frequently-asked-questions/`}
                    target="_blank"
                  >
                    FAQ
                  </a>
                )}
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <h4>Download Us</h4>
              <Box className="app-logo">
                <Box className="link-wrap playstore-logo">
                  <a
                    href={
                      Config.release == "AU"
                        ? "https://play.google.com/store/apps/details?id=au.com.smartb"
                        : "https://play.google.com/store/apps/details?id=in.com.smartb"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={playstore}
                      alt="playstore"
                      className="playstore-img"
                    />
                  </a>
                </Box>
                <Box className="link-wrap">
                  <a
                    href={
                      Config.release == "AU"
                        ? "https://apps.apple.com/au/app/smartb-app/id1672189237"
                        : "https://apps.apple.com/us/app/smartb-india/id6479695004"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={AppleStore}
                      alt="Apple-store"
                      className="playstore-img"
                    />
                  </a>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xl={2} lg={2} md={6} sm={6} xs={6}>
            <h4>{localesData?.MENU?.HELP}</h4>
            <Box className="link-wrap">
              <Link to="/comingsoon">{localesData?.MENU?.LIVE_CHAT}</Link>
              <Link to="/comingsoon">{localesData?.MENU?.HELP_CENTER}</Link>
            </Box>
          </Grid> */}
          </Grid>
          <Box className="responsible-gambling-wrap">
            <span className="eighteen-plus">
              <EighteenPlus />
            </span>
            <span className="responsible-gambling-text">
              {fetTagline()} For free and confidential support call{" "}
              <a
                href="tel:1800 858 858"
                className="responsible-gambling-helpline-no"
              >
                {" "}
                1800 858 858{" "}
              </a>{" "}
              or visit{" "}
            </span>
            <a
              href="https://www.gamblinghelponline.org.au/"
              className="responsible-gambling-logo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GamblingHelp />
            </a>
          </Box>
        </Box>
        <p className="copyright">
          {localesData?.footer?.copyright} © SmartB Pty Ltd {moment()?.year()}
          <span style={{ display: "block", paddingTop: "5px" }}>{version}</span>
        </p>
      </Box>
    </>
  );
}
