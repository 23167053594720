import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import RaceHorses from "../../../assets/images/Sport/race_hourses.svg";
import RaceGreyhounds from "../../../assets/images/Sport/races_greyhounds.svg";
import RaceHarness from "../../../assets/images/Sport/race_harness.svg";
import { ReactComponent as AmericanFootball } from "../../../assets/images/SportIcon/outlineSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../../assets/images/SportIcon/outlineSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../../assets/images/SportIcon/outlineSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../../assets/images/SportIcon/outlineSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../../assets/images/SportIcon/outlineSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../../assets/images/SportIcon/outlineSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../../assets/images/SportIcon/outlineSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../../assets/images/SportIcon/outlineSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../../assets/images/SportIcon/outlineSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../../assets/images/SportIcon/outlineSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../../assets/images/SportIcon/outlineSportIcon/Tennis.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import NotepadBig from "../../../assets/images/notepadBig.png";
import Facebook from "../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../assets/images/social/x-link.svg";
import Snapchat from "../../../assets/images/social/sc-link.svg";
import Copy from "../../../assets/images/social/copy-link.svg";
import Messenger from "../../../assets/images/social/mess-link.svg";
import Wp from "../../../assets/images/social/wp-link.svg";
import Message from "../../../assets/images/social/messages.svg";
import Share from "../../../assets/images/social/share-icon.svg";
import Messages from "../../../assets/images/social/messages-link.png";
import Select, { components } from "react-select";
import OurPartner from "../ourPartners";
import moment from "moment";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "src/helpers/context";
import "./socialShare.scss";
import { useLocation, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import Loader from "src/components/Loader";
import { fetchBetSlipShareToggle } from "src/helpers/store/Actions/BetSlipShareToggle";
import { ToastContainer, toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
// import {
//   SnapshareCustom,
//   SnapShareButton,
//   Snapshare
// } from "@thezano/react-snapshare";

const options = [
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" }
  // { value: "3", label: "Win Tote" },
  // { value: "4", label: "Place Tote" }
  // { value: "4", label: "Starting Price" },
];

const SocialSharePage = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const cartKey = location.search?.replace("?cartKey=", "").split("&fbclid")[0];
  const cartkeyDecode = decodeURIComponent(cartKey);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const BetSlipShareToggle = useSelector(
    state => state?.reduxData?.BetSlipShare
  );
  const BetSlipData = useSelector(state => state?.reduxData?.BetSlipData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [betSlipData, setBetSlipData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [link, setLink] = useState(window.location.href);

  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored"
    });
  };

  const shareContent = {
    dataShareUrl: "https://twang.dev/react-snapshare/",
    stickerAssetURL: "https://kit.snapchat.com/ckweb/test/image.png"
  };

  const url =
    Config.baseURL.replace("/api", "") +
    location.pathname.replace(/^\/+/, "") +
    "?cartKey=" +
    encodeURIComponent(
      location.search?.replace("?cartKey=", "").split("&fbclid")[0]
    );

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds?.[0]?.pageAdLists);
    }
  }, [ReduxAdsData]);
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const oddsicon = BookKeeperId => {
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    return (
      <img
        className="bookmaker-thumb"
        src={
          iconData?.small_logo?.includes("uploads")
            ? Config.mediaURL + iconData?.small_logo
            : iconData?.small_logo
        }
        alt="Odds Icon"
      />
    );
  };

  const raceIcon = sportId => {
    if (sportId === 1) {
      return RaceHorses;
    } else if (sportId === 2) {
      return RaceHarness;
    } else if (sportId === 3) {
      return RaceGreyhounds;
    } else if (sportId === 15) {
      return <AmericanFootball />;
    } else if (sportId === 9) {
      return <AustralianRules />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MixedMartialArts />;
    } else if (sportId === 12) {
      return <RugbyLeague />;
    } else if (sportId === 13) {
      return <RugbyUnion />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };

  useEffect(() => {
    if (BetSlipShareToggle) {
      setTimeout(() => {
        fetchBetslipData();
      }, 500);

      fetchBookKeeper();
    }
  }, [BetSlipShareToggle]);

  useEffect(() => {
    fetchBetslipData();
    fetchBookKeeper();
  }, []);

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const fetchBetslipData = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/public/betSlipCard/BetSlipCard?cartKey=${encodeURIComponent(
          cartkeyDecode
        )}`
      );
      if (status === 200) {
        const editedData = data?.result?.map(item => {
          return {
            ...item,
            selectedMarket: 1,
            returns: item?.stack ? item?.stack * item?.odd : 0
          };
        });
        setBetSlipData(editedData);
        setLoader(false);
        dispatch(fetchBetSlipShareToggle(false));
      } else {
        setLoader(false);
        dispatch(fetchBetSlipShareToggle(false));
      }
    } catch (err) {
      setLoader(false);
      dispatch(fetchBetSlipShareToggle(false));
    }
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  // const handleSnapchatLogin = () => {
  //   if (window.SnapKit) {
  //     window.SnapKit.AuthorizeAsync()
  //       .then(response => {
  //         // Handle the login response here
  //         console.log("Snapchat login response:", response);
  //       })
  //       .catch(error => {
  //         console.error("Snapchat login failed", error);
  //       });
  //   } else {
  //     console.error("Snapchat SnapKit not found");
  //   }
  // };

  const shareOnMessenger = () => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: url // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: "708883073941381",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0"
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        {loader && (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        )}
        {!loader &&
          (betSlipData?.length > 0 ? (
            <>
              <Box className="social-share-wrap">
                <Box className="bet-share-option">
                  <Box className="share-header-box">
                    <Typography className="share-header">
                      Share your Bet slip
                    </Typography>
                    <img src={Share} alt={"shareicon"} className="share-icon" />
                  </Box>
                  <Box className="share-option">
                    <Box className="copy-link">
                      <CopyToClipboard text={link} onCopy={handleCopy}>
                        <Box>
                          <img src={Copy} alt="Copy" />
                          <Typography className="option-name">
                            Copy Link
                          </Typography>
                        </Box>
                      </CopyToClipboard>
                    </Box>
                    <Box>
                      <WhatsappShareButton url={url}>
                        <img src={Wp} alt="Whatsapp" />
                      </WhatsappShareButton>
                      <Typography className="option-name">Whatsapp</Typography>
                    </Box>
                    <Box>
                      <FacebookShareButton url={url}>
                        <img src={Facebook} alt="Facebook" />
                      </FacebookShareButton>
                      <Typography className="option-name">Facebook</Typography>
                    </Box>
                    <Box>
                      {/* <FacebookMessengerShareButton url={url}> */}
                      <img
                        src={Messenger}
                        alt="Messenger"
                        className="social-icon"
                        onClick={() => shareOnMessenger()}
                      />
                      {/* </FacebookMessengerShareButton> */}
                      <Typography className="option-name">Messenger</Typography>
                    </Box>
                    <Box>
                      <TwitterShareButton url={url}>
                        {" "}
                        <img src={Twitter_X} alt="Twitter_X" />
                      </TwitterShareButton>
                      <Typography className="option-name">Twitter</Typography>
                    </Box>
                    <Box>
                      <LinkedinShareButton url={url}>
                        {" "}
                        <img src={Linkedin} alt="Linkedin" />
                      </LinkedinShareButton>
                      <Typography className="option-name">Linkedin</Typography>
                    </Box>
                    {/* <Box
                      className="copy-link"
                      // onClick={() => handleSnapchatLogin()}
                    >
                      <img src={Snapchat} alt="Snapchat" />
                      <Typography className="option-name">Snapchat</Typography>
                    </Box> */}
                    {/* <Snapshare
                      dataShareUrl="https://twang.dev/react-snapshare/"
                      stickerAssetURL="https://kit.snapchat.com/ckweb/test/image.png"
                    /> */}
                    {/* <SnapshareCustom
                      customButtonStyles={{ background: "yellow" }}
                      customButtonClass="snapchat-button"
                      dataShareUrl="https://twang.dev/react-snapshare/"
                    /> */}
                    {/* <Box>
                      <LinkedinShareButton url={url}>
                        {" "}
                        <img src={Messages} alt="Message" />
                      </LinkedinShareButton>
                      <Typography className="option-name">Message</Typography>
                    </Box> */}
                  </Box>
                </Box>
                <Box className="bet-slip-data">
                  <Box className="bet-slip-data-box">
                    {betSlipData?.map(item => {
                      return (
                        <>
                          <Box className="bet-slip-single-data">
                            <Box className="bet-slip-header-details">
                              <Box className="bet-slip-details">
                                {item?.sportId === 1 ||
                                item?.sportId === 2 ||
                                item?.sportId === 3 ? (
                                  <>
                                    <Box className="sport-img">
                                      <img
                                        src={raceIcon(item?.sportId)}
                                        alt="icon"
                                      />
                                    </Box>
                                    <Box className="text-details">
                                      <Typography className="bet-slip-text mb-4 dark-text">
                                        {item?.runnerNumber}
                                        {"."} {item?.teamName} (
                                        {item?.barrierNumber})
                                      </Typography>
                                      <Typography className="bet-slip-text mb-4">
                                        {"R" + item?.raceNumber}{" "}
                                        {item?.trackName}
                                      </Typography>
                                      <Typography className="bet-slip-text">
                                        <span>
                                          {" "}
                                          {moment
                                            .utc(item?.eventTime)
                                            .local()
                                            .format("DD MMM YYYY")}{" "}
                                        </span>
                                        |
                                        <span>
                                          {" "}
                                          {moment
                                            .utc(item?.eventTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </span>
                                      </Typography>
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Box className="sport-img">
                                      {raceIcon(item?.sportId)}
                                    </Box>
                                    <Box className="text-details">
                                      <Typography className="bet-slip-text mb-4 dark-text">
                                        {item?.teamName}
                                      </Typography>
                                      <Typography className="bet-slip-text mb-4">
                                        {item?.eventName}
                                      </Typography>
                                      <Typography className="bet-slip-text">
                                        <span>
                                          {" "}
                                          {moment
                                            .utc(item?.eventTime)
                                            .local()
                                            .format("DD MMM YYYY")}{" "}
                                        </span>
                                        |
                                        <span>
                                          {" "}
                                          {moment
                                            .utc(item?.eventTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </span>
                                      </Typography>
                                    </Box>
                                  </>
                                )}
                              </Box>
                              {/* <Box
                        className="delete-box cursor-pointer"
                        onClick={() => handleDeleteBetslip(item)}
                      >
                        <Delete />
                      </Box> */}
                            </Box>
                            <Box
                              className={
                                item?.sportId === 1 ||
                                item?.sportId === 2 ||
                                item?.sportId === 3
                                  ? "stake-details"
                                  : "stake-details teamsports-stake-details"
                              }
                            >
                              {item?.sportId === 1 ||
                              item?.sportId === 2 ||
                              item?.sportId === 3 ? (
                                <>
                                  <Select
                                    className="React fixed-select"
                                    value={options?.find(item => {
                                      return (
                                        Number(item?.value) ===
                                        item?.selectedMarket
                                      );
                                    })}
                                    // onChange={e =>
                                    //   handleSelectedMarketChange(e?.value, item)
                                    // }
                                    options={options}
                                    // isOptionDisabled={option => option.disabled}
                                    classNamePrefix="select"
                                    placeholder="Win Fixed"
                                    isSearchable={false}
                                    components={{ DropdownIndicator }}
                                    isDisabled
                                  />
                                  <Box className="stake-field">
                                    <Typography className="stake">
                                      Stake
                                    </Typography>
                                    <TextField
                                      id="standard-start-adornment"
                                      className="stake-input-field"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            $
                                          </InputAdornment>
                                        )
                                      }}
                                      value={item?.stack ? item?.stack : "0"}
                                      //   onBlur={e =>
                                      //     handleStackChange(e.target.value, item)
                                      //   }
                                    />
                                  </Box>
                                </>
                              ) : (
                                <Box className="stake-field teamsport-stake-field">
                                  <Typography className="stake">
                                    Stake
                                  </Typography>
                                  <TextField
                                    id="standard-start-adornment"
                                    className="stake-input-field"
                                    disabled
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      )
                                    }}
                                    value={item?.stack ? item?.stack : "0"}
                                    // onBlur={e =>
                                    //   handleStackChange(e.target.value, item)
                                    // }
                                  />
                                </Box>
                              )}
                            </Box>
                            <Box className="odds-returns-wrap">
                              <Box className="odds-returns-top">
                                <Typography className="odds-wrap odds-returns-text mr-70">
                                  Odd
                                </Typography>
                                <Typography className="returns-wrap odds-returns-text">
                                  Potential Returns
                                </Typography>
                              </Box>
                              <Box className="odds-returns-bottom">
                                <Box className="left-wrap">
                                  {oddsicon(item?.BookKeeperId)}
                                  <Typography className="betslip-odds">
                                    {item?.selectedMarket === 1
                                      ? item?.odd
                                      : item?.oddPlace}
                                  </Typography>
                                </Box>
                                <Box className="right-wrap">
                                  <Typography>
                                    {item?.returns
                                      ? formatter?.format(
                                          item?.returns?.toFixed(2)
                                        )
                                      : "N/A"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            {/* <Box className="bet-now-btn">
                      <Button variant="contained" className="button-bet-now">
                        Bet Now
                      </Button>
                    </Box> */}
                          </Box>
                        </>
                      );
                    })}
                  </Box>

                  {/* <Box className="share-now-btn">
              <Button variant="contained" className="button-share-now">
                share betSlip
              </Button>
            </Box> */}
                </Box>
              </Box>
            </>
          ) : (
            <Box className="bet-slip-empty">
              <Typography className="bet-slip-empty-header">
                Your Bet slip is empty.
              </Typography>
              <Typography className="bet-slip-empty-header empty-text">
                Start placing your bets on today's races and sports events.
              </Typography>
            </Box>
          ))}
        <OurPartner />
      </Box>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default SocialSharePage;
