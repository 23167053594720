import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useParams, useNavigate } from "react-router-dom";
// import Placeholder from "../../../../assets/images/placeOrder.png";
// import Video1 from "../../../../assets/images/video1.png";
// import Video2 from "../../../../assets/images/video2.png";
import adNews from "../../../../assets/images/ad-placeholder/adnews.webp";
import DefaultImg from "../../../../assets/images/smartb_default.png";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment-timezone";
import axiosInstance from "src/helpers/Axios/axiosInstance";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import RightSidebar from "../../righsidebar/rightsidebar";

// const slider1Data = [
//   {
//     id: 1,
//     video: Video1
//   },
//   {
//     id: 2,
//     video: Video1
//   },
//   {
//     id: 3,
//     video: Video1
//   },
//   {
//     id: 4,
//     video: Video1
//   },
//   {
//     id: 5,
//     video: Video1
//   },
//   {
//     id: 6,
//     video: Video1
//   }
// ];

// const slider2Data = [
//   {
//     id: 1,
//     video: Video2
//   },
//   {
//     id: 2,
//     video: Video2
//   },
//   {
//     id: 3,
//     video: Video2
//   },
//   {
//     id: 4,
//     video: Video2
//   },
//   {
//     id: 5,
//     video: Video2
//   },
//   {
//     id: 6,
//     video: Video2
//   }
// ];

const RightNewsSideBar = ({ fetchSideNewsAds }) => {
  const ReduxNewsData = useSelector(state => state?.reduxData?.RelatedNewsData);
  const params = useParams();
  const navigate = useNavigate();
  // const [slide1, setslide1] = useState(null);
  // const [slide2, setslide2] = useState(null);
  const [NewsRelatedArticles, setNewsRelatedArticles] = useState([]);
  const [NewsCategoryId, setNewsCatergoryId] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (ReduxNewsData) {
      setNewsRelatedArticles(ReduxNewsData?.NewsRelatedArticles);
      setNewsCatergoryId(ReduxNewsData?.NewsCategoryId);
    }
  }, [ReduxNewsData]);

  useEffect(() => {
    fetchNewsCategoryList();
  }, []);

  useEffect(() => {
    if (categoryList?.length > 0) {
      fetchCategoryName(categoryList);
    }
  }, [categoryList, params?.categoryId]);

  const fetchNewsCategoryList = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        "v2/news/category?All=0"
      );
      if (status === 200) {
        setLoading(false);
        let response = data?.result;
        let newdata = [];
        let FinalData = response?.map(item => {
          newdata.push({
            label: item?.title,
            value: item?.id
          });
        });
        const allCategory = { label: "all", value: 0 };
        let allCategoryList = [...newdata, allCategory];
        let sortData = allCategoryList?.sort((a, b) => {
          return a.value > b.value ? 1 : -1;
        });
        setCategoryList(sortData);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchCategoryName = id => {
    let categoryName = categoryList?.filter(item => item?.value == id);
    return categoryName?.[0]?.label;
  };

  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} hours ago`;
      }
    } else {
      return `${timeDiffMinutes} minutes ago`;
    }
  };

  return (
    <>
      {/* <Box className="right-section-wrap">
        {!params?.id ? (
          <>
            <Box>
              {/* <Typography variant="h4" className="news-heading">
                Latest News{" "}
              </Typography>
              <Slider
                asNavFor={slide2}
                ref={slider => setslide1(slider)}
                arrows={false}
                className="first-slider"
              >
                {slider1Data?.map(item => (
                  <Box>
                    <img src={item?.video} alt="Placeholder" />
                  </Box>
                ))}
              </Slider>
              <Slider
                asNavFor={slide1}
                ref={slider => setslide2(slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                className="second-slider"
                prevArrow={
                  <Box className="slick-prev">
                    <ChevronLeftIcon />
                  </Box>
                }
                nextArrow={
                  <Box className="slick-next">
                    <ChevronRightIcon />
                  </Box>
                }
              >
                {slider2Data?.map(item => (
                  <Box>
                    <img src={item?.video} alt="Placeholder" />
                  </Box>
                ))}
              </Slider> */}
      {/* {fetchSideNewsAds(3, "187px", adNews)} /}
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Typography variant="h4" className="news-heading">
                Related Articles
              </Typography>
              {NewsRelatedArticles?.length > 0 ? (
                NewsRelatedArticles?.map((item, index) => (
                  <>
                    <Box
                      key={item?.id}
                      className="single-related-stories"
                      onClick={() =>
                        navigate(
                          `/news/${NewsCategoryId}/${item?.relatedArticleId}`
                        )
                      }
                    >
                      <Box className="related-stories-img">
                        <img
                          src={
                            item?.NewsArticle?.mainMedia?.gallery
                              ? item?.NewsArticle?.mainMedia?.gallery?.url && item?.NewsArticle?.mainMedia?.gallery?.url !==
                                ""
                                ? item?.NewsArticle?.mainMedia?.gallery?.url
                                : DefaultImg
                              : item?.NewsArticle?.mainMedia?.[0]?.gallery
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                  ?.url && item?.NewsArticle?.mainMedia?.[0]?.gallery
                                  ?.url !== ""
                                ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ?.url
                                : DefaultImg
                              : DefaultImg
                          }
                          alt={
                            item?.NewsArticle?.mainMedia?.gallery
                              ? item?.NewsArticle?.mainMedia?.gallery?.alt
                              : item?.NewsArticle?.mainMedia?.[0]?.gallery
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery.alt
                              : ""
                          }
                        />
                      </Box>
                      <Box className="stories-details">
                        <Typography
                          className="details"
                          dangerouslySetInnerHTML={{
                            __html: item?.NewsArticle?.title
                          }}
                        ></Typography>
                        <Box className="text-details">
                          <span className="stories-tag">
                            {" "}
                            {fetchCategoryName(NewsCategoryId)}
                          </span>
                          <Typography className="stories-time">
                            <span className="watch">
                              <Watch />
                            </span>
                            {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/* {index == 1 ? fetchSideNewsAds(3, "187px", adNews) : <></>} /}
                  </>
                ))
              ) : (
                <Box className="no-data">
                  <Typography>No Data Available</Typography>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box> */}

      <RightSidebar />
    </>
  );
};

export default RightNewsSideBar;
