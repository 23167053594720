import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tabs,
  Tab,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Collapse,
  withStyles,
  makeStyles
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Link, useParams, useNavigate } from "react-router-dom";
import { IntlContext } from "src/App";
import AdvertisingBannner from "../../../../component/advertisingBanner/index";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import moment from "moment-timezone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { parseISO } from "date-fns";
import { Button } from "react-scroll";
import { format, parse } from "date-fns";

import OurPartner from "../../../ourPartners";
import "./outRights.scss";
import _ from "lodash";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import NoDataComp from "src/views/component/UI/NoData";
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    minWidth: "457px",
    width: "457px",
    backgroundColor: "#ffffff",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    minWidth: "457px",
    width: "457px",
    backgroundColor: "#ffffff",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px"
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px"
    }
  }
}))(TableCell);
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const OutRights = ({
  eventList,
  teamSportType,
  fetchDayName,
  isEventLoading,
  fetchOutrightsTable,
  sponsoredId,
  SlideTable,
  fetchSeeAllTeamOddsvalue,
  oddsicon,
  fetchAllEvents,
  fetchOutrightTeamlogo,
  fetchSeeAllOutRightsOdds,
  fetchLatestOdds,
  tabvalue,
  showOdds
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [value, setValue] = useState(1);
  const [sportTabValue, setSportTabValue] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [scrollX, setscrollX] = useState("left");
  const [seeAll, setSeeAll] = useState(params?.seeall);
  const [selectedDate, setselectedDate] = useState(
    moment.tz(moment(), timezone).format("YYYY-MM-DD")
  );
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [MobileBestOpenHome, setMobileBestOpenHome] = useState(-1);
  const [MobileBestOpenAway, setMobileBestOpenAway] = useState(-1);
  const [isSeeAllEventLoading, setIsSeeAllEventLoading] = useState(false);
  const [individualTeamData, setindividualTeamData] = useState({});
  const [MobileBestOpenOutrights, setMobileBestOpenOutrights] = useState(-1);

  const groupedData = _.groupBy(eventList, item =>
    moment
      .utc(item?.startTime)
      .local()
      .format("DD/MM/YYYY")
  );
  const handleSeeBest = () => {
    setSeeAll("false");
    navigate(`/teamsports/${params?.type}/outrights/0/false`);
    // setindividualTeamData({});
    fetchAllEvents(0, 1, 0, 0, null, "outrights");
    // setEventPage(0);
  };
  const handleSeeAll = item => {
    setSeeAll("true");
    navigate(`/teamsports/${params?.type}/outrights/${item?.id}/true`);
    // setindividualTeamData(item);
  };
  const fetchTableHeading = async Bookkeeper => {
    try {
      let SportId =
        teamSportType === "cricket"
          ? 4
          : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "rugbyunion"
          ? 13
          : teamSportType === "basketball"
          ? 10
          : teamSportType === "americanfootball"
          ? 15
          : teamSportType === "australianrules"
          ? 9
          : teamSportType === "golf"
          ? 16
          : teamSportType === "tennis"
          ? 7
          : teamSportType === "baseball"
          ? 11
          : teamSportType === "icehockey"
          ? 17
          : teamSportType === "boxing"
          ? 6
          : teamSportType === "mma"
          ? 5
          : teamSportType === "soccer"
          ? 8
          : 14;
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders?SportId=${SportId}`
      );
      if (status === 200) {
        let filteredHeading = data?.result?.filter(item =>
          Bookkeeper?.includes(item?.BookKeeperId)
        );
        setPageHeadingData(filteredHeading);
      } else {
      }
    } catch (err) {}
  };

  const renderTableCell = BookKeeperId => {
    return (
      <>
        <TableCell className={classes.head}>
          {oddsicon(BookKeeperId, "header")}
        </TableCell>
      </>
    );
  };

  const result = _.map(groupedData, (objects, date) => ({ date, objects }));
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchSeeAllOutRightsData = item => {
    let OutRightTeams =
      teamSportType === "cricket"
        ? item?.CricketOutRightTeams
        : teamSportType === "basketball"
        ? item?.NBAOutRightTeams
        : teamSportType === "americanfootball"
        ? item?.AFLOutRightTeams
        : teamSportType === "australianrules"
        ? item?.AROutRightTeams
        : teamSportType === "golf"
        ? item?.GolfOutRightTeams
        : teamSportType === "tennis"
        ? item?.TennisOutRightTeams
        : teamSportType === "baseball"
        ? item?.BaseballOutRightTeams
        : teamSportType === "icehockey"
        ? item?.IceHockeyOutRightTeams
        : teamSportType === "boxing"
        ? item?.BoxingOutRightTeams
        : teamSportType === "mma"
        ? item?.MMAOutRightTeams
        : teamSportType === "soccer"
        ? item?.SoccerOutRightTeams
        : item?.RLOutRightTeams;
    let OutrightsTeamdata = OutRightTeams?.map(obj => {
      return teamSportType === "cricket"
        ? obj?.CricketTeam
        : teamSportType === "basketball"
        ? obj?.NBATeam
        : teamSportType === "americanfootball"
        ? obj?.AFLTeam
        : teamSportType === "australianrules"
        ? obj?.ARTeam
        : teamSportType === "golf"
        ? obj?.GolfTeam
        : teamSportType === "tennis"
        ? obj?.TennisTeam
        : teamSportType === "baseball"
        ? obj?.BaseballTeam
        : teamSportType === "icehockey"
        ? obj?.IceHockeyTeam
        : teamSportType === "boxing"
        ? obj?.BoxingTeam
        : teamSportType === "mma"
        ? obj?.MMATeam
        : teamSportType === "soccer"
        ? obj?.SoccerTeam
        : obj?.RLTeam;
    });
    return (
      <>
        {OutrightsTeamdata?.map((teamdata, index) => {
          return (
            <React.Fragment key={index}>
              <TableRow className="individual-team-row">
                <StickyTableCell className={`${classes.cell} body-runner-name`}>
                  <Box className="team-wrap">
                    {/* <span className="team-img-wrap">
                      {fetchOutrightTeamlogo(teamdata)}
                    </span> */}
                    <span className="team-name">
                      {teamdata?.name ? teamdata?.name : ""}
                    </span>
                  </Box>
                </StickyTableCell>
                {pageHeadingData?.length > 0 ? (
                  pageHeadingData?.map((id, index) => (
                    <TableCell className={classes.cell} key={index}>
                      <span className="odds">
                        {fetchSeeAllOutRightsOdds(
                          item,
                          id?.BookKeeperId,
                          teamdata?.id
                        )}
                      </span>
                    </TableCell>
                  ))
                ) : (
                  <TableCell className={classes.cell} key={index}>
                    {" "}
                    <span className="odds">NOA</span>{" "}
                  </TableCell>
                )}
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const fetchIndividualTeamdata = async () => {
    try {
      setIsSeeAllEventLoading(true);
      const passApi =
        teamSportType === "cricket"
          ? `public/crickets/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "rugbyleague"
          ? `public/rls/event/${params?.id}?SportId=12&isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "rugbyunion"
          ? `public/rls/event/${params?.id}?SportId=13&isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "basketball"
          ? `public/nba/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "americanfootball"
          ? `public/afl/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "australianrules"
          ? `public/ar/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "golf"
          ? `public/golf/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "tennis"
          ? `public/tennis/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "baseball"
          ? `public/baseball/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "icehockey"
          ? `public/icehockey/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "boxing"
          ? `public/boxing/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "mma"
          ? `public/mma/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : teamSportType === "soccer"
          ? `public/soccer/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`
          : `public/rls/event/${params?.id}?SportId=14&isOutright=true&isAdmin=false&isHome=false`;
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        let fullData = [];
        let teamdata = [data?.result];
        let datas = teamdata?.map(item => {
          let newData =
            teamdata?.length > 0
              ? teamSportType === "cricket"
                ? teamdata?.[0]?.CricketBetOffers
                : teamSportType === "basketball"
                ? teamdata?.[0]?.NBABetOffers
                : teamSportType === "americanfootball"
                ? teamdata?.[0]?.AFLBetOffers
                : teamSportType === "australianrules"
                ? teamdata?.[0]?.ARBetOffers
                : teamSportType === "golf"
                ? teamdata?.[0]?.GolfBetOffers
                : teamSportType === "tennis"
                ? teamdata?.[0]?.TennisBetOffers
                : teamSportType === "baseball"
                ? teamdata?.[0]?.BaseballBetOffers
                : teamSportType === "icehockey"
                ? teamdata?.[0]?.IceHockeyBetOffers
                : teamSportType === "boxing"
                ? teamdata?.[0]?.BoxingBetOffers
                : teamSportType === "mma"
                ? teamdata?.[0]?.MMABetOffers
                : teamSportType === "soccer"
                ? teamdata?.[0]?.SoccerBetOffers
                : teamdata?.[0]?.RLBetOffers
              : [];

          let SportsOdds =
            teamSportType === "cricket"
              ? newData?.[0]?.CricketOdds
              : teamSportType === "basketball"
              ? newData?.[0]?.NBAOdds
              : teamSportType === "americanfootball"
              ? newData?.[0]?.AFLOdds
              : teamSportType === "australianrules"
              ? newData?.[0]?.AROdds
              : teamSportType === "golf"
              ? newData?.[0]?.GolfOdds
              : teamSportType === "tennis"
              ? newData?.[0]?.TennisOdds
              : teamSportType === "baseball"
              ? newData?.[0]?.BaseballOdds
              : teamSportType === "icehockey"
              ? newData?.[0]?.IceHockeyOdds
              : teamSportType === "boxing"
              ? newData?.[0]?.BoxingOdds
              : teamSportType === "mma"
              ? newData?.[0]?.MMAOdds
              : teamSportType === "soccer"
              ? newData?.[0]?.SoccerOdds
              : newData?.[0]?.RLOdds;

          let SportMarket =
            teamSportType === "cricket"
              ? newData?.[0]?.CricketMarket?.name
              : teamSportType === "basketball"
              ? newData?.[0]?.NBAMarket?.name
              : teamSportType === "americanfootball"
              ? newData?.[0]?.AFLMarket?.name
              : teamSportType === "australianrules"
              ? newData?.[0]?.ARMarket?.name
              : teamSportType === "golf"
              ? newData?.[0]?.GolfMarket?.name
              : teamSportType === "tennis"
              ? newData?.[0]?.TennisMarket?.name
              : teamSportType === "baseball"
              ? newData?.[0]?.BaseballMarket?.name
              : teamSportType === "icehockey"
              ? newData?.[0]?.IceHockeyMarket?.name
              : teamSportType === "boxing"
              ? newData?.[0]?.BoxingMarket?.name
              : teamSportType === "mma"
              ? newData?.[0]?.MMAMarket?.name
              : teamSportType === "soccer"
              ? newData?.[0]?.SoccerMarket?.name
              : newData?.[0]?.RLMarket?.name;
          let Bookkeeper = [];
          let BookkeeperList = SportsOdds?.map(element => {
            return Bookkeeper?.push(element?.BookKeeperId);
          });
          fetchTableHeading([...new Set(Bookkeeper)]);

          setindividualTeamData(item);
          fullData.push(item);
          setTimeout(() => {
            setIsSeeAllEventLoading(false);
          }, 4000);
        });
      }
    } catch {
      setIsSeeAllEventLoading(false);
    }
  };

  const fetchFormatedDayname = date => {
    const parseddate = parse(date, "dd/MM/yyyy", new Date());
    const dayName = format(parseddate, "EEEE");
    return dayName;
  };

  const fetchMobileViewSeeAllOutrightsTable = item => {
    let OutRightTeams =
      teamSportType === "cricket"
        ? item?.CricketOutRightTeams
        : teamSportType === "basketball"
        ? item?.NBAOutRightTeams
        : teamSportType === "americanfootball"
        ? item?.AFLOutRightTeams
        : teamSportType === "australianrules"
        ? item?.AROutRightTeams
        : teamSportType === "golf"
        ? item?.GolfOutRightTeams
        : teamSportType === "tennis"
        ? item?.TennisOutRightTeams
        : teamSportType === "baseball"
        ? item?.BaseballOutRightTeams
        : teamSportType === "icehockey"
        ? item?.IceHockeyOutRightTeams
        : teamSportType === "boxing"
        ? item?.BoxingOutRightTeams
        : teamSportType === "mma"
        ? item?.MMAOutRightTeams
        : teamSportType === "soccer"
        ? item?.SoccerOutRightTeams
        : item?.RLOutRightTeams;
    let OutrightsTeamdata = OutRightTeams?.map(obj => {
      return teamSportType === "cricket"
        ? obj?.CricketTeam
        : teamSportType === "basketball"
        ? obj?.NBATeam
        : teamSportType === "americanfootball"
        ? obj?.AFLTeam
        : teamSportType === "australianrules"
        ? obj?.ARTeam
        : teamSportType === "golf"
        ? obj?.GolfTeam
        : teamSportType === "tennis"
        ? obj?.TennisTeam
        : teamSportType === "baseball"
        ? obj?.BaseballTeam
        : teamSportType === "icehockey"
        ? obj?.IceHockeyTeam
        : teamSportType === "boxing"
        ? obj?.BoxingTeam
        : teamSportType === "mma"
        ? obj?.MMATeam
        : teamSportType === "soccer"
        ? obj?.SoccerTeam
        : obj?.RLTeam;
    });
    return (
      <>
        {OutrightsTeamdata?.map((teamdata, index) => {
          return (
            <React.Fragment key={index}>
              <Box className="home-team-wrap">
                <Box className="team-wrap">
                  {/* <span className="team-img-wrap">
                    {fetchOutrightTeamlogo(teamdata)}
                  </span> */}
                  <span className="team-name">
                    {teamdata?.name ? teamdata?.name : ""}
                  </span>
                </Box>
                <Box className="mobile-best5odds-wrap">
                  <Box className="mobile-best5odds-select">
                    <Typography>{localesData?.sport?.SEE_ALL_ODDS}</Typography>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setMobileBestOpenOutrights(
                          MobileBestOpenOutrights === index ? -1 : index
                        )
                      }
                    >
                      {MobileBestOpenOutrights === index ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Box>
                    <Collapse
                      in={MobileBestOpenOutrights === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableContainer>
                        <Table className="individual-team-mobile-table">
                          <TableHead></TableHead>
                          <TableBody>
                            <TableRow className="individual-team-mobile-row">
                              {pageHeadingData?.length > 0 ? (
                                pageHeadingData?.map((id, index) => (
                                  <TableCell
                                    className={classes.cell}
                                    key={index}
                                  >
                                    <span className="odds">
                                      {fetchSeeAllOutRightsOdds(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        teamdata?.id
                                      )}
                                    </span>
                                  </TableCell>
                                ))
                              ) : (
                                <TableCell className={classes.cell}>
                                  <span className="odds">NOA</span>
                                </TableCell>
                              )}
                            </TableRow>
                            <TableRow className="individual-team-mobile-row odds-icon-seeall">
                              {pageHeadingData?.map(id =>
                                renderTableCell(id?.BookKeeperId)
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </Box>
                </Box>
              </Box>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    setSeeAll(params?.seeall);
    if (params?.oddstype === "outrights" && params?.seeall === "true") {
      fetchIndividualTeamdata();
      // fetchTableHeading();
      fetchLatestOdds();
    } else {
      setindividualTeamData({});
    }
  }, [params?.id, params?.seeall]);
  return (
    <>
      <Box className="content-wrap team-sports-layout">
        {isEventLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : result?.length > 0 ? (
          params?.seeall === "false" ? (
            result?.map((obj, index) => {
              return (
                <>
                  <Box className="team-sport-table-wrap outrights-wrap">
                    {obj?.objects?.map((item, indexObj) => {
                      return (
                        <>
                          <Box className="individual-outrights-wrap">
                            <Box className="table-header-wrap">
                              <Box>
                                <Typography variant="h1">
                                  {fetchFormatedDayname(obj?.date)} {obj?.date}
                                </Typography>
                              </Box>
                              {teamSportType !== "golf" ||
                                (teamSportType === "golf" && tabvalue === 2 && (
                                  <Box className="currentbest-seeall">
                                    <span>
                                      <Typography variant="h1">
                                        {" "}
                                        {
                                          localesData?.RUNNER_TABLE
                                            ?.CURRENT_BEST
                                        }
                                      </Typography>
                                    </span>
                                    <span
                                      className="teamsports-seeall"
                                      onClick={() => handleSeeAll(item)}
                                    >
                                      <Typography variant="h1">
                                        {localesData?.RUNNER_TABLE?.SEE_ALL}
                                      </Typography>
                                    </span>
                                  </Box>
                                ))}
                            </Box>

                            <Box className="team-sport-table">
                              <Box className="team-sport-table-indicator">
                                <Box className="time-indicator-left">
                                  <Box className="team-time-market">
                                    {moment
                                      .utc(item?.startTime)
                                      .local()
                                      .format("hh:mm A")}{" "}
                                    |{" "}
                                    {`${
                                      item?.marketLength
                                        ? item?.marketLength
                                        : 0
                                    } Markets`}
                                  </Box>
                                  <span className="sport-indicator-btn">
                                    {teamSportType === "cricket"
                                      ? item?.CricketTournament?.name
                                      : teamSportType === "rugbyleague" ||
                                        teamSportType === "rugbyunion" ||
                                        teamSportType === "rugbyunionsevens"
                                      ? item?.RLTournament?.name
                                      : teamSportType === "americanfootball"
                                      ? item?.AFLTournament?.name
                                      : teamSportType === "australianrules"
                                      ? item?.ARTournament?.name
                                      : teamSportType === "golf"
                                      ? item?.GolfTournament?.name
                                      : teamSportType === "tennis"
                                      ? item?.TennisTournament?.name
                                      : teamSportType === "baseball"
                                      ? item?.BaseballTournament?.name
                                      : teamSportType === "icehockey"
                                      ? item?.IceHockeyTournament?.name
                                      : teamSportType === "boxing"
                                      ? item?.BoxingTournament?.name
                                      : teamSportType === "mma"
                                      ? item?.MMATournament?.name
                                      : teamSportType === "soccer"
                                      ? item?.SoccerTournament?.name
                                      : teamSportType === "basketball"
                                      ? item?.NBATournament?.NBACategory ===
                                        null
                                        ? item?.NBATournament?.name
                                        : item?.NBATournament?.name +
                                          " " +
                                          item?.NBATournament?.NBACategory?.name
                                      : ""}
                                  </span>
                                </Box>
                                {/* <Box>
                                <span
                                  className="ex-full see-filter"
                                  onClick={() => handleSeeAll(item)}
                                >
                                  {localesData?.RUNNER_TABLE?.SEE_ALL}
                                </span>
                              </Box> */}
                              </Box>
                              <Accordion className="outrights-accordian">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="team-sport-table outrights-accordian-summary"
                                >
                                  Outright Betting
                                </AccordionSummary>
                                <AccordionDetails className="outrights-accordian-details">
                                  {screenWidth > 1023 ? (
                                    <Table
                                      className="racing-data outrights-table"
                                      aria-label="customized table"
                                      style={{ minWidth: 700 }}
                                    >
                                      <TableHead className="racing-track-table-head">
                                        <TableRow
                                          style={{ fontSize: 12 }}
                                          className="head-row"
                                        >
                                          <TableCell
                                            className={
                                              showOdds
                                                ? "track-table-th"
                                                : "track-table-th show-odds-th"
                                            }
                                            style={{
                                              textAlign: "left",
                                              textTransform: "uppercase"
                                            }}
                                          >
                                            TEAMS
                                          </TableCell>
                                          {teamSportType !== "golf" ||
                                          (teamSportType === "golf" &&
                                            tabvalue === 2) ? (
                                            showOdds ? (
                                              <TableCell
                                                className="track-tabel-odds-th table-head"
                                                style={{
                                                  textAlign: "center"
                                                }}
                                              >
                                                {
                                                  localesData?.RUNNER_TABLE
                                                    ?.BEST_AT_OPEN
                                                }
                                              </TableCell>
                                            ) : (
                                              <></>
                                            )
                                          ) : (
                                            <TableCell></TableCell>
                                          )}

                                          {teamSportType !== "golf" ||
                                          (teamSportType === "golf" &&
                                            tabvalue === 2) ? (
                                            showOdds ? (
                                              <TableCell
                                                className="track-tabel-odds-th table-head current-best"
                                                style={{
                                                  textAlign: "center"
                                                }}
                                              >
                                                {
                                                  localesData?.RUNNER_TABLE
                                                    ?.CURRENT_BEST
                                                }
                                              </TableCell>
                                            ) : (
                                              <></>
                                            )
                                          ) : (
                                            <TableCell
                                              className="track-tabel-odds-th table-head current-best"
                                              style={{
                                                textAlign: "center"
                                              }}
                                            >
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.CURRENT_BEST
                                              }
                                            </TableCell>
                                          )}

                                          {sponsoredId?.length > 0 &&
                                          showOdds ? (
                                            <TableCell
                                              colSpan={2}
                                              className="track-tabel-odds-th table-head sponsored-header"
                                              style={{
                                                width: "180px",
                                                textAlign: "center"
                                              }}
                                            >
                                              <span className="sponsored">
                                                {localesData?.sport?.SPONSORED}
                                              </span>
                                            </TableCell>
                                          ) : (
                                            <></>
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {fetchOutrightsTable(item, index)}
                                      </TableBody>
                                    </Table>
                                  ) : (
                                    <Box className="mobile-table-container racing-data">
                                      {fetchOutrightsTable(item, index)}
                                    </Box>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </Box>
                        </>
                      );
                    })}

                    {/* {screenWidth > 1023 ? (
            <Table className="team-sport-table">
              <TableHead>
                <TableRow>
                  <TableCell className="border-right">
                    <Box className="team-sport-table-indicator">
                      <Box className="team-time-market">
                        {moment
                          .utc(item?.startTime)
                          .local()
                          .format("hh:mm A")}{" "}
                        |{" "}
                        {`${
                          item?.marketLength ? item?.marketLength : 0
                        } Markets`}
                      </Box>
                      <span className="sport-indicator-btn">
                        {teamSportType === "cricket"
                          ? item?.CricketTournament?.name
                          : teamSportType === "rugbyleague" ||
                            teamSportType === "rugbyunion" ||
                            teamSportType === "rugbyunionsevens"
                          ? item?.RLTournament?.name
                          : teamSportType === "americanfootball"
                          ? item?.AFLTournament?.name
                          : teamSportType === "australianrules"
                          ? item?.ARTournament?.name
                          : teamSportType === "golf"
                          ? item?.GolfTournament?.name
                          : teamSportType === "tennis"
                          ? item?.TennisTournament?.name
                          : teamSportType === "baseball"
                          ? item?.BaseballTournament?.name
                          : teamSportType === "icehockey"
                          ? item?.IceHockeyTournament?.name
                          : teamSportType === "boxing"
                          ? item?.BoxingTournament?.name
                          : teamSportType === "mma"
                          ? item?.MMATournament?.name
                          : teamSportType === "soccer"
                          ? item?.SoccerTournament?.name
                          : teamSportType === "basketball"
                          ? item?.NBATournament?.NBACategory === null
                            ? item?.NBATournament?.name
                            : item?.NBATournament?.name +
                              " " +
                              item?.NBATournament?.NBACategory?.name
                          : ""}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      width: sponsoredId?.length > 0 ? "25%" : "auto"
                    }}
                    className={"table-head table-head-after"}
                  >
                    {localesData?.sport?.BEST_AT_OPEN}
                  </TableCell>

                  <TableCell
                    className={`table-head table-current ${
                      sponsoredId?.length > 0 ? "table-head-after" : ""
                    } `}
                  >
                    {localesData?.sport?.CURRENT_BEST}
                  </TableCell>
                  {sponsoredId?.length > 0 ? (
                    <TableCell
                      colSpan={2}
                      className="table-head table-sponsored-head"
                    >
                      <Typography className="sponsored-head-to-head">
                        {localesData?.sport?.SPONSORED}
                      </Typography>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {item?.outrights === false ? (
                  <>
                    <TableRow className="teamsports-tablerow hometeam-row">
                      <TableCell className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(item, "hometeam")}
                        </span>
                        <span className="team-name">
                          {item?.homeTeam?.name ? item?.homeTeam?.name : ""}
                        </span>
                      </TableCell>
                      <TableCell className="border-right border-left td-cell">
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {fetchBestAtOpenOdds(item, "odds", "hometeam")}
                          </span>
                          {fetchBestAtOpenIconOdds(item, "odds", "hometeam")}
                        </Box>
                      </TableCell>
                      <TableCell className="border-right current-best">
                        {" "}
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {fetchCurrentBestOdds(item, "odds", "hometeam")}
                          </span>
                          {fetchCurrentBestsOddsIcon(item, "odds", "hometeam")}
                        </Box>
                      </TableCell>
                      {sponsoredId?.length > 0 ? (
                        fetchSponsoredOddsHome(item)
                      ) : (
                        <></>
                      )}
                    </TableRow>
                    <TableRow className="awayteam-row">
                      <TableCell className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(item, "awayteam")}
                        </span>
                        <span className="team-name">
                          {" "}
                          {item?.awayTeam?.name ? item?.awayTeam?.name : ""}
                        </span>
                      </TableCell>
                      <TableCell className="border-right border-left td-cell">
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {" "}
                            {fetchBestAtOpenOdds(item, "odds", "awayteam")}
                          </span>
                          {fetchBestAtOpenIconOdds(item, "odds", "awayteam")}
                        </Box>
                      </TableCell>
                      <TableCell className="border-right current-best">
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {fetchCurrentBestOdds(item, "odds", "awayteam")}
                          </span>
                          {fetchCurrentBestsOddsIcon(item, "odds", "awayteam")}
                        </Box>
                      </TableCell>
                      {sponsoredId?.length > 0 ? (
                        fetchSponsoredOddsAway(item)
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </>
                ) : (
                  fetchOutrightsTable(item)
                )}
              </TableBody>
            </Table>
          ) : (
            <Box className="mobileview-teamsport-table-wrap">
              <Box className="team-match-wrap">
                <Box className="time-market-team-wrap">
                  <Box className="mobile-temsport-indicator">
                    <span className="sport-indicator-btn">
                      {teamSportType === "cricket"
                        ? item?.CricketTournament?.name
                        : teamSportType === "basketball"
                        ? item?.NBATournament?.name +
                          " " +
                          item?.NBATournament?.NBACategory?.name
                        : teamSportType === "rugbyleague" ||
                          teamSportType === "rugbyunion" ||
                          teamSportType === "rugbyunionsevens"
                        ? item?.RLTournament?.name
                        : teamSportType === "americanfootball"
                        ? item?.AFLTournament?.name
                        : teamSportType === "australianrules"
                        ? item?.ARTournament?.name
                        : teamSportType === "golf"
                        ? item?.GolfTournament?.name
                        : teamSportType === "tennis"
                        ? item?.TennisTournament?.name
                        : teamSportType === "baseball"
                        ? item?.BaseballTournament?.name
                        : teamSportType === "icehockey"
                        ? item?.IceHockeyTournament?.name
                        : teamSportType === "boxing"
                        ? item?.BoxingTournament?.name
                        : teamSportType === "mma"
                        ? item?.MMATournament?.name
                        : teamSportType === "soccer"
                        ? item?.SoccerTournament?.name
                        : ""}
                    </span>
                    <Typography className="des-label">
                      {" "}
                      {moment
                        .utc(item?.startTime)
                        .local()
                        .format("hh:mm A")}{" "}
                      |{" "}
                      {`${item?.marketLength ? item?.marketLength : 0} Markets`}
                    </Typography>
                  </Box>
                  {item?.[teamScores]?.length > 0 ? (
                    <Table className="mobile-live-table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Score</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell className="table-head">
                            {item?.homeTeam?.name}V/S
                          </TableCell>
                          <TableCell>
                            {fetchteamscore(item, item?.homeTeamId)}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="table-head">
                            {item?.awayTeam?.name}
                          </TableCell>
                          <TableCell>
                            {fetchteamscore(item, item?.awayTeamId)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    <Box className="teams-details">
                      <Box className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(item, "hometeam")}
                        </span>
                        <span className="team-name">
                          {item?.homeTeam?.name ? item?.homeTeam?.name : ""}
                        </span>
                      </Box>
                      <Box className="vs"> V/S </Box>
                      <Box className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(item, "awayteam")}
                        </span>
                        <span className="team-name">
                          {" "}
                          {item?.awayTeam?.name
                            ? item?.awayTeam?.name
                            : ""}{" "}
                        </span>
                      </Box>
                    </Box>
                  )}
                </Box>
                {item?.[teamScores]?.length > 0 ? (
                  <>
                    <Box className="win-odds-live">
                      <Box className="win-odds-padding">
                        <Box className="odds-label">
                          {" "}
                          {item?.homeTeam?.name ? item?.homeTeam?.name : ""}
                        </Box>
                        <Box className="odds-wrap">
                          <span className="odds odds-left odds-long">
                            {fetchCurrentBestOdds(item, "odds", "hometeam")}
                          </span>
                          {fetchCurrentBestsOddsIcon(item, "odds", "hometeam")}
                        </Box>
                      </Box>
                      <Box>
                        <Box className="odds-label">
                          {" "}
                          {item?.awayTeam?.name ? item?.awayTeam?.name : ""}
                        </Box>
                        <Box className="odds-wrap">
                          <span className="odds odds-left odds-long">
                            {fetchCurrentBestOdds(item, "odds", "awayteam")}
                          </span>
                          {fetchCurrentBestsOddsIcon(item, "odds", "awayteam")}
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography className="des-label des-head">
                      {localesData?.sport?.BEST_AT_OPEN}
                    </Typography>
                    <Box className="odds-detail">
                      <Box className="odds-body">
                        <Box>
                          <Box className="odds-wrap">
                            <span className="bookmaker-long odds-left">
                              {fetchBestAtOpenIconOdds(
                                item,
                                "odds",
                                "hometeam"
                              )}
                            </span>

                            <span className="odds">
                              {fetchBestAtOpenOdds(item, "odds", "hometeam")}
                            </span>
                          </Box>
                        </Box>
                        <Box>
                          <Box className="odds-wrap">
                            <span className="odds odds-left">
                              {" "}
                              {fetchBestAtOpenOdds(item, "odds", "awayteam")}
                            </span>
                            <span className="bookmaker-long">
                              {fetchBestAtOpenIconOdds(
                                item,
                                "odds",
                                "awayteam"
                              )}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Typography className="des-label des-head des-current-best">
                      {localesData?.sport?.CURRENT_BEST}
                    </Typography>
                    <Box className="odds-detail odds-details-best">
                      <Box className="odds-body">
                        <Box>
                          <Box className="odds-wrap">
                            <span className="bookmaker-long odds-left">
                              {fetchCurrentBestsOddsIcon(
                                item,
                                "odds",
                                "hometeam"
                              )}
                            </span>

                            <span className="odds">
                              {fetchCurrentBestOdds(item, "odds", "hometeam")}
                            </span>
                          </Box>
                        </Box>
                        <Box>
                          <Box className="odds-wrap">
                            <span className="odds odds-left">
                              {" "}
                              {fetchCurrentBestOdds(item, "odds", "awayteam")}
                            </span>
                            <span className="bookmaker-long">
                              {fetchCurrentBestsOddsIcon(
                                item,
                                "odds",
                                "awayteam"
                              )}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}

                {sponsoredId?.length > 0 ? (
                  <>
                    <Typography className="des-label des-head des-head-to-head">
                      <span className="sponsored-head-to-head">
                        {" "}
                        {localesData?.sport?.SPONSORED_HEAD_TO_HEAD}
                      </span>
                    </Typography>
                    <Box
                      className={`${
                        sponsoredId?.length > 1 ? "odds-after" : ""
                      } odds-detail`}
                    >
                      <Box className="odds-body">
                        <Box className="sponsored-odds-body">
                          {fetchSponsoredOddsHome(item)}
                        </Box>
                        <Box className="sponsored-odds-body">
                          {fetchSponsoredOddsAway(item)}
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          )} */}
                  </Box>
                </>
              );
            })
          ) : screenWidth > 1023 ? (
            isSeeAllEventLoading ? (
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            ) : (
              <Box className="teamsports-seeall-tablewrap outrights-seeall-tablewrap">
                <TableContainer
                  className="table-seeall-container"
                  id="individual-team-table"
                >
                  <Box className="overlay"></Box>
                  <Box className="arrow-wrap">
                    <span
                      className={scrollX == "right" ? "arrow" : "disable"}
                      onClick={() => SlideTable("left")}
                    >
                      <ChevronLeftIcon />
                    </span>

                    <span
                      className={scrollX == "left" ? "arrow" : "disable"}
                      onClick={() => SlideTable("right")}
                    >
                      <ChevronRightIcon />
                    </span>
                  </Box>
                  <Box className="see-best" onClick={() => handleSeeBest()}>
                    {" "}
                    See Best
                  </Box>
                  <Table
                    style={{ minWidth: 700 }}
                    className="individual-table outrights-individual-table"
                  >
                    <TableHead className="individual-team-head">
                      <TableRow>
                        <StickyTableCell className={classes.head}>
                          <Box className="select-wrap">
                            {/* <Select
                            className="React oddstype"
                            value={MarketList?.find(item => {
                              return item?.value === selectedOption;
                            })}
                            onChange={e => {
                              setSelectedOption(e?.value);
                              fetchIndividualTeamdata(e?.value);
                            }}
                            menuPosition="fixed"
                            options={MarketList}
                            // isOptionDisabled={option => option.disabled}
                            classNamePrefix="select"
                            isSearchable={false}
                            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            // menuPortalTarget={document.body}
                            // menuPosition={'fixed'}
                          /> */}
                          </Box>
                        </StickyTableCell>
                        {pageHeadingData?.map(id =>
                          renderTableCell(id?.BookKeeperId)
                        )}
                        <TableCell className={classes.head}></TableCell>
                      </TableRow>
                    </TableHead>
                    {individualTeamData ? (
                      <TableBody className="individual-team-body">
                        <TableRow>
                          <StickyTableCell className="time-market">
                            {fetchDayName(individualTeamData?.startTime)}{" "}
                            {moment
                              .utc(individualTeamData?.startTime)
                              .local()
                              .format("DD/MM/YYYY")}{" "}
                            |{" "}
                            {moment
                              .utc(individualTeamData?.startTime)
                              .local()
                              .format("hh:mm A")}{" "}
                            |{" "}
                            {`${
                              individualTeamData?.marketLength
                                ? individualTeamData?.marketLength
                                : 0
                            } Markets`}
                          </StickyTableCell>
                        </TableRow>
                        {fetchSeeAllOutRightsData(individualTeamData)}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={100}>
                            <Box style={{ textAlign: "center" }}>
                              {" "}
                              No Data Available
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            )
          ) : (
            <>
              {isSeeAllEventLoading ? (
                <div className="allsport-loader-center ">
                  <Loader />
                </div>
              ) : (
                <Box className="individual-table-mobileview outrights-table-mobileview">
                  <Box className="individual-table-head">
                    <Box className="select-wrap">
                      {/* <Select
                      className="React oddstype"
                      value={MarketList?.find(item => {
                        return item?.value === selectedOption;
                      })}
                      onChange={e => {
                        setSelectedOption(e?.value);
                        fetchIndividualTeamdata(e?.value);
                      }}
                      options={MarketList}
                      // isOptionDisabled={option => option.disabled}
                      classNamePrefix="select"
                      isSearchable={false}
                      // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      // menuPortalTarget={document.body}
                      // menuPosition={'fixed'}
                    /> */}
                    </Box>
                    <Box className="current-best-head">
                      <Typography className="current-best-mobile">
                        Current Best
                      </Typography>
                      <Box className="see-best" onClick={() => handleSeeBest()}>
                        {" "}
                        See Best
                      </Box>
                    </Box>
                  </Box>
                  {individualTeamData ? (
                    <>
                      <Box className="date-markets-section">
                        {fetchDayName(individualTeamData?.startTime)}{" "}
                        {moment
                          .utc(individualTeamData?.startTime)
                          .local()
                          .format("DD/MM/YYYY")}{" "}
                        |{" "}
                        {moment
                          .utc(individualTeamData?.startTime)
                          .local()
                          .format("hh:mm A")}{" "}
                        |{" "}
                        {`${
                          individualTeamData?.marketLength
                            ? individualTeamData?.marketLength
                            : 0
                        } Markets`}
                      </Box>

                      {fetchMobileViewSeeAllOutrightsTable(individualTeamData)}
                    </>
                  ) : (
                    <Box style={{ textAlign: "center", padding: "10px" }}>
                      {" "}
                      No Data Available{" "}
                    </Box>
                  )}
                </Box>
              )}
            </>
          )
        ) : (
          <Box style={{ textAlign: "center" }}>
            {/* No Data Available  */}
            <NoDataComp />
          </Box>
        )}
      </Box>
    </>
  );
};

export default OutRights;
