import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FeaturedNews1 from "../../../assets/images/featuredNews1.jpg";
import FeaturedNews2 from "../../../assets/images/featuredNews2.jpg";
import DefaultImg from "../../../assets/images/smartb_default.png";
import SliderDummy from "../../../assets/images/slider_dummy_img.png";
import { IntlContext } from "src/App";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosInstance from "../../../helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";

const FeaturedNews = ({ newsType, tag, teamSportType }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const [featureNewsData, setFeatureNewsData] = useState([]);
  const [featureNewsLoader, setFeatureNewsLoader] = useState(false);
  const [dragging, setDragging] = useState(false);

  const settingsNews = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
    // adaptiveHeight: true,
    beforeChange: (current, next) => {
      handleBeforeChange();
    },
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 610,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  useEffect(() => {
    fetchFeaturedNews();
  }, []);
  const SportId =
    teamSportType === "cricket"
      ? 4
      : teamSportType === "rugbyleague"
      ? 12
      : teamSportType === "rugbyunion"
      ? 13
      : teamSportType === "basketball"
      ? 10
      : teamSportType === "americanfootball"
      ? 15
      : teamSportType === "australianrules"
      ? 9
      : teamSportType === "baseball"
      ? 11
      : teamSportType === "boxing"
      ? 6
      : teamSportType === "icehockey"
      ? 17
      : teamSportType === "mma"
      ? 5
      : teamSportType === "soccer"
      ? 8
      : teamSportType === "tennis"
      ? 7
      : teamSportType === "golf"
      ? 16
      : "";
  const fetchFeaturedNews = async () => {
    setFeatureNewsLoader(true);
    const allSportId = [
      ...release[Config.release]?.sportId,
      ...release[Config.release]?.raceSportId
    ];
    try {
      const passApi = teamSportType
        ? `/v2/news/feature/articles?SportId=${SportId}&tag=${
            tag ? tag.trim() : ""
          }`
        : `/v2/news/feature/articles?SportId=${
            Config.release == "IN" ? allSportId?.toString() : ""
          }`;
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setFeatureNewsLoader(false);
        const articalData = data?.result;
        setFeatureNewsData(articalData);
      }
    } catch (error) {
      setFeatureNewsLoader(false);
    }
  };

  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} hours ago`;
      }
    } else {
      return `${timeDiffMinutes} minutes ago`;
    }
  };

  const handleNavigate = useCallback(
    (item, event) => {
      const url = window?.location?.origin;

      if (!dragging) {
        if (event.ctrlKey || event.metaKey) {
          window.open(
            url +
              `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticle?.id}`,
            "_blank"
          );
        } else {
          navigate(
            `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticle?.id}`
          );
        }
      }
    },
    [dragging]
  );
  return (
    <>
      {featureNewsLoader ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : featureNewsData?.length > 0 ? (
        <Box className="racing-slider-wrap">
          <Box>
            <Typography variant="h3" className="home-slider-header">
              {localesData?.HOME?.FEATURED_NEWS}
            </Typography>

            <Slider
              {...settingsNews}
              className="racing-slick-slider"
              afterChange={handleAfterChange}
            >
              {featureNewsData?.map((item, index) => (
                <Box
                  key={index}
                  className="single-silder"
                  // onClick={e => handleNavigate(item, e)}
                >
                  <NavLink
                    to={`/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticle?.id}`}
                  >
                    <Box
                      className="background-overlay"
                      style={{
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url('${
                          item?.NewsArticle?.mainMedia?.gallery
                            ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.gallery?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.gallery?.url
                              : DefaultImg
                            : item?.NewsArticle?.mainMedia?.[0]?.gallery
                            ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url
                              : DefaultImg
                            : DefaultImg
                        }')`,
                        backgroundSize: "cover",
                        backgroundPosition: "center top",
                        filter: "blur(10px)",
                        zIndex: -1,
                        borderRadius: "8px"
                      }}
                    ></Box>
                    <Box className="slider-img">
                      <img
                        src={
                          item?.NewsArticle?.mainMedia?.gallery
                            ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.gallery?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.gallery?.url
                              : DefaultImg
                            : item?.NewsArticle?.mainMedia?.[0]?.gallery
                            ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url &&
                              item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ?.url !== ""
                              ? item?.NewsArticle?.mainMedia?.[0]?.gallery?.url
                              : DefaultImg
                            : DefaultImg
                        }
                        alt="slider-img"
                      />
                    </Box>
                    <span className="news-tag">
                      {item?.NewsArticle?.NewsCategory?.initialTitle}
                    </span>
                    <Box className="details-back">
                      <Box className="slider-news-deatils">
                        <Typography
                          className="title-news"
                          dangerouslySetInnerHTML={{
                            __html: item?.NewsArticle?.title
                              ? item?.NewsArticle?.title
                              : item?.NewsArticle?.subTitle
                          }}
                        ></Typography>
                        <Typography>
                          {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                        </Typography>
                      </Box>
                    </Box>
                  </NavLink>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default FeaturedNews;
