import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Checkbox,
  TextField,
  ClickAwayListener
} from "@material-ui/core";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import Bet365 from "src/assets/images/bet365.png";
import moment from "moment";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as Uncheck } from "src/assets/images/icons/Checkbox.svg";
import { ReactComponent as Check } from "src/assets/images/icons/check.svg";
import { ReactComponent as Notcheck } from "src/assets/images/icons/notcheck.svg";
import { ReactComponent as Minus } from "src/assets/images/icons/counter-minus.svg";
import { ReactComponent as Plus } from "src/assets/images/icons/counter-plus.svg";
import { ReactComponent as BlackbookRight } from "src/assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "src/assets/images/icons/blackbookPlus.svg";
import { ReactComponent as RightBlack } from "src/assets/images/icons/rightblack.svg";
import { ReactComponent as PlusBlack } from "src/assets/images/icons/plusblack.svg";
import { ReactComponent as AddBookIcon } from "src/assets/images/add-blackbook.svg";
import { ReactComponent as TooltipArrow } from "src/assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as Share } from "../../../assets/images/icons/share.svg";
import { ReactComponent as BlueCheck } from "src/assets/images/icons/blue-checkbox.svg";
import Facebook from "../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../assets/images/social/x-link.svg";
import Snapchat from "../../../assets/images/social/sc-link.svg";
import Copy from "../../../assets/images/social/copy-link.svg";
import Messenger from "../../../assets/images/social/mess-link.svg";
import Wp from "../../../assets/images/social/wp-link.svg";
import Message from "../../../assets/images/social/messages.svg";
import AdBannner from "../AdBanner/AdBanner";
import tippingAbove from "../../../assets/images/ad-placeholder/tipping1.png";
import tippingBelow from "../../../assets/images/ad-placeholder/tipping2.png";
import BannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.png";
import CopyToClipboard from "react-copy-to-clipboard";
import NoDataComp from "src/views/component/UI/NoData";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import { array } from "prop-types";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import { release } from "src/helpers/context/release";
import SmartBookModal from "../UI/smartbookModal";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const TipsTab = ({
  allEventData,
  isLoading,
  eventLength,
  params,
  stepperCount,
  isJoker,
  completedRound,
  jokerRound,
  eventByIdData,
  cutoffDate,
  getEventByID,
  redux_userData,
  tabvalue,
  setIsJoker,
  lastEventDate
}) => {
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);

  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [groupedEventData, setGroupedData] = useState({});
  const [counts, setCounts] = useState(0);
  const [lastDate, setLastDate] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [shareTooltipOpen, setShareTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredParentIndex, setHoveredParentIndex] = useState(null);
  const [clickToolTipTeamId, setClickToolTipTeamId] = useState(null);
  const [SBopen, setSBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  useEffect(() => {
    if (cutoffDate && eventByIdData?.cutOffTime) {
      const time = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss"
        )
        .tz(timezone)
        .format("HH:mm:ss");
      const dateTime = moment(`${cutoffDate} ${time}`, "YYYY-MM-DD HH:mm:ss");
      setLastDate(dateTime);
    }
  }, [cutoffDate, eventByIdData?.cutOffTime]);

  useEffect(() => {
    setEventsData(allEventData);
  }, [allEventData]);
  const convetToGroupData = eventdata => {
    const groupedData = _.groupBy(eventdata, event =>
      moment(event?.startTime).format("YYYY-MM-DD")
    );
    setGroupedData(groupedData);
  };
  // const groupedData = _.groupBy(tipsData, tip =>
  //   moment(tip.date).format("YYYY-MM-DD")
  // );

  // const initialCounts = tipsData.reduce((acc, tip) => {
  //   acc[tip.id] = 0;
  //   return acc;
  // }, {});

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const MyMinusNutton = () => {
    return (
      <Button>
        <Minus />
      </Button>
    );
  };
  const MyPLusNutton = () => {
    return (
      <Button>
        <Plus />
      </Button>
    );
  };

  useEffect(() => {
    fetchBookKeeper();
    fetchAdsData();
  }, []);

  useEffect(() => {
    if (allEventData?.length > 0) {
      const modifiedEventData = allEventData?.map((item, index) =>
        index === 0 ? { ...item, margin: true } : item
      );

      convetToGroupData(modifiedEventData);
      setEventsData(modifiedEventData);
      const margin = allEventData?.[0]?.roundData?.margin;

      setCounts(margin ? margin : 0);
    }
  }, [allEventData]);

  const fetchAdsData = async () => {
    try {
      const passPageId =
        params?.SportId == 4 ? 41 : params?.SportId == 12 ? 42 : 43;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${passPageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.page_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };
  // const handleToggle = value => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];
  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setChecked(newChecked);
  // };

  const handleIncrement = () => {
    setCounts(Number(counts) + 1);
  };

  const handleDecrement = (tipId, index) => {
    // const newCounts = { ...counts };
    // if (newCounts[tipId] > 0) {
    //   newCounts[tipId]--;
    //   setCounts(newCounts);
    // }
    if (counts > 0) {
      setCounts(Number(counts) - 1);
    }
  };

  const handleBookkeeperCounter = async (BookKeeperId, type, item) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(item?.SportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const oddsicon = (BookKeeperId, type, item, fullData) => {
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    return (
      <a
        href={iconData?.affiliate_link}
        target="_blank"
        rel="noopener noreferrer"
        className="odds-icon-anchor"
      >
        <img
          className="bookmaker-thumb odd-img"
          src={
            iconData?.small_logo?.includes("uploads")
              ? Config.mediaURL + iconData?.small_logo
              : iconData?.small_logo
          }
          alt="Odds Icon"
          onClick={() => handleBookkeeperCounter(BookKeeperId, type, fullData)}
        />
      </a>
    );
  };
  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    const oddsInfo = BookKeeperData?.[0];
    // let isAdded = BetslipData?.some(el => {
    //   if (
    //     el?.BookKeeperId == BookKeeperId &&
    //     el?.betOfferId == oddsInfo?.id &&
    //     el?.eventId == fulldata?.id
    //   )
    //     return true;
    //   else return false;
    // });

    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      <>
        {odds ? (
          <span
            className="odds-btn cursor-pointer"
            onClick={e => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              // IsBetslip === "nobetslip"
              //   ? handleBookkeeperCounter(BookKeeperId, type, e)
              //   : isAdded
              //   ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
              //   : handleAddToBetSlip(
              //       BookKeeperId,
              //       type,
              //       item,
              //       fulldata,
              //       Isscore,
              //       e
              //     );
            }}
          >
            <span
              className={
                "odds-link odds"
                // isAdded && IsBetslip === "betslip"
                //   ? "betslip-added odds-link"
                //   : "odds-link"
              }
            >
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : (
          <span className="odds-btn">NOA</span>
        )}
      </>
      // {/* <span
      //   className="odds-link"
      //   onClick={() => handleAddToBetSlip(BookKeeperId, type, item, fulldata)}
      //   //  onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
      // >
      //   {Number(odds).toFixed(2)}
      // </span> */}

      // </a>
    );
    // }
  };
  const fetchCurrentBestOdds = (data, type, team, teamid, Isscore) => {
    let allTeamOdds =
      data?.SportId === 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.SportId === 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : data?.SportId === 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : data?.SportId === 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : data?.SportId === 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : data?.SportId === 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : data?.SportId === 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : data?.SportId === 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : data?.SportId === 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : data?.SportId === 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : data?.SportId === 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.SportId === 10
        ? item?.NBATeamId == data?.homeTeamId
        : data?.SportId === 15
        ? item?.AFLTeamId == data?.homeTeamId
        : data?.SportId === 9
        ? item?.ARTeamId == data?.homeTeamId
        : data?.SportId === 16
        ? item?.GolfTeamId == data?.homeTeamId
        : data?.SportId === 7
        ? item?.TennisTeamId == data?.homeTeamId
        : data?.SportId === 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : data?.SportId === 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : data?.SportId === 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : data?.SportId === 5
        ? item?.MMATeamId == data?.homeTeamId
        : data?.SportId === 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.SportId === 10
        ? item?.NBATeamId == data?.awayTeamId
        : data?.SportId === 15
        ? item?.AFLTeamId == data?.awayTeamId
        : data?.SportId === 9
        ? item?.ARTeamId == data?.awayTeamId
        : data?.SportId === 16
        ? item?.GolfTeamId == data?.awayTeamId
        : data?.SportId === 7
        ? item?.TennisTeamId == data?.awayTeamId
        : data?.SportId === 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : data?.SportId === 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : data?.SportId === 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : data?.SportId === 5
        ? item?.MMATeamId == data?.awayTeamId
        : data?.SportId === 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);

    if (maxno !== -1) {
      if (maxno !== 0) {
        let bookkeeperid = teamData
          ?.map(obj => {
            if (obj?.odd === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);
        return fetchClickableOdds(
          maxno,
          bookkeeperid?.[0],
          "header",
          teamData,
          data,
          "betslip",
          Isscore
        );
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };
  const fetchCurrentBestsOddsIcon = (data, type, team, teamid) => {
    let allTeamOdds =
      data?.SportId === 4
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : data?.SportId === 10
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : data?.SportId === 15
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : data?.SportId === 9
        ? data?.ARBetOffers?.[0]?.AROdds
        : data?.SportId === 16
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : data?.SportId === 7
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : data?.SportId === 11
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : data?.SportId === 17
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : data?.SportId === 6
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : data?.SportId === 5
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : data?.SportId === 8
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.homeTeamId
        : data?.SportId === 10
        ? item?.NBATeamId == data?.homeTeamId
        : data?.SportId === 15
        ? item?.AFLTeamId == data?.homeTeamId
        : data?.SportId === 9
        ? item?.ARTeamId == data?.homeTeamId
        : data?.SportId === 16
        ? item?.GolfTeamId == data?.homeTeamId
        : data?.SportId === 7
        ? item?.TennisTeamId == data?.homeTeamId
        : data?.SportId === 11
        ? item?.BaseballTeamId == data?.homeTeamId
        : data?.SportId === 17
        ? item?.IceHockeyTeamId == data?.homeTeamId
        : data?.SportId === 6
        ? item?.BoxingTeamId == data?.homeTeamId
        : data?.SportId === 5
        ? item?.MMATeamId == data?.homeTeamId
        : data?.SportId === 8
        ? item?.SoccerTeamId == data?.homeTeamId
        : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter(item => {
      return data?.SportId === 4
        ? item?.CricketTeamId == data?.awayTeamId
        : data?.SportId === 10
        ? item?.NBATeamId == data?.awayTeamId
        : data?.SportId === 15
        ? item?.AFLTeamId == data?.awayTeamId
        : data?.SportId === 9
        ? item?.ARTeamId == data?.awayTeamId
        : data?.SportId === 16
        ? item?.GolfTeamId == data?.awayTeamId
        : data?.SportId === 7
        ? item?.TennisTeamId == data?.awayTeamId
        : data?.SportId === 11
        ? item?.BaseballTeamId == data?.awayTeamId
        : data?.SportId === 17
        ? item?.IceHockeyTeamId == data?.awayTeamId
        : data?.SportId === 6
        ? item?.BoxingTeamId == data?.awayTeamId
        : data?.SportId === 5
        ? item?.MMATeamId == data?.awayTeamId
        : data?.SportId === 8
        ? item?.SoccerTeamId == data?.awayTeamId
        : item?.RLTeamId == data?.awayTeamId;
    });

    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;

    let teamData = teamInfo;

    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let providerid = teamData
          ?.map(obj => {
            let oddsType = obj?.odd;
            if (oddsType === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);

        return oddsicon(providerid?.[0], "header", teamData, data);
      } else {
        return "";
        // <span className="odds"> - </span>
      }
    } else {
      return "";
      // <span className="odds"> - </span>
    }
  };

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const handleTooltipContentClick = (event, teamId, objindex, parentIndex) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(objindex);
    setClickToolTipTeamId(teamId);
    setHoveredParentIndex(parentIndex);
  };

  const handleOutsideClick = (item, teamId, index, parentIndex) => {
    if (
      hoveredIndex === index &&
      clickToolTipTeamId === teamId &&
      hoveredParentIndex === parentIndex
    ) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, teamId, index, teamType, parentIndex) => {
    let tournamentName =
      item?.SportId === 4 && item?.CricketTournament?.name
        ? item?.CricketTournament?.name
        : item?.SportId === 10 && item?.NBATournament?.name
        ? item?.NBATournament?.name
        : item?.SportId === 15 && item?.AFLTournament?.name
        ? item?.AFLTournament?.name
        : item?.SportId === 9 && item?.ARTournament?.name
        ? item?.ARTournament?.name
        : item?.SportId === 16 && item?.GolfTournament?.name
        ? item?.GolfTournament?.name
        : item?.SportId === 7 && item?.TennisTournament?.name
        ? item?.TennisTournament?.name
        : item?.SportId === 11 && item?.BaseballTournament?.name
        ? item?.BaseballTournament?.name
        : item?.SportId === 17 && item?.IceHockeyTournament?.name
        ? item?.IceHockeyTournament?.name
        : item?.SportId === 6 && item?.BoxingTournament?.name
        ? item?.BoxingTournament?.name
        : item?.SportId === 5 && item?.MMATournament?.name
        ? item?.MMATournament?.name
        : item?.SportId === 8 && item?.SoccerTournament?.name
        ? item?.SoccerTournament?.name
        : item?.RLTournament?.name;

    let tournamentId =
      item?.SportId === 4 && item?.CricketTournamentId
        ? item?.CricketTournamentId
        : item?.SportId === 10 && item?.NBATournamentId
        ? item?.NBATournamentId
        : item?.SportId === 15 && item?.AFLTournamentId
        ? item?.AFLTournamentId
        : item?.SportId === 9 && item?.ARTournamentId
        ? item?.ARTournamentId
        : item?.SportId === 16 && item?.GolfTournamentId
        ? item?.GolfTournamentId
        : item?.SportId === 7 && item?.TennisTournamentId
        ? item?.TennisTournamentId
        : item?.SportId === 11 && item?.BaseballTournamentId
        ? item?.BaseballTournamentId
        : item?.SportId === 17 && item?.IceHockeyTournamentId
        ? item?.IceHockeyTournamentId
        : item?.SportId === 6 && item?.BoxingTournamentId
        ? item?.BoxingTournamentId
        : item?.SportId === 5 && item?.MMATournamentId
        ? item?.MMATournamentId
        : item?.SportId === 8 && item?.SoccerTournamentId
        ? item?.SoccerTournamentId
        : item?.RLTournamentId;

    return (
      <Box
        className="custom-tooltip"
        onClick={e => handleTooltipContentClick(e, teamId, index, parentIndex)}
      >
        <Box
          // className={showOdds ? "smartboook-icon-box" : "smartboook-icon-box"}
          className="smartboook-icon-box"
        >
          {(teamType === "hometeam"
            ? Boolean(item?.isSmartBookHomeTeam)
            : Boolean(item?.isSmartBookAwayTeam)) &&
          Boolean(item?.isSmartBookTournament) ? (
            <BlackbookRight className="book-icon" />
          ) : (
            <BlackbookPlus className="book-icon" />
          )}
        </Box>
        {hoveredIndex === index &&
        hoveredParentIndex === parentIndex &&
        clickToolTipTeamId === teamId ? (
          tooltipOpen && (
            <Box className="tooltip comp-smartbook-tooltip">
              <TooltipArrow className="tooltip-arrow comp-smartbook-tooltip-arrow" />
              <Typography className="add-smartbook">
                <AddBookIcon /> Add To My Smartbook
              </Typography>
              <Box className="black-details">
                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        teamType === "hometeam"
                          ? item?.homeTeam?.name
                          : item?.awayTeam?.name,
                        teamType === "hometeam"
                          ? item?.homeTeam?.id
                          : item?.awayTeam?.id,
                        "Team",
                        item
                      )
                    }
                  >
                    <span>
                      {" "}
                      Team:{" "}
                      {teamType === "hometeam"
                        ? item?.homeTeam?.name
                        : item?.awayTeam?.name}
                    </span>
                    {(teamType === "hometeam" ? (
                      Boolean(item?.isSmartBookHomeTeam)
                    ) : (
                      Boolean(item?.isSmartBookAwayTeam)
                    )) ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                    {/* {(teamType === "hometeam" &&
                      item?.isSmartBookAwayTeam === true) ||
                    (teamType === "awayteam" &&
                      item?.isSmartBookHomeTeam === true) ? (
                      <RightBlack className="icon-blackbook" />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )} */}
                  </Typography>
                </Box>

                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        tournamentName,
                        tournamentId,
                        "Tournament",
                        item
                      )
                    }
                  >
                    <span>
                      League: {tournamentName ? tournamentName : "N/A"}
                    </span>
                    {Boolean(item?.isSmartBookTournament) ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const handleModal = () => {
    setSBOpen(true);
  };

  const handleClose = () => {
    setSBOpen(false);
  };
  const handleBlackBookModal = (title, id, type, item) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    setSelectedSportId(item?.SportId);
    handleModal();
  };

  const handleCheckboxClick = (event, item, teamType) => {
    const eventList = eventsData?.map(obj => {
      if (obj?.id == item?.id) {
        if (teamType === "hometeam") {
          return {
            ...obj,
            awayTeam: {
              ...obj?.awayTeam,
              isTip: event.target.checked ? 0 : obj?.awayTeam?.isTip
            },
            homeTeam: {
              ...obj?.homeTeam,
              isTip: event.target.checked ? 1 : 0
            }
          };
        } else {
          return {
            ...obj,
            awayTeam: {
              ...obj?.awayTeam,
              isTip: event.target.checked ? 1 : 0
            },
            homeTeam: {
              ...obj?.homeTeam,
              isTip: event.target.checked ? 0 : obj?.homeTeam?.isTip
            }
          };
        }
      } else {
        return obj;
      }
    });
    convetToGroupData(eventList);
    setEventsData(eventList);
  };

  const handleSubmit = async () => {
    let payload = {};
    const finalPayload = eventsData?.map((item, index) => {
      payload = {
        round: stepperCount,
        teamId:
          item?.awayTeam?.isTip == 1
            ? item?.awayTeamId
            : item?.homeTeam?.isTip == 1
            ? item?.homeTeamId
            : null,
        eventId: item?.id,
        SportId: item?.SportId
      };
      payload = index === 0 ? { ...payload, margin: counts } : payload;
      return payload;
    });
    const passPayload = {
      jocker: jokerRound ? jokerRound : isJoker ? stepperCount : null,
      events: finalPayload
    };
    try {
      const { status, data } = await axiosInstance.put(
        `tipping/addEvent/${Number(params?.compsId)}?timezone=${timezone}`,
        passPayload
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        // navigate("/tipscompetition/my-comps");
        getEventByID(stepperCount);
        setIsSubmit(true);
      } else {
      }
    } catch (err) {
      setCounts(0);
      handleReset();
      toast.success(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
      console.log("error", err);
    }
  };

  const handleReset = () => {
    const eventList = eventsData?.map(obj => {
      return {
        ...obj,
        awayTeam: {
          ...obj?.awayTeam,
          isTip: 0
        },
        homeTeam: {
          ...obj?.homeTeam,
          isTip: 0
        }
      };
    });
    convetToGroupData(eventList);
    setEventsData(eventList);
    setCounts(0);
  };
  const fetchDayandTime = () => {
    if (eventByIdData?.cutOffTime) {
      const dayWithTime = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss"
        )
        .tz(timezone)
        .format("dddd [|] h:mm A");
      return <span>{dayWithTime}</span>;
    }
  };
  const Renderer = ({ days, hours, minutes, seconds }) => {
    return null;
  };

  const shareOnMessenger = link => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored"
    });
  };

  const handleShareTooltipContentClick = e => {
    e.stopPropagation();
    setShareTooltipOpen(!shareTooltipOpen);
  };

  const handleShareOutsideClick = () => {
    if (shareTooltipOpen) {
      setShareTooltipOpen(false);
    }
  };

  const handleOpenShareTooltip = () => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/share-tips?round=${stepperCount}&compId=${Number(
        params?.compsId
      )}&referral_type=tippingComp&referral=${
        redux_userData?.referralCode
      }&sportId=${Number(params?.SportId)}&tournamentId=${Number(
        params?.tournamentId
      )}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={e => handleShareTooltipContentClick(e)}
      >
        {/* <Box> */}
        <Button className="share-btn mt-0" endIcon={<Share />}>
          Share
        </Button>
        {/* </Box> */}
        {shareTooltipOpen && (
          <Box className="tooltip">
            <Box style={{ position: "relative" }}>
              <TooltipArrow className="tooltip-arrow" />
            </Box>
            <Box className="share-option">
              <Box className="social-option-box">
                <Box className="option">
                  <CopyToClipboard text={link} onCopy={handleCopy}>
                    <Box className="cursor-pointer">
                      <img src={Copy} alt="Copy" />
                      <Typography className="option-name">Copy Link</Typography>
                    </Box>
                  </CopyToClipboard>
                </Box>
                <Box className="option">
                  <WhatsappShareButton url={link}>
                    <img src={Wp} alt="Whatsapp" />
                  </WhatsappShareButton>
                  <Typography className="option-name">Whatsapp</Typography>
                </Box>
                <Box className="option">
                  <FacebookShareButton url={link}>
                    <img src={Facebook} alt="Facebook" />
                  </FacebookShareButton>
                  <Typography className="option-name">Facebook</Typography>
                </Box>
                <Box className="option cursor-pointer">
                  <img
                    src={Messenger}
                    alt="Messenger"
                    className="social-icon"
                    onClick={() => shareOnMessenger(link)}
                  />
                  <Typography className="option-name">Messenger</Typography>
                </Box>
                <Box className="option">
                  <TwitterShareButton url={link}>
                    <img src={Twitter_X} alt="Twitter_X" />
                  </TwitterShareButton>
                  <Typography className="option-name">Twitter</Typography>
                </Box>
                <Box className="option">
                  <LinkedinShareButton url={link}>
                    <img src={Linkedin} alt="Linkedin" />
                  </LinkedinShareButton>
                  <Typography className="option-name">Linkedin</Typography>
                </Box>
                {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {fetchAds(
        1,
        "100%",
        Config?.release == "IN" ? BannerIndia : tippingAbove
      )}
      {isLoading ? (
        <div className="allsport-loader-center app-loader">
          <Loader />
        </div>
      ) : (
        <>
          {eventsData && eventsData?.length > 0 ? (
            <>
              {Object.entries(groupedEventData)?.map(
                ([date, tips], parentIndex) => {
                  return (
                    <Box className="tips-sec" key={date}>
                      <Box className="title-date-sec">
                        <Typography className="title-date">
                          {date ? fetchDayName(date) : ""}{" "}
                          {date
                            ? moment
                                .utc(date)
                                .local()
                                .format("DD/MM/YYYY")
                            : ""}
                        </Typography>
                      </Box>
                      {tips?.map((tip, index) => {
                        const dateTocheck =
                          eventByIdData?.tipDeadline == "game"
                            ? moment(tip?.startTime)
                            : lastDate;
                        return (
                          <>
                            <Box className="comp-tip-data-sec">
                              <Typography className="time-venue-txt">
                                {tip?.startTime
                                  ? moment
                                      .utc(tip?.startTime)
                                      .local()
                                      .format("hh:mm A")
                                  : ""}{" "}
                                <span>
                                  {tip?.venue ? "|" + tip?.venue : ""}
                                </span>
                              </Typography>
                              <Box
                                className={
                                  eventLength === 0
                                    ? `comp-odds-tips-sec`
                                    : tip?.homeScore ||
                                      (tip?.ScoreBoard &&
                                        Object.keys(tip?.ScoreBoard)?.length >
                                          0 &&
                                        tip?.ScoreBoard?.Epr == 2)
                                    ? `comp-odds-tips-sec `
                                    : tip?.winnerCode == 3 ||
                                      (tip?.winnerCode === null &&
                                        tip?.status === null &&
                                        moment
                                          .utc(tip?.startTime)
                                          .tz(timezone) < moment().tz(timezone))
                                    ? `comp-odds-tips-sec indefinite-result no-result`
                                    : `comp-odds-tips-sec no-result`
                                }
                              >
                                <Box className="left-sec">
                                  <Box className="team-wrap">
                                    <Box className="team-img-wrap">
                                      <img
                                        className="team-img"
                                        src={
                                          tip?.homeTeam?.flag?.includes(
                                            "uploads"
                                          )
                                            ? Config.mediaURL +
                                              tip?.homeTeam?.flag
                                            : Melbourne
                                        }
                                        alt="team"
                                      />
                                    </Box>
                                    <Box className="comp-name-tip-wrap">
                                      <Typography className="team-name">
                                        {tip?.homeTeam?.name}
                                      </Typography>
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          handleOutsideClick(
                                            tip,
                                            tip?.homeTeamId,
                                            index,
                                            parentIndex
                                          )
                                        }
                                      >
                                        <Box className="blackbook-icon">
                                          {handleOpenTooltip(
                                            tip,
                                            tip?.homeTeamId,
                                            index,
                                            "hometeam",
                                            parentIndex
                                          )}
                                        </Box>
                                      </ClickAwayListener>
                                    </Box>
                                  </Box>
                                  {eventLength === 0 ? (
                                    release[Config.release]?.oddsCheck ? (
                                      <Box className="odds-wrap">
                                        {/* <Typography className="odds">
                                    {tip?.homeOdds}
                                  </Typography> */}
                                        {fetchCurrentBestOdds(
                                          tip,
                                          null,
                                          "hometeam",
                                          tip?.homeTeamId,
                                          true
                                        )}
                                        {fetchCurrentBestsOddsIcon(
                                          tip,
                                          null,
                                          "hometeam",
                                          tip?.homeTeamId
                                        )}
                                      </Box>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <>
                                      {Number(params?.SportId) != 4 ? (
                                        tip?.homeScore ? (
                                          <Box className="blue-score">
                                            <Typography className="score">
                                              {tip?.homeScore?.current ?? 0}
                                            </Typography>
                                          </Box>
                                        ) : (
                                          <></>
                                        )
                                      ) : tip?.ScoreBoard &&
                                        Object.keys(tip?.ScoreBoard)?.length >
                                          0 &&
                                        tip?.ScoreBoard?.Epr == 2 ? (
                                        <Box className="blue-score">
                                          {tip?.ScoreBoard?.Exd > "1" ? (
                                            <Typography className="score">
                                              {tip?.ScoreBoard?.Tr1C1 ?? "-"}
                                              {tip?.ScoreBoard?.Tr1CW1 &&
                                              tip?.ScoreBoard?.Tr1CW1 === 10
                                                ? ""
                                                : (tip?.ScoreBoard?.Tr1CW1 ||
                                                    tip?.ScoreBoard?.Tr1CW1 ===
                                                      0) &&
                                                  `/${tip?.ScoreBoard?.Tr1CW1}${
                                                    tip?.ScoreBoard?.Tr1CD1 &&
                                                    tip?.ScoreBoard?.Tr1CD1 ===
                                                      1
                                                      ? "d"
                                                      : ""
                                                  }`}{" "}
                                              {(tip?.ScoreBoard?.Tr1C2 ||
                                                tip?.ScoreBoard?.Tr1C2 === 0) &&
                                                `& ${tip?.ScoreBoard?.Tr1C2}`}
                                              {tip?.ScoreBoard?.Tr1CW2 &&
                                              tip?.ScoreBoard?.Tr1CW2 === 10
                                                ? ""
                                                : (tip?.ScoreBoard?.Tr1CW2 ||
                                                    tip?.ScoreBoard?.Tr1CW2 ===
                                                      0) &&
                                                  `/${tip?.ScoreBoard?.Tr1CW2}${
                                                    tip?.ScoreBoard?.Tr1CD2 &&
                                                    tip?.ScoreBoard?.Tr1CD2 ===
                                                      1
                                                      ? "d"
                                                      : ""
                                                  }`}{" "}
                                            </Typography>
                                          ) : (
                                            <Typography className="score">
                                              {tip?.ScoreBoard?.Tr1C1 ?? "-"}
                                              {tip?.ScoreBoard?.Tr1CW1 &&
                                              tip?.ScoreBoard?.Tr1CW1 === 10
                                                ? ""
                                                : (tip?.ScoreBoard?.Tr1CW1 ||
                                                    tip?.ScoreBoard?.Tr1CW1 ===
                                                      0) &&
                                                  `/${tip?.ScoreBoard?.Tr1CW1}`}{" "}
                                            </Typography>
                                          )}
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}

                                  <Box className="check-box-sec">
                                    <FormControl>
                                      <label>
                                        <Checkbox
                                          icon={
                                            tip?.winnerCode == 3 ? (
                                              <Notcheck />
                                            ) : dateTocheck < moment() &&
                                              tip?.homeTeam?.isTip !== 1 &&
                                              tip?.awayTeam?.isTip !== 1 ? (
                                              <Notcheck />
                                            ) : (
                                              <Uncheck />
                                            )
                                          }
                                          checkedIcon={
                                            tip?.winnerCode ? (
                                              tip?.winnerCode == 1 ? (
                                                <Check />
                                              ) : (
                                                <Notcheck />
                                              )
                                            ) : (
                                              <Check />
                                            )
                                          }
                                          disabled={
                                            dateTocheck < moment() ||
                                            Boolean(tip?.winnerCode)
                                          }
                                          // name="filter"
                                          value="homeTeamId"
                                          onChange={event => {
                                            // setSelectedIds({
                                            //   ...selectedIds,
                                            //   [tip?.id]:
                                            //     selectedIds[tip?.id] ===
                                            //     tip?.homeTeamId
                                            //       ? null
                                            //       : tip?.homeTeamId
                                            // });
                                            handleCheckboxClick(
                                              event,
                                              tip,
                                              "hometeam"
                                            );
                                          }}
                                          checked={tip?.homeTeam?.isTip == 1}
                                        />
                                        {/* {filter?.icon} */}
                                      </label>
                                    </FormControl>
                                  </Box>
                                </Box>
                                <Box className="left-sec flex-rr">
                                  <Box className="team-wrap">
                                    <Box className="team-img-wrap">
                                      <img
                                        className="team-img"
                                        src={
                                          tip?.awayTeam?.flag?.includes(
                                            "uploads"
                                          )
                                            ? Config.mediaURL +
                                              tip?.awayTeam?.flag
                                            : Brisbane
                                        }
                                        alt="team"
                                      />
                                    </Box>
                                    <Box className="comp-name-tip-wrap">
                                      <Typography className="team-name">
                                        {tip?.awayTeam?.name}
                                      </Typography>
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          handleOutsideClick(
                                            tip,
                                            tip?.awayTeamId,
                                            index,
                                            parentIndex
                                          )
                                        }
                                      >
                                        <Box className="blackbook-icon">
                                          {handleOpenTooltip(
                                            tip,
                                            tip?.awayTeamId,
                                            index,
                                            "awayteam",
                                            parentIndex
                                          )}
                                        </Box>
                                      </ClickAwayListener>
                                    </Box>
                                  </Box>
                                  {eventLength === 0 ? (
                                    release[Config.release]?.oddsCheck ? (
                                      <Box className="odds-wrap odd-rr">
                                        {fetchCurrentBestOdds(
                                          tip,
                                          null,
                                          "awayteam",
                                          tip?.awayTeamId,
                                          true
                                        )}
                                        {/* {item?.homeBestBookKeepertId
                                  ? oddsicon(
                                      item?.homeBestBookKeepertId,
                                      "header",
                                      item
                                    )
                                  : ""} */}
                                        {fetchCurrentBestsOddsIcon(
                                          tip,
                                          null,
                                          "awayteam",
                                          tip?.awayTeamId
                                        )}
                                      </Box>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <>
                                      {Number(params?.SportId) != 4 ? (
                                        tip?.awayScore ? (
                                          <Box className="blue-score">
                                            <Typography className="score">
                                              {tip?.awayScore?.current ?? 0}
                                            </Typography>
                                          </Box>
                                        ) : (
                                          <></>
                                        )
                                      ) : tip?.ScoreBoard &&
                                        Object.keys(tip?.ScoreBoard)?.length >
                                          0 &&
                                        tip?.ScoreBoard?.Epr == 2 ? (
                                        <Box className="blue-score">
                                          {tip?.ScoreBoard?.Exd > "1" ? (
                                            <Typography className="score">
                                              {tip?.ScoreBoard?.Tr2C1 ?? "-"}
                                              {tip?.ScoreBoard?.Tr2CW1 &&
                                              tip?.ScoreBoard?.Tr2CW1 === 10
                                                ? ""
                                                : (tip?.ScoreBoard?.Tr2CW1 ||
                                                    tip?.ScoreBoard?.Tr2CW1 ===
                                                      0) &&
                                                  `/${tip?.ScoreBoard?.Tr2CW1}${
                                                    tip?.ScoreBoard?.Tr2CD1 &&
                                                    tip?.ScoreBoard?.Tr2CD1 ===
                                                      1
                                                      ? "d"
                                                      : ""
                                                  }`}{" "}
                                              {(tip?.ScoreBoard?.Tr2C2 ||
                                                tip?.ScoreBoard?.Tr2C2 === 0) &&
                                                `& ${tip?.ScoreBoard?.Tr2C2}`}
                                              {tip?.ScoreBoard?.Tr2CW2 &&
                                              tip?.ScoreBoard?.Tr2CW2 === 10
                                                ? ""
                                                : (tip?.ScoreBoard?.Tr2CW2 ||
                                                    tip?.ScoreBoard?.Tr2CW2 ===
                                                      0) &&
                                                  `/${tip?.ScoreBoard?.Tr2CW2}${
                                                    tip?.ScoreBoard?.Tr2CD2 &&
                                                    tip?.ScoreBoard?.Tr2CD2 ===
                                                      1
                                                      ? "d"
                                                      : ""
                                                  }`}{" "}
                                            </Typography>
                                          ) : (
                                            <Typography className="score">
                                              {tip?.ScoreBoard?.Tr2C1 ?? "-"}
                                              {tip?.ScoreBoard?.Tr2CW1 &&
                                              tip?.ScoreBoard?.Tr2CW1 === 10
                                                ? ""
                                                : (tip?.ScoreBoard?.Tr2CW1 ||
                                                    tip?.ScoreBoard?.Tr2CW1 ===
                                                      0) &&
                                                  `/ ${tip?.ScoreBoard?.Tr2CW1}`}{" "}
                                            </Typography>
                                          )}
                                        </Box>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                  <Box className="check-box-sec">
                                    <FormControl>
                                      <label>
                                        <Checkbox
                                          icon={
                                            tip?.winnerCode == 3 ? (
                                              <Notcheck />
                                            ) : dateTocheck < moment() &&
                                              tip?.awayTeam?.isTip !== 1 &&
                                              tip?.homeTeam?.isTip !== 1 ? (
                                              <Notcheck />
                                            ) : (
                                              <Uncheck />
                                            )
                                          }
                                          checkedIcon={
                                            tip?.winnerCode ? (
                                              tip?.winnerCode == 2 ? (
                                                <Check />
                                              ) : (
                                                <Notcheck />
                                              )
                                            ) : (
                                              <Check />
                                            )
                                          }
                                          disabled={
                                            dateTocheck < moment() ||
                                            Boolean(tip?.winnerCode)
                                          }
                                          // name="filter"
                                          value="awayTeamId"
                                          onChange={event => {
                                            handleCheckboxClick(
                                              event,
                                              tip,
                                              "awayteam"
                                            );
                                          }}
                                          checked={tip?.awayTeam?.isTip == 1}
                                        />
                                        {/* {filter?.icon} */}
                                      </label>
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Box>
                              {tip?.margin && eventByIdData?.marginRound == 1 && (
                                // !moment(tip?.startTime).isBefore(moment()) &&
                                <>
                                  <Box className="margin-sec">
                                    <Typography className="margin-txt">
                                      {params?.sports == "cricket"
                                        ? "Number of 6s"
                                        : "Margin"}
                                    </Typography>
                                  </Box>
                                  <Box className="counter-score-sec">
                                    <Box className="counter-input">
                                      <Button
                                        disableFocusRipple
                                        disableRipple
                                        className="counter-input__button"
                                        onClick={e => {
                                          e.preventDefault();
                                          handleDecrement(tip?.id, index);
                                        }}
                                        disabled={
                                          counts == 0 || dateTocheck < moment()
                                        }
                                        // startIcon={<LeftArrow />}
                                      >
                                        {/* <Minus /> */}
                                        <MyMinusNutton />
                                      </Button>
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        className="margin-textfield"
                                        name="compName"
                                        onChange={e => {
                                          const input = e?.target?.value;
                                          const numericInput = input?.replace(
                                            /[^0-9]/g,
                                            ""
                                          );
                                          const removeLeadingZeros = numericInput => {
                                            if (numericInput.length >= 2) {
                                              const result = parseInt(
                                                numericInput,
                                                10
                                              ).toString();
                                              return result;
                                            }
                                            return numericInput;
                                          };
                                          const finalMargin = removeLeadingZeros(
                                            numericInput
                                          );

                                          setCounts(
                                            finalMargin
                                              ? Number(finalMargin)
                                              : 0
                                          );
                                        }}
                                        onFocus={e => e.target.select()}
                                        value={counts}
                                        disabled={dateTocheck < moment()}
                                      />
                                      {/* <div className="counter-input__content">
                                      {counts}
                                    </div> */}

                                      <Button
                                        disableFocusRipple
                                        disableRipple
                                        className="counter-input__button plus-btn"
                                        onClick={e => {
                                          e.preventDefault();
                                          handleIncrement(tip?.id, index);
                                        }}
                                        disabled={dateTocheck < moment()}
                                        // endIcon={<RightArrow />}
                                      >
                                        {/* <Plus /> */}
                                        <MyPLusNutton />
                                      </Button>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </Box>
                            {eventByIdData?.tipDeadline == "game" && (
                              <Countdown
                                date={moment.utc(tip?.startTime).toDate()}
                                renderer={Renderer}
                                onComplete={() =>
                                  setTimeout(
                                    () => getEventByID(stepperCount),
                                    1000
                                  )
                                }
                                key={moment.utc(tip?.startTime).format()}
                              />
                            )}
                          </>
                        );
                      })}
                    </Box>
                  );
                }
              )}
              {eventByIdData?.jokerRound === 1 && (
                <Box className="joker-box">
                  <Box className="top-sec">
                    <Typography className="joker-txt">Joker Round</Typography>
                  </Box>
                  <Box className="bottom-sec">
                    <Box>
                      <FormControl>
                        <label>
                          <Checkbox
                            icon={<Uncheck />}
                            checkedIcon={<BlueCheck />}
                            checked={isJoker}
                            onChange={e => setIsJoker(e)}
                            disabled={
                              (lastDate < moment() &&
                                eventByIdData?.tipDeadline === "round") ||
                              (moment(lastEventDate) < moment().tz(timezone) &&
                                eventByIdData?.tipDeadline === "game") ||
                              jokerRound ||
                              jokerRound == 0 ||
                              completedRound?.includes(Number(stepperCount))
                            }
                          />
                          {/* {filter?.icon} */}
                        </label>
                      </FormControl>
                    </Box>
                    <Box>
                      <Typography className="select-txt">
                        Select Joker Round
                      </Typography>
                      <Typography className="detail-txt">
                        You are allowed to select Joker Round once per season.
                        Selecting a Joker will double your round points.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {(eventByIdData?.tipDeadline == "round" ? (
                lastDate > moment()
              ) : (
                !completedRound?.includes(stepperCount) ||
                Boolean(moment(lastEventDate) > moment().tz(timezone))
              )) ? (
                <>
                  <Box className="btn-flex">
                    <>
                      <Box className="submit-tips-btn-sec">
                        <Button
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          fullWidth
                          className="submit-tips-btn"
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={
                            eventByIdData?.marginRound == 1 &&
                            Boolean(
                              eventsData?.[0]?.awayTeam?.isTip ||
                                eventsData?.[0]?.homeTeam?.isTip
                            ) &&
                            counts === 0
                          }
                        >
                          {eventLength == 0 ? "Submit" : "Update"} tips
                        </Button>
                      </Box>
                      <Button
                        className="share-btn mt-0"
                        endIcon={<Share />}
                        onClick={
                          () =>
                            navigate(
                              `/tipscompetition/share-tips?round=${stepperCount}&compId=${Number(
                                params?.compsId
                              )}&referral_type=tippingComp&referral=${
                                redux_userData?.referralCode
                              }&sportId=${Number(
                                params?.SportId
                              )}&tournamentId=${Number(params?.tournamentId)}
    `
                            )
                          // navigate(
                          //   `/tipscompetition/share-tips/${stepperCount}/${Number(
                          //     params?.compsId
                          //   )}/tippingComp/${
                          //     redux_userData?.referralCode
                          //   }/${Number(params?.SportId)}/${Number(
                          //     params?.tournamentId
                          //   )}`
                          // )
                        }
                      >
                        Share tips
                      </Button>
                    </>
                  </Box>
                  {eventByIdData?.marginRound == 1 &&
                    Boolean(
                      eventsData?.[0]?.awayTeam?.isTip ||
                        eventsData?.[0]?.homeTeam?.isTip
                    ) &&
                    counts === 0 && (
                      <Box className="margin-error">
                        <Typography className="error">
                          Please select{" "}
                          {params?.sports == "cricket"
                            ? "Number of 6s"
                            : "Margin"}{" "}
                          at least 1
                        </Typography>
                      </Box>
                    )}
                  <Box className="clear-all-box">
                    <Typography
                      className="clear-all"
                      onClick={() => {
                        handleReset();
                      }}
                    >
                      Clear all
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box className="btn-flex">
                    <Button
                      className="share-btn mt-0"
                      endIcon={<Share />}
                      onClick={
                        () =>
                          navigate(
                            `/tipscompetition/share-tips?round=${stepperCount}&compId=${Number(
                              params?.compsId
                            )}&referral_type=tippingComp&referral=${
                              redux_userData?.referralCode
                            }&sportId=${Number(
                              params?.SportId
                            )}&tournamentId=${Number(params?.tournamentId)}
    `
                          )
                        // navigate(
                        //   `/tipscompetition/share-tips/${stepperCount}/${Number(
                        //     params?.compsId
                        //   )}/tippingComp/${
                        //     redux_userData?.referralCode
                        //   }/${Number(params?.SportId)}/${Number(
                        //     params?.tournamentId
                        //   )}`
                        // )
                      }
                    >
                      Share tips
                    </Button>
                  </Box>
                </>
              )}
            </>
          ) : (
            <Box
              style={{
                textAlign: "center"
              }}
            >
              <NoDataComp />
            </Box>
          )}
          <SmartBookModal
            open={SBopen}
            handleClose={handleClose}
            title={selectedTitle}
            type={selectedType}
            closeIcon={true}
            typeId={selectedId}
            dataId={selectedId}
            sportId={selectedSportId}
            listingFunction={() =>
              setTimeout(() => {
                getEventByID(stepperCount);
              }, 3000)
            }
          />
        </>
      )}
      {fetchAds(
        2,
        "100%",
        Config?.release == "IN" ? BannerIndia : tippingBelow
      )}
    </>
  );
};

export default TipsTab;
