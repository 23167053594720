import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Breadcrumbs,
  Button,
  ClickAwayListener,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.png";
import NotepadBig from "../../../assets/images/notepadBig.png";
import Facebook from "../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../assets/images/social/x-link.svg";
import Snapchat from "../../../assets/images/social/sc-link.svg";
import Copy from "../../../assets/images/social/copy-link.svg";
import Messenger from "../../../assets/images/social/mess-link.svg";
import Wp from "../../../assets/images/social/wp-link.svg";
import Message from "../../../assets/images/social/messages.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import AdvertisingBannner from "../advertisingBanner";
import TipsTab from "./tips";
import LadderTab from "./ladder";
import { Link, useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import OurPartner from "../ourPartners";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Share } from "../../../assets/images/icons/share.svg";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as TooltipArrow } from "src/assets/images/icons/tooltip_arrow.svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton
} from "react-share";
import { ToastContainer, toast } from "react-toastify";
import DefaultImg from "src/assets/images/smartb_default.png";
import moment from "moment-timezone";
import AdBannner from "../AdBanner/AdBanner";
import "./individualCompetition.scss";
import CompDetailsTab from "./compDetails";

const newTabData = [
  {
    id: 0,
    name: "Tips"
  },
  { id: 1, name: "Ladder" },
  { id: 2, name: "Comp Details" }
];

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const IndividualCompetitionPage = () => {
  const params = useParams();
  const redux_userData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [tabvalue, setTabValue] = useState(0);
  const [stepperCount, setStepperCount] = useState(0);
  const [eventLength, setEventLength] = useState(0);
  const [eventByIdData, setEventByIdData] = useState({});
  const [allEventData, setAllEventData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isJoker, setIsJoker] = useState(false);
  const [jokerRound, setJockerRound] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [newRoundOptions, setNewRoundOptions] = useState([]);
  const [noRoundData, setNoRoundData] = useState("");
  const [completedRound, setCompletedRound] = useState([]);
  const [cutoffDate, setcutoffDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [recallAPI, setRecallAPI] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [lastEventDate, setLastEventDate] = useState(null);
  useEffect(() => {
    if (cutoffDate && eventByIdData?.cutOffTime) {
      const time = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss"
        )
        .tz(timezone)
        .format("HH:mm:ss");
      const dateTime = moment(`${cutoffDate} ${time}`, "YYYY-MM-DD HH:mm:ss");
      setLastDate(dateTime);
    }
  }, [cutoffDate, eventByIdData?.cutOffTime]);

  useEffect(() => {
    if (lastDate && lastDate > moment()) {
      const updateCurrentTime = () => {
        const currentTimePassed = lastDate < moment();
        if (currentTimePassed) {
          setRecallAPI(true);
          clearInterval(intervalId);
        }
      };

      const intervalId = setInterval(updateCurrentTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [lastDate]);

  useEffect(() => {
    if (recallAPI) {
      getEventByID(stepperCount);
      // setRecallAPI(false);
    }
  }, [recallAPI]);

  const handleTabChange = (e, id) => {
    setTabValue(id);
    if (id === 0) {
      getEventByID(stepperCount);
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  useEffect(() => {
    fetchRoundData(
      Number(params?.tournamentId),
      Number(params?.SportId),
      Number(params?.compsId)
    );
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const getEventByID = async round => {
    const compId = Number(params?.compsId);
    // const compId = Number(params?.compId);
    setLoading(true);
    try {
      //
      const { status, data } = await axiosInstance.get(
        `tipping/get/${compId}?timezone=${timezone}&round=${round}`
      );
      if (status === 200) {
        setEventByIdData(data?.result);
        // setStepperCount(round);
        setEventLength(data?.events?.length);
        setIsJoker(round == data?.result?.joker);
        setJockerRound(data?.result?.joker);

        if (data?.events?.length === 0) {
          getAllEvents(round);
        } else {
          setAllEventData(data?.events);
          setcutoffDate(data?.result?.date);
          setLoading(false);
          setLastEventDate(
            moment
              .utc(data?.events?.[data?.events?.length - 1]?.startTime)
              .tz(timezone)
              .format("YYYY-MM-DD HH:mm:ss")
          );
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getAllEvents = async round => {
    const sId = Number(params?.SportId);
    const tId = Number(params?.tournamentId);
    const compId = Number(params?.compsId);
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/events?timezone=${timezone}&tournamentId=${tId}&SportId=${sId}&startDate=${moment()
          .tz(timezone)
          .format("YYYY-MM-DD")}&round=${round}&competitionId=${compId}`
      );
      if (status === 200) {
        // setStepperCount(round);
        setLoading(false);
        setAllEventData(data?.result);
        setcutoffDate(data?.competition?.date);
        setLastEventDate(
          moment
            .utc(data?.result?.[data?.result?.length - 1]?.startTime)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm:ss")
        );
        // setRounds(round);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const fetchRoundData = async (tID, sID, cID) => {
    const date = moment(Date()).format("YYYY-MM-DD");
    // &startDate=${date}
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/rounds?timezone=${timezone}&tournamentId=${tID}&SportId=${sID}&competitionId=${cID}`
      );
      if (status === 200) {
        let newdata = [];
        const filteredRound = data?.result?.filter(
          round => !data?.completedRound.includes(round)
        );
        let track = filteredRound?.map(item => {
          newdata.push({
            label: `Round ${Number(item)}`,
            value: item
          });
        });
        data?.result &&
          data?.result?.length > 0 &&
          getEventByID(data?.current ? data?.current : data?.result?.[0]);

        if (newdata?.length > 0) {
          setNewRoundOptions(newdata);
          setNoRoundData("");
        } else {
          setNoRoundData(
            "There are no rounds available for this tournament!, Try selecting a different League."
          );
        }
        setCompletedRound(data?.completedRound);
        setRounds(data?.result);
        setStepperCount(data?.current ? data?.current : data?.result?.[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // let weekDay = moment
  //   .tz(eventByIdData?.cutOffTime, "HH:mm:ss", timezone)
  //   .local()
  //   .format("dddd");
  // let time = moment
  //   .tz(eventByIdData?.cutOffTime, "HH:mm:ss", timezone)
  //   .local()
  //   .format("HH:mm:ss");
  const currentDate = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  const fetchDayandTime = () => {
    if (eventByIdData?.cutOffTime) {
      const dayWithTime = moment
        .utc(
          `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
          "dddd HH:mm:ss"
        )
        .tz(timezone)
        .format("h:mm A");
      return (
        <span>
          {eventByIdData?.cutOffWeek +
            " " +
            moment(cutoffDate).format("DD/MM/YYYY") +
            " | " +
            dayWithTime}
        </span>
      );
    }
  };

  const handleTooltipContentClick = e => {
    e.stopPropagation();
    setTooltipOpen(!tooltipOpen);
  };

  const handleOutsideClick = () => {
    if (tooltipOpen) {
      setTooltipOpen(false);
    }
  };

  const handleOpenTooltip = () => {
    const link =
      Config.baseURL.replace("/api", "") +
      `tipscompetition/private-comps?code=${eventByIdData?.code}`;
    return (
      <Box
        className="comp-custom-tooltip right-sec"
        onClick={e => handleTooltipContentClick(e)}
      >
        {/* <Box> */}
        <Button className="share-btn" endIcon={<Share />}>
          Share
        </Button>
        {/* </Box> */}
        {tooltipOpen && (
          <Box className="tooltip">
            <Box style={{ position: "relative" }}>
              <TooltipArrow className="tooltip-arrow" />
            </Box>
            <Box className="share-option">
              <Box className="social-option-box">
                <Box className="option">
                  <CopyToClipboard text={link} onCopy={handleCopy}>
                    <Box className="cursor-pointer">
                      <img src={Copy} alt="Copy" />
                      <Typography className="option-name">Copy Link</Typography>
                    </Box>
                  </CopyToClipboard>
                </Box>
                <Box className="option">
                  <WhatsappShareButton url={link}>
                    <img src={Wp} alt="Whatsapp" />
                  </WhatsappShareButton>
                  <Typography className="option-name">Whatsapp</Typography>
                </Box>
                <Box className="option">
                  <FacebookShareButton url={link}>
                    <img src={Facebook} alt="Facebook" />
                  </FacebookShareButton>
                  <Typography className="option-name">Facebook</Typography>
                </Box>
                <Box className="option cursor-pointer">
                  <img
                    src={Messenger}
                    alt="Messenger"
                    className="social-icon"
                    onClick={() => shareOnMessenger(link)}
                  />
                  <Typography className="option-name">Messenger</Typography>
                </Box>
                <Box className="option">
                  <TwitterShareButton url={link}>
                    <img src={Twitter_X} alt="Twitter_X" />
                  </TwitterShareButton>
                  <Typography className="option-name">Twitter</Typography>
                </Box>
                <Box className="option">
                  <LinkedinShareButton url={link}>
                    <img src={Linkedin} alt="Linkedin" />
                  </LinkedinShareButton>
                  <Typography className="option-name">Linkedin</Typography>
                </Box>
                {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored"
    });
  };

  const shareOnMessenger = link => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  const aboutLines = eventByIdData?.about?.split("\n");

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerIndia : banner
          )}
        </Box>

        <Box className="in-comp-wrap">
          <Box className="in-comp-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    HOME
                  </Link>

                  <Link
                    underline="hover"
                    color="inherit"
                    to=""
                    className="cursor-default"
                  >
                    Tipping Competition
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    to="/tipscompetition/my-comps"
                  >
                    My Comp
                  </Link>

                  <Typography>
                    {eventByIdData && eventByIdData?.competitionName}
                  </Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Box className="comp-details-wrap">
              <Box className="title-about-wrap">
                <Typography variant="h1">
                  {eventByIdData && eventByIdData?.competitionName}
                </Typography>
                {screenWidth < 800 && (
                  <Box className="comp-share-btn-wrap">
                    <ClickAwayListener onClickAway={() => handleOutsideClick()}>
                      <Box className="share-tooltip-icon">
                        {handleOpenTooltip()}
                      </Box>
                    </ClickAwayListener>
                    {/* <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="share-btn"
                      endIcon={<Share />}
                    >
                      Share
                    </Button> */}
                  </Box>
                )}
                {eventByIdData?.about && (
                  <Box className="about-wrap">
                    <span className="about-label">About This Comp:</span>
                    <span className="about-des">
                      {/* {eventByIdData && eventByIdData?.about} */}
                      {aboutLines?.map((line, index) => (
                        <p key={index} className="about-line">
                          {line} <br />
                        </p>
                      ))}
                    </span>
                  </Box>
                )}
              </Box>
              {screenWidth < 800 && (
                <Box className="comp-img-wrap">
                  <img
                    src={
                      eventByIdData?.Media?.filePath
                        ? Config.countryMediaURL +
                          eventByIdData?.Media?.filePath
                        : DefaultImg
                    }
                    alt="comp-image"
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box className="tab-search-section">
            <Box className="team-sports-tab">
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons="off"
                indicatorColor="primary"
                textColor="primary"
                className="teamsport-tab-detail"
              >
                {newTabData?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={item?.name}
                        value={item?.id}
                        className={item?.id === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>

            {(tabvalue === 0 || tabvalue === 1) && (
              <Box className="stepper-score-sec">
                <Box className="stepper-input">
                  <Button
                    className="stepper-input__button"
                    onClick={e => {
                      const index = rounds?.indexOf(stepperCount);
                      e.preventDefault();
                      setStepperCount(rounds[index - 1]);
                      getEventByID(rounds[index - 1]);
                    }}
                    disabled={!stepperCount || stepperCount == rounds[0]}
                    startIcon={<LeftArrow />}
                  >
                    Previous
                  </Button>

                  <div className="stepper-input__content">
                    {stepperCount ? `Round ${Number(stepperCount)}` : `Round 0`}
                  </div>

                  <Button
                    className="stepper-input__button Next-btn"
                    onClick={e => {
                      const index = rounds?.indexOf(stepperCount);
                      e.preventDefault();
                      setStepperCount(rounds[index + 1]);
                      getEventByID(rounds[index + 1]);
                    }}
                    endIcon={<RightArrow />}
                    disabled={
                      stepperCount == null ||
                      stepperCount == rounds[rounds?.length - 1]
                    }
                  >
                    Next
                  </Button>
                </Box>

                <Box className="score-share-sec">
                  <Box className="score-sec">
                    {/* <Box className="round-score-sec">
                    <Box className="round-score">
                      <Typography className="score">Last round:</Typography>
                      <Typography className="score">0/6</Typography>
                    </Box>
                    <Box className="round-score">
                      <Typography className="score">Total score:</Typography>
                      <Typography className="score">0/0</Typography>
                    </Box>
                  </Box> */}
                    <Box className="bottom-line-sec">
                      <Typography className="final-txt">
                        Round {Number(stepperCount)}
                        {eventByIdData && eventByIdData?.tipDeadline == "game"
                          ? " Game Based"
                          : ` tipping closes at:`}
                        {eventByIdData &&
                          eventByIdData?.tipDeadline == "round" && (
                            <span> {fetchDayandTime()}</span>
                          )}
                      </Typography>
                      {/* {tabvalue === 0 && eventByIdData?.jokerRound == 1 && (
                        <Box className="joker-switch-box">
                          <FormControlLabel
                            value="start"
                            control={
                              <Switch
                                color="primary"
                                checked={isJoker}
                                onChange={e => setIsJoker(e.target.checked)}
                              />
                            }
                            disabled={jokerRound}
                            label="Joker"
                            labelPlacement="start"
                          />
                        </Box>
                      )} */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {screenWidth > 799 && (
              <Box className="comp-img-share-wrap">
                <Box className="comp-img-wrap">
                  <img
                    src={
                      eventByIdData?.Media?.filePath
                        ? Config.countryMediaURL +
                          eventByIdData?.Media?.filePath
                        : DefaultImg
                    }
                    alt="comp-image"
                  />
                </Box>
                <Box className="comp-share-btn-wrap">
                  <ClickAwayListener onClickAway={() => handleOutsideClick()}>
                    <Box className="share-tooltip-icon">
                      {handleOpenTooltip()}
                    </Box>
                  </ClickAwayListener>
                  {/* <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="share-btn"
                    endIcon={<Share />}
                  >
                    Share
                  </Button> */}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            className={`page-deatils-wrap ${
              tabvalue === 0 || tabvalue === 2 ? "padding-container" : ""
            }`}
          >
            {tabvalue === 0 ? (
              <>
                <TipsTab
                  allEventData={allEventData}
                  isLoading={isLoading}
                  eventLength={eventLength}
                  params={params}
                  stepperCount={stepperCount}
                  isJoker={isJoker}
                  jokerRound={jokerRound}
                  completedRound={completedRound}
                  eventByIdData={eventByIdData}
                  cutoffDate={cutoffDate}
                  getEventByID={getEventByID}
                  redux_userData={redux_userData}
                  tabvalue={tabvalue}
                  lastEventDate={lastEventDate}
                  setIsJoker={e => setIsJoker(e?.target?.checked)}
                />
              </>
            ) : tabvalue === 1 ? (
              <>
                <LadderTab
                  stepperCount={stepperCount}
                  tabvalue={tabvalue}
                  redux_userData={redux_userData}
                  eventByIdData={eventByIdData}
                  compsId={params?.compsId}
                />
              </>
            ) : (
              <>
                <CompDetailsTab
                  eventByIdData={eventByIdData}
                  redux_userData={redux_userData}
                  roundsData={rounds}
                  newRoundOptions={newRoundOptions}
                  noRoundData={noRoundData}
                  getEventByID={getEventByID}
                  stepperCount={stepperCount}
                />
              </>
            )}
          </Box>
          <OurPartner />
        </Box>
      </Box>
    </>
  );
};

export default IndividualCompetitionPage;
