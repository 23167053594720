import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import AdvertisingBannner from "../advertisingBanner";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.png";
import "./profile.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment";
import Loader from "../../../components/Loader";
import AdBannner from "../AdBanner/AdBanner";
import { IntlContext } from "src/App";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import { ToastContainer, toast } from "react-toastify";
import DialogBox from "../UI/dialogBox";
import { fetchBetSlipToggle } from "src/helpers/store/Actions/BetSlipToggle";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { fetchUpcomingRaces } from "src/helpers/store/Actions/sport";
import { Config } from "src/helpers/context";

function ProfilePage() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const reduxSubscriptionData = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [isLoading, setLoading] = useState(false);
  const [userData, setuserData] = useState({});
  const [open, setOpen] = useState(false);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const handleClickUser = (type, data) => {
    // navigate(`/profile-edit`);
    navigate("/profile-edit", { state: { Data: data, name: type } });
  };

  useEffect(() => {
    handleUserProfile();
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleUserProfile = async () => {
    setLoading(true);
    // let token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InVtZXNoLmpuZXh0QGdtYWlsLmNvbSIsImlkIjoxMjgsInJvbGUiOiJtZW1iZXIiLCJpYXQiOjE2NjczOTAzNDAsImV4cCI6MTY2ODExMDM0MH0.mLJKm5-UU8OS0X_cPp5wxjRioKhx_qYSrxqrgL44qrc";
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`
    //   }
    // };
    try {
      const { status, data } = await axiosInstance.get("user/profile");
      if (status === 200) {
        setuserData(data);
        setLoading(false);
      } else if (status === 403) {
        setLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else if (status === 404) {
        setLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/");
        dispatch(loginUser(""));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // localStorage.removeItem("auth_token");
      // navigate("/");
      // dispatch(loginUser(""));
    }
  };
  const handleLogout = () => {
    let Type = localStorage.getItem("type");
    let Url = localStorage.getItem("url");
    localStorage.removeItem("auth_token");
    dispatch(loginUser(""));
    dispatch(fetchBetSlipToggle(false));
    dispatch(fetchBetSlipData([]));
    dispatch(fetchBetSlipCount(0));
    dispatch(fetchSubscriptionData(undefined));
    dispatch(fetchUpcomingRaces(undefined));
    localStorage.removeItem("loginPopupShown");
    const intervalId = localStorage.getItem("popup_intervalId");
    clearTimeout(intervalId);
    localStorage.removeItem("popup_intervalId");
    if (Type && Url) {
      window.location.href = Url;
      localStorage.removeItem("type");
      localStorage.removeItem("url");
      localStorage.setItem("user_status", "logout");
    } else {
      navigate("/");
    }
  };
  const handleuserDelete = async id => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.delete(`/user/${id}`);
      if (status === 200) {
        let Type = localStorage.getItem("type");
        let Url = localStorage.getItem("url");
        setLoading(false);
        localStorage.removeItem("auth_token");
        dispatch(loginUser(""));
        toast.success(localesData?.register?.USER_DELETED, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        // let timer = setTimeout(() => {
        if (Type && Url) {
          window.location.href = Url;
          localStorage.removeItem("auth_token");
          dispatch(loginUser(""));
          localStorage.removeItem("type");
          localStorage.removeItem("url");
          localStorage.setItem("user_status", "logout");
        } else {
          localStorage.removeItem("auth_token");
          dispatch(loginUser(""));
          navigate("/");
        }
        // }, 4000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  let BookMaker = userData?.data?.bookMaker?.filter(
    item => item !== "Other - Please specify"
  );

  let newbookMakerOther = userData?.data?.bookMakerOther?.join("");
  let newBookMakerdata =
    newbookMakerOther?.length > 0
      ? BookMaker?.push(newbookMakerOther)
      : BookMaker;

  let sport = userData?.data?.sportOrEvent?.filter(
    item => item !== "Other - Please specify"
  );
  let newsportOrEventOther = userData?.data?.sportOrEventOther?.join("");
  let newsportData =
    newsportOrEventOther?.length > 0
      ? sport?.push(newsportOrEventOther)
      : sport;

  let offering = userData?.data?.offerings?.filter(
    item => item !== "Other - Please specify"
  );
  let offeringsOther = userData?.data?.offeringsOther?.join("");
  let newofferingData =
    offeringsOther?.length > 0 ? offering?.push(offeringsOther) : offering;

  const fetchNotificationData = data => {
    const SelectedData =
      data?.NotificationPreferences?.length > 0
        ? data?.NotificationPreferences?.filter(
            ele => ele?.tips || ele?.news || ele?.fixtures
          )
        : [];

    const finalData = SelectedData?.filter(
      e => e?.SportId !== 2 && e?.SportId !== 3
    )?.sort((a, b) => a?.Sport?.sportName.localeCompare(b?.Sport?.sportName));

    if (finalData?.length > 0) {
      const notification = finalData.map((item, index) => {
        let newData = [];
        const offeringTips = item?.tips && newData.push("Tips");
        const offeringNews = item?.news && newData.push("News");
        const offeringFixture =
          item?.fixtures && newData.push("Fixtures & Results");

        return (
          <tr key={index}>
            {index === 0 ? (
              <th className="user-details">
                {localesData?.EDITPROFILE?.RECEIVE_NOTIFICATION} <br />
                <span
                  className="profile-edit"
                  onClick={() => handleClickUser("notifications", data)}
                >
                  {localesData?.EDITPROFILE?.EDIT}
                </span>
              </th>
            ) : (
              <th></th>
            )}
            <td>
              <span>
                {item?.SportId === 1 ? "Racing" : item?.Sport?.sportName}:{" "}
              </span>
              {newData?.toString()}
            </td>
          </tr>
        );
      });

      return notification;
    } else {
      return (
        <tr>
          <th className="user-details">
            {localesData?.EDITPROFILE?.RECEIVE_NOTIFICATION} <br />
            <span
              className="profile-edit"
              onClick={() => handleClickUser("notifications", data)}
            >
              {localesData?.EDITPROFILE?.EDIT}
            </span>
          </th>
          <td></td>
        </tr>
      );
    }
  };

  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchHearAboutData = data => {
    let hearedAboutData;
    if (data?.hearedAbout) {
      if (Array.isArray(data?.hearedAbout)) hearedAboutData = data?.hearedAbout;
      else hearedAboutData = JSON.parse(data?.hearedAbout);
    }
    const updatedHearAbout =
      hearedAboutData &&
      hearedAboutData.map(item => {
        if (item?.includes("other")) {
          if (item?.split(":")[1]) {
            return item?.split(":")[1];
          } else {
            return "Other";
          }
        }
        return item;
      });
    return updatedHearAbout?.toString();
  };
  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        {fetchPageHeaderAds(
          "218px",
          Config?.release == "IN" ? bannerIndia : banner
        )}
        <Box className="profile-contanier">
          <Box className="profile-details">
            <h3>{localesData?.EDITPROFILE?.PROFILE}</h3>
            {isLoading ? (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            ) : (
              <Box className="details-profile">
                {(reduxSubscriptionData?.address === null ||
                  reduxSubscriptionData?.address?.country === null) && (
                  <Typography className="error-message">
                    You must Add Country to continue!
                  </Typography>
                )}
                {(reduxSubscriptionData?.address === null ||
                  reduxSubscriptionData?.address?.state === null) && (
                  <Typography className="error-message">
                    You must Add State to continue!
                  </Typography>
                )}
                {(reduxSubscriptionData?.dob === "" ||
                  reduxSubscriptionData?.dob === null) && (
                  <Typography className="error-message">
                    You must Add DOB to continue!
                  </Typography>
                )}

                <table>
                  <tbody>
                    <tr>
                      <th className="user-details">
                        {localesData?.EDITPROFILE?.USER_DETAILS}
                        <br />
                        <span
                          className="profile-edit"
                          onClick={() =>
                            handleClickUser("useDetails", userData?.data)
                          }
                        >
                          {localesData?.EDITPROFILE?.EDIT}
                        </span>
                      </th>
                      <td>
                        {localesData?.register?.title}:{" "}
                        <span>{userData?.data?.titlePrefix}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.first_name}:{" "}
                        <span>{userData?.data?.firstName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.last_name}:{" "}
                        <span>{userData?.data?.lastName}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.dob}:{" "}
                        <span>
                          {userData?.data?.dob
                            ? moment(userData?.data?.dob).format("DD/MM/YYYY")
                            : ""}
                          {/* {moment(userData?.data?.dob).format("DD/MM/YYYY")
                            ? moment(userData?.data?.dob).format("DD/MM/YYYY")
                            : "22/05/2022"} */}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.country_of_residence}:
                        <span>
                          {" "}
                          {userData?.data?.address?.Country?.country}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.state}:
                        <span> {userData?.data?.address?.State?.state}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.phonenumber}:{" "}
                        <span>{userData?.data?.phone}</span>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.email}:{" "}
                        <span>{userData?.data?.username}</span>
                      </td>
                    </tr>
                    {/* <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.WP_USER}:{" "}
                        <span>{userData?.data?.wpUserName}</span>
                      </td>
                    </tr> */}
                    <tr>
                      <th></th>
                      <td>
                        {localesData?.register?.REFERENCE}:{" "}
                        <span>
                          {userData?.data?.hearAbout}
                          {fetchHearAboutData(userData?.data)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {localesData?.register?.password} <br />
                        <span
                          className="profile-edit"
                          onClick={() =>
                            handleClickUser("password", userData?.data)
                          }
                        >
                          {localesData?.EDITPROFILE?.EDIT}
                        </span>
                      </th>
                      <td>***********</td>
                    </tr>
                    {Config.release == "AU" ? (
                      <>
                        <tr>
                          <th>
                            {localesData?.EDITPROFILE?.BOOKMAKER_ACCOUNTS}{" "}
                            <br />
                            <span
                              className="profile-edit"
                              onClick={() =>
                                handleClickUser("bookmakers", userData?.data)
                              }
                            >
                              {localesData?.EDITPROFILE?.EDIT}
                            </span>
                          </th>
                          <td>{BookMaker?.toString()}</td>
                        </tr>
                        <tr>
                          <th>
                            {localesData?.EDITPROFILE?.SPORTS} <br />
                            <span
                              className="profile-edit"
                              onClick={() =>
                                handleClickUser("sports", userData?.data)
                              }
                            >
                              {localesData?.EDITPROFILE?.EDIT}
                            </span>
                          </th>
                          <td>{sport?.toString()}</td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                    {fetchNotificationData(userData?.data)}

                    {/* {userData?.data?.isPremiumUser ? (
                      <>
                        <tr>
                          <th>
                            {" "}
                            {
                              localesData?.EDITPROFILE?.SUBSCRIPTION_DETAILS
                            }{" "}
                            <br />
                            {userData?.data?.subscription?.status ===
                            "deactive" ? (
                              <span
                                className="profile-edit"
                                onClick={() =>
                                  navigate("/subscription-payment")
                                }
                              >
                                {localesData?.EDITPROFILE?.SUBSCRIBE}
                              </span>
                            ) : (
                              <span
                                className="profile-edit"
                                onClick={() =>
                                  handleClickUser(
                                    "subscription",
                                    userData?.data
                                  )
                                }
                              >
                                {localesData?.EDITPROFILE?.VIEW}
                              </span>
                            )}
                          </th>
                          <td>
                            {userData?.data?.subscription?.status ===
                            "deactive" ? (
                              <span>
                                Expiring soon
                                <span className="plan-amount">
                                  {moment(
                                    userData?.data?.subscription?.expireAt
                                  ).format("DD/MM/YYYY")}
                                </span>
                              </span>
                            ) : (
                              <span>
                                {userData?.data?.subscription
                                  ? userData?.data?.subscription
                                      ?.SubscriptionPlan?.name
                                  : ""}
                                <span className="plan-amount">
                                  (
                                  {"$" +
                                    userData?.data?.subscription
                                      ?.SubscriptionPlan?.amount +
                                    "/month"}
                                  )
                                </span>
                              </span>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )} */}
                    <tr>
                      <th>
                        {" "}
                        {localesData?.EDITPROFILE?.DELETE_ACCOUNT} <br />
                        <span
                          className="profile-edit"
                          onClick={() => handleModal()}
                        >
                          {localesData?.EDITPROFILE?.DELETE}
                        </span>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <Box className="profile-logout-delete">
                  {Config.release == "AU" && (
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="blackbook"
                      onClick={() => navigate("/blackbook")}
                    >
                      BlackBook
                    </Button>
                  )}

                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="blackbook"
                    onClick={() => navigate("/smartbook")}
                  >
                    Smartbook
                  </Button>
                </Box>
                <Box className="profile-logout-delete">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="logout"
                    onClick={() => handleLogout()}
                  >
                    {localesData?.auth_btn?.logout}
                  </Button>
                  {/* <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="logout delete"
                    // onClick={() => {
                    //   handleuserDelete(userData?.data?.id);
                    // }}
                    onClick={() => handleModal()}
                  >
                    Delete
                  </Button> */}

                  {/* {userData?.data?.isPremiumUser === false && (
                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="logout delete"
                      onClick={() => {
                        navigate("/subscription-payment");
                      }}
                    >
                      {localesData?.EDITPROFILE?.SUBSCRIBE}
                    </Button>
                  )} */}
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <DialogBox
          open={open}
          onClose={handleClose}
          handleClose={handleClose}
          title={false}
          content={
            <>
              <Box className="delete-dialogbox">
                <Typography className="delete-title">
                  Are you sure you want to delete your account?
                </Typography>
                <Box className="delete-btn">
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="btn-cancel"
                    onClick={() => {
                      handleuserDelete(userData?.data?.id);
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    className="btn-save"
                    onClick={() => handleClose()}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </>
          }
          closeIcon={false}
        />
      </Box>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default ProfilePage;
