import React, { useEffect, useState } from "react";
import { Box, Tooltip } from "@material-ui/core";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { Config } from "../../../helpers/context/config";

const GetRuunerodds = ({
  itemApiRaceIdentifier,
  obj,
  BookkeeperData,
  raceTrackdata,
  tipsInfo
}) => {
  const [RaceRunnerOdds, setRaceRunnerOdds] = useState([]);
  const [runnerOddsLoading, setRunnerOddsLoading] = useState(false);

  useEffect(() => {
    tipsRaceRunnerOddsData(obj?.id);
  }, [obj?.id]);
  const tipsRaceRunnerOddsData = async id => {
    setRunnerOddsLoading(true);
    const payload = [id];
    try {
      const { status, data } = await axiosInstance.post(
        `expertTips/odds`,
        payload
      );
      if (status === 200) {
        setRunnerOddsLoading(false);
        setRaceRunnerOdds(data?.odds);
      }
    } catch (err) {
      setRunnerOddsLoading(false);
    }
  };

  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(tipsInfo?.SportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item) => {
    const newData = itemApiRaceIdentifier?.ApiRaceIdentifier?.[0]?.map(obj => {
      const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter(item => {
        return item?.BookKeeperId === BookKeeperId;
      });
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null
      };
    });
    const filteredData = newData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    if (item && BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="bookmaker"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      ) : (
        <></>
      );
    } else {
      return (
        <a
          href={
            filteredData?.length > 0 && filteredData?.[0]?.url
              ? filteredData?.[0]?.url + `?Referrer=SmartB`
              : iconData?.affiliate_link
          }
          // href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            alt="bookmaker"
            onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
          />
        </a>
      );
    }
  };

  const fetchCurrentBestValue = (data, fulldata) => {
    if (data && data?.length > 0) {
      let maxno = data?.reduce((max, obj) => {
        obj?.RaceOdds?.[0]?.intValue > max
          ? (max = obj?.RaceOdds?.[0]?.intValue)
          : (max = max);
        return max;
      }, -1);
      if (maxno !== -1) {
        if (maxno !== 0) {
          let providerid = data
            ?.map(obj => {
              if (obj?.RaceOdds?.[0]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            .filter(x => x !== undefined);
          return fetchClickableOdds(
            maxno,
            providerid?.[0],
            "header",
            data,
            fulldata,
            "betslip"
          );
        } else {
          return "SP";
        }
      } else {
        return "SP";
      }
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchCurrentBestIcon = data => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip
  ) => {
    const BookKeeperData = item?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    // let isAdded = BetslipData?.some((el) => {
    //   if (
    //     el?.BookKeeperId == BookKeeperId &&
    //     el?.betOfferId == BookKeeperData?.[0]?.RaceOdds?.[0]?.MarketRelationId
    //     //  &&
    //     // el?.eventId == Number(params?.raceId)
    //   )
    //     return true;
    //   else return false;
    // });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8)

    //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0]
    //   return (
    //     Bet365Data && Bet365Data?.length > 0 ?
    //       (<a href={Odds?.providerMarketId && Odds?.providerParticipantId ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId
    //         }~${Odds?.intValue}~1&bet=1` : `${iconData?.affiliate_link}`} target="_blank" rel="noopener noreferrer" className="odds-link" >  {odds === "SP" ? "SP" : Number(odds).toFixed(2)} </a>
    //       ) : (<></>)
    //   )
    // } else {

    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      <span
        // className={isAdded && IsBetslip === "betslip" ? "betslip-added odds-link" : "odds-link"}
        // onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : isAdded ? handleDeleteBetslip(BookKeeperId, item) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
        onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
        className="odds-link"
      >
        {/* {IsBetslip === "betslip" ? (
          // && !isResult
          <Tooltip title="Bet" className="bet-tooltip" placement="top">
            <span className="bet-now">
              {" "}
              {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
            </span>
          </Tooltip>
        ) : (
          <> */}
        {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
        {/* </>
        )} */}
      </span>
      // </a>
    );
    // }
  };

  return (
    <>
      {runnerOddsLoading ? (
        <Loader />
      ) : (
        <Box className="odds-value-wrap race-odds-value-wrap">
          <Box>
            <span className="odds-value">
              {fetchCurrentBestValue(RaceRunnerOdds, [])}
            </span>
          </Box>
          {fetchCurrentBestIcon(RaceRunnerOdds)}
        </Box>
      )}
    </>
  );
};

export default GetRuunerodds;
