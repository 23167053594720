import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RaceHorses from "../../../assets/images/Sport/race_hourses.svg";
import RaceGreyhounds from "../../../assets/images/Sport/races_greyhounds.svg";
import RaceHarness from "../../../assets/images/Sport/race_harness.svg";
import { IntlContext } from "src/App";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { number } from "prop-types";

const racingData = [
  {
    id: 1,
    sportName: "Horse Racing",
    eventName: "Canterbury",
    countryName: "AUS",
    runnerNumber: "R6",
    jumpTime: "10:55am",
    distance: "1710m",
    raceDetails: [
      {
        id: 1,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      },
      {
        id: 2,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      }
    ]
  },
  {
    id: 2,
    sportName: "Harness Racing",
    eventName: "Canterbury",
    countryName: "AUS",
    runnerNumber: "R6",
    jumpTime: "10:55am",
    distance: "1710m",
    raceDetails: [
      {
        id: 1,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      },
      {
        id: 2,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      },
      {
        id: 3,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      },
      {
        id: 4,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      }
    ]
  },
  {
    id: 3,
    sportName: "Greyhound Racing",
    eventName: "Canterbury",
    countryName: "AUS",
    runnerNumber: "R6",
    jumpTime: "10:55am",
    distance: "1710m",
    raceDetails: [
      {
        id: 1,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      },
      {
        id: 2,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      },
      {
        id: 3,
        jockeyNumber: "1",
        raceName: "Incredible Pinto",
        runnerNumber: "1",
        wName: "00kg",
        jName: "[Jockey]",
        FName: "[Form]",
        TName: "[Trainer]",
        odds: "1.73"
      }
    ]
  }
];

const FeaturedRace = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [featureRaceData, setFeatureRaceData] = useState([]);
  const [featureRaceLoader, setFeatureRaceLoader] = useState(false);
  const [dragging, setDragging] = useState(false);
  const settingsRace = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    dots: false,
    // adaptiveHeight: true,
    beforeChange: (current, next) => {
      handleBeforeChange();
    },
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 610,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    fetchFeaturedRace();
  }, []);

  const fetchFeaturedRace = async () => {
    setFeatureRaceLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/racingFeatured?RaceOddKeyId=1`
      );
      if (status === 200) {
        setFeatureRaceLoader(false);
        setFeatureRaceData(data?.result);
        const raceinfo = data?.result?.map(race => {
          let newdata = race?.Races?.[0]?.runner?.map(ele => {
            const maxno = ele?.MarketRelations?.reduce((max, obj) => {
              obj?.RaceOdds?.[0]?.intValue > max
                ? (max = obj?.RaceOdds?.[0]?.intValue)
                : (max = max);
              return max;
            }, 0);

            return {
              ...ele,
              currentBest: maxno === 0 ? "SP" : Number(maxno)
            };
            // let bestodds = fetchCurrentBestValue(item);
          });
          let filteredData = newdata?.filter(
            item =>
              item.isScratched != "1" &&
              item.isScratched != 1 &&
              item.isScratched != "true" &&
              item.isScratched != null &&
              item.isScratched != undefined
          );

          const numbers = filteredData?.filter(
            item => typeof item?.currentBest == "number"
          );
          const allSP = filteredData?.every(item => item?.currentBest == "SP");
          if (allSP) {
            return {
              ...race,
              runner: filteredData?.slice(0, 3)
            };
          }
          if (numbers.length > 0) {
            numbers.sort(
              (a, b) => Number(a?.currentBest) - Number(b?.currentBest)
            );
            return {
              ...race,
              runner: numbers.slice(0, 3)
            };
          }
          // let sortedData = newdata?.sort(
          //   (a, b) => a?.currentBest - b?.currentBest
          // );
        });
        setFeatureRaceData(raceinfo);
      }
    } catch (error) {
      setFeatureRaceLoader(false);
    }
  };
  const fetchCurrentBestValue = data => {
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        return maxno;
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };

  const handleNavigate = useCallback(
    data => {
      if (!dragging) {
        const race =
          data?.sportId === 1
            ? "horse"
            : data?.sportId === 2
            ? "harness"
            : "greyhounds";
        const intl =
          data?.track?.Country?.id == 13 || data?.track?.Country?.id == 157
            ? false
            : true;
        navigate(
          `/racing/${race}/${data?.sportId}/${data?.Races?.[0]?.id}/${
            data?.trackId
          }/${data?.id}?date=${moment(data?.Races?.[0]?.startTimeDate)
            .tz(timezone)
            .format("YYYY-MM-DD")}&intl=${intl}`
        );
      }
    },
    [dragging]
  );

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);
  const currentDate = moment().format("YYYY-MM-DD");
  return (
    <>
      {featureRaceLoader ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : featureRaceData?.length > 0 ? (
        <Box className="racing-slider-wrap">
          <Box>
            <Typography variant="h3" className="home-slider-header">
              {localesData?.HOME?.FEATURED_RACES}
            </Typography>

            <Slider
              {...settingsRace}
              afterChange={handleAfterChange}
              className="racing-slick-slider"
            >
              {featureRaceData?.map(race => {
                return (
                  <>
                    <Box
                      className="racing-slider-details racing-slider cursor-pointer"
                      onClick={() => {
                        handleNavigate(race);
                      }}
                    >
                      <Box className="slider-header-wrap d-flex text-align content-space-betwen">
                        <Box className="event-name-deatils d-flex text-align">
                          <Box className="d-flex text-align">
                            <span className="race-img">
                              <img
                                src={
                                  race?.sportId === 1
                                    ? RaceHorses
                                    : race?.sportId === 2
                                    ? RaceHarness
                                    : RaceGreyhounds
                                }
                                alt="horserace"
                              />
                            </span>
                            <span className="event-name">
                              {race?.eventName}
                            </span>
                            <span className="event-name country-name">
                              {race?.track?.Country
                                ? "(" + race?.track?.Country?.countryCode + ")"
                                : ""}
                            </span>
                          </Box>

                          {race?.Races?.[0]?.raceNumber ? (
                            <span className="runner-name">
                              {" "}
                              {"R" + race?.Races?.[0]?.raceNumber}{" "}
                            </span>
                          ) : (
                            <></>
                          )}
                        </Box>
                        <Box className="event-time-distance">
                          <span className="time-distance">
                            Jump time:
                            {moment(currentDate).isSame(
                              moment(race?.Races?.[0]?.startTimeDate).format(
                                "YYYY-MM-DD"
                              )
                            )
                              ? moment
                                  .utc(race?.Races?.[0]?.startTimeDate)
                                  .local()
                                  .format("hh:mm a")
                              : moment
                                  .utc(race?.Races?.[0]?.startTimeDate)
                                  .local()
                                  .format("YYYY/MM/DD hh:mm a")}
                          </span>{" "}
                          |{" "}
                          <span className="time-distance">
                            Distance:
                            {race?.Races?.[0]?.Distance
                              ? race?.Races?.[0]?.Distance?.name + "m"
                              : ""}
                          </span>
                        </Box>
                      </Box>
                      {race?.runner?.map(item => {
                        return (
                          <>
                            {(item?.isScratched === "false" ||
                              item?.isScratched === null ||
                              item?.isScratched === "0") && (
                              <Box className="slider-event-race-deatils d-flex text-align content-space-betwen">
                                <Box className="details-race">
                                  <Typography className="race-name">
                                    {item?.runnerNumber}
                                    {"."} {item?.animal?.name} (
                                    {item?.barrierNumber})
                                  </Typography>
                                  <Box className="d-flex text-align deatils-1">
                                    <Typography className="race-deatils">
                                      W:{" "}
                                      {Number(item?.JockeyWeight).toFixed(2) +
                                        "Kg"}
                                    </Typography>
                                    <Typography className="race-deatils">
                                      {item?.Jockey
                                        ? race?.sportId === "2"
                                          ? `D: `
                                          : `J: ` + item?.Jockey?.name
                                        : item?.Trainer
                                        ? `T: ` + item?.Trainer?.name
                                        : ""}
                                    </Typography>
                                  </Box>
                                  <Box className="d-flex text-align deatils-1">
                                    {race?.sportId === 1 ||
                                    race?.sportId === 2 ? (
                                      <Typography className="race-deatils">
                                        T: {item?.Trainer?.name}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                                <Box className="best-value">
                                  <span className="best">Best</span>
                                  <span className="best-odds">
                                    {fetchCurrentBestValue(
                                      item?.MarketRelations
                                    )}
                                  </span>
                                </Box>
                              </Box>
                            )}
                          </>
                        );
                      })}
                    </Box>
                  </>
                );
              })}
            </Slider>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default FeaturedRace;
