import React, { useState, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { IntlContext } from "src/App";
import AdvertisingBannner from "src/views/component/advertisingBanner";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import "./futuresTable.scss";
import { Link } from "react-router-dom";
import Select from "react-select";
import OurPartner from "src/views/component/ourPartners";
import { ReactComponent as Order } from "../../../../../assets/images/icons/order.svg";
import Bet365 from "../../../../../assets/images/bookmakers/bet365-thumb.svg";
import UniBet from "../../../../../assets/images/bookmakers/unibet-thumb.svg";
import BookMaker from "../../../../../assets/images/bookmakers/bookmakers-thumb.svg";
import Neds from "../../../../../assets/images/bookmakers/neds-thumb.svg";
import PlayUp from "../../../../../assets/images/bookmakers/playup-thumb.svg";
import BetStar from "../../../../../assets/images/bookmakers/betstar-thumb.svg";
// import ViceBet from "../../../../../assets/images/bookmakers/vice-bet-thumb.svg";
// import BlueBet from "../../../../../assets/images/bookmakers/BlueBet-thumb.png";
// import TopSport from "../../../../../assets/images/bookmakers/top-sport-thumb.svg";
// import betFair from "../../../../../assets/images/bookmakers/betfair-thumb.svg";
// import BoomBet from "../../../../../assets/images/bookmakers/BoomBet.png";
// import LadBrokes from "../../../../../assets/images/bookmakers/Ladbrokes-thumb.png";
// import Draftstars from "../../../../../assets/images/bookmakers/DraftStars.png";
// import SouthernCrossBet from "../../../../../assets/images/bookmakers/SouthernCrossBet.png";
// import { ReactComponent as NextSlide } from "../../../../../assets/images/icons/slide-next.svg";
// import { ReactComponent as PrvSlide } from "../../../../../assets/images/icons/slide-prv.svg";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const options = [
  { value: "1", label: "Champions Mile 2022" },
  { value: "2", label: "Champions Sprint 2022" },
  { value: "3", label: "COOlmore stud Stakes 2022" },
  { value: "4", label: "Empire rose stakes 2022" },
  { value: "5", label: "golden eagle 2022" },
];
const optionData = [
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" },
];
const dummyData = [
  {
    id: 1,
    runner: "Anamoe",
    odd1: "1.06",
    odd2: "1.06",
    odd3: "1.06",
    odd4: "1.05",
    odd5: "10.5",
    odd6: "1.06",
    odd7: "1.06",
    odd8: "1.06",
    odd9: "1.05",
    odd10: "10.5",
  },
  {
    id: 2,
    runner: "Zaaki",
    odd1: "1.06",
    odd2: "1.06",
    odd3: "1.06",
    odd4: "1.05",
    odd5: "10.5",
    odd6: "1.06",
    odd7: "1.06",
    odd8: "1.06",
    odd9: "1.05",
    odd10: "10.5",
  },
  {
    id: 3,
    runner: "I'm Thuderstruck",
    odd1: "1.06",
    odd2: "1.06",
    odd3: "1.06",
    odd4: "1.05",
    odd5: "10.5",
    odd6: "1.06",
    odd7: "1.06",
    odd8: "1.06",
    odd9: "1.05",
    odd10: "10.5",
  },
  {
    id: 4,
    runner: "Private Eye",
    odd1: "1.06",
    odd2: "1.06",
    odd3: "1.06",
    odd4: "1.05",
    odd5: "10.5",
    odd6: "1.06",
    odd7: "1.06",
    odd8: "1.06",
    odd9: "1.05",
    odd10: "10.5",
  },
  {
    id: 5,
    runner: "Mr Brightside",
    odd1: "1.06",
    odd2: "1.06",
    odd3: "1.06",
    odd4: "1.05",
    odd5: "10.5",
    odd6: "1.06",
    odd7: "1.06",
    odd8: "1.06",
    odd9: "1.05",
    odd10: "10.5",
  },
];
const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    minWidth: "457px",
    "@media(max-width: 799px)": {
      minWidth: "200px",
    },
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "1px 0px 6px -2px rgb(0 0 0 / 16%)",
    display: "block",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));
function FuturesTablePage() {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [eventName, seteventName] = useState(1);
  const [selectedOption, setselectedOption] = useState(1);
  const [scrollX, setscrollX] = useState("left");

  const SlideTable = (direction) => {
    setscrollX(direction);
    var container = document.getElementById("futures-table");
    var scrollCompleted = 0;
    var slideVar = setInterval(function() {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 600) {
        window.clearInterval(slideVar);
      }
    }, 15);
  };

  return (
    <>
      <AdvertisingBannner src={banner} />
      <Box className="allracing-wrap ">
        <Box className="side-space">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                {localesData?.MENU?.HOME}
              </Link>
              <Link underline="hover" color="inherit" to="/racing">
                {localesData?.MENU?.RACING}
              </Link>
              <Link to="/">Horse Racing</Link>

              <Typography>{localesData?.MENU?.FUTURES}</Typography>
            </Breadcrumbs>
          </Box>
        </Box>
        <Box className="future-side-wrap">
          <Box className="select-wrap">
            <Select
              className="React"
              value={options?.find((item) => {
                return item?.value == eventName;
              })}
              onChange={(e) => seteventName(e?.value)}
              options={options}
              isOptionDisabled={(option) => option.disabled}
              classNamePrefix="select"
              isSearchable={false}
            />
          </Box>
          <Box className="future-event-name">
            <span className="event-name">CHAMPIONS MILE 2022</span>
            <span className="event-date">
              {" "}
              {localesData?.sport?.START_TIME}: Nov 5, 2022 11:00AM
            </span>
          </Box>
        </Box>
        <Box className="future-table">
          <Box className="table-wrap">
            <Box className="select-wrap">
              <Select
                className="React"
                value={optionData?.find((item) => {
                  return item?.value == selectedOption;
                })}
                onChange={(e) => setselectedOption(e?.value)}
                options={optionData}
                isOptionDisabled={(optionData) => optionData.disabled}
                classNamePrefix="select"
                isSearchable={false}
              />
            </Box>
            <span className="table-see-best">
              {localesData?.RUNNER_TABLE?.SEE_BEST}
            </span>
          </Box>
          <TableContainer
            className="table-futures-container"
            id="futures-table"
          >
            <Box className="overlay"></Box>
            <Box className="arrow-wrap">
              <span
                className={scrollX == "right" ? "arrow" : "disable"}
                onClick={() => SlideTable("left")}
              >
                <ChevronLeftIcon />
              </span>

              <span
                className={scrollX == "left" ? "arrow" : "disable"}
                onClick={() => SlideTable("right")}
              >
                <ChevronRightIcon />
              </span>
            </Box>
            <Table style={{ minWidth: 700 }} className="table-futures">
              <TableHead className="futures-head">
                <TableRow>
                  <StickyTableCell className={classes.head}>
                    <span>
                      {localesData?.RUNNER_TABLE?.RUNNER}
                      <Order
                        className="player-order"
                        // onClick={handleRunnerOrder}
                      />
                    </span>
                  </StickyTableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={Bet365}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={UniBet}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={BookMaker}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={Neds}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={PlayUp}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={BetStar}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={BetStar}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={BetStar}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={BetStar}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}>
                    <img
                      className="bookmaker-thumb"
                      src={BetStar}
                      alt="Odds Icon"
                    />
                  </TableCell>
                  <TableCell className={classes.head}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="futures-body">
                {dummyData?.map((type, i) => {
                  return (
                    <>
                      <TableRow key={i}>
                        <StickyTableCell
                          className={`${classes.cell} body-runner-name`}
                        >
                          {type?.runner}
                        </StickyTableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd1}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd2}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd3}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd4}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd5}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd6}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd7}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd8}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd9}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {type?.odd10}
                        </TableCell>
                        <TableCell className={classes.cell}></TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
}

export default FuturesTablePage;
