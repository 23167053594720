import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles,
  Typography,
  IconButton,
  Collapse
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useParams } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "../../../../../../components/Loader";
import { IntlContext } from "src/App";
import StatisticsTable from "../../StatisticsTable";
import AboveTable from "../../../../../../assets/images/ad-placeholder/sports1.webp";
import NoDataComp from "src/views/component/UI/NoData";
const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    width: "250px",
    "@media(max-width: 799px)": {
      maxWidth: "222px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    "@media(max-width: 799px)": {
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

function ProfileInfoPage({ fetchAds }) {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const params = useParams();
  const localesData = intlContext?.messages;
  const [collapseMenu, setCollapseMenu] = useState(-1);
  const [collapseMenuSecond, setCollapseMenuSecond] = useState(-1);
  const [Last12Monthcollapse, setLast12Monthcollapse] = useState(-1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [OverAllStats, setOverAllStats] = useState([]);
  const [IsOverAllStatsLoading, setIsOverAllStatsLoading] = useState(false);
  const [Last5YearData, setLast5YearData] = useState([]);
  const [IsLast5YearLoading, setIsLast5YearLoading] = useState(false);
  const [Last12MonthBreakdownData, setLast12MonthBreakdownData] = useState([]);
  const [monthBreakdownLoading, setmonthBreakdownLoading] = useState(false);
  const [
    TrackConditionCareercollapse,
    setTrackConditionCareercollapse
  ] = useState(-1);
  const [TrackConditionCareerData, setTrackConditionCareerData] = useState([]);
  const [
    IsTrackConditionCareerLoading,
    setTrackConditionCareerLoading
  ] = useState(false);
  const [
    TrackConditionMonthcollapse,
    setTrackConditionMonthcollapse
  ] = useState(-1);
  const [TrackConditionMonthData, setTrackConditionMonthData] = useState([]);
  const [
    IsTrackConditionMonthLoading,
    setTrackConditionMonthLoading
  ] = useState(false);
  const [TrackTypesCareercollapse, setTrackTypesCareercollapse] = useState(-1);
  const [TrackTypesCareerData, setTrackTypesCareerData] = useState([]);
  const [IsTrackTypesCareerLoading, setTrackTypesCareerLoading] = useState(
    false
  );
  const [TrackTypesMonthcollapse, setTrackTypesMonthcollapse] = useState(-1);
  const [TrackTypesMonthData, setTrackTypesMonthData] = useState([]);
  const [IsTrackTypesMonthLoading, setTrackTypesMonthLoading] = useState(false);
  const [BarrierCareercollapse, setBarrierCareercollapse] = useState(-1);
  const [BarrierCareerData, setBarrierCareerData] = useState([]);
  const [IsBarrierCareerLoading, setBarrierCareerLoading] = useState(false);
  const [BarrierMonthcollapse, setBarrierMonthcollapse] = useState(-1);
  const [BarrierMonthData, setBarrierMonthData] = useState([]);
  const [IsBarrierMonthLoading, setBarrierMonthLoading] = useState(false);
  const [PrizeCareercollapse, setPrizeCareercollapse] = useState(-1);
  const [PrizeCareerData, setPrizeCareerData] = useState([]);
  const [IsPrizeCareerLoading, setPrizeCareerLoading] = useState(false);
  const [PrizeMonthcollapse, setPrizeMonthcollapse] = useState(-1);
  const [PrizeMonthData, setPrizeMonthData] = useState([]);
  const [IsPrizeMonthLoading, setPrizeMonthLoading] = useState(false);
  const [WeightCareercollapse, setWeightCareercollapse] = useState(-1);
  const [WeightCareerData, setWeightCareerData] = useState([]);
  const [IsWeightCareerLoading, setWeightCareerLoading] = useState(false);
  const [WeightMonthcollapse, setWeightMonthcollapse] = useState(-1);
  const [WeightMonthData, setWeightMonthData] = useState([]);
  const [IsWeightMonthLoading, setWeightMonthLoading] = useState(false);
  const [DistancesCareercollapse, setDistancesCareercollapse] = useState(-1);
  const [DistancesCareerData, setDistancesCareerData] = useState([]);
  const [IsDistancesCareerLoading, setDistancesCareerLoading] = useState(false);
  const [DistancesMonthcollapse, setDistancesMonthcollapse] = useState(-1);
  const [DistancesMonthData, setDistancesMonthData] = useState([]);
  const [IsDistancesMonthLoading, setDistancesMonthLoading] = useState(false);

  const fetchOverallStat = async () => {
    setIsOverAllStatsLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const career = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }`
      );
      const Last12Month = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&duration=12`
      );
      const Last10 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&limit=10`
      );
      const Last100 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&limit=100`
      );
      const careerData = career?.data?.result;
      const Last12MonthData = Last12Month?.data?.result;
      const Last10Data = Last10?.data?.result;
      const Last100Data = Last100?.data?.result;
      careerData["name"] = "career";
      Last12MonthData["name"] = "12 Months";
      Last10Data["name"] = "Last 10";
      Last100Data["name"] = "Last 100";
      let MergeData = [
        ...[careerData],
        ...[Last12MonthData],
        ...[Last10Data],
        ...[Last100Data]
      ];
      setIsOverAllStatsLoading(false);
      setOverAllStats(MergeData);
    } catch (err) {}
  };

  const fetchYearlyBreakDown = async () => {
    const currentDate = moment();
    const last5Years = [];

    for (let i = 0; i < 5; i++) {
      const Year = currentDate.subtract(1, "years").format("YYYY");
      const lastDateOfYear = currentDate.endOf("year").format("YYYY-MM-DD");

      last5Years.push({ Year, lastDate: lastDateOfYear });
    }
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const apiPromises = last5Years?.map(async item => {
        const { Year, lastDate } = item;
        const { status, data } = await axiosInstance.get(
          `statistics/${passApi}/profile/${params?.personId}?trackId=${
            params?.trackId === "0" ? "" : params?.trackId
          }&breakDown=year&startTime=${lastDate}&duration=1`
        );
        return { Year, data };
      });

      const results = await Promise.all(apiPromises);
      setLast5YearData(results);
    } catch (error) {}
  };

  const fetchMonthlyBreakdown = async () => {
    setmonthBreakdownLoading(true);
    const currentDate = moment();
    const last12MonthWithLastDates = [];
    for (let i = 0; i < 12; i++) {
      const Month = currentDate.subtract(1, "month").format("MMM YYYY");
      const lastDateOfMonth = currentDate.endOf("month").format("YYYY-MM-DD");

      last12MonthWithLastDates.push({ Month, lastDate: lastDateOfMonth });
    }
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const apiPromises = last12MonthWithLastDates?.map(async item => {
        const { Month, lastDate } = item;
        const { status, data } = await axiosInstance.get(
          `statistics/${passApi}/profile/${params?.personId}?trackId=${
            params?.trackId === "0" ? "" : params?.trackId
          }&duration=1&breakDown=month&startTime=${lastDate}`
        );
        return { Month, data };
      });
      const Last12MonthData = await Promise.all(apiPromises);
      setLast12MonthBreakdownData(Last12MonthData);
      setmonthBreakdownLoading(false);
    } catch (error) {
      setmonthBreakdownLoading(false);
    }
  };

  const fetchTrackConditionCareer = async () => {
    setTrackConditionCareerLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Firm = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Firm`
      );
      const Good = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Good`
      );
      const Soft = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Soft`
      );
      const Heavy = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Heavy`
      );
      const Synthetic = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Synthetic`
      );
      const Unknown = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Unknown`
      );
      const Soft7 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Soft7`
      );

      const FirmData = Firm?.data?.result;
      const GoodData = Good?.data?.result;
      const SoftData = Soft?.data?.result;
      const HeavyData = Heavy?.data?.result;
      const SyntheticData = Synthetic?.data?.result;
      const UnknownData = Unknown?.data?.result;
      const Soft7Data = Soft7?.data?.result;
      let MergeData = [
        ...[FirmData],
        ...[GoodData],
        ...[SoftData],
        ...[HeavyData],
        ...[SyntheticData],
        ...[UnknownData],
        ...[Soft7Data]
      ];
      setTrackConditionCareerData(MergeData);
      setTrackConditionCareerLoading(false);
    } catch (error) {
      setTrackConditionCareerLoading(false);
    }
  };
  const fetchTrackConditionMonth = async () => {
    setTrackConditionMonthLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Firm = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Firm&duration=12`
      );
      const Good = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Good&duration=12`
      );
      const Soft = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Soft&duration=12`
      );
      const Heavy = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Heavy&duration=12`
      );
      const Synthetic = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Synthetic&duration=12`
      );
      const Unknown = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Unknown&duration=12`
      );
      const Soft7 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackCondition=Soft7&duration=12`
      );

      const FirmData = Firm?.data?.result;
      const GoodData = Good?.data?.result;
      const SoftData = Soft?.data?.result;
      const HeavyData = Heavy?.data?.result;
      const SyntheticData = Synthetic?.data?.result;
      const UnknownData = Unknown?.data?.result;
      const Soft7Data = Soft7?.data?.result;
      let MergeData = [
        ...[FirmData],
        ...[GoodData],
        ...[SoftData],
        ...[HeavyData],
        ...[SyntheticData],
        ...[UnknownData],
        ...[Soft7Data]
      ];
      setTrackConditionMonthData(MergeData);
      setTrackConditionMonthLoading(false);
    } catch (error) {
      setTrackConditionMonthLoading(false);
    }
  };

  const fetchTrackTypeCareer = async () => {
    setTrackTypesCareerLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Turf = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackType=Turf`
      );
      const Cushion = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackType=Cushion`
      );

      const TurfData = Turf?.data?.result;
      const CushionData = Cushion?.data?.result;

      TurfData["name"] = "Turf";
      CushionData["name"] = "Cushion";

      let MergeData = [...[TurfData], ...[CushionData]];
      setTrackTypesCareerData(MergeData);
      setTrackTypesCareerLoading(false);
    } catch (error) {
      setTrackTypesCareerLoading(false);
    }
  };

  const fetchTrackTypeMonth = async () => {
    setTrackTypesMonthLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Turf = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackType=Turf&duration=12`
      );
      const Cushion = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&trackType=Cushion&duration=12`
      );

      const TurfData = Turf?.data?.result;
      const CushionData = Cushion?.data?.result;

      TurfData["name"] = "Turf";
      CushionData["name"] = "Cushion";

      let MergeData = [...[TurfData], ...[CushionData]];
      setTrackTypesMonthData(MergeData);
      setTrackTypesMonthLoading(false);
    } catch (error) {
      setTrackTypesMonthLoading(false);
    }
  };

  const fetchDistancesCareer = async () => {
    setDistancesCareerLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Distances1000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=0&endDistance=1000`
      );
      const Distances1300 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=1001&endDistance=1300`
      );
      const Distances1500 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=1301&endDistance=1500`
      );
      const Distances2000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=1501&endDistance=2000`
      );
      const Distances2400 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=2001&endDistance=2400`
      );
      const DistancesPlus2401 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=2401`
      );

      const Distances1000Data = Distances1000?.data?.result;
      const Distances1300Data = Distances1300?.data?.result;
      const Distances1500Data = Distances1500?.data?.result;
      const Distances2000Data = Distances2000?.data?.result;
      const Distances2400Data = Distances2400?.data?.result;
      const DistancesPlus2401Data = DistancesPlus2401?.data?.result;

      let MergeData = [
        ...[Distances1000Data],
        ...[Distances1300Data],
        ...[Distances1500Data],
        ...[Distances2000Data],
        ...[Distances2400Data],
        ...[DistancesPlus2401Data]
      ];
      setDistancesCareerData(MergeData);
      setDistancesCareerLoading(false);
    } catch (error) {
      setDistancesCareerLoading(false);
    }
  };
  const fetchDistancesMonth = async () => {
    setDistancesMonthLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Distances1000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=0&endDistance=1000&duration=12`
      );
      const Distances1300 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=1001&endDistance=1300&duration=12`
      );
      const Distances1500 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=1301&endDistance=1500&duration=12`
      );
      const Distances2000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=1501&endDistance=2000&duration=12`
      );
      const Distances2400 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=2001&endDistance=2400&duration=12`
      );
      const DistancesPlus2401 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startDistance=2401&duration=12`
      );

      const Distances1000Data = Distances1000?.data?.result;
      const Distances1300Data = Distances1300?.data?.result;
      const Distances1500Data = Distances1500?.data?.result;
      const Distances2000Data = Distances2000?.data?.result;
      const Distances2400Data = Distances2400?.data?.result;
      const DistancesPlus2401Data = DistancesPlus2401?.data?.result;

      let MergeData = [
        ...[Distances1000Data],
        ...[Distances1300Data],
        ...[Distances1500Data],
        ...[Distances2000Data],
        ...[Distances2400Data],
        ...[DistancesPlus2401Data]
      ];
      setDistancesMonthData(MergeData);
      setDistancesMonthLoading(false);
    } catch (error) {
      setDistancesMonthLoading(false);
    }
  };

  const fetchBarrierCareer = async () => {
    setBarrierCareerLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Barrier13 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=1&endBarrier=3`
      );
      const Barrier46 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=4&endBarrier=6`
      );
      const Barrier710 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=7&endBarrier=10`
      );
      const Barrier1113 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=11&endBarrier=13`
      );
      const Barrier1416 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=14&endBarrier=16`
      );
      const Barrier17 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=17`
      );

      const Barrier13Data = Barrier13?.data?.result;
      const Barrier46Data = Barrier46?.data?.result;
      const Barrier710Data = Barrier710?.data?.result;
      const Barrier1113Data = Barrier1113?.data?.result;
      const Barrier1416Data = Barrier1416?.data?.result;
      const Barrier17Data = Barrier17?.data?.result;

      // TurfData["name"] = "Turf";
      // CushionData["name"] = "Cushion";

      let MergeData = [
        ...[Barrier13Data],
        ...[Barrier46Data],
        ...[Barrier710Data],
        ...[Barrier1113Data],
        ...[Barrier1416Data],
        ...[Barrier17Data]
      ];
      setBarrierCareerData(MergeData);
      setBarrierCareerLoading(false);
    } catch (error) {
      setBarrierCareerLoading(false);
    }
  };
  const fetchBarrierMonth = async () => {
    setBarrierMonthLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Barrier13 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=1&endBarrier=3&duration=12`
      );
      const Barrier46 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=4&endBarrier=6&duration=12`
      );
      const Barrier710 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=7&endBarrier=10&duration=12`
      );
      const Barrier1113 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=11&endBarrier=13&duration=12`
      );
      const Barrier1416 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=14&endBarrier=16&duration=12`
      );
      const Barrier17 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startBarrier=17&duration=12`
      );

      const Barrier13Data = Barrier13?.data?.result;
      const Barrier46Data = Barrier46?.data?.result;
      const Barrier710Data = Barrier710?.data?.result;
      const Barrier1113Data = Barrier1113?.data?.result;
      const Barrier1416Data = Barrier1416?.data?.result;
      const Barrier17Data = Barrier17?.data?.result;

      // TurfData["name"] = "Turf";
      // CushionData["name"] = "Cushion";

      let MergeData = [
        ...[Barrier13Data],
        ...[Barrier46Data],
        ...[Barrier710Data],
        ...[Barrier1113Data],
        ...[Barrier1416Data],
        ...[Barrier17Data]
      ];
      setBarrierMonthData(MergeData);
      setBarrierMonthLoading(false);
    } catch (error) {
      setBarrierMonthLoading(false);
    }
  };
  const fetchPrizeCareer = async () => {
    setPrizeCareerLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Prize5000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=0&endPrize=5000&duration=`
      );
      const Prize10000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=5001&endPrize=10000&duration=`
      );
      const Prize20000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=10001&endPrize=20000&duration=`
      );
      const Prize35000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=20001&endPrize=35000&duration=`
      );
      const Prize75000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=35001&endPrize=75000&duration=`
      );
      const Prize75000Plus = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=75001&duration=`
      );

      const Prize5000Data = Prize5000?.data?.result;
      const Prize10000Data = Prize10000?.data?.result;
      const Prize20000Data = Prize20000?.data?.result;
      const Prize35000Data = Prize35000?.data?.result;
      const Prize75000Data = Prize75000?.data?.result;
      const Prize75000PlusData = Prize75000Plus?.data?.result;
      Prize5000Data["name"] = "$0 - $5000";
      Prize10000Data["name"] = "$5001 - $10000";
      Prize20000Data["name"] = "$10001 - $20000";
      Prize35000Data["name"] = "$20001 - $35000";
      Prize75000Data["name"] = "$35001 - $75000";
      Prize75000PlusData["name"] = "$75001+";
      let MergeData = [
        ...[Prize5000Data],
        ...[Prize10000Data],
        ...[Prize20000Data],
        ...[Prize35000Data],
        ...[Prize75000Data],
        ...[Prize75000PlusData]
      ];
      setPrizeCareerData(MergeData);
      setPrizeCareerLoading(false);
    } catch (error) {
      setPrizeCareerLoading(false);
    }
  };
  const fetchPrizeMonth = async () => {
    setPrizeMonthLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Prize5000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=0&endPrize=5000&duration=12`
      );
      const Prize10000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=5001&endPrize=10000&duration=12`
      );
      const Prize20000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=10001&endPrize=20000&duration=12`
      );
      const Prize35000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=20001&endPrize=35000&duration=12`
      );
      const Prize75000 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=35001&endPrize=75000&duration=12`
      );
      const Prize75000Plus = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startPrize=75001&duration=12`
      );

      const Prize5000Data = Prize5000?.data?.result;
      const Prize10000Data = Prize10000?.data?.result;
      const Prize20000Data = Prize20000?.data?.result;
      const Prize35000Data = Prize35000?.data?.result;
      const Prize75000Data = Prize75000?.data?.result;
      const Prize75000PlusData = Prize75000Plus?.data?.result;
      Prize5000Data["name"] = "$0 - $5000";
      Prize10000Data["name"] = "$5001 - $10000";
      Prize20000Data["name"] = "$10001 - $20000";
      Prize35000Data["name"] = "$20001 - $35000";
      Prize75000Data["name"] = "$35001 - $75000";
      Prize75000PlusData["name"] = "$75001+";
      let MergeData = [
        ...[Prize5000Data],
        ...[Prize10000Data],
        ...[Prize20000Data],
        ...[Prize35000Data],
        ...[Prize75000Data],
        ...[Prize75000PlusData]
      ];
      setPrizeMonthData(MergeData);
      setPrizeMonthLoading(false);
    } catch (error) {
      setPrizeMonthLoading(false);
    }
  };
  const fetchWeightCareer = async () => {
    setWeightCareerLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Weight52 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=51&endWeight=52&duration=`
      );
      const Weight54 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=53&endWeight=54&duration=`
      );
      const Weight56 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=55&endWeight=56&duration=`
      );
      const Weight58 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=57&endWeight=58&duration=`
      );
      const Weight59Plus = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=59&duration=`
      );

      const Weight52Data = Weight52?.data?.result;
      const Weight54Data = Weight54?.data?.result;
      const Weight56Data = Weight56?.data?.result;
      const Weight58Data = Weight58?.data?.result;
      const Weight59PlusData = Weight59Plus?.data?.result;

      let MergeData = [
        ...[Weight52Data],
        ...[Weight54Data],
        ...[Weight56Data],
        ...[Weight58Data],
        ...[Weight59PlusData]
      ];
      setWeightCareerData(MergeData);
      setWeightCareerLoading(false);
    } catch (error) {
      setWeightCareerLoading(false);
    }
  };
  const fetchWeightMonth = async () => {
    setWeightMonthLoading(true);
    try {
      let passApi =
        params?.personType === "jockey" ? "getJockeyData" : "getTrainerData";
      const Weight52 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=51&endWeight=52&duration=12`
      );
      const Weight54 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=53&endWeight=54&duration=12`
      );
      const Weight56 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=55&endWeight=56&duration=12`
      );
      const Weight58 = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=57&endWeight=58&duration=12`
      );
      const Weight59Plus = await axiosInstance.get(
        `statistics/${passApi}/profile/${params?.personId}?trackId=${
          params?.trackId === "0" ? "" : params?.trackId
        }&startWeight=59&duration=12`
      );

      const Weight52Data = Weight52?.data?.result;
      const Weight54Data = Weight54?.data?.result;
      const Weight56Data = Weight56?.data?.result;
      const Weight58Data = Weight58?.data?.result;
      const Weight59PlusData = Weight59Plus?.data?.result;

      let MergeData = [
        ...[Weight52Data],
        ...[Weight54Data],
        ...[Weight56Data],
        ...[Weight58Data],
        ...[Weight59PlusData]
      ];
      setWeightMonthData(MergeData);
      setWeightMonthLoading(false);
    } catch (error) {
      setWeightMonthLoading(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchYearlyBreakDown();
    fetchMonthlyBreakdown();
    fetchOverallStat();
    // fetchTrackConditionCareer();
    // fetchTrackConditionMonth();
    // fetchTrackTypeCareer();
    // fetchTrackTypeMonth();
    // fetchBarrierCareer();
  }, []);
  return (
    <>
      {fetchAds(1, "102px", AboveTable)}
      <Box className="profile-info-wrap">
        <Box className="profile-details-wrap-1">
          {screenWidth > 799 ? (
            <>
              {IsOverAllStatsLoading && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!IsOverAllStatsLoading && (
                <>
                  <TableContainer id="details-table" className="details-table">
                    <Table className="table-wrap" style={{ minWidth: 940 }}>
                      <TableHead>
                        <TableRow className="table-head-row ">
                          <StickyTableCell className={classes.head}>
                            {localesData?.RUNNER_TABLE?.COLLAPSE?.OVERALL}{" "}
                            {localesData?.STATISTICS?.STATS}
                          </StickyTableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.STARTS}
                          </TableCell>
                          <TableCell className={classes.head}>1st</TableCell>
                          <TableCell className={classes.head}>2nd</TableCell>
                          <TableCell className={classes.head}>3rd</TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.WIN}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.PLACE}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.AVG_WIN_ODDS}
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.ROI}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {OverAllStats?.length > 0 ? (
                          OverAllStats?.map((item, i) => (
                            <>
                              <TableRow key={i} className="table-body-row ">
                                <StickyTableCell
                                  className={`${classes.cell} `}
                                  // onClick={() => handlenavigation(item)}
                                >
                                  {item?.name}
                                </StickyTableCell>
                                <TableCell className={classes.cell}>
                                  {item?.starts}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.first}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.second}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.third}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.win
                                    ? Number(item?.win).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.place
                                    ? Number(item?.place).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.avgWinOdd
                                    ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.roi}
                                </TableCell>
                              </TableRow>
                            </>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={12} className="no-data-td">
                              {/* No Data Available */}
                              <NoDataComp />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          ) : (
            <>
              <Box className="trackprofile-mobile-details">
                <Typography variant="h6" className="trackprofile-heading">
                  Overall Stats
                </Typography>
                {/* {OverAllStats?.map((item, index) => (
                  <> */}
                {!IsOverAllStatsLoading &&
                  (OverAllStats?.length > 0 ? (
                    OverAllStats?.map((item, index) => (
                      <>
                        <Box
                          className={
                            collapseMenu !== index
                              ? "trackprofile-border-bottom trackprofile-deatils"
                              : "trackprofile-deatils"
                          }
                          onClick={() => {
                            return setCollapseMenu(
                              collapseMenu === index ? -1 : index
                            );
                          }}
                        >
                          <Typography className="details-name">
                            {item?.name}
                          </Typography>
                          <Box className="arrow-details">
                            <IconButton aria-label="expand row" size="small">
                              {collapseMenu === index ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                        <Collapse
                          in={collapseMenu === index}
                          timeout="auto"
                          unmountOnExit
                          className="collapse-box collapse-box-bottom"
                        >
                          <Box className="collapse-deatils">
                            <Table className="collapse-table">
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.STARTS}
                                </TableCell>
                                <TableCell className="td-right">
                                  {" "}
                                  {item?.starts}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">1st</TableCell>
                                <TableCell className="td-right">
                                  {item?.first}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">2nd</TableCell>
                                <TableCell className="td-right">
                                  {item?.second}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">3rd</TableCell>
                                <TableCell className="td-right">
                                  {item?.third}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.WIN}%
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.win
                                    ? Number(item?.win).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.PLACE}%
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.place
                                    ? Number(item?.place).toFixed(2) + "%"
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.AVG_WIN_ODDS}
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.avgWinOdd
                                    ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {" "}
                                  {localesData?.sport?.ROI}
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.roi}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </Box>
                          {/* <Box className="view-profile-box">
                        <Typography
                          variant="h6"
                          className="view-profile"
                          onClick={() => handlenavigation(item)}
                        >
                          View Profile
                        </Typography>
                      </Box> */}
                        </Collapse>
                      </>
                    ))
                  ) : (
                    <Box className="no-data-mobile">
                      {/* No Data Available */}
                      <NoDataComp />
                    </Box>
                  ))}
              </Box>
            </>
          )}
        </Box>
        <Box className="profile-details-wrap-2">
          {screenWidth > 799 ? (
            <>
              {IsLast5YearLoading && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!IsLast5YearLoading && (
                <>
                  <TableContainer id="details-table" className="details-table">
                    <Table className="table-wrap" style={{ minWidth: 940 }}>
                      <TableHead>
                        <TableRow className="table-head-row ">
                          <StickyTableCell className={classes.head}>
                            {localesData?.STATISTICS?.YEARLY_BREAKDOWN}
                            <br />
                            <span className="head-row-span">
                              {localesData?.STATISTICS?.LAST_5_YEARS}
                            </span>
                          </StickyTableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.STARTS}
                          </TableCell>
                          <TableCell className={classes.head}>1st</TableCell>
                          <TableCell className={classes.head}>2nd</TableCell>
                          <TableCell className={classes.head}>3rd</TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.WIN}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.PLACE}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.AVG_WIN_ODDS}
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.ROI}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Last5YearData?.length > 0 ? (
                          Last5YearData?.map((obj, i) => {
                            let item = obj?.data?.result;
                            return (
                              <>
                                <TableRow key={i} className="table-body-row ">
                                  <StickyTableCell
                                    className={`${classes.cell} `}
                                    // onClick={() => handlenavigation(item)}
                                  >
                                    {obj?.Year}
                                  </StickyTableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.starts}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.first}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.second}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.third}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.win
                                      ? Number(item?.win).toFixed(2) + "%"
                                      : 0}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.place
                                      ? Number(item?.place).toFixed(2) + "%"
                                      : 0}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.avgWinOdd
                                      ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                      : 0}
                                  </TableCell>
                                  <TableCell className={classes.cell}>
                                    {item?.roi}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={12} className="no-data-td">
                              {/* No Data Available */}
                              <NoDataComp />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          ) : (
            <>
              <Box className="trackprofile-mobile-details">
                <Typography variant="h6" className="trackprofile-heading">
                  {localesData?.STATISTICS?.YEARLY_BREAKDOWN}
                  <br />
                  <span className="head-row-span">
                    {localesData?.STATISTICS?.LAST_5_YEARS}
                  </span>
                </Typography>
                {/* {Last5YearData?.map((obj, index) => {
                  let item = obj?.data?.result;
                  return (
                    <> */}
                {IsLast5YearLoading && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!IsLast5YearLoading &&
                  (Last5YearData?.length > 0 ? (
                    Last5YearData?.map((obj, index) => {
                      let item = obj?.data?.result;
                      return (
                        <>
                          <Box
                            className={
                              collapseMenuSecond !== index
                                ? "trackprofile-border-bottom trackprofile-deatils"
                                : "trackprofile-deatils"
                            }
                            onClick={() => {
                              return setCollapseMenuSecond(
                                collapseMenuSecond === index ? -1 : index
                              );
                            }}
                          >
                            <Typography className="details-name">
                              {obj?.Year}
                            </Typography>
                            <Box className="arrow-details">
                              <IconButton aria-label="expand row" size="small">
                                {collapseMenuSecond === index ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </Box>
                          </Box>
                          <Collapse
                            in={collapseMenuSecond === index}
                            timeout="auto"
                            unmountOnExit
                            className="collapse-box collapse-box-bottom"
                          >
                            <Box className="collapse-deatils">
                              <Table className="collapse-table">
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">
                                    {localesData?.sport?.STARTS}
                                  </TableCell>
                                  <TableCell className="td-right">
                                    {" "}
                                    {item?.starts}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">1st</TableCell>
                                  <TableCell className="td-right">
                                    {item?.first}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">2nd</TableCell>
                                  <TableCell className="td-right">
                                    {item?.second}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">3rd</TableCell>
                                  <TableCell className="td-right">
                                    {item?.third}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">
                                    {localesData?.sport?.WIN}%
                                  </TableCell>
                                  <TableCell className="td-right">
                                    {item?.win
                                      ? Number(item?.win).toFixed(2) + "%"
                                      : 0}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">
                                    {localesData?.sport?.PLACE}%
                                  </TableCell>
                                  <TableCell className="td-right">
                                    {item?.place
                                      ? Number(item?.place).toFixed(2) + "%"
                                      : 0}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">
                                    {localesData?.sport?.AVG_WIN_ODDS}
                                  </TableCell>
                                  <TableCell className="td-right">
                                    {item?.avgWinOdd
                                      ? "$" + Number(item?.avgWinOdd).toFixed(2)
                                      : 0}
                                  </TableCell>
                                </TableRow>
                                <TableRow className="collapse-table-row">
                                  <TableCell className="td-left">
                                    {" "}
                                    {localesData?.sport?.ROI}
                                  </TableCell>
                                  <TableCell className="td-right">
                                    {item?.roi}
                                  </TableCell>
                                </TableRow>
                              </Table>
                            </Box>
                            {/* <Box className="view-profile-box">
                        <Typography
                          variant="h6"
                          className="view-profile"
                          onClick={() => handlenavigation(item)}
                        >
                          View Profile
                        </Typography>
                      </Box> */}
                          </Collapse>
                        </>
                      );
                    })
                  ) : (
                    <Box className="no-data-mobile">
                      {/* No Data Available */}
                      <NoDataComp />
                    </Box>
                  ))}
              </Box>
            </>
          )}
        </Box>
        <Box className="profile-details-wrap-2">
          {screenWidth > 799 ? (
            <>
              {monthBreakdownLoading && (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              )}
              {!monthBreakdownLoading && (
                <>
                  <TableContainer id="details-table" className="details-table">
                    <Table className="table-wrap" style={{ minWidth: 940 }}>
                      <TableHead>
                        <TableRow className="table-head-row ">
                          <StickyTableCell className={classes.head}>
                            {localesData?.STATISTICS?.MONTHLY_BREAKDOWN}
                            <br />
                            <span className="head-row-span">
                              {localesData?.STATISTICS?.LAST_12_MONTHS}
                            </span>
                          </StickyTableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.STARTS}
                          </TableCell>
                          <TableCell className={classes.head}>1st</TableCell>
                          <TableCell className={classes.head}>2nd</TableCell>
                          <TableCell className={classes.head}>3rd</TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.WIN}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.PLACE}%
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.AVG_WIN_ODDS}
                          </TableCell>
                          <TableCell className={classes.head}>
                            {localesData?.sport?.ROI}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Last12MonthBreakdownData?.length > 0 ? (
                          Last12MonthBreakdownData?.map((item, i) => (
                            <>
                              <TableRow key={i} className="table-body-row ">
                                <StickyTableCell
                                  className={`${classes.cell} `}
                                  // onClick={() => handlenavigation(item)}
                                >
                                  {item?.Month}
                                </StickyTableCell>
                                <TableCell className={classes.cell}>
                                  {item?.data?.result?.starts}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.data?.result?.first}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.data?.result?.second}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.data?.result?.third}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.data?.result?.win
                                    ? Number(item?.data?.result?.win).toFixed(
                                        2
                                      ) + "%"
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.data?.result?.place
                                    ? Number(item?.data?.result?.place).toFixed(
                                        2
                                      ) + "%"
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.data?.result?.avgWinOdd
                                    ? "$" +
                                      Number(
                                        item?.data?.result?.avgWinOdd
                                      ).toFixed(2)
                                    : 0}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {item?.data?.result?.roi}
                                </TableCell>
                              </TableRow>
                            </>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={12} className="no-data-td">
                              {/* No Data Available */}
                              <NoDataComp />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          ) : (
            <>
              <Box className="trackprofile-mobile-details">
                <Typography variant="h6" className="trackprofile-heading">
                  {localesData?.STATISTICS?.MONTHLY_BREAKDOWN}
                  <br />
                  <span className="head-row-span">
                    {localesData?.STATISTICS?.LAST_12_MONTHS}
                  </span>
                </Typography>
                {monthBreakdownLoading && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!monthBreakdownLoading &&
                  (Last12MonthBreakdownData?.length > 0 ? (
                    Last12MonthBreakdownData?.map((item, index) => (
                      <>
                        <Box
                          className={
                            Last12Monthcollapse !== index
                              ? "trackprofile-border-bottom trackprofile-deatils"
                              : "trackprofile-deatils"
                          }
                          onClick={() => {
                            return setLast12Monthcollapse(
                              Last12Monthcollapse === index ? -1 : index
                            );
                          }}
                        >
                          <Typography className="details-name">
                            {item?.Month}
                          </Typography>
                          <Box className="arrow-details">
                            <IconButton aria-label="expand row" size="small">
                              {Last12Monthcollapse === index ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                        <Collapse
                          in={Last12Monthcollapse === index}
                          timeout="auto"
                          unmountOnExit
                          className="collapse-box collapse-box-bottom"
                        >
                          <Box className="collapse-deatils">
                            <Table className="collapse-table">
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.STARTS}
                                </TableCell>
                                <TableCell className="td-right">
                                  {" "}
                                  {item?.data?.result?.starts}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">1st</TableCell>
                                <TableCell className="td-right">
                                  {item?.data?.result?.first}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">2nd</TableCell>
                                <TableCell className="td-right">
                                  {item?.data?.result?.second}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">3rd</TableCell>
                                <TableCell className="td-right">
                                  {item?.data?.result?.third}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.WIN}%
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.data?.result?.win
                                    ? Number(item?.data?.result?.win).toFixed(
                                        2
                                      ) + "%"
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.PLACE}%
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.data?.result?.place
                                    ? Number(item?.data?.result?.place).toFixed(
                                        2
                                      ) + "%"
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {localesData?.sport?.AVG_WIN_ODDS}
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.data?.result?.avgWinOdd
                                    ? "$" +
                                      Number(
                                        item?.data?.result?.avgWinOdd
                                      ).toFixed(2)
                                    : 0}
                                </TableCell>
                              </TableRow>
                              <TableRow className="collapse-table-row">
                                <TableCell className="td-left">
                                  {" "}
                                  {localesData?.sport?.ROI}
                                </TableCell>
                                <TableCell className="td-right">
                                  {item?.data?.result?.roi}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </Box>
                          {/* <Box className="view-profile-box">
                        <Typography
                          variant="h6"
                          className="view-profile"
                          onClick={() => handlenavigation(item)}
                        >
                          View Profile
                        </Typography>
                      </Box> */}
                        </Collapse>
                      </>
                    ))
                  ) : (
                    <Box className="no-data-mobile">
                      {/* No Data Available */}
                      <NoDataComp />
                    </Box>
                  ))}
              </Box>
            </>
          )}
        </Box>
        <StatisticsTable
          Name="Track Conditions"
          dataName="Career"
          isLoading={IsTrackConditionCareerLoading}
          TableData={TrackConditionCareerData}
          collapseMenu={TrackConditionCareercollapse}
          setcollapseMenu={setTrackConditionCareercollapse}
          apiCallFunction={() => fetchTrackConditionCareer()}
        />
        <StatisticsTable
          Name="Track Conditions"
          dataName="Last 12 Months"
          isLoading={IsTrackConditionMonthLoading}
          TableData={TrackConditionMonthData}
          collapseMenu={TrackConditionMonthcollapse}
          setcollapseMenu={setTrackConditionMonthcollapse}
          apiCallFunction={() => fetchTrackConditionMonth()}
        />
        <StatisticsTable
          Name="Track Types"
          dataName="Career"
          isLoading={IsTrackTypesCareerLoading}
          TableData={TrackTypesCareerData}
          collapseMenu={TrackTypesCareercollapse}
          setcollapseMenu={setTrackTypesCareercollapse}
          apiCallFunction={() => fetchTrackTypeCareer()}
        />
        <StatisticsTable
          Name="Track Types"
          dataName="Last 12 Months"
          isLoading={IsTrackTypesMonthLoading}
          TableData={TrackTypesMonthData}
          collapseMenu={TrackTypesMonthcollapse}
          setcollapseMenu={setTrackTypesMonthcollapse}
          apiCallFunction={() => fetchTrackTypeMonth()}
        />
        <StatisticsTable
          Name="Distances"
          dataName="Career"
          isLoading={IsDistancesCareerLoading}
          TableData={DistancesCareerData}
          collapseMenu={DistancesCareercollapse}
          setcollapseMenu={setDistancesCareercollapse}
          apiCallFunction={() => fetchDistancesCareer()}
        />
        <StatisticsTable
          Name="Distances"
          dataName="Last 12 Months"
          isLoading={IsDistancesMonthLoading}
          TableData={DistancesMonthData}
          collapseMenu={DistancesMonthcollapse}
          setcollapseMenu={setDistancesMonthcollapse}
          apiCallFunction={() => fetchDistancesMonth()}
        />
        <StatisticsTable
          Name="Barrier"
          dataName="Career"
          isLoading={IsBarrierCareerLoading}
          TableData={BarrierCareerData}
          collapseMenu={BarrierCareercollapse}
          setcollapseMenu={setBarrierCareercollapse}
          apiCallFunction={() => fetchBarrierCareer()}
        />
        <StatisticsTable
          Name="Barrier"
          dataName="Last 12 Months"
          isLoading={IsBarrierMonthLoading}
          TableData={BarrierMonthData}
          collapseMenu={BarrierMonthcollapse}
          setcollapseMenu={setBarrierMonthcollapse}
          apiCallFunction={() => fetchBarrierMonth()}
        />
        <StatisticsTable
          Name="Prize Money"
          dataName="Career"
          isLoading={IsPrizeCareerLoading}
          TableData={PrizeCareerData}
          collapseMenu={PrizeCareercollapse}
          setcollapseMenu={setPrizeCareercollapse}
          apiCallFunction={() => fetchPrizeCareer()}
        />
        <StatisticsTable
          Name="Prize Money"
          dataName="Last 12 Months"
          isLoading={IsPrizeMonthLoading}
          TableData={PrizeMonthData}
          collapseMenu={PrizeMonthcollapse}
          setcollapseMenu={setPrizeMonthcollapse}
          apiCallFunction={() => fetchPrizeMonth()}
        />
        <StatisticsTable
          Name="Weight"
          dataName="Career"
          isLoading={IsWeightCareerLoading}
          TableData={WeightCareerData}
          collapseMenu={WeightCareercollapse}
          setcollapseMenu={setWeightCareercollapse}
          apiCallFunction={() => fetchWeightCareer()}
        />
        <StatisticsTable
          Name="Weight"
          dataName="Last 12 Months"
          isLoading={IsWeightMonthLoading}
          TableData={WeightMonthData}
          collapseMenu={WeightMonthcollapse}
          setcollapseMenu={setWeightMonthcollapse}
          apiCallFunction={() => fetchWeightMonth()}
        />
      </Box>
    </>
  );
}

export default ProfileInfoPage;
