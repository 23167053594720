import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "./ui.scss";

const SportsTipModal = ({
  handleClose,
  open,
  title,
  SportId,
  raceId,
  data,
  type,
  closeIcon,
  listingFunction
}) => {
  const location = useLocation();
  const [comment, setComment] = useState("");
  //   const GetBlackBook = async () => {
  //     setIsLoading(true);
  //     try {
  //       const { status, data } = await axiosInstance.get(
  //         `blackbook/popUpData/${typeId}?type=${type}`
  //       );
  //       if (status === 200) {
  //         setPopUpData(data?.result);
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(false);
  //       }
  //     } catch (err) {
  //       setIsLoading(false);
  //     }
  //   };
  //   const handleAddBlackBook = async () => {
  //     try {
  //       const payload = {
  //         type: type,
  //         id: typeId,
  //         comment: comment
  //       };
  //       const { status, data } = await axiosInstance.post(
  //         `blackbook/favoriteData`,
  //         payload
  //       );
  //       if (status === 200) {
  //         toast.success(data?.message, {
  //           position: "bottom-center",
  //           autoClose: 2000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           theme: "colored"
  //         });
  //         listingFunction();
  //         setTimeout(() => {
  //           handleClose();
  //           setPopUpData([]);
  //           setComment("");
  //         }, 3000);
  //       } else {
  //       }
  //     } catch (error) {
  //       toast.error("An Error Occured".data?.message, {
  //         position: "bottom-center",
  //         autoClose: 2000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         theme: "colored"
  //       });
  //     }
  //   };
  const handleAddTips = async () => {
    let payload;
    if (type == "race") {
      payload = {
        type: type,
        comment: comment,
        SportId: SportId,
        raceId: raceId,
        runnerId: data?.animal?.id
      };
    } else {
      payload = {
        type: type,
        comment: comment,
        SportId: SportId,
        eventId: data?.id,
        teamId: raceId //raceid = selected tipping teamId
      };
    }

    try {
      const { status, data } = await axiosInstance.post(
        `/tipping/addTip`,
        payload
      );
      if (status === 200) {
        toast.success(data?.message ? data?.message : "Tips Is Added", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        setTimeout(() => {
          handleClose();
          setComment("");
        }, 3000);

        listingFunction();
      } else {
      }
    } catch (error) {
      toast.error("An Error Occured".data?.message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };

  const fetchTournamentName = () => {
    const tournamentName =
      SportId == 4
        ? data?.CricketTournament?.name
        : SportId == 10
        ? data?.NBATournament?.name
        : SportId == 15
        ? data?.AFLTournament?.name
        : SportId == 9
        ? data?.ARTournament?.name
        : SportId == 16
        ? data?.GolfTournament?.name
        : SportId == 7
        ? data?.TennisTournament?.name
        : SportId == 11
        ? data?.BaseballTournament?.name
        : SportId == 17
        ? data?.IceHockeyTournament?.name
        : SportId == 6
        ? data?.BoxingTournament?.name
        : SportId == 5
        ? data?.MMATournament?.name
        : SportId == 8
        ? data?.SoccerTournament?.name
        : data?.RLTournament?.name;
    return tournamentName;
  };
  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  return (
    <Dialog
      className="blackBook-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {title && closeIcon && (
          <DialogTitle>
            <Box className="custom-dialog-title">
              <Typography variant="h3" className="dialog-title">
                <Box className="title-chip">
                  {title}
                  {type == "sport" && (
                    <span className="sport-indicator-btn">
                      {fetchTournamentName()}
                    </span>
                  )}
                </Box>

                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      handleClose();
                      // setPopUpData([]);
                      // setComment("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
          </DialogTitle>
        )}
        <Box className="smartbook-content-wrap">
          <Box className="smartbook-type-wrap">
            <Box className="blackbook-content">
              <span className="content-label">
                {" "}
                {fetchDayName(data?.startTime)} {"-"}
                <span className="content-des">
                  {" "}
                  {data?.startTime
                    ? moment
                        .utc(data?.startTime)
                        .local()
                        .format("DD/MM/YYYY")
                    : ""}
                </span>{" "}
              </span>
            </Box>
            <Box className="blackbook-content details">
              <span className="des-wrap">
                <span className="content-label">
                  {" "}
                  {data?.startTime
                    ? moment
                        .utc(data?.startTime)
                        .local()
                        .format("hh:mm A")
                    : ""}{" "}
                </span>{" "}
                |{" "}
                <span className="content-label">
                  {" "}
                  {`${data?.marketLength ? data?.marketLength : 0} Markets`}
                </span>
              </span>

              {/* <span className="des-wrap">
                  <span className="content-label"> Jockey -</span>{" "}
                  <span className="content-des">
                    {popUpData?.runner?.[0]?.Jockey?.name
                      ? popUpData?.runner?.[0]?.Jockey?.name
                      : "N/A"}{" "}
                  </span>{" "}
                    </span> */}
            </Box>
          </Box>

          <TextareaAutosize
            id="outlined-basic"
            variant="outlined"
            className="details-textarea-field"
            placeholder="Comment(optional)..."
            multiline
            maxRows={6}
            name="message"
            style={{ width: "97%", height: "241px" }}
            onChange={e => setComment(e.target.value)}
            value={comment}
          />
          <Box className="blackbook-btn-wrap">
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              className="blackbook-button"
              onClick={() => {
                handleAddTips();
              }}
            >
              {/* {isEdit ? "Update Smartbook" : "Add to my Smartbook"} */}
              Confirm Tip
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
    </Dialog>
  );
};

export default SportsTipModal;
