import React, { useState, useEffect, useContext, useRef } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Collapse
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { IntlContext } from "src/App";
import { useDispatch, useSelector } from "react-redux";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchAdvtData } from "../../helpers/store/Actions/Ads";
import MainMenubar from "./MainMenubar";
import LeftSideBar from "../../views/component/leftsidebar/leftsidebar";
import RightSideBar from "../../views/component/righsidebar/rightsidebar";
import Footer from "./../../views/component/footer/footer";
import "src/layouts/layout.scss";
import AdvertisingScreenPage from "src/views/component/advertisingScreenPage";
import TopHeaderBanner from "../../assets/images/ad-placeholder/Topheader.webp";
import TopHeaderBannerIndia from "../../assets/images/ad-placeholder/IPL_Header_Banner_1350x292_1.png";
import SideBgBanner from "../../assets/images/ad-placeholder/side-bg-ad.webp";
import SideBgBannerIndia from "../../assets/images/ad-placeholder/IPL_Side_Banner_218x766_2.png";
import RaceHorses from "../../assets/images/Sport/race_hourses.svg";
import RaceGreyhounds from "../../assets/images/Sport/races_greyhounds.svg";
import RaceHarness from "../../assets/images/Sport/race_harness.svg";
import { ReactComponent as AmericanFootball } from "../../assets/images/SportIcon/outlineSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../assets/images/SportIcon/outlineSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../assets/images/SportIcon/outlineSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../assets/images/SportIcon/outlineSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../assets/images/SportIcon/outlineSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../assets/images/SportIcon/outlineSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../assets/images/SportIcon/outlineSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../assets/images/SportIcon/outlineSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../assets/images/SportIcon/outlineSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../assets/images/SportIcon/outlineSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../assets/images/SportIcon/outlineSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../assets/images/SportIcon/outlineSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../assets/images/SportIcon/outlineSportIcon/Tennis.svg";
import GeofencingPage from "src/views/pages/RestrictedPage/geofencingpage";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import DynamicScript from "src/views/component/DynamicScript/DynamicScript";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import DialogBox from "src/views/component/UI/dialogBox";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { fetchBetSlipToggle } from "src/helpers/store/Actions/BetSlipToggle";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipShareToggle } from "src/helpers/store/Actions/BetSlipShareToggle";
import { ReactComponent as BetSlipArrow } from "../../assets/images/icons/betSlipArrow.svg";
import { ReactComponent as Notepad } from "../../assets/images/icons/notepad.svg";
import NotepadBig from "../../assets/images/notepadBig.png";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as ShareSlip } from "src/assets/images/icons/shareSlip.svg";
import Bet365Slip from "src/assets/images/bet365Slip.png";
import Select, { components } from "react-select";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import MaintenancePage from "src/views/pages/MaintenancePage";
import { fetchSubscriptionData } from "../../helpers/store/Actions/SubscriptionData";
import { release } from "src/helpers/context/release";
import HederOddsComp from "src/views/component/RacingOddsComparison";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const options = [
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" }
  // { value: "3", label: "Win Tote" },
  // { value: "4", label: "Place Tote" }
  // { value: "4", label: "Starting Price" },
];

const DashboardLayout = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const redux_user_data = useSelector(
    state => state?.reduxData?.SubscripitionData
  );
  const redux_auth = useSelector(state => state?.reduxData?.authData);
  const redux_channel = useSelector(state => state?.reduxData?.channelData);
  const redux_userData = useSelector(state => state?.reduxData?.userData);
  const user_statics = redux_userData
    ? redux_userData
    : fetchFromStorage("userData");
  const auth = redux_auth ? redux_auth : fetchFromStorage("authData");
  const channelData = redux_channel
    ? redux_channel
    : fetchFromStorage("channelData");
  const redux_tooltipStep = useSelector(state => state?.reduxData?.tooltipData);
  const redux_intervaldata = useSelector(
    state => state?.reduxData?.intervalData
  );

  const intervalPage = useSelector(state => state?.reduxData?.page);
  const tooltipStep = redux_tooltipStep
    ? redux_tooltipStep
    : fetchFromStorage("tooltipStep");
  const redux_organisationData = useSelector(
    state => state?.reduxData?.organisationData
  );
  const organisationData = redux_organisationData
    ? redux_organisationData
    : fetchFromStorage("organisationData");
  const token = localStorage.getItem("auth_token");
  const verify_count = useSelector(
    state => state?.reduxData?.user_profile_count
  );
  const BetSlipToggleData = useSelector(
    state => state?.reduxData?.BetSlipToggle
  );
  const BetslipReduxData = useSelector(state => state?.reduxData?.BetSlipData);
  const restrictedUser = useSelector(state => state?.reduxData?.RestrictedData);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const [SideBGAdData, setSideBGAdData] = useState([]);
  const [TopHeaderAdData, setTopHeaderAdData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [betSlipData, setBetSlipData] = useState([]);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const myDiv = document.getElementById("adv-wrap");
  const [open, setOpen] = useState(false);
  const [progress, setprogress] = useState(false);
  const [cartKey, setCartKey] = useState("");
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(fetchRestrictedRoute(false));
    setOpen(false);
    localStorage.removeItem("prev_authroute");
  };

  useEffect(() => {
    if (
      (redux_user_data?.address === null ||
        redux_user_data?.address?.country === null ||
        redux_user_data?.address?.state === null ||
        redux_user_data?.dob === "") &&
      !location.pathname.includes("profile-edit")
    ) {
      navigate("/profile");
    }
  }, [location.pathname, redux_user_data]);
  useEffect(() => {
    if (restrictedUser) {
      handleModal();
    }
  }, [restrictedUser]);

  useEffect(() => {
    if (myDiv) myDiv.scrollTop = 0;
  }, [myDiv, location]);

  useEffect(() => {
    if (document.getElementById("adv-wrap")) {
      document.getElementById("adv-wrap").scrollIntoView();
      document.getElementById("adv-wrap").scrollTop += 10;
    }
  }, [location]);
  useEffect(() => {
    if (
      (location.pathname.includes("/racing") ||
        location.pathname.includes("/profile") ||
        location.pathname.includes("/statistics") ||
        location.pathname.includes("/teamsports") ||
        location.pathname.includes("/sportmatchups") ||
        location.pathname.includes("/news") ||
        location.pathname.includes("/combatsports") ||
        location.pathname.includes("/sportmarket") ||
        location?.pathname?.includes("/blackbook") ||
        location?.pathname?.includes("/smartbook") ||
        location?.pathname?.includes("/tips-marketplace/enter-sports-tips") ||
        location?.pathname?.includes("/tips-marketplace/sports-tips") ||
        location?.pathname?.includes("/tipper-profile") ||
        location?.pathname?.includes("/tips-marketplace/enter-racing-tips") ||
        location?.pathname?.includes("/purchased-tips") ||
        location?.pathname?.includes("/team-sports-data") ||
        location?.pathname?.includes("/race/event-calendar") ||
        location?.pathname?.includes("/sport/event-calendar") ||
        location?.pathname?.includes("/recommended-websites") ||
        location?.pathname?.includes("/create-competition") ||
        location?.pathname?.includes("/my-comps") ||
        location?.pathname?.includes("/rankings") ||
        location?.pathname?.includes("/our-people") ||
        location?.pathname?.includes("/tips") ||
        location?.pathname?.includes("/cart-payment") ||
        location?.pathname?.includes("/messages") ||
        location?.pathname?.includes("/podcasts") ||
        location?.pathname?.includes("/gettips") ||
        location?.pathname?.includes("/international-racing") ||
        location?.pathname?.includes("/subscription-payment")) &&
      !location?.pathname?.includes("/race/event-calendar/details") &&
      !location?.pathname?.includes("/sport/event-calendar/details")
    ) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [location.pathname]);
  if (redux_intervaldata) {
    if (intervalPage === "teamsports") {
      if (window.location.href.includes("teamsports")) {
      } else {
        clearInterval(redux_intervaldata);
        clearTimeout(redux_intervaldata);
      }
    } else {
      if (
        window.location.href.includes("horse") ||
        window.location.href.includes("greyhounds") ||
        window.location.href.includes("harness")
      ) {
      } else {
        clearInterval(redux_intervaldata);
        clearTimeout(redux_intervaldata);
      }
    }
  }
  const advertisingScreen = location.pathname.includes("/advertisingscreen");
  const maintenanceScreen = location.pathname.includes("/issue-page");
  const restrictedScreen = location.pathname.includes("/restricted");
  const racingOddsComparison = location.pathname.includes("odds-comparison");
  useEffect(() => {
    if (!advertisingScreen) {
      fetchAdsData();
    }
    token && fetchBetslipData();
    fetchBookKeeper();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (BetSlipToggleData) {
      fetchBetslipData();
    }
  }, [BetSlipToggleData]);

  useEffect(() => {
    // if (BetslipReduxData) {
    //   fetchBetslipData();
    // }
    BetslipReduxData &&
      BetslipReduxData?.length > 0 &&
      BetslipReduxData?.length !== betSlipData?.length &&
      fetchBetslipData();
  }, [BetslipReduxData]);

  const raceIcon = sportId => {
    if (sportId === 1) {
      return RaceHorses;
    } else if (sportId === 2) {
      return RaceHarness;
    } else if (sportId === 3) {
      return RaceGreyhounds;
    } else if (sportId === 15) {
      return <AmericanFootball />;
    } else if (sportId === 9) {
      return <AustralianRules />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MixedMartialArts />;
    } else if (sportId === 12) {
      return <RugbyLeague />;
    } else if (sportId === 13) {
      return <RugbyUnion />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };
  const handleAdsImression = async (id, position) => {
    try {
      const payload = {
        id: id,
        position_id: [position]
      };
      const { status, data } = await axiosInstance.post(
        `addImpression`,
        payload
      );
      if (status === 200) {
      }
    } catch (err) {}
  };

  const fetchAdsData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=1,9,10,11,40&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        dispatch(fetchAdvtData(data?.result));
        let sideBgAdsData = data?.result?.filter(item => item?.page_id === 9);
        setSideBGAdData(sideBgAdsData);
        let topHeaderAdsData = data?.result?.filter(
          item => item?.page_id === 10
        );
        setTopHeaderAdData(topHeaderAdsData);

        if (screenWidth > 1440) {
          sideBgAdsData?.length > 0 &&
            handleAdsImression(sideBgAdsData?.[0]?.id, 1);
        }
        topHeaderAdsData?.length > 0 &&
          handleAdsImression(topHeaderAdsData?.[0]?.id, 1);
      }
    } catch (err) {}
  };
  const handleClick = async id => {
    try {
      let payload = {
        id: id,
        position_id: 1
      };
      const { status, data } = await axiosInstance.post(`addClick`, payload);
      if (status === 200) {
      }
    } catch (err) {}
  };
  const handleNavigate = () => {
    navigate("/advertisingenquiries", { state: location });
  };
  const fetchTopheaderAds = () => {
    if (TopHeaderAdData?.length > 0) {
      let addetails = TopHeaderAdData?.[0];
      if (addetails) {
        return addetails?.MediaDetail?.type === "image" &&
          addetails?.MediaDetail?.image !== "" ? (
          <a
            href={addetails?.Campaign?.url}
            className="top-header-banner-wrap"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={
                addetails?.MediaDetail?.image?.includes("uploads")
                  ? Config.countryMediaURL + addetails?.MediaDetail?.image
                  : addetails?.MediaDetail?.image
              }
              onClick={() => handleClick(addetails?.id)}
              alt="ad-banner"
              className="top-header-banner"
              // preload="true"
              // loading="lazy"
              effect="blur"
              width="100%"
            />
          </a>
        ) : addetails?.MediaDetail?.type === "script" &&
          addetails?.MediaDetail?.script !== "" ? (
          <a
            href={addetails?.Campaign?.url}
            className="top-header-banner-wrap"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DynamicScript
              dynamicscript={addetails?.MediaDetail?.script}
              addetails={addetails}
              handleClick={handleClick}
            />
          </a>
        ) : (
          <Box
            // href={addetails?.Campaign?.url}
            className="top-header-banner-wrap"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="top-header-banner"
              src={
                Config.release == "IN" ? TopHeaderBannerIndia : TopHeaderBanner
              }
              alt="banner"
              onClick={() => handleNavigate()}
              // preload="true"
              // loading="lazy"
              effect="blur"
            />
          </Box>
        );
      } else {
        return (
          <Box
            // href="mailto:info@smartb.com.au"
            className="top-header-banner-wrap"
          >
            <img
              className="top-header-banner"
              src={
                Config.release == "IN" ? TopHeaderBannerIndia : TopHeaderBanner
              }
              alt="banner"
              onClick={() => handleNavigate()}
              // preload="true"
              // loading="lazy"
              effect="blur"
            />
          </Box>
        );
      }
    } else {
      return (
        <Box
          // href="mailto:info@smartb.com.au"

          className="top-header-banner-wrap"
        >
          <img
            className="top-header-banner"
            src={
              Config.release == "IN" ? TopHeaderBannerIndia : TopHeaderBanner
            }
            alt="banner"
            onClick={() => handleNavigate()}
            // preload="true"
            // loading="lazy"
            effect="blur"
          />
        </Box>
      );
    }
  };

  const fetchSideBgAds = location => {
    if (SideBGAdData?.length > 0) {
      let addetails = SideBGAdData?.[0];
      if (addetails) {
        return addetails?.MediaDetail?.type === "image" &&
          addetails?.MediaDetail?.image !== "" ? (
          <a
            href={addetails?.Campaign?.url}
            className={`side-bg-banner ${location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={
                addetails?.MediaDetail?.image?.includes("uploads")
                  ? Config.countryMediaURL + addetails?.MediaDetail?.image
                  : addetails?.MediaDetail?.image
              }
              onClick={() => handleClick(addetails?.id)}
              alt="ad-banner"
              effect="blur"
              width="100%"
              // preload="true"
              // loading="lazy"
            />
          </a>
        ) : addetails?.MediaDetail?.type === "script" &&
          addetails?.MediaDetail?.script !== "" ? (
          <a
            href={addetails?.Campaign?.url}
            className={`side-bg-banner ${location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <DynamicScript
              dynamicscript={addetails?.MediaDetail?.script}
              addetails={addetails}
              handleClick={handleClick}
            />
          </a>
        ) : (
          <Box
            // href={addetails?.Campaign?.url}
            className={`side-bg-banner ${location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Config.release == "IN" ? SideBgBannerIndia : SideBgBanner}
              alt="banner"
              onClick={() => handleNavigate()}
              // preload="true"
              // loading="lazy"
              effect="blur"
            />
          </Box>
        );
      } else {
        return (
          <Box
            // href="mailto:info@smartb.com.au"
            className="top-header-banner-wrap"
          >
            <img
              src={Config.release == "IN" ? SideBgBannerIndia : SideBgBanner}
              alt="banner"
              onClick={() => handleNavigate()}
              // preload="true"
              // loading="lazy"
              effect="blur"
            />
          </Box>
        );
      }
    } else {
      return (
        <Box
          // href="mailto:info@smartb.com.au"
          className={`side-bg-banner ${location}`}
        >
          <img
            src={Config.release == "IN" ? SideBgBannerIndia : SideBgBanner}
            alt="banner"
            onClick={() => handleNavigate()}
            // preload="true"
            // loading="lazy"
            effect="blur"
          />
        </Box>
      );
    }
  };

  const handleBetSlipClose = () => {
    dispatch(fetchBetSlipToggle(false));
    document.body.classList.remove("no-scroll");
  };

  const fetchBetslipData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/betSlipCard/BetSlipCard?OddKeyId=1`
      );
      if (status === 200) {
        const editedData = data?.result?.map(item => {
          return {
            ...item,
            selectedMarket: 1,
            returns: item?.stack ? item?.stack * item?.odd : 0
          };
        });
        setCartKey(data?.cartKey);
        setBetSlipData(editedData);
        dispatch(fetchBetSlipData(editedData));
        dispatch(fetchBetSlipCount(editedData?.length));
      } else {
      }
    } catch (err) {}
  };
  const handleSelectedMarketChange = (value, item) => {
    let chageData = betSlipData?.map(obj => {
      if (obj?.id === item?.id) {
        return {
          ...obj,
          selectedMarket: Number(value),
          returns:
            Number(value) === 1
              ? (Number(item?.stack) || 0) * Number(item?.odd || 0)
              : (Number(item?.stack) || 0) * Number(item?.oddPlace || 0)
        };
      } else {
        return obj;
      }
    });
    dispatch(fetchBetSlipData(chageData));
    setBetSlipData(chageData);
  };
  const handleStackChange = (value, item) => {
    let chageData = betSlipData?.map(obj => {
      if (obj?.id === item?.id) {
        return {
          ...obj,
          returns:
            item?.selectedMarket === 1
              ? (Number(value) || 0) * Number(item?.odd || 0)
              : (Number(value) || 0) * Number(item?.oddPlace || 0),
          stack: value !== "" ? Number(value) : null
        };
      } else {
        return obj;
      }
    });
    dispatch(fetchBetSlipData(chageData));
    setBetSlipData(chageData);
    // handleUpdateBetslip(item, value);
  };
  const handleUpdateBetslip = async (item, value) => {
    try {
      const payload = {
        stack: value !== "" ? Number(value) : null
      };
      const { status, data } = await axiosInstance.put(
        `BetSlipCard/betSlipCard/${item?.id}`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {}
  };
  const handleDeleteBetslip = async item => {
    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${item?.id}`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        let chageData = betSlipData?.filter(obj => obj?.id !== item?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(betSlipData?.length - 1));
        dispatch(fetchBetSlipShareToggle(true));
        setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };
  const handleClearAll = async item => {
    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?isAll=true`
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        dispatch(fetchBetSlipData([]));
        dispatch(fetchBetSlipCount(0));
        dispatch(fetchBetSlipShareToggle(true));
        setBetSlipData([]);
      } else {
      }
    } catch (err) {}
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  const handleTotalData = () => {
    const totalTask = betSlipData?.reduce(
      (total, item) => total + (item?.stack || 0),
      0
    );
    const potentialReturn = betSlipData?.reduce(
      (total, item) => total + (item?.returns || 0),
      0
    );
    return (
      <>
        <Box className="betslip-details mb-4">
          <Typography className="details-text">Total Stake:</Typography>
          <Typography className="details-text">
            {totalTask ? totalTask : 0.0}
          </Typography>
        </Box>
        <Box className="betslip-details">
          <Typography className="details-text">Potential returns:</Typography>
          <Typography className="details-text">
            {potentialReturn ? formatter.format(potentialReturn) : 0.0}
          </Typography>
        </Box>
      </>
    );
  };
  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const oddsicon = BookKeeperId => {
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    return (
      <img
        className="bookmaker-thumb"
        src={
          iconData?.small_logo?.includes("uploads")
            ? Config.mediaURL + iconData?.small_logo
            : iconData?.small_logo
        }
        alt="Odds Icon"
      />
    );
  };
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  const collapseStyles = {
    transformOrigin: "top right", // Set the transformation origin to top right
    transform: `scaleX(${BetSlipToggleData ? 1 : 0})`, // Scale the element in X-axis to 1 (normal size) when open, 0 when closed
    transition: "transform 0.3s ease-in-out" // Add a transition for a smooth animation
  };

  const handleshareBetSlipNavigate = id => {
    navigate(`/social-betslipshare?cartKey=${id}`);
    dispatch(fetchBetSlipShareToggle(true));
    if (screenWidth < 799) {
      dispatch(fetchBetSlipToggle(false));
      document.body.classList.remove("no-scroll");
    }
  };
  const pageTopRef = useRef(null);

  // const scrollToTop = () => {
  //   if (pageTopRef.current) {
  //     pageTopRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY;
    const scrollThreshold = 200;
    if (scrollY > scrollThreshold) {
      setprogress(true);
    } else {
      setprogress(false);
    }
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleURLnavigate = data => {
    if (data?.url) {
      window.open(data.url + "?Referrer=SmartB", "_blank");
    } else {
      let bookkeeperDataID = BookkeeperData?.filter(
        obj => obj?.BookKeeperId === data?.BookKeeperId
      );
      let logoLink = bookkeeperDataID?.[0]?.BookKeeper;
      window.open(logoLink?.affiliate_link, "_blank");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const tokenAuth =
        redux_auth && redux_auth !== ""
          ? redux_auth
          : fetchFromStorage("auth_token");
      tokenAuth && fetchSubscriptionPaymentData();
    }, 2000);
  }, []);

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };

  useEffect(() => {
    let SportId =
      params?.type === "cricket"
        ? 4
        : params?.type === "rugbyleague"
        ? 12
        : params?.type === "rugbyunion"
        ? 13
        : params?.type === "basketball"
        ? 10
        : params?.type === "americanfootball"
        ? 15
        : params?.type === "australianrules"
        ? 9
        : params?.type === "golf"
        ? 16
        : params?.type === "tennis"
        ? 7
        : params?.type === "baseball"
        ? 11
        : params?.type === "icehockey"
        ? 17
        : params?.type === "boxing"
        ? 6
        : params?.type === "mma"
        ? 5
        : params?.type === "soccer"
        ? 8
        : "";
    if (Config.release == "IN" && (SportId || Number(params?.sportId))) {
      if (
        release[Config.release]?.sportId?.includes(SportId) ||
        release[Config.release]?.sportId?.includes(Number(params?.sportId))
      ) {
      } else {
        navigate("/");
      }
    }
  }, []);
  return (
    <>
      {racingOddsComparison ? (
        <HederOddsComp />
      ) : advertisingScreen ? (
        <AdvertisingScreenPage />
      ) : restrictedScreen ? (
        <GeofencingPage />
      ) : maintenanceScreen ? (
        <MaintenancePage />
      ) : (
        <Box className="wrapper" ref={pageTopRef}>
          {/* <a
            href="mailto:info@smartb.com.au"
            className="top-header-banner-wrap"
          >
            <img
              className="top-header-banner"
              src={TopHeaderBanner}
              alt="banner"
            />
          </a> */}

          <Box className="Main-Wrap">
            <Box className="container">
              {/* <a
                href="mailto:info@smartb.com.au"
                className="side-bg-banner left-bg-banner"
              >
                <img src={SideBgBanner} alt="banner" />
              </a> */}
              {fetchSideBgAds("left-bg-banner")}
              {fetchSideBgAds("right-bg-banner")}
              <Box className="external-wrapper" id="external-wrapper">
                {screenWidth >= 800 ? fetchTopheaderAds() : ""}
                <MainMenubar
                  auth={auth}
                  user_statics={user_statics}
                  organisationData={organisationData}
                  verify_count={verify_count}
                  onMobileNavOpen={() => setMobileNavOpen(true)}
                  tooltipStep={tooltipStep}
                />

                <Box
                  // className={
                  //   !isHome || BetSlipToggleData
                  //     ? "layout-wrapper"
                  //     : "full-layout layout-wrapper"
                  // }
                  className={
                    !isHome ? "layout-wrapper" : "full-layout layout-wrapper"
                  }
                >
                  <Box id="left-sidebar">
                    <div className="left-sidebarcontent">
                      <LeftSideBar />
                    </div>
                  </Box>
                  <Box className="content">
                    <Box className="inner-content" id="inner-content">
                      <Outlet />
                    </Box>
                    <Box className="Footer">
                      <Footer />
                    </Box>
                  </Box>
                  <Box id="right-sidebar">
                    {!isHome ? (
                      <Box className="right-sidebarcontent">
                        <RightSideBar />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Collapse
                    in={BetSlipToggleData}
                    className="betslip-collapse"
                    style={screenWidth < 799 ? collapseStyles : ""}
                  >
                    <Box className="bet-slip-wrap">
                      <Box
                        className="bet-slip-header"
                        onClick={() => handleBetSlipClose()}
                      >
                        <Typography className="bet-slip-text">
                          <BetSlipArrow />
                          {localesData?.BET_SLIP?.BET_SLIP_HEADER}
                        </Typography>
                        <Notepad />
                      </Box>
                      {betSlipData?.length > 0 ? (
                        <>
                          <Box className="bet-slip-data">
                            {betSlipData?.map(item => {
                              return (
                                <>
                                  <Box className="bet-slip-single-data">
                                    <Box className="bet-slip-header-details">
                                      <Box className="bet-slip-details">
                                        {item?.sportId === 1 ||
                                        item?.sportId === 2 ||
                                        item?.sportId === 3 ? (
                                          <>
                                            <Box className="sport-img">
                                              <img
                                                src={raceIcon(item?.sportId)}
                                                alt="icon"
                                              />
                                            </Box>
                                            <Box className="text-details">
                                              <Typography className="bet-slip-text mb-4 dark-text">
                                                {item?.runnerNumber}
                                                {"."} {item?.teamName} (
                                                {item?.barrierNumber})
                                              </Typography>
                                              <Typography className="bet-slip-text mb-4">
                                                {"R" + item?.raceNumber}{" "}
                                                {item?.trackName}
                                              </Typography>
                                              <Typography className="bet-slip-text">
                                                <span>
                                                  {" "}
                                                  {moment
                                                    .utc(item?.eventTime)
                                                    .local()
                                                    .format("DD MMM YYYY")}{" "}
                                                </span>
                                                |
                                                <span>
                                                  {" "}
                                                  {moment
                                                    .utc(item?.eventTime)
                                                    .local()
                                                    .format("hh:mm a")}
                                                </span>
                                              </Typography>
                                            </Box>
                                          </>
                                        ) : (
                                          <>
                                            <Box className="sport-img">
                                              {raceIcon(item?.sportId)}
                                            </Box>
                                            <Box className="text-details">
                                              <Typography className="bet-slip-text mb-4 dark-text">
                                                {item?.teamName}
                                              </Typography>
                                              <Typography className="bet-slip-text mb-4">
                                                {item?.eventName}
                                              </Typography>
                                              <Typography className="bet-slip-text">
                                                <span>
                                                  {" "}
                                                  {moment
                                                    .utc(item?.eventTime)
                                                    .local()
                                                    .format("DD MMM YYYY")}{" "}
                                                </span>
                                                |
                                                <span>
                                                  {" "}
                                                  {moment
                                                    .utc(item?.eventTime)
                                                    .local()
                                                    .format("hh:mm a")}
                                                </span>
                                              </Typography>
                                            </Box>
                                          </>
                                        )}
                                      </Box>
                                      <Box
                                        className="delete-box cursor-pointer"
                                        onClick={() =>
                                          handleDeleteBetslip(item)
                                        }
                                      >
                                        <Delete />
                                      </Box>
                                    </Box>
                                    <Box
                                      className={
                                        item?.sportId === 1 ||
                                        item?.sportId === 2 ||
                                        item?.sportId === 3
                                          ? "stake-details"
                                          : "stake-details teamsports-stake-details"
                                      }
                                    >
                                      {item?.sportId === 1 ||
                                      item?.sportId === 2 ||
                                      item?.sportId === 3 ? (
                                        <>
                                          <Select
                                            className="React fixed-select"
                                            // value={providerOption?.find(item => {
                                            //   return item?.value === selectedGraphId;
                                            // })}
                                            onChange={e =>
                                              handleSelectedMarketChange(
                                                e?.value,
                                                item
                                              )
                                            }
                                            options={options}
                                            // isOptionDisabled={option => option.disabled}
                                            classNamePrefix="select"
                                            placeholder="Win Fixed"
                                            isSearchable={false}
                                            components={{ DropdownIndicator }}
                                          />
                                          <Box className="stake-field">
                                            <Typography className="stake">
                                              Stake
                                            </Typography>
                                            <TextField
                                              id="standard-start-adornment"
                                              className="stake-input-field"
                                              InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    $
                                                  </InputAdornment>
                                                )
                                              }}
                                              value={
                                                item?.stack ? item?.stack : null
                                              }
                                              // onChange={(e)=>{}}
                                              onChange={e =>
                                                handleStackChange(
                                                  e.target.value,
                                                  item
                                                )
                                              }
                                              onBlur={e =>
                                                handleUpdateBetslip(
                                                  item,
                                                  e.target.value
                                                )
                                              }
                                              onKeyPress={e => {
                                                if (!/[0-9]/.test(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                              inputProps={{ maxLength: 7 }}
                                            />
                                          </Box>
                                        </>
                                      ) : (
                                        <Box className="stake-field teamsport-stake-field">
                                          <Typography className="stake">
                                            Stake
                                          </Typography>
                                          <TextField
                                            id="standard-start-adornment"
                                            className="stake-input-field"
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  $
                                                </InputAdornment>
                                              )
                                            }}
                                            value={
                                              item?.stack ? item?.stack : null
                                            }
                                            onChange={e =>
                                              handleStackChange(
                                                e.target.value,
                                                item
                                              )
                                            }
                                            onBlur={e =>
                                              handleUpdateBetslip(
                                                item,
                                                e.target.value
                                              )
                                            }
                                            inputProps={{ maxLength: 7 }}
                                            onKeyPress={e => {
                                              if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                    <Box className="odds-returns-wrap">
                                      <Box className="odds-returns-top">
                                        <Typography className="odds-wrap odds-returns-text">
                                          Odd
                                        </Typography>
                                        <Typography className="returns-wrap odds-returns-text">
                                          Potential Returns
                                        </Typography>
                                      </Box>
                                      <Box className="odds-returns-bottom">
                                        <Box className="left-wrap">
                                          {oddsicon(item?.BookKeeperId)}
                                          <Typography className="betslip-odds">
                                            {item?.selectedMarket === 1
                                              ? item?.odd
                                              : item?.oddPlace}
                                          </Typography>
                                        </Box>
                                        <Box className="right-wrap">
                                          <Typography>
                                            {item?.returns
                                              ? formatter?.format(
                                                  item?.returns?.toFixed(2)
                                                )
                                              : "N/A"}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="bet-now-btn">
                                      <Button
                                        variant="contained"
                                        className="button-bet-now"
                                        onClick={() => handleURLnavigate(item)}
                                      >
                                        Bet Now
                                      </Button>
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                          </Box>
                          <Box className="betslip-total">
                            <Box className="share-betslip">
                              <Typography
                                className="share-deatils"
                                onClick={() =>
                                  handleshareBetSlipNavigate(cartKey)
                                }
                              >
                                Share Betslip <ShareSlip />
                              </Typography>
                            </Box>
                            <Box className="betslip-total-deatils">
                              {handleTotalData()}

                              <Box
                                className="clear-btn"
                                onClick={() => handleClearAll()}
                              >
                                <Button className="clear-bet-btn">
                                  Clear bets
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box className="bet-slip-empty">
                          <img src={NotepadBig} alt="notpad" />
                          <Typography className="bet-slip-empty-header">
                            Your Bet slip is empty.
                          </Typography>
                          <Typography className="bet-slip-empty-header empty-text">
                            Start placing your bets on today's races and sports
                            events.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            </Box>
            <DialogBox
              open={open}
              onClose={handleClose}
              handleClose={handleClose}
              title={false}
              content={
                <>
                  <Box className="delete-dialogbox">
                    <Typography className="restriction-title">
                      Log In Required
                    </Typography>
                    <Typography className="restriction-text">
                      Please log in with your SmartB account for unrestricted
                      access.
                    </Typography>

                    <Button
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className="login-button"
                      onClick={() => {
                        navigate("/sign-in");
                        dispatch(fetchRestrictedRoute(false));
                      }}
                    >
                      Log In
                    </Button>
                    <Box>
                      {" "}
                      <span className="dont-account-text">
                        Don't have an account?
                      </span>{" "}
                      <span
                        className="signup-text"
                        onClick={() => {
                          navigate("/sign-up");
                          dispatch(fetchRestrictedRoute(false));
                        }}
                      >
                        Sign Up Now
                      </span>
                    </Box>
                  </Box>
                </>
              }
              closeIcon={true}
            />
          </Box>
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Box style={{ display: progress ? "block" : "none" }}>
            <Box className="arrow-up" onClick={() => scrollToTop()}>
              <ArrowUpwardIcon />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DashboardLayout;
