import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tabs,
  Tab,
  Checkbox,
  FormControl,
  Grid,
} from "@material-ui/core";
import { IntlContext } from "src/App";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import "./racing.scss";
import Horse from "../../../../assets/images/dark_horse.svg";
import Greys from "../../../../assets/images/dar-greyhound.svg";
import Harnes from "../../../../assets/images/dark_harness.svg";
import { ReactComponent as Unchecked } from "../../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../../assets/images/icons/smartb-check.svg";
import RacingListeView from "./racingList/racingList";
import OurPartners from "../../ourPartners";
import RacingAboveOurPartnerBanner from "../../../../assets/images/ad-placeholder/sports4.webp";
import RacingBelowOurPartnerBanner from "../../../../assets/images/ad-placeholder/sports5.webp";
// import { collapseToast } from "react-toastify";
import DateFnsUtils from "@date-io/date-fns";
import { parseISO } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import RacingFuturesList from "./racingList/racingFuturesList";
import AdBannner from "../../AdBanner/AdBanner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ScriptLoader from "../../scriptLoader/ScriptLoader";
import { Helmet } from "react-helmet";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const day = [
  {
    id: 0,
    name: "Yesterday",
    date: moment
      .tz(moment(), timezone)
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .subtract(1, "days")
      .format("dddd"),
  },
  {
    id: 1,
    name: "Today",
    date: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).format("dddd"),
  },
  {
    id: 2,
    name: "Tomorrow",
    date: moment
      .tz(moment(), timezone)
      .add(1, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(1, "days")
      .format("dddd"),
  },
  {
    id: 3,
    name: "Wednesday",
    date: moment
      .tz(moment(), timezone)
      .add(2, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(2, "days")
      .format("dddd"),
  },
  {
    id: 4,
    name: "Thursday",
    date: moment
      .tz(moment(), timezone)
      .add(3, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(3, "days")
      .format("dddd"),
  },
  {
    id: 5,
    name: "Friday",
    date: moment
      .tz(moment(), timezone)
      .add(4, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(4, "days")
      .format("dddd"),
  },
  {
    id: 6,
    name: "Saturday",
    date: moment
      .tz(moment(), timezone)
      .add(5, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(5, "days")
      .format("dddd"),
  },
  {
    id: 7,
    name: "Futures",
    // dayName: "Futures",
    date: moment
      .tz(moment(), timezone)
      .add(1, "days")
      .format("YYYY-MM-DD"),
  },
  {
    id: 8,
    name: "Archive",
    // dayName: "Archive",
    date: moment
      .tz(moment(), timezone)
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
  },
];

// const useStyles = makeStyles({
//   root: {
//     ".MuiTab-root": {
//       minWidth: "120px",
//     },
//   },
// });

const Racing = () => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  let searchParamsType = query.get("type");
  const [filterRaceType, setfilterRaceType] = useState([
    {
      id: 1,
      Racevalue: "Horse Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Horse} alt="horse" />
        </span>
      ),
    },
    {
      id: 3,
      Racevalue: "Greyhound Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Greys} alt="greyhound" />
        </span>
      ),
    },
    {
      id: 2,
      Racevalue: "Harness Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Harnes} alt="harness" />
        </span>
      ),
    },
  ]);
  const [filterCountry, setfilterCountry] = useState([
    {
      id: 1,
      name: "Aus/NZ",
      value: "Aus/NZ",
      isChecked: true,
    },
    {
      id: 2,
      name: "Int'l",
      value: "Intl",
      isChecked: true,
    },
  ]);
  // const classes = useStyles();
  const [value, setValue] = useState(1);
  const [selectedDate, setselectedDate] = useState(
    moment.tz(moment(), timezone).format("YYYY-MM-DD")
  );
  const [collepseRace, setcollepseRace] = useState(
    searchParamsType
      ? searchParamsType?.split(",")?.map((item) => {
        return Number(item);
      })
      : [1, 2, 3]
  );
  const [collepseCountry, setcollepseCountry] = useState(["Aus/NZ", "Intl"]);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [open, setOpen] = useState(false);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    setcollepseRace(
      searchParamsType
        ? searchParamsType?.split(",")?.map((item) => {
          return Number(item);
        })
        : [1, 2, 3]
    );
  }, [searchParamsType]);

  useEffect(() => {
    fetchAdsData();
  }, []);
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const handleDateChange = (date) => {
    setselectedDate(date ? moment(date).format("YYYY-MM-DD") : null);
  };

  // if (value == "7" || value == "8") {
  //   navigate("/");
  // } else {

  // Change Day Tab
  const handleChange = (event, value) => {
    // if (value == "7") {
    //   // navigate("/");
    // } else {
    setValue(value);
    let SelectDate = day?.filter((item) => {
      return item.id === value;
    });
    setselectedDate(
      SelectDate?.map((item) => {
        return item?.date;
      })?.[0]
    );
    // }
  };

  // Change Racing Filter

  const ChangeRaceFilter = (event, item) => {
    const flag = event.target.checked;

    if (flag) {
      const newItem = [...collepseRace, item];
      setcollepseRace(newItem);
      navigate(`/racing?type=${newItem}`);
    } else {
      if (collepseRace?.length >= 2) {
        const removeItem = collepseRace?.filter((eItem) => eItem !== item);
        navigate(`/racing?type=${removeItem}`);
        setcollepseRace(removeItem);
      }
    }
  };

  // Change Timezone Filter
  const ChangeCountryFilter = (event, item) => {
    const flag = event.target.checked;
    if (flag) {
      const newItem = [...collepseCountry, item];
      setcollepseCountry(newItem);
    } else {
      if (collepseCountry?.length >= 2) {
        const removeItem = collepseCountry?.filter((eItem) => eItem !== item);
        setcollepseCountry(removeItem);
      }
    }
  };

  const Today = moment.tz(moment(), timezone).format("YYYY-MM-DD");
  const Tommorow = moment
    .tz(moment(), timezone)
    .add(1, "days")
    .format("YYYY-MM-DD");
  const yesterDay = moment
    .tz(moment(), timezone)
    .subtract(1, "days")
    .format("YYYY-MM-DD");

  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) { }
  // };

  const fetchAdsData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=2&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) { }
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.page_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  const clearDate = () => {
    setselectedDate(null);
    setOpen(false);
  };
  return (
    <>
      {searchParamsType == 1 ? (
        <Helmet>
          <title>SmartB | Covering Different Types of Racing Details</title>
          <meta
            name="description"
            content="Discover updated racing news on SmartB. Here in this page, you can check all the latest racing information you need. Learn more."
          />
        </Helmet>
      ) : searchParamsType == 2 ? (
        <Helmet>
          <title>SmartB | Covering More Racing Details Here</title>
          <meta
            name="description"
            content="Find the most recent racing news on SmartB. You can find all the most recent racing information you require right here on one website."
          />
        </Helmet>
      ) : searchParamsType == 3 ? (
        <Helmet>
          <title>SmartB | Covering Different Types of Racing Details</title>
          <meta
            name="description"
            content="On SmartB, you can get the latest racing news. Find all the most recent race information you need for winning decisions. Learn more. "
          />
        </Helmet>
      ) : (
        ""
      )}
      {/* <a href="mailto:info@smartb.com.au">
        <img src={banner} alt="banner" width="100%" />
      </a> */}
      {/* {fetchAds(1, "218px", banner)} */}
      {/* {fetchPageHeaderAds("218px", banner)} */}
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="banner-img-fix">
        {fetchPageHeaderAds("218px", banner)}
      </Box>
      <Box className="allracing-wrap ">
        <Box className="side-space">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                {localesData?.MENU?.HOME}
              </Link>
              {searchParamsType == 1 ||
                searchParamsType == 3 ||
                searchParamsType == 2 ? (
                <Link underline="hover" color="inherit" to="/racing">
                  {localesData?.MENU?.RACING}
                </Link>
              ) : (
                <Typography>{localesData?.MENU?.RACING}</Typography>
              )}
              {searchParamsType == 1 ||
                searchParamsType == 3 ||
                searchParamsType == 2 ? (
                <Typography>
                  {searchParamsType == 1
                    ? "Horse Racing"
                    : searchParamsType == 3
                      ? "Greyhound Racing"
                      : searchParamsType == 2
                        ? "Harness Racing"
                        : ""}
                </Typography>
              ) : (
                ""
              )}
            </Breadcrumbs>
          </Box>
          <Typography variant="h1">{localesData?.MENU?.RACING}</Typography>
        </Box>
        <Box className="sport-tab">
          <Tabs
            value={value}
            variant="scrollable"
            scrollButtons="off"
            indicatorColor="primary"
            textColor="primary"
            className="racing-tab-detail"
          >
            {day?.map((item, index) => {
              return (
                <Box key={index}>
                  <Tab
                    disableRipple
                    disableFocusRipple
                    label={
                      item?.date == Today
                        ? "Today"
                        : item?.name == "Archive"
                          ? "Archive"
                          : item?.name == "Futures"
                            ? "Futures"
                            : item?.date == Tommorow
                              ? "Tommorow"
                              : item?.date == yesterDay
                                ? "Yesterday"
                                : item?.dayName
                    }
                    value={item?.id}
                    className={item?.id == value ? "active" : ""}
                    onChange={(event, newValue) =>
                      handleChange(event, item?.id)
                    }
                  />
                </Box>
              );
            })}
          </Tabs>
          <Box className="Filteritemlist-wrap">
            <Box className="filter-list-racing">
              <ul className="Filteritemlist-racing">
                {filterRaceType?.length > 0 &&
                  filterRaceType?.map((filter, i) => (
                    <li key={i}>
                      <FormControl>
                        <label>
                          <Checkbox
                            className="filter-racing"
                            icon={<Unchecked />}
                            checkedIcon={<Checked />}
                            name="filter"
                            value={filter?.id}
                            onChange={(event) => {
                              ChangeRaceFilter(event, filter?.id);
                            }}
                            checked={collepseRace.includes(filter?.id)}
                          />
                          {filter?.icon}
                        </label>
                      </FormControl>
                    </li>
                  ))}
              </ul>

              <ul className="Filteritemlist-racing">
                {filterCountry?.length > 0 &&
                  filterCountry?.map((countryItem, i) => (
                    <li key={i}>
                      <label>
                        <Checkbox
                          className="filter-country"
                          icon={<Unchecked />}
                          checkedIcon={<Checked />}
                          name="filtercountry"
                          value={countryItem?.value}
                          onChange={(event) => {
                            ChangeCountryFilter(event, countryItem?.value);
                          }}
                          checked={collepseCountry?.includes(
                            countryItem?.value
                          )}
                        />

                        {countryItem?.name}
                      </label>
                    </li>
                  ))}
              </ul>
            </Box>
            { }
            {value == 8 || value == 7 ? (
              <Box className="Filteritemlist-datepicker">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container style={{ justifyContent: "end" }}>
                    <KeyboardDatePicker
                      autoOk
                      clearable
                      // disableToolbar
                      // variant="inline"
                      format="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      margin="normal"
                      id="date-picker-inline"
                      inputVariant="outlined"
                      value={selectedDate ? parseISO(selectedDate) : null}
                      onClear={clearDate}
                      onChange={(e) => handleDateChange(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      views={["year", "month", "date"]}
                      disableFuture={value == 8}
                      disablePast={value == 7}
                      // style={{ marginRight: 5 }}
                      className="details-search-picker"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
        <Box className="racing-type-wrap">
          <RacingListeView
            selectedDate={selectedDate}
            filterRaceType={collepseRace}
            filterCountry={collepseCountry}
            fetchAds={fetchAds}
          />
        </Box>
        {/* {value == 7 ? (
          <Box className="racing-type-wrap">
            <RacingFuturesList />
          </Box>
        ) : (
          <>
            {" "}
            <Box className="racing-type-wrap">
              <RacingListeView
                selectedDate={selectedDate}
                filterRaceType={collepseRace}
                filterCountry={collepseCountry}
              />
            </Box>
            <Box className="track-info">
              <ul>
                <li>
                  <img src={FixedMark} alt="fixed-indicator" /> {localesData?.RACING?.FIXED_OODS_OFFERED}
                </li>
                <li>
                  <img src={Close} alt="close-icon" /> -5 {localesData?.RACING?.MINS_TO_JUMP}
                </li>
                <li>
                  <img src={CloseSecondary} alt="close-icon" /> -30 {localesData?.RACING?.MINS_TO_JUMP}
                </li>
                <li>
                  <img src={Interim} alt="interim" /> {localesData?.RACING?.INTERIM}
                </li>
                <li>
                  <img src={Paying} alt="paying" /> {localesData?.RACING?.PAYING}
                </li>
              </ul>
            </Box>
          </>
        )} */}
        {collepseRace?.includes(2) && fetchAds(4, "102px", RacingAboveOurPartnerBanner)}
        <OurPartners />
        {fetchAds(5, "102px", RacingBelowOurPartnerBanner)}
      </Box>
    </>
  );
};

export default Racing;
