import React, { useState, useContext, useEffect } from "react";
// import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import "../profile.scss";
import Select from "react-select";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import differenceInYears from "date-fns/differenceInYears";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import Loader from "../../../../components/Loader";
import { ReactComponent as BackArrow } from "../../../../assets/images/Back_Arrow.svg";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { Config } from "src/helpers/context";

const titleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Ms", label: "Ms" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" }
];

const hearAboutList = [
  { id: 1, name: "Google" },
  { id: 2, name: "Radio" },
  { id: 3, name: "Social Media" },
  { id: 4, name: "Friend" },
  { id: 5, name: "Subversion" },
  { id: 6, name: "Other" }
];

function UserEditDetails(data) {
  let dispatch = useDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [newData, setnewData] = useState(data?.data);
  const [formvalues, setformvalues] = useState();
  const [countryAll, setCountryAll] = useState([]);
  const [country, setcountry] = useState(null);
  const [stateAll, setStateAll] = useState([]);
  const [count, setcount] = useState(0);
  const [statecount, setstatecount] = useState(0);
  const [pageCountry, setpageCountry] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [state, setstate] = useState("");
  const [searchCountryCount, setsearchCountryCount] = useState(0);
  const [searchCountry, setsearchCountry] = useState([]);
  const [isCountrySearch, setisCountrySearch] = useState("");
  const [SearchCountrypage, setSearchCountrypage] = useState(0);
  const [searchStateCount, setsearchStateCount] = useState(0);
  const [searchState, setsearchState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStatePage, setsearchStatePage] = useState(0);
  const [error, seterror] = useState(false);
  const [errorCountry, seterrorCountry] = useState("");
  const [errorState, seterrorState] = useState("");
  const [isCountrySelectOpen, setisCountrySelectOpen] = useState(false);
  const [countryApiCount, setCountryApiCount] = useState(0);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [stateApiCount, setStateApiCount] = useState(-1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updatedHearAbout = newData?.hearedAbout
    ? newData?.hearedAbout?.map(item => {
        if (item?.includes("other")) {
          return `Other`;
        }
        return item;
      })
    : [];
  const [hearAboutData, setHearAboutData] = useState(updatedHearAbout);
  const updatedHearAboutOther = newData?.hearedAbout?.filter(item =>
    item?.includes("other")
  );
  const [hearAboutOther, sethearAboutOther] = useState(
    updatedHearAboutOther?.[0]?.split(":")[1]
  );
  const handleHearAboutOther = e => {
    sethearAboutOther(e.target.value);
  };
  useEffect(() => {
    let seletedCountry = [
      {
        label: newData?.address?.Country?.country,
        value: newData?.address?.country
      }
    ];
    let selectedState = [
      {
        label: newData?.address?.State?.state,
        value: newData?.address?.state
      }
    ];
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    setCountryAll(newData?.address?.country ? seletedCountry : countryAll);
    setcountry(newData?.address?.country ? newData?.address?.country : "");
    setstate(newData?.address?.state ? newData?.address?.state : "");
    setStateAll(newData?.address?.state ? selectedState : stateAll);
    fetchCountry(0, seletedCountry);
    fetchState(
      newData?.address?.country,
      0,
      false,
      selectedState,
      stateApiCount
    );
  }, []);

  const fetchCountry = async (page, countryData) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        // let track = data?.result?.rows?.map(item => {
        //   newdata.push({
        //     label: item?.country,
        //     value: item?.id
        //   });
        // });

        const topCountries = [
          {
            label: "Australia",
            value: 13
          },
          {
            label: "United Kingdom",
            value: 230
          },
          {
            label: "United States",
            value: 231
          },
          {
            label: "India",
            value: 101
          }
        ];

        const nonPrioritizedCountries = data?.result?.rows?.filter(
          country => !topCountries?.includes(country?.value)
        );

        let track = nonPrioritizedCountries?.map(item => {
          newdata.push({
            label: item?.country,
            value: item?.id
          });
        });

        // let filterSelectedCountry = topCountries?.filter(
        //   item => countryData[0]?.value !== item?.value
        // );

        // let orderedPriorityCountries = _.unionBy(filterSelectedCountry, newdata);

        // let orderedCountries = newdata;
        // setCountryAll(
        //   _.unionBy(
        //     country,
        //     page == 0 ? orderedPriorityCountries : orderedCountries
        //   )
        // );
        // let finalNewData = page === 0 ? orderedPriorityCountries : newdata;
        if (countryData) {
          // setCountryAll(_.unionBy(countryAll, countryData, newdata));
          let filterData = _.unionBy(countryAll, countryData, newdata)?.sort(
            (a, b) => {
              return a?.label.localeCompare(b?.label);
            }
          );
          const finalArray = filterData?.unshift(...topCountries);

          setCountryAll(
            _.uniqBy(filterData, function(e) {
              return e.value;
            })
          );
        } else {
          // setCountryAll(_.unionBy(countryAll, newdata));
          let filterData = _.unionBy(countryAll, newdata)?.sort((a, b) => {
            return a?.label.localeCompare(b?.label);
          });
          const finalArray = filterData?.unshift(...topCountries);
          setCountryAll(
            _.uniqBy(filterData, function(e) {
              return e.value;
            })
          );
        }
        // let filterData = _.unionBy(countryAll, newdata);
        // setCountryAll(
        //   _.uniqBy(filterData, function(e) {
        //     return e.value;
        //   })
        // );
        // if (countryData) {
        //   // setCountryAll(_.unionBy(countryAll, countryData, newdata));
        //   let filterData = _.unionBy(countryAll, countryData, newdata);

        //   setCountryAll(
        //     _.uniqBy(filterData, function(e) {
        //       return e.value;
        //     })
        //   );
        // } else {
        //   // setCountryAll(_.unionBy(countryAll, newdata));
        //   let filterData = _.unionBy(countryAll, newdata);
        //   setCountryAll(
        //     _.uniqBy(filterData, function(e) {
        //       return e.value;
        //     })
        //   );
        // }
      }
    } catch (err) {}
  };

  const fetchState = async (id, page, type, stateData, statecount) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${id}?limit=20&offset=${page}`
      );
      if (status === 200) {
        setpageState(page);
        setStateApiCount(statecount + 1);
        setstatecount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.state,
            value: item?.id
          });
        });
        if (stateData) {
          // setStateAll(_.unionBy(stateAll, stateData, newdata));
          let filterData = _.unionBy(stateAll, stateData, newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setStateAll(
            _.uniqBy(sortData, function(e) {
              return e.value;
            })
          );
        } else {
          if (type) {
            // setStateAll(_.unionBy(newdata));
            let filterData = _.unionBy(newdata);
            let sortData = filterData?.sort((a, b) => {
              return a.label > b.label ? 1 : -1;
            });
            setStateAll(
              _.uniqBy(sortData, function(e) {
                return e.value;
              })
            );
          } else {
            // setStateAll(_.unionBy(stateAll, newdata));
            let filterData = _.unionBy(stateAll, newdata);
            let sortData = filterData?.sort((a, b) => {
              return a.label > b.label ? 1 : -1;
            });
            setStateAll(
              _.uniqBy(sortData, function(e) {
                return e.value;
              })
            );
          }
        }
      }
    } catch (err) {}
  };
  const handleOnScrollBottomCountry = () => {
    if (
      isCountrySearch !== "" &&
      searchCountryCount !== Math.ceil(SearchCountrypage / 20)
    ) {
      handleCountryInputChange(SearchCountrypage + 20, isCountrySearch);

      setSearchCountrypage(SearchCountrypage + 20);
    } else {
      if (count !== Math.ceil(pageCountry / 20)) {
        fetchCountry(pageCountry + 20);
        setpageCountry(pageCountry + 20);
      }
    }
  };
  const handleOnScrollBottomState = () => {
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(searchStatePage / 20)
    ) {
      handleStateInputChange(searchStatePage + 20, isStateSearch);

      setsearchStatePage(searchStatePage + 20);
    } else {
      if (statecount !== Math.ceil(pageState / 20)) {
        fetchState(country, pageState + 20, false, null, stateApiCount);
        setpageState(pageState + 20);
      }
    }
  };

  const handleCountryInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.country,
              value: item?.id
            });
          });
          let filterData = _.unionBy(searchCountry, newdata);
          setsearchCountry(
            _.uniqBy(filterData, function(e) {
              return e.value;
            })
          );
          setisCountrySearch(value);
        }
      });
  };

  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${country}?limit=20&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;

          setsearchStateCount(Math.ceil(res?.data?.result?.count / 20));

          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.state,
              value: item?.id
            });
          });
          const finalStateData = newdata.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          let filterData = _.unionBy(searchState, finalStateData);
          setsearchState(
            _.uniqBy(filterData, function(e) {
              return e.value;
            })
          );
          setisStateSearch(value);
        }
      });
  };
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountrySelectOpen) {
          if (isCountrySearch === "" && countryApiCount !== count) {
            fetchCountry(pageCountry + 20);
            setpageCountry(pageCountry + 20);
          }
        }
      }, 2000);
    }
  }, [isCountrySelectOpen, countryApiCount, isCountrySearch]);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && stateApiCount !== statecount) {
            fetchState(country, pageState + 20, false, null, stateApiCount);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);
  const validate = () => {
    let flag = true;

    if (country === "") {
      flag = false;
      seterror(true);
      seterrorCountry("This field is required");
      isLoading(false);
    } else if (state === "") {
      flag = false;
      seterror(true);
      seterrorState("This field is required");
      isLoading(false);
    } else {
      flag = true;
      seterror(false);
      seterrorCountry("");
      seterrorState("");
    }

    return flag;
  };

  const fetchSubscriptionPaymentData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/user/get-user`);
      if (status === 200) {
        if (Config.release == "IN") {
          data["oddfixtures"] = false;
        }
        dispatch(fetchSubscriptionData(data));
      } else {
        dispatch(fetchSubscriptionData(undefined));
      }
    } catch (err) {
      dispatch(fetchSubscriptionData(undefined));
    }
  };

  const handleSave = async () => {
    const EditHearAbout = hearAboutData?.map(item => {
      if (item === "Other") {
        return `other:${hearAboutOther ? hearAboutOther : ""}`;
      }
      return item;
    });
    setLoading(true);
    const payload = {
      titlePrefix: formvalues?.title,
      firstName: formvalues?.firstname,
      lastName: formvalues?.lastname,
      dob: formvalues?.dob,
      address: {
        country: country,
        state: state == "" ? null : state
      },
      phone: formvalues?.phonenumber,
      bookMaker: newData?.bookMaker,
      bookMakersOther: newData?.bookMakerOther,
      sportOrEvent: newData?.sportOrEvent,
      sportOrEventOther: newData?.sportOrEventOther,
      offerings: newData?.offerings,
      offeringsOther: newData?.offeringsOther,
      // username: formvalues?.email
      hearedAbout: EditHearAbout
      // wpUserName: formvalues?.wpUserName ? formvalues?.wpUserName : null
    };
    try {
      if (validate()) {
        const { status, data } = await axiosInstance.put(
          "user/editProfile",
          payload
        );
        if (status === 200) {
          // setuserData(data);
          setLoading(false);
          navigate("/profile");
          fetchSubscriptionPaymentData();
        } else if (status === 403) {
          setLoading(false);
          localStorage.removeItem("auth_token");
          navigate("/sign-in");
          dispatch(loginUser(""));
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleOnChangeHearAbout = e => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...hearAboutData, value];
      setHearAboutData(checkboxdata);
    } else {
      let checkboxdata = hearAboutData?.filter(element => element !== value);
      setHearAboutData(checkboxdata);
    }
  };
  const handleBack = () => {
    navigate("/profile");
  };
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  return (
    <>
      <Formik
        initialValues={{
          title: newData?.titlePrefix,
          firstname: newData?.firstName,
          lastname: newData?.lastName,
          dob: newData?.dob,
          // countrys: newData?.address?.country,
          // states: newData?.address?.state,
          phonenumber: newData?.phone,
          email: newData?.username,
          hearAboutInfo:
            newData?.hearedAbout && newData?.hearedAbout?.length > 0
              ? newData?.hearedAbout
              : ""
          // wpUserName: newData?.wpUserName
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .nullable()
            .required(localesData?.validation?.required_message),
          firstname: Yup.string()
            .nullable()
            .required(localesData?.validation?.required_message)
            .matches(/^[A-Za-z]+$/, "Only letters are allowed for first name"),
          lastname: Yup.string()
            .nullable()
            .required(localesData?.validation?.required_message)
            .matches(/^[A-Za-z]+$/, "Only letters are allowed for last name"),
          dob: Yup.date()
            .nullable()
            .typeError("")
            .test("dob", "You have to be at least 18", function(value) {
              return differenceInYears(new Date(), new Date(value)) >= 18;
            })
            .required(localesData?.validation?.required_message),
          // countrys: Yup.string().required(
          //   localesData?.validation?.required_message
          // ),
          // states: Yup.string().required(
          //   localesData?.validation?.required_message
          // ),
          phonenumber: Yup.string()
            .nullable()
            .matches(phoneRegExp, localesData?.validation?.number_valid)
            .required(localesData?.validation?.required_message)
            .min(10, localesData?.validation?.NUMBER_MIN_MAX_LENGTH)
            .max(12, localesData?.validation?.NUMBER_MIN_MAX_LENGTH),
          email: Yup.string()
            .nullable()
            .email(localesData?.validation?.valid_email)
            .max(255)
            .required(localesData?.validation?.required_message)
          //  wpUserName: Yup.string()
        })}
        validate={values => {
          if (!values.hearAboutInfo.length) {
            return {
              hearAboutInfo: "Please Choose Atleast One"
            };
          }
          return {};
        }}
        onSubmit={() => handleSave()}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Box className="useredit-details" onClick={() => handleBack()}>
                <span>
                  <BackArrow className="back-arrow" />
                </span>
                <h4>{localesData?.EDITPROFILE?.EDIT_USER_DETAILS}</h4>
              </Box>
              {isLoading && (
                <div className="loader_center">
                  <Loader />
                </div>
              )}

              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.title}:
                </Typography>
                <Box className="title-select-wrap">
                  <Select
                    className="select select-title "
                    classNamePrefix="select"
                    placeholder="-Select-"
                    onChange={e => {
                      setFieldValue("title", e?.value);
                    }}
                    value={titleOptions?.find(item => {
                      return item?.value == values?.title;
                    })}
                    options={titleOptions}
                  />
                  <span className="text-danger">
                    {touched?.title && errors?.title ? errors?.title : ""}
                  </span>
                </Box>
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.first_name}:
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="details-textfield"
                  type="text"
                  name="firstname"
                  // value={firstName}
                  // onChange={e => setfirstName(e.target.value)}
                  error={Boolean(touched?.firstname && errors?.firstname)}
                  helperText={touched?.firstname ? errors?.firstname : ""}
                  onChange={handleChange}
                  value={values?.firstname}
                  onKeyDown={e => {
                    if (/[^a-zA-Z ]/g.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.last_name}:
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="details-textfield"
                  type="text"
                  name="lastname"
                  // value={lastName}
                  // onChange={e => setlastName(e.target.value)}
                  error={Boolean(touched?.lastname && errors?.lastname)}
                  helperText={touched?.lastname ? errors?.lastname : ""}
                  onChange={handleChange}
                  value={values?.lastname}
                  onKeyDown={e => {
                    if (/[^a-zA-Z ]/g.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.dob}:
                </Typography>
                <Box className="Filteritemlist-datepicker">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      placeholder="DD/MM//YYYY"
                      margin="normal"
                      id="date-picker-inline"
                      inputVariant="outlined"
                      // value={parseISO(selectedDate)}
                      // onChange={(e) => handleDateChange(e)}
                      value={values?.dob}
                      onChange={date => {
                        setFieldValue("dob", date);
                      }}
                      views={["year", "month", "date"]}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      className="datebirth-picker"
                    />
                  </MuiPickersUtilsProvider>
                  <span className="text-danger">
                    {touched?.dob && errors?.dob ? errors?.dob : ""}
                  </span>
                </Box>
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.country_of_residence}:
                </Typography>
                <Box>
                  <Select
                    className="select select-country-state"
                    classNamePrefix="select"
                    placeholder="-Select-"
                    // name="country"
                    // isSearchable={false}
                    onMenuScrollToBottom={e => handleOnScrollBottomCountry(e)}
                    onInputChange={e => handleCountryInputChange(0, e)}
                    onChange={e => {
                      setcountry(e?.value);
                      setStateAll([]);
                      setStateApiCount(0);
                      fetchState(e?.value, 0, true, null, 0);
                      setpageState(0);
                      setstate("");
                      setisCountrySelectOpen(false);
                    }}
                    onFocus={() => setisCountrySelectOpen(true)}
                    onBlur={() => setisCountrySelectOpen(false)}
                    value={
                      isCountrySearch
                        ? searchCountry?.find(item => {
                            return item?.value == country;
                          })
                        : countryAll?.find(item => {
                            return item?.value == country;
                          })
                    }
                    options={isCountrySearch ? searchCountry : countryAll}
                  />

                  <span className="text-danger">
                    {/* {touched?.countrys && errors?.countrys
                        ? errors?.countrys
                        : ""} */}
                    {errorCountry ? errorCountry : ""}
                  </span>
                </Box>
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.state}:{" "}
                </Typography>
                <Box>
                  <Select
                    className="select select-country-state"
                    classNamePrefix="select"
                    placeholder="-Select-"
                    // isSearchable={false}
                    onMenuScrollToBottom={e => handleOnScrollBottomState(e)}
                    onInputChange={e => handleStateInputChange(0, e)}
                    onChange={e => {
                      setstate(e?.value);
                      setisStateSelectOpen(false);
                      setStateApiCount(0);
                    }}
                    onFocus={() => setisStateSelectOpen(true)}
                    onBlur={() => setisStateSelectOpen(false)}
                    value={
                      isStateSearch
                        ? searchState?.find(item => {
                            return item?.value == state;
                          })
                        : state !== "" &&
                          stateAll?.find(item => {
                            return item?.value == state;
                          })
                    }
                    options={isStateSearch ? searchState : stateAll}
                  />

                  <span className="text-danger">
                    {/* {touched?.states && errors?.states ? errors?.states : ""} */}
                    {errorState ? errorState : ""}
                  </span>
                </Box>
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.phonenumber}:
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="details-textfield"
                  type="text"
                  name="phonenumber"
                  error={Boolean(touched?.phonenumber && errors?.phonenumber)}
                  helperText={touched?.phonenumber ? errors?.phonenumber : ""}
                  onChange={handleChange}
                  value={values?.phonenumber}
                />
              </Box>
              <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.email}:
                </Typography>
                <TextField
                  id="outlined-basic"
                  disabled
                  variant="outlined"
                  className="details-textfield"
                  type="text"
                  name="email"
                  error={Boolean(touched?.email && errors?.email)}
                  helperText={touched?.email ? errors?.email : ""}
                  onChange={handleChange}
                  value={values?.email}
                />
              </Box>
              {/* <Box className="details">
                <Typography className="textfield-text">
                  {localesData?.register?.WP_USER}:
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="details-textfield"
                  type="text"
                  name="wpUserName"
                  // value={firstName}
                  // onChange={e => setfirstName(e.target.value)}
                  // error={Boolean(touched?.firstname && errors?.firstname)}
                  // helperText={touched?.firstname ? errors?.firstname : ""}
                  onChange={handleChange}
                  value={values?.wpUserName}
                />
              </Box> */}
              <Box className="details hear-about-details">
                <Typography className="textfield-text">
                  {localesData?.register?.HEAR_ABOUT}
                </Typography>
                <Box className="checkbox-list">
                  <Box className="checkbox-list-1">
                    {hearAboutList?.map(element => (
                      <FormControlLabel
                        className="bookmakers-check"
                        key={element.id}
                        onChange={e => {
                          handleChange(e);
                          handleOnChangeHearAbout(e);
                        }}
                        control={<Checkbox className="bookmakers-checkbox" />}
                        label={element?.name}
                        value={element?.name}
                        name="hearAboutInfo"
                        checked={hearAboutData?.includes(element?.name)}
                      />
                    ))}
                  </Box>
                  <span className="text-danger">
                    {touched?.hearAboutInfo && errors?.hearAboutInfo
                      ? errors?.hearAboutInfo
                      : ""}
                  </span>
                  <Box className="other-filed filed-1 ">
                    <Box></Box>
                    <Box>
                      {hearAboutData?.includes("Other") ? (
                        <Box>
                          <TextField
                            placeholder={""}
                            variant="outlined"
                            type="text"
                            name="OtherPleaseSpecify"
                            className="text-field"
                            value={hearAboutOther}
                            onChange={e => handleHearAboutOther(e)}
                          />
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="profile-button">
                <Box className="profile-btn">
                  <Button
                    variant="outlined"
                    className="btn-cancel"
                    onClick={() => handleBack()}
                  >
                    {localesData?.EDITPROFILE?.CANCEL}
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-save"
                    type="submit"
                    // onClick={() => handleSave()}
                  >
                    {localesData?.EDITPROFILE?.SAVE}
                  </Button>
                </Box>
              </Box>
            </Box>
            {setformvalues(values)}
          </form>
        )}
      </Formik>
    </>
  );
}

export default UserEditDetails;
