import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import AdvertisingBannner from "../../advertisingBanner";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.png";
import { Helmet } from "react-helmet";
import UserEditDetails from "./userEdit";
import PasswordEdit from "./passwordEdit";
import BookmakersEdit from "./bookmakersEdit";
import SportsEdit from "./sportsEdit";
import NotificationsEdit from "./notificationsEdit";
import { IntlContext } from "src/App";
import SubscriptionEditDetails from "./subscriptionEdit";
import "../profile.scss";
import AdBannner from "../../AdBanner/AdBanner";
import { Config } from "src/helpers/context";

function UserEdit() {
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>SmartB | Edit Your Profile</title>
        <meta
          name="description"
          content="SmartB is a leading online sports news service and an aggregator of odds comparison. Edit your user profile from this page."
        />
      </Helmet>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        {fetchPageHeaderAds(
          "218px",
          Config?.release == "IN" ? bannerIndia : banner
        )}
        <Box className="profile-contanier">
          <Box className="profile-details">
            <h3>{localesData?.EDITPROFILE?.PROFILE}</h3>
            {location?.state?.name === "useDetails" ? (
              <UserEditDetails data={location?.state?.Data} />
            ) : location?.state?.name === "password" ? (
              <PasswordEdit data={location?.state?.Data} />
            ) : location?.state?.name === "bookmakers" ? (
              <BookmakersEdit data={location?.state?.Data} />
            ) : location?.state?.name === "sports" ? (
              <SportsEdit data={location?.state?.Data} />
            ) : location?.state?.name === "notifications" ? (
              <NotificationsEdit data={location?.state?.Data} />
            ) : location?.state?.name === "subscription" ? (
              <SubscriptionEditDetails data={location?.state?.Data} />
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default UserEdit;
