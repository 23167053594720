import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import bannerHeader from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.png";
import AdvertisingBannner from "../advertisingBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import OurPartner from "../ourPartners";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DefaultImg from "../../../assets/images/smartb_default.png";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { ReactComponent as Timepicker } from "src/assets/images/eventcalendar/Timepicker.svg";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/smartb-check.svg";
import compImage from "../../../assets/images/round-dummy-image.png";
import { ReactComponent as AddButton } from "../../../assets/images/round-add-icon.svg";
import AdBannner from "../AdBanner/AdBanner";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import { Formik } from "formik";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import DateFnsUtils from "@date-io/date-fns";
// import { parseISO } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
  KeyboardTimePicker,
  TimePicker
} from "@material-ui/pickers";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./createComp.scss";
import moment from "moment-timezone";
import SocialShareModal from "../UI/socialShareModal";
import { release } from "src/helpers/context/release";
import UploadImageModal from "../UI/uploadImage";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};
// const DropdownIndicatorDay = props => {
//   return (
//     <components.DropdownIndicator {...props}>
//       <Datepicker />
//     </components.DropdownIndicator>
//   );
// };

const roundOptions = [
  {
    label: "Round 1",
    value: 1
  }
  // {
  //   label: "Round 2",
  //   value: 1
  // },
  // {
  //   label: "Round 3",
  //   value: 2
  // },
  // {
  //   label: "Round 4",
  //   value: 3
  // },
  // {
  //   label: "Round 5",
  //   value: 4
  // }
];
const dayOptions = [
  {
    label: "Sunday",
    value: 1
  },
  {
    label: "Monday",
    value: 2
  },
  {
    label: "Tuesday",
    value: 3
  },
  {
    label: "Wednesday",
    value: 4
  },
  {
    label: "Thursday",
    value: 5
  },
  {
    label: "Friday",
    value: 6
  },
  {
    label: "Saturday",
    value: 7
  }
];
const privacyOptions = [
  {
    label: "Public",
    value: 1
  },
  {
    label: "Private",
    value: 2
  }
];
const tipDeadlineOptions = [
  {
    label: "Round Based",
    value: 1
  },
  {
    label: "Game Based",
    value: 2
  }
];
const ifNoTipOptions = [
  {
    label: "Zero Score for round",
    value: 1
  },
  {
    label: "Lowest Score for round",
    value: 2
  },
  {
    label: "All Away Teams auto picked",
    value: 3
  },
  {
    label: "All Home Teams auto picked",
    value: 4
  }
];

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CreateCompPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const limit = 20;
  const [teamApiCount, setTeamApiCount] = useState(0);
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [loader, setLoader] = useState(false);
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState("");
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState("");
  const [OrgAll, setOrgAll] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [compName, setCompName] = useState("");
  const [compAbout, setCompAbout] = useState("");
  const [compNameError, setCompNameError] = useState("");
  const [open, setOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [openSocialShareModal, setOpenSocialShareModal] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [formvalues, setformvalues] = useState({});
  const [dateSelected, setDateSelected] = useState("");
  const [selectedTime, handleTimeChange] = useState("");
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [countOrg, setcountOrg] = useState(0);
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [isTipsterShown, setIsTipsterShown] = useState([
    {
      id: 1,
      isTipsterShown: "Show",
      value: true
    },
    {
      id: 2,
      isTipsterShown: "Hide",
      value: false
    }
  ]);
  const [selectedTipsterShown, setSelectedTipsterShown] = useState(null);
  const [roundBonus, setRoundBonus] = useState([
    {
      id: 1,
      Roundvalue: "Yes",
      isRoundBonus: false
    },
    {
      id: 2,
      Roundvalue: "No",
      isRoundBonus: false
    }
  ]);
  const [roundBonusOption, setRoundBonusOption] = useState();
  const [jokerBonus, setJokerBonus] = useState([
    {
      id: 1,
      Jokervalue: "Yes",
      isJokerBonus: false
    },
    {
      id: 2,
      Jokervalue: "No",
      isJokerBonus: false
    }
  ]);
  const [jokerBonusOption, setJokerBonusOption] = useState();
  const [marginSystem, setMarginSystem] = useState([
    {
      id: 1,
      MarginSystemvalue: "Yes",
      isMarginSystem: false
    },
    {
      id: 2,
      MarginSystemvalue: "No",
      isMarginSystem: false
    }
  ]);
  const [marginSystemOption, setMarginSystemOption] = useState();
  const [rounds, setRounds] = useState([]);
  const [selectedRound, setSelectedRound] = useState("");
  const [selectedRoundStartDate, setSelectedRoundStartDate] = useState(null);
  const [selectedPrivacy, setSelectedPrivacy] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTipDeadline, setSelectedTipDeadline] = useState("");
  const [selectedNoTip, setSelectedNoTip] = useState("");
  const [shareCode, setShareCode] = useState("");
  const [noRoundData, setNoRoundData] = useState("");
  const [expireRoundError, setExpireRoundError] = useState("");
  const [expireStartTimeError, setExpireStartTimeError] = useState("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [cropImage, setCropImage] = useState(null);

  useEffect(() => {
    setCurrentPage(0);
    fetchSportData();
  }, []);
  useEffect(() => {
    if (selectedOrg && selectedSports) {
      fetchRoundData(selectedOrg, selectedSports, selectedOrgName);
    }
  }, [selectedOrg]);

  const handleClose = () => {
    setOpenSocialShareModal(false);
    navigate("/tipscompetition/my-comps");
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const onHandleTimeChange = newTime => {
    handleTimeChange(newTime);
    const parsedTime = moment(newTime);
    const currentWeekDateTime = parsedTime.clone().day(selectedDay);
    if (
      moment(currentWeekDateTime.format()).format("YYYY-MM-DD HH:mm:ss") >
      moment(selectedRoundStartDate).format("YYYY-MM-DD HH:mm:ss")
    ) {
      setExpireStartTimeError(
        "Please select Day and Time Before Round Starting Time"
      );
    } else {
      setExpireStartTimeError("");
    }
  };

  const getFields = (list, field) => {
    return list.reduce(function(carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        const FilteredSportData = data?.result?.filter(
          item => item?.id == 12 || item?.id == 9 || item?.id == 4
        );
        const releaseFilterSportData = FilteredSportData?.filter(item =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        var sportsdata = releaseFilterSportData?.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });

        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var filterSports = getFields(sdata, "id");
        setSelectedSports("");
        setSports(sdata);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          item?.name !== null &&
            newdata.push({
              label: item?.name,
              value: item?.id
            });
        });
        // fetchRoundData(selectedOrg, sID);
        let filterData = _.unionBy(OrgAll, newdata);
        // let allteamdatas = filterData?.unshift({
        //   label: "All Tournaments",
        //   value: 0
        // });
        setOrgAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchRoundData = async (tID, sID, tournament) => {
    setRounds([]);
    const date = moment(Date()).format("YYYY-MM-DD");
    // &startDate=${date}
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/rounds?timezone=${timezone}&tournamentId=${tID}&SportId=${sID}`
      );
      if (status === 200) {
        let newdata = [];

        const filteredRound = data?.roundList?.filter(
          round => !data?.completedRound.includes(round?.round)
        );
        let track = filteredRound?.map(item => {
          newdata.push({
            label: `Round ${item?.round} ${
              item?.startTime
                ? "  (" +
                  moment(item?.startTime)
                    .tz(timezone)
                    .format("DD-MM-YYYY hh:mm A") +
                  ")"
                : ""
            }`,
            value: item?.round,
            roundStartDate: item?.startTime
          });
        });
        if (newdata && newdata?.length > 0) {
          setRounds(newdata);
          setNoRoundData("");
        } else {
          setNoRoundData(
            "There are no rounds available for this tournament!, Try selecting a different League."
          );
        }

        // setRounds(
        //   newdata?.length > 0 ? newdata : roundOptions
        // );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  // const handleSave = async () => {
  //   const payload = {
  //     currentPassword: formvalues?.currentPassword,
  //     password: formvalues?.newPassword
  //   };
  // };

  // const clearDate = () => {
  //   setDateSelected(null);
  //   setOpen(false);
  // };
  const clearTime = () => {
    handleTimeChange(null);
    setTimeOpen(false);
  };

  const ifNoTipOptionsFunc = value => {
    if (value === 1) {
      return "ZERO_SCORE_ROUND";
    } else if (value === 2) {
      return "LOWEST_SCORE_ROUND";
    } else if (value === 3) {
      return "AWAY_TEAM_PICKED";
    } else {
      return "HOME_TEAM_PICKED";
    }
  };

  const handleValidation = () => {
    let flag = true;
    if (compName === "") {
      setCompNameError("Competition name is required");
      flag = false;
    } else {
      setCompNameError("");
      flag = true;
    }
    if (expireRoundError !== "") {
      flag = false;
    } else {
      flag = true;
    }
    if (expireStartTimeError !== "") {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  const submit = async () => {
    let payload = {
      SportId: formvalues?.selectSport,
      tournamentId: formvalues?.selectLeague,
      competitionName: formvalues?.compName,
      startingRound: formvalues?.startingRound,
      privacy: formvalues?.privacySettings === 1 ? "public" : "private",
      tipDeadline: formvalues?.tipDeadline === 1 ? "round" : "game",
      noTip: ifNoTipOptionsFunc(formvalues?.ifNoTip),
      numberOfTippers: formvalues?.numberOfTippers,
      perfectRound: formvalues?.roundBonus === 1 ? true : false,
      JokerRound: formvalues?.jokerRound === 1 ? true : false,
      marginRound: formvalues?.marginSystem === 1 ? true : false,
      about: compAbout,
      MediaId: cropImage?.id
    };
    if (formvalues?.tipDeadline === 1) {
      payload = {
        ...payload,
        cutOffWeek: formvalues?.selectDay,
        cutOffTime: moment(formvalues?.selectTime)
          .tz(timezone)
          .format("HH:mm:ss")
      };
    }
    if (handleValidation()) {
      setisLoading(true);
      try {
        const { status, data } = await axiosInstance.post(
          `tipping/createCompetition?timezone=${timezone}`,
          payload
        );
        if (status === 200) {
          toast.success("Competition Created", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored"
          });
          setisLoading(false);
          setShareCode(data?.result?.code);
          setSelectedSports("");
          setSelectedOrg("");
          setSelectedOrgName("");
          setSelectedRound("");
          setSelectedRoundStartDate(null);
          setSelectedDay("");
          setSelectedPrivacy("");
          setSelectedTipDeadline("");
          setSelectedNoTip("");
          setDateSelected("");
          handleTimeChange("");
          setRoundBonusOption();
          setJokerBonusOption();
          setMarginSystemOption();
          setCompName("");
          setCompAbout("");
          setSelectedTipsterShown(null);
          setTimeout(() => {
            setOpenSocialShareModal(true);
          }, 2000);
        } else {
          toast.error("An Error Occurred", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored"
          });
        }
      } catch (err) {
        setisLoading(false);
        toast.error(err?.response?.data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      }
    }
  };

  const handleImageModal = () => {
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
  };

  const getImage = data => {
    setCropImage(data);
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerHeader : banner
          )}
        </Box>
        <Box className="comp-wrap">
          <Box className="comp-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  HOME
                </Link>

                <Link
                  underline="hover"
                  color="inherit"
                  to=""
                  className="cursor-default"
                >
                  Tipping Competition
                </Link>

                <Typography>Create Comps</Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">Create Comps</Typography>
          </Box>
          <Box className="page-deatils-wrap">
            <Formik
              ref={formikRef}
              enableReinitialize
              initialValues={{
                selectSport: selectedSports,
                selectLeague: selectedOrg ? selectedOrg : "",
                compName: compName,
                // compAbout: compAbout,
                startingRound: selectedRound,
                privacySettings: selectedPrivacy,
                tipDeadline: selectedTipDeadline,
                selectDay: selectedDay,
                // dateSelected
                selectTime: selectedTime,
                ifNoTip: selectedNoTip,
                numberOfTippers: selectedTipsterShown,
                roundBonus: roundBonusOption,
                jokerRound: jokerBonusOption,
                marginSystem: marginSystemOption
              }}
              validationSchema={Yup.object().shape({
                selectSport: Yup.string().required("Sport is required"),
                selectLeague: Yup.string().required("League is required"),
                compName: Yup.string()
                  .trim()
                  .required("Competition name is required"),
                // .matches(
                //   /^[^0-9]+$/,
                //   "Numbers are not allowed for Competition Name"
                // ),
                // .test(
                //   "compName",
                //   "Only letters and Spacial characters are allowed for Competition Name",
                //   value => value && value.match(/^[a-zA-Z\s'()-]+$/)
                //   // value.match(
                //   //   /^\p{L}[\p{L}'.\s]*(?:[\-]{0,1}[\p{L}.'\s]*\p{L}+)*$/gu
                //   // )
                // )
                startingRound: Yup.string().required(
                  "Starting round is required"
                ),
                privacySettings: Yup.string().required(
                  "Privacy settings is required"
                ),
                tipDeadline: Yup.string().required("Tip deadline is required"),
                ifNoTip: Yup.string().required("This field is required"),
                selectDay: Yup.string().when("tipDeadline", {
                  is: "1",
                  then: Yup.string().required("Choose a Day"),
                  otherwise: Yup.string()
                    .nullable()
                    .notRequired()
                }),
                selectTime: Yup.string().when("tipDeadline", {
                  is: "1",
                  then: Yup.string().required("Choose a Time"),
                  otherwise: Yup.string()
                    .nullable()
                    .notRequired()
                }),
                numberOfTippers: Yup.boolean()
                  .nullable()
                  .required("This field is required"),
                roundBonus: Yup.string().required("This field is required"),
                jokerRound: Yup.string().required("This field is required"),
                marginSystem: Yup.string().required("This field is required")
              })}
              onSubmit={reqdata => {
                setformvalues({ ...formvalues, ...reqdata });
                handleValidation();
                submit();
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography className="title">
                    Create a Competition
                  </Typography>
                  <Box
                    className="comp-field-container"
                    style={{ marginTop: "21px" }}
                  >
                    <Box className="comp-image-name-wrap">
                      <Box
                        className="comp-image-wrap"
                        onClick={() => handleImageModal()}
                      >
                        <img
                          className="comp-image"
                          src={
                            cropImage?.filePath
                              ? Config?.countryMediaURL + cropImage?.filePath
                              : compImage
                          }
                          alt="comp-image"
                        />
                        <AddButton className="add-button" />
                      </Box>
                      <Box
                        className="comp-image-text-wrap"
                        onClick={() => handleImageModal()}
                      >
                        <Typography className="image-text">
                          Upload Comp Image
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="comp-details about-comp-title">
                      <Typography className="textfield-text">
                        About This Comp:
                      </Typography>
                      <Box className="field-container">
                        <TextareaAutosize
                          id="outlined-basic"
                          variant="outlined"
                          className="details-textarea-field"
                          placeholder="- About -"
                          name="compAbout"
                          maxRows={3}
                          minRows={2}
                          onChange={e => setCompAbout(e?.target?.value)}
                          value={compAbout}
                          // error={Boolean(touched?.compAbout && errors?.compAbout)}
                          // helperText={touched?.compAbout ? errors?.compAbout : ""}
                        />
                      </Box>
                    </Box>
                    <Box className="comp-details">
                      <Typography className="textfield-text">
                        Select Sport<span className="danger">*</span>:
                      </Typography>
                      <Box className="field-container">
                        <Select
                          className="React sort-select"
                          value={
                            selectedSports !== "" &&
                            sports?.find(item => {
                              return item?.value === selectedSports;
                            })
                          }
                          onChange={e => {
                            setSelectedSports(e?.value);
                            setCurrentPage(0);
                            // setSelectedTeams(null);
                            setSelectedOrg(null);
                            setSelectedOrgName("");
                            setTeamsAll([]);
                            setOrgAll([]);
                            // if (!e?.isAll) {
                            fetchOrgData(0, e?.value, []);
                            // }
                          }}
                          // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                          options={sports}
                          classNamePrefix="select"
                          placeholder="- Select Sport -"
                          // isSearchable={false}
                          // onFocus={() => setTournamentSelect(true)}
                          // onBlur={() => setTournamentSelect(false)}
                          components={{ DropdownIndicator }}
                        />

                        <span className="text-danger">
                          {touched?.selectSport && errors?.selectSport
                            ? errors?.selectSport
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="comp-details">
                      <Typography className="textfield-text">
                        Select League<span className="danger">*</span>:
                      </Typography>
                      <Box className="field-container">
                        <Select
                          className="React sort-select"
                          onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
                          onInputChange={e => {
                            handleOrgInputChange(0, e, selectedSports);
                          }}
                          onChange={e => {
                            // fetchRoundData(e?.value, selectedSports);
                            setSelectedOrg(e?.value ? e?.value : "");
                            setSelectedOrgName(e?.label ? e?.label : "");
                            // setSelectedTeams(null);
                            setSelectedRound("");
                            setSelectedRoundStartDate(null);
                            setOrgApiCount(0);
                            setpageOrg(0);
                            setisOrgSelectOpen(false);
                            setCurrentPage(0);
                          }}
                          onFocus={() => setisOrgSelectOpen(true)}
                          onBlur={() => setisOrgSelectOpen(false)}
                          value={
                            selectedOrg &&
                            (isOrgSearch
                              ? searchOrg?.find(item => {
                                  return item?.value == selectedOrg;
                                })
                              : OrgAll?.find(item => {
                                  return item?.value == selectedOrg;
                                }))
                          }
                          options={isOrgSearch ? searchOrg : OrgAll}
                          classNamePrefix="select"
                          placeholder="- Select League -"
                          isDisabled={!selectedSports ? true : false}
                          // isDisabled={
                          //   selectedSports && selectedSports.length > 0
                          //     ? true
                          //     : false
                          // }
                          components={{ DropdownIndicator }}
                        />
                        <span className="text-danger">
                          {touched?.selectLeague && errors?.selectLeague
                            ? errors?.selectLeague
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="comp-details">
                      <Typography className="textfield-text">
                        Competition name<span className="danger">*</span>:
                      </Typography>
                      <Box className="field-container">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          className="comp-textfield"
                          placeholder="Choose a name"
                          name="compName"
                          onChange={e => setCompName(e?.target?.value)}
                          value={compName}
                          error={Boolean(touched?.compName && errors?.compName)}
                          helperText={touched?.compName ? errors?.compName : ""}
                        />
                        {/* <span className="text-danger">
                          {!compName && compNameError ? compNameError : ""}
                        </span> */}
                      </Box>
                    </Box>
                    <Box className="comp-details">
                      <Typography className="textfield-text">
                        Starting Round<span className="danger">*</span>:
                      </Typography>
                      <Box className="field-container">
                        <Select
                          className="React sort-select"
                          value={
                            selectedRound !== "" &&
                            rounds?.find(item => {
                              return item?.value === selectedRound;
                            })
                          }
                          options={rounds}
                          onChange={e => {
                            setSelectedRound(e?.value);
                            setSelectedRoundStartDate(e?.roundStartDate);
                            handleTimeChange("");
                            setExpireStartTimeError("");
                            if (moment(e?.roundStartDate) < moment()) {
                              setExpireRoundError(
                                "You can not select this Round!, Please Try another round"
                              );
                            } else {
                              setExpireRoundError("");
                            }
                          }}
                          classNamePrefix="select"
                          placeholder="- Select Round -"
                          components={{ DropdownIndicator }}
                          isDisabled={
                            rounds?.length === 0 && noRoundData !== ""
                          }
                        />
                        <span className="text-danger">
                          {rounds?.length === 0 && noRoundData
                            ? noRoundData
                            : ""}
                          {expireRoundError && expireRoundError !== ""
                            ? expireRoundError
                            : ""}
                        </span>
                        <span className="text-danger">
                          {touched?.startingRound && errors?.startingRound
                            ? errors?.startingRound
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="comp-details">
                      <Typography className="textfield-text">
                        Privacy settings<span className="danger">*</span>:
                      </Typography>
                      <Box className="field-container">
                        <Select
                          className="React sort-select"
                          value={
                            selectedPrivacy !== "" &&
                            privacyOptions?.find(item => {
                              return item?.value === selectedPrivacy;
                            })
                          }
                          onChange={e => setSelectedPrivacy(e?.value)}
                          options={privacyOptions}
                          classNamePrefix="select"
                          placeholder="- Select -"
                          components={{ DropdownIndicator }}
                        />
                        <span className="text-danger">
                          {touched?.privacySettings && errors?.privacySettings
                            ? errors?.privacySettings
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="comp-details">
                      <Typography className="textfield-text">
                        Tip deadline<span className="danger">*</span>:
                      </Typography>
                      <Box className="field-container">
                        <Select
                          className="React sort-select"
                          value={
                            selectedTipDeadline !== "" &&
                            tipDeadlineOptions?.find(item => {
                              return item?.value === selectedTipDeadline;
                            })
                          }
                          onChange={e => setSelectedTipDeadline(e?.value)}
                          options={tipDeadlineOptions}
                          classNamePrefix="select"
                          placeholder="- Select Deadline -"
                          components={{ DropdownIndicator }}
                        />
                        <span className="text-danger">
                          {touched?.tipDeadline && errors?.tipDeadline
                            ? errors?.tipDeadline
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    {selectedTipDeadline == 1 ? (
                      <Box className="day-time-section">
                        <Box className="day-time-box">
                          <Typography className="cutoff-txt">
                            Select a day and time for cut off
                          </Typography>

                          <Box className="day-time">
                            <Box className="comp-date-selected-wrap">
                              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                clearable
                                autoOk
                                // disableToolbar
                                // variant="inline"
                                format="dd/MM/yyyy"
                                placeholder="- Select day -"
                                margin="normal"
                                id="date-picker-inline"
                                inputVariant="outlined"
                                value={dateSelected ? dateSelected : null}
                                onChange={e => handleLiveDateChange(e)}
                                onClear={clearDate}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                views={["year", "month", "date"]}
                                KeyboardButtonProps={{
                                  "aria-label": "Select Date"
                                }}
                                keyboardIcon={
                                  <Datepicker className="keyboard-icon" />
                                }
                                // className="comp-comp-details-search-picker"
                              />
                            </MuiPickersUtilsProvider> */}
                              <Box className="field-container">
                                <Select
                                  className="React sort-select"
                                  value={
                                    selectedDay !== "" &&
                                    dayOptions?.find(item => {
                                      return item?.label === selectedDay;
                                    })
                                  }
                                  onChange={e => {
                                    setSelectedDay(e?.label);
                                    if (selectedTime && selectedTime != "") {
                                      const parsedTime = moment(selectedTime);
                                      const currentWeekDateTime = parsedTime
                                        .clone()
                                        .day(e?.label);
                                      if (
                                        moment(
                                          currentWeekDateTime.format()
                                        ).format("YYYY-MM-DD HH:mm:ss") >
                                        moment(selectedRoundStartDate).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      ) {
                                        setExpireStartTimeError(
                                          "Please select Day and Time Before Round Starting Time"
                                        );
                                      } else {
                                        setExpireStartTimeError("");
                                      }
                                    }
                                  }}
                                  options={dayOptions}
                                  classNamePrefix="select"
                                  placeholder="Select Day"
                                  components={{ DropdownIndicator }}
                                  isDisabled={
                                    expireRoundError
                                      ? true
                                      : selectedRound == "0"
                                      ? false
                                      : !selectedRound
                                  }
                                />
                              </Box>
                              <span className="text-danger">
                                {touched?.selectDay && errors?.selectDay
                                  ? errors?.selectDay
                                  : ""}
                              </span>
                            </Box>
                            <Box className="comp-date-selected-wrap border-gray">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                  clearable
                                  autoOk
                                  onClear={clearTime}
                                  placeholder="--:--"
                                  value={selectedTime ? selectedTime : null}
                                  onChange={e => onHandleTimeChange(e)}
                                  ampm={false}
                                  open={timeOpen}
                                  onOpen={() => setTimeOpen(true)}
                                  onClose={() => setTimeOpen(false)}
                                  format="HH:mm"
                                  TextFieldComponent={props => (
                                    <TextField
                                      {...props}
                                      // className="comp-comp-details-search-picker"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton>
                                              <Timepicker className="keyboard-icon" />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  )}
                                  disabled={
                                    expireRoundError
                                      ? true
                                      : selectedRound == "0"
                                      ? false
                                      : !selectedRound
                                  }
                                />
                              </MuiPickersUtilsProvider>
                              <span className="text-danger">
                                {touched?.selectTime && errors?.selectTime
                                  ? errors?.selectTime
                                  : ""}
                              </span>
                            </Box>
                          </Box>
                          <span className="text-danger">
                            {expireStartTimeError && expireStartTimeError !== ""
                              ? expireStartTimeError
                              : ""}
                          </span>
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Box className="comp-details">
                      <Typography className="textfield-text">
                        If no tip is entered<span className="danger">*</span>:
                      </Typography>
                      <Box className="field-container">
                        <Select
                          className="React sort-select"
                          value={
                            selectedNoTip !== "" &&
                            ifNoTipOptions?.find(item => {
                              return item?.value === selectedNoTip;
                            })
                          }
                          onChange={e => setSelectedNoTip(e?.value)}
                          options={ifNoTipOptions}
                          classNamePrefix="select"
                          placeholder="- Select -"
                          components={{ DropdownIndicator }}
                        />
                        <span className="text-danger">
                          {touched?.ifNoTip && errors?.ifNoTip
                            ? errors?.ifNoTip
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="comp-details jc-start">
                      <Typography className="textfield-text">
                        Number of Tipsters<span className="danger">*</span>:
                      </Typography>
                      <Box className="Filteritemlist-wrap">
                        <Box className="field-container">
                          <Box className="filter-list-racing">
                            <ul className="Filteritemlist-racing">
                              {isTipsterShown?.length > 0 &&
                                isTipsterShown?.map((obj, i) => (
                                  <li key={i}>
                                    <FormControl>
                                      <label>
                                        <Checkbox
                                          className="filter-racing"
                                          icon={<Unchecked />}
                                          checkedIcon={<Checked />}
                                          name="filter"
                                          onChange={() =>
                                            setSelectedTipsterShown(obj?.value)
                                          }
                                          checked={
                                            selectedTipsterShown === obj?.value
                                          }
                                        />
                                        {obj?.isTipsterShown}
                                      </label>
                                    </FormControl>
                                  </li>
                                ))}
                            </ul>
                          </Box>
                          <span className="text-danger">
                            {touched?.numberOfTippers && errors?.numberOfTippers
                              ? errors?.numberOfTippers
                              : ""}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="comp-details jc-start">
                      <Typography className="textfield-text">
                        Perfect round Bonus<span className="danger">*</span>:
                      </Typography>
                      <Box className="Filteritemlist-wrap">
                        <Box className="field-container">
                          <Box className="filter-list-racing">
                            <ul className="Filteritemlist-racing">
                              {roundBonus?.length > 0 &&
                                roundBonus?.map((obj, i) => (
                                  <li key={i}>
                                    <FormControl>
                                      <label>
                                        <Checkbox
                                          className="filter-racing"
                                          icon={<Unchecked />}
                                          checkedIcon={<Checked />}
                                          name="filter"
                                          onChange={() =>
                                            setRoundBonusOption(obj?.id)
                                          }
                                          checked={roundBonusOption === obj?.id}
                                        />
                                        {obj?.Roundvalue}
                                      </label>
                                    </FormControl>
                                  </li>
                                ))}
                            </ul>
                          </Box>
                          <span className="text-danger">
                            {touched?.roundBonus && errors?.roundBonus
                              ? errors?.roundBonus
                              : ""}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="comp-details jc-start">
                      <Typography className="textfield-text">
                        Include Joker Round<span className="danger">*</span>:
                      </Typography>
                      <Box className="Filteritemlist-wrap">
                        <Box className="field-container">
                          <Box className="filter-list-racing">
                            <ul className="Filteritemlist-racing">
                              {jokerBonus?.length > 0 &&
                                jokerBonus?.map((obj, i) => (
                                  <li key={i}>
                                    <FormControl>
                                      <label>
                                        <Checkbox
                                          className="filter-racing"
                                          icon={<Unchecked />}
                                          checkedIcon={<Checked />}
                                          name="filter"
                                          onChange={() =>
                                            setJokerBonusOption(obj?.id)
                                          }
                                          checked={jokerBonusOption === obj?.id}
                                        />
                                        {obj?.Jokervalue}
                                      </label>
                                    </FormControl>
                                  </li>
                                ))}
                            </ul>
                          </Box>
                          <span className="text-danger">
                            {touched?.jokerRound && errors?.jokerRound
                              ? errors?.jokerRound
                              : ""}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="comp-details jc-start">
                      <Typography className="textfield-text">
                        Include Margin System<span className="danger">*</span>:
                      </Typography>
                      <Box className="Filteritemlist-wrap">
                        <Box className="field-container">
                          <Box className="filter-list-racing">
                            <ul className="Filteritemlist-racing">
                              {marginSystem?.length > 0 &&
                                marginSystem?.map((obj, i) => (
                                  <li key={i}>
                                    <FormControl>
                                      <label>
                                        <Checkbox
                                          className="filter-racing"
                                          icon={<Unchecked />}
                                          checkedIcon={<Checked />}
                                          name="filter"
                                          onChange={() =>
                                            setMarginSystemOption(obj.id)
                                          }
                                          checked={
                                            marginSystemOption === obj?.id
                                          }
                                        />
                                        {obj?.MarginSystemvalue}
                                      </label>
                                    </FormControl>
                                  </li>
                                ))}
                            </ul>
                          </Box>
                          <span className="text-danger">
                            {touched?.marginSystem && errors?.marginSystem
                              ? errors?.marginSystem
                              : ""}
                          </span>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="comp-button">
                      <Box className="comp-btn">
                        <Button
                          variant="contained"
                          className="btn-save"
                          type="submit"
                        >
                          {/* Create competition */}
                          {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Create competition"
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  {setformvalues(values)}
                </form>
              )}
            </Formik>
          </Box>
          <OurPartner />
        </Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
      <SocialShareModal
        open={openSocialShareModal}
        handleClose={handleClose}
        title={""}
        closeIcon={true}
        shareCode={shareCode}
      />
      <UploadImageModal
        open={imageModalOpen}
        handleClose={handleImageClose}
        title={"Upload Comp Image"}
        closeIcon={false}
        getImage={getImage}
        //  listingFunction={() =>
        //    setTimeout(() => {
        //      getEventByID(stepperCount);
        //    }, 3000)
        //  }
      />
    </>
  );
};

export default CreateCompPage;
