import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Breadcrumbs,
  Tabs,
  Tab,
  Button,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from "@material-ui/pickers";
import AdvertisingBannner from "../advertisingBanner";
import OurPartner from "src/views/component/ourPartners";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.png";
import Select, { components } from "react-select";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
// import { ReactComponent as Month } from "src/assets/images/eventcalendar/month.svg";
// import { ReactComponent as Day } from "src/assets/images/eventcalendar/day.svg";
import { ReactComponent as Search } from "src/assets/images/eventcalendar/search.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import DateFnsUtils from "@date-io/date-fns";
import SelectList from "./SelectList";
import EventData from "./EventData";
import EventMonth from "./EventMonth";
import EventDay from "./EventDay";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useSelector } from "react-redux";
import AdBannner from "../AdBanner/AdBanner";
import moment from "moment-timezone";
import _ from "lodash";
import "./eventscalendar.scss";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const EventsCalendarPage = () => {
  const navigate = useNavigate();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [eventLoader, setEventLoader] = useState(false);
  const [selectedStartDate, setselectedStartDate] = useState(
    moment(Date()).format("YYYY-MM-DD")
    // .startOf("week")
  );
  const [selectedStartDateDay, setselectedStartDateDay] = useState();
  const [selectedMonth, setSelectedMonth] = useState(null);
  // const [selectedEndDate, setselectedEndDate] = useState(
  //   moment(Date())
  //     .endOf("week")
  //     .format("YYYY-MM-DD")
  // );

  const endDate = moment(Date())
    .add(6, "days")
    .format("YYYY-MM-DD");
  const [selectedEndDate, setselectedEndDate] = useState(endDate);
  const [eventData, setEventData] = useState([]);
  const [filter, setFilter] = useState("day");
  const [searchValue, setSearchValue] = useState("");
  const [eventListData, setEventListData] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 20;
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState("");
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState("");
  const [VenAll, setVenAll] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState("");
  const [OrgAll, setOrgAll] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");

  const [isTeamSearch, setIsTeamSearch] = useState("");
  const [isTeamSelectOpen, setisTeamSelectOpen] = useState(false);
  const [searchTeam, setsearchTeam] = useState([]);
  const [searchTeamCount, setsearchTeamCount] = useState(0);
  const [SearchTeampage, setSearchTeampage] = useState(0);
  const [pageTeam, setpageTeam] = useState(0);
  const [teamApiCount, setTeamApiCount] = useState(0);

  const [isVenSearch, setIsVenSearch] = useState("");
  const [isVenSelectOpen, setisVenSelectOpen] = useState(false);
  const [searchVen, setsearchVen] = useState([]);
  const [searchVenCount, setsearchVenCount] = useState(0);
  const [SearchVenpage, setSearchVenpage] = useState(0);
  const [pageVen, setpageVen] = useState(0);
  const [VenApiCount, setVenApiCount] = useState(0);

  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [count, setcount] = useState(0);
  const [countVen, setcountVen] = useState(0);
  const [countOrg, setcountOrg] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleDateChange = date => {
    setselectedStartDate(
      date
        ? moment(date)
            .tz(timezone)
            .format("YYYY-MM-DD")
        : null
    );
    if (
      moment(date)
        .tz(timezone)
        .format("YYYY-MM-DD") !== "Invalid date"
    ) {
      setCurrentPage(0);
      fetchEventData(
        0,
        searchValue,
        selectedTeams,
        selectedVenues,
        selectedOrg,
        moment(date)
          .tz(timezone)
          .format("YYYY-MM-DD"),
        selectedEndDate,
        selectedSports
      );
    }
  };
  const handleDateChangeDay = date => {
    setselectedStartDateDay(
      date
        ? moment(date)
            .tz(timezone)
            .format("YYYY-MM-DD")
        : null
    );
    if (
      moment(date)
        .tz(timezone)
        .format("YYYY-MM-DD") !== "Invalid date"
    ) {
      setCurrentPage(0);
      fetchEventData(
        currentPage,
        searchValue,
        selectedTeams,
        selectedVenues,
        selectedOrg,
        moment(date)
          .tz(timezone)
          .format("YYYY-MM-DD"),
        "",
        selectedSports
      );
    }
  };
  const handleMonthChange = date => {
    setHasMore(false);
    setSelectedMonth(
      moment(date)
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    fetchMonthlyEvents(
      currentPage,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      moment(date)
        .tz(timezone)
        .format("YYYY-MM-DD"),
      moment(date)
        .endOf("month")
        .format("YYYY-MM-DD"),
      selectedSports,
      true
    );
  };
  const handleEndDateChange = date => {
    setselectedEndDate(
      date
        ? moment(date)
            .tz(timezone)
            .format("YYYY-MM-DD")
        : null
    );
    if (
      moment(date)
        .tz(timezone)
        .format("YYYY-MM-DD") !== "Invalid date"
    ) {
      setCurrentPage(0);
      fetchEventData(
        0,
        searchValue,
        selectedTeams,
        selectedVenues,
        selectedOrg,
        selectedStartDate,
        moment(date)
          .tz(timezone)
          .format("YYYY-MM-DD"),
        selectedSports
      );
    }
  };
  const onchangeSelectedMonth = date => {
    setSelectedMonth(
      moment(date)
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
  };
  const onSelectFilter = item => {
    setFilter(item);
    if (item === "month") {
      setSelectedMonth(
        moment(Date())
          .startOf("month")
          .format("YYYY-MM-DD")
      );
      setCurrentPage(0);
      // fetchEventData(
      //   0,
      //   searchValue,
      //   selectedTeams,
      //   selectedVenues,
      //   selectedOrg,
      //   moment(Date())
      //     .startOf("month")
      //     .format("YYYY-MM-DD"),
      //   moment(Date())
      //     .endOf("month")
      //     .format("YYYY-MM-DD"),
      //   selectedSports,
      //   true,
      //   item
      // );
      fetchMonthlyEvents(
        0,
        searchValue,
        selectedTeams,
        selectedVenues,
        selectedOrg,
        moment(Date())
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment(Date())
          .endOf("month")
          .format("YYYY-MM-DD"),
        selectedSports,
        true,
        item
      );
    } else if (item === "day") {
      setselectedStartDateDay(
        moment(Date())
          .tz(timezone)
          .format("YYYY-MM-DD")
      );
      setCurrentPage(0);
      fetchEventData(
        0,
        searchValue,
        selectedTeams,
        selectedVenues,
        selectedOrg,
        moment(Date()).format("YYYY-MM-DD"),
        moment(Date()).format("YYYY-MM-DD"),
        selectedSports,
        false,
        item
      );
    } else if (item === "list") {
      setCurrentPage(0);
      fetchEventData(
        currentPage,
        searchValue,
        selectedTeams,
        selectedVenues,
        selectedOrg,
        selectedStartDate,
        selectedEndDate,
        selectedSports,
        false,
        item
      );
      // fetchEventData(
      //   currentPage,
      //   searchValue,
      //   selectedTeams,
      //   selectedVenues,
      //   selectedOrg,
      //   moment(Date())
      //     .startOf("week") //isoWeek
      //     .format("YYYY-MM-DD"),
      //   moment(Date())
      //     .endOf("week")
      //     .format("YYYY-MM-DD"),
      //   selectedSports
      // );
    }
  };
  const getFields = (list, field) => {
    return list.reduce(function(carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };
  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(`/sports/sport`);
      if (status === 200) {
        const allSportId = [
          ...release[Config.release]?.sportId,
          ...release[Config.release]?.raceSportId
        ];
        const releaseFilterSportData = data?.result?.filter(item =>
          allSportId?.includes(item?.id)
        );
        var sportsdata = releaseFilterSportData?.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        var filterSports = getFields(sportsdata, "id");
        setSelectedSports(filterSports);
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var mergedArray = _.concat(
          [{ label: "ALL", value: filterSports, isAll: true }],
          sdata
        );
        setSports(mergedArray);
        fetchEventData(
          currentPage,
          searchValue,
          selectedTeams,
          selectedVenues,
          selectedOrg,
          moment(Date()).format("YYYY-MM-DD"),
          moment(Date()).format("YYYY-MM-DD"),
          filterSports
        );
      }
    } catch (err) {}
  };

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        setOrgAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };
  const fetchTeamData = async (page, sID, teamsAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/team?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setTeamApiCount(teamApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(teamsAll, newdata);
        setTeamsAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };
  const fetchVenData = async (page, sID, VenAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/stadium?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setVenApiCount(teamApiCount + 1);
        setcountVen(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(VenAll, newdata);
        setVenAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };
  const fetchEventData = async (
    cPage,
    search,
    teamid,
    tournamentid,
    orgid,
    startdate,
    enddate,
    sportId,
    IsMonth,
    sFil
  ) => {
    setEventLoader(true);
    var sid = sportId ? sportId : selectedSports;
    var SportId = sid && sid.length > 0 ? sid.toString() : sid;
    var sdate =
      IsMonth && startdate && sFil === "month"
        ? startdate
        : sFil === "day"
        ? startdate
        : !sFil && startdate
        ? startdate
        : sFil === "month" && selectedMonth
        ? selectedMonth
        : sFil === "list"
        ? startdate
        : "";
    // IsMonth && startdate && filter === "month"
    //   ? startdate
    //   : filter === "day"
    //   ? startdate
    //   : !filter && startdate
    //   ? startdate
    //   : filter === "month" && selectedMonth
    //   ? selectedMonth
    //   : sFil === "list"
    //   ? startdate
    //   : "";
    var edate =
      sFil === "list"
        ? enddate
        : filter === "day"
        ? startdate
        : !sFil && enddate
        ? enddate
        : sFil === "month"
        ? enddate
        : "";
    // var edate =
    //   filter === "day"
    //     ? startdate
    //     : !filter && enddate
    //     ? enddate
    //     : filter === "month"
    //     ? enddate
    //     : sFil === "list"
    //     ? enddate
    //     : "";
    var dur =
      sFil === "day"
        ? false
        : IsMonth
        ? true
        : filter === "month"
        ? true
        : startdate && enddate
        ? false
        : true;
    // var dur =
    //   filter === "day"
    //     ? false
    //     : IsMonth
    //     ? true
    //     : filter === "month"
    //     ? true
    //     : startdate && enddate
    //     ? false
    //     : true;
    var flimit = sFil === "month" || sFil === "month" ? "" : limit;
    var curPage = sFil === "month" || sFil === "month" ? "" : cPage;
    // var flimit = sFil === "month" || filter === "month" ? "" : limit;
    // var curPage = sFil === "month" || filter === "month" ? "" : cPage;
    try {
      const { status, data } = await axiosInstance.get(
        `/eventCalendar/eventCalender?startDate=${sdate}&endDate=${edate}&timezone=${timezone}&teamId=${teamid}&stadiumId=${tournamentid}&tournamentid=${orgid}&limit=${flimit}&page=${curPage}&search=${search}&sportId=${SportId}&duration=${dur}`
      );
      if (status === 200) {
        data && setEventData(data?.result);
        data && setEventListData(data);
        setEventLoader(false);
      }
    } catch (err) {
      setEventLoader(false);
    }
  };
  const fetchMonthlyEvents = async (
    cPage,
    search,
    teamid,
    tournamentid,
    orgid,
    startdate,
    enddate,
    sportId,
    IsMonth,
    sFil
  ) => {
    setEventLoader(true);
    var sid = sportId ? sportId : selectedSports;
    var SportId = sid && sid.length > 0 ? sid.toString() : sid;
    var sdate =
      IsMonth && startdate && sFil === "month"
        ? startdate
        : sFil === "day"
        ? startdate
        : !sFil && startdate
        ? startdate
        : sFil === "month" && selectedMonth
        ? selectedMonth
        : sFil === "list"
        ? startdate
        : "";
    var edate =
      sFil === "list"
        ? enddate
        : sFil === "day"
        ? startdate
        : !sFil && enddate
        ? enddate
        : sFil === "month"
        ? enddate
        : "";
    var dur =
      sFil === "day"
        ? false
        : IsMonth
        ? true
        : filter === "month"
        ? true
        : startdate && enddate
        ? false
        : true;
    var flimit = sFil === "month" || sFil === "month" ? "" : limit;
    var curPage = sFil === "month" || sFil === "month" ? "" : cPage;
    try {
      const { status, data } = await axiosInstance.get(
        `eventCalendar/monthEventcalendar?startDate=${sdate}&endDate=${edate}&timezone=${timezone}&teamId=${teamid}&stadiumId=${tournamentid}&tournamentid=${orgid}&search=${search}&sportId=${SportId}`
      );
      if (status === 200) {
        data && setEventData(data?.result);
        data && setEventListData(data);
        setEventLoader(false);
      }
    } catch (err) {
      setEventLoader(false);
    }
  };
  const handleTeamInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/team?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchTeamCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchTeam, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchTeam(filterData);
          // setsearchTeam(
          //   _.uniqBy(filterData, function(e) {
          //     return e.value;
          //   })
          // );
          setIsTeamSearch(value);
        }
      });
  };
  const handleVenInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/stadium?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchVenCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchVen, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchVen(filterData);
          setIsVenSearch(value);
        }
      });
  };
  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };
  const handleOnScrollBottomTeams = () => {
    if (
      isTeamSearch !== "" &&
      searchTeamCount !== Math.ceil(SearchTeampage / 20)
    ) {
      handleTeamInputChange(SearchTeampage + 20, isTeamSearch, selectedSports);

      setSearchTeampage(SearchTeampage + 20);
    } else {
      if (count !== 0 && count !== Math.ceil(pageTeam / 20 + 1)) {
        fetchTeamData(pageTeam + 20, selectedSports, teamsAll);
        setpageTeam(pageTeam + 20);
      }
    }
  };
  const handleOnScrollBottomVen = () => {
    if (
      isVenSearch !== "" &&
      searchVenCount !== Math.ceil(SearchVenpage / 20)
    ) {
      handleVenInputChange(SearchVenpage + 20, isVenSearch, selectedSports);

      setSearchTeampage(SearchVenpage + 20);
    } else {
      if (countVen !== 0 && countVen !== Math.ceil(pageVen / 20 + 1)) {
        fetchVenData(pageVen + 20, selectedSports, VenAll);
        setpageVen(pageVen + 20);
      }
    }
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  useEffect(() => {
    // var start_week = moment(Date())
    //   .startOf("weeks")
    //   .startOf("week")
    //   .format("YYYY-MM-DD");
    // var start_week = moment(Date())
    //   .startOf("week")
    //   .format("YYYY-MM-DD");
    // var end_week = moment(Date())
    //   .endOf("weeks")
    //   .startOf("week")
    //   .format("YYYY-MM-DD");
    // var end_week = moment(Date())
    //   .endOf("week")
    //   .format("YYYY-MM-DD");

    // fetchEventData(
    //   currentPage,
    //   searchValue,
    //   selectedTeams,
    //   selectedVenues,
    //   selectedOrg,
    //   moment(Date())
    //     .startOf("week") //isoWeek
    //     .format("YYYY-MM-DD"),
    //   moment(Date())
    //     .endOf("week")
    //     .format("YYYY-MM-DD"),
    //   selectedSports
    // );

    setselectedStartDateDay(
      moment(Date())
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    setCurrentPage(0);
    // fetchEventData(
    //   0,
    //   searchValue,
    //   selectedTeams,
    //   selectedVenues,
    //   selectedOrg,
    //   moment(Date()).format("YYYY-MM-DD"),
    //   moment(Date()).format("YYYY-MM-DD"),
    //   selectedSports
    // );

    fetchSportData();
    // fetchTournamentData();
    // fetchTeamData();
    // fetchStadiumData();
  }, []);
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isTeamSelectOpen) {
          if (isTeamSearch === "" && teamApiCount !== count) {
            fetchTeamData(pageTeam + 20, selectedSports, teamsAll);
            setpageTeam(pageTeam + 20);
          }
        }
      }, 2000);
    }
  }, [isTeamSelectOpen, teamApiCount, isTeamSearch]);
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isVenSelectOpen) {
          if (isVenSearch === "" && VenApiCount !== count) {
            fetchVenData(pageVen + 20, selectedSports, VenAll);
            setpageVen(pageVen + 20);
          }
        }
      }, 2000);
    }
  }, [isVenSelectOpen, teamApiCount, isVenSearch]);
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isOrgSelectOpen) {
          if (isOrgSearch === "" && OrgApiCount !== count) {
            fetchOrgData(pageTeam + 20, selectedSports, OrgAll);
            setpageOrg(pageTeam + 20);
          }
        }
      }, 2000);
    }
  }, [isOrgSelectOpen, teamApiCount, isOrgSearch]);

  const previousEvents = () => {
    // var preEvent = moment(selectedStartDate).week(-1);
    // var preEvent = moment(selectedStartDate).subtract(1, "weeks");
    var preEventStart = moment(selectedStartDate).subtract(7, "days");
    var preEventEnd = moment(preEventStart).add(6, "days");

    setselectedStartDate(
      moment(preEventStart)
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    setselectedEndDate(
      moment(preEventEnd)
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    fetchEventData(
      currentPage,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      moment(preEventStart)
        .tz(timezone)
        .format("YYYY-MM-DD"),
      moment(preEventEnd)
        .tz(timezone)
        .format("YYYY-MM-DD"),
      selectedSports
    );
  };
  const nextEvents = () => {
    var nextEventStart = moment(selectedStartDate).add(7, "days");
    var nextEventEnd = moment(nextEventStart).add(6, "days");
    setselectedStartDate(
      moment(nextEventStart)
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    setselectedEndDate(
      moment(nextEventEnd)
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    fetchEventData(
      currentPage,
      searchValue,
      selectedTeams,
      selectedVenues,
      selectedOrg,
      moment(nextEventStart)
        .tz(timezone)
        .format("YYYY-MM-DD"),
      moment(nextEventEnd)
        .tz(timezone)
        .format("YYYY-MM-DD"),
      selectedSports
    );
  };

  const clearDate = () => {
    setselectedStartDateDay(moment(Date()).format("YYYY-MM-DD"));
    setOpen(false);
  };
  const clearStartDate = () => {
    setselectedStartDate(moment(Date()).format("YYYY-MM-DD"));
    setOpen(false);
  };
  const clearEndDate = () => {
    setEndOpen(false);
    setselectedEndDate(
      moment(Date())
        .add(6, "days")
        .format("YYYY-MM-DD")
    );
  };

  return (
    <>
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        {fetchPageHeaderAds(
          "218px",
          Config?.release == "IN" ? bannerIndia : banner
        )}
        <Box className="league-data-wrap">
          <Box className="teamsport-data-header">
            <Box className="bredcrumn-deatils">
              <Box className="bredcrumn-wrap">
                <Breadcrumbs
                  separator="/"
                  aria-label="breadcrumb"
                  className="breadcrumb"
                >
                  <Link underline="hover" color="inherit" to="/">
                    Home
                  </Link>
                  <Typography>Events</Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Typography variant="h1">Events</Typography>
          </Box>
          <Box className="event-calendar">
            <Box className="event-calendar-top">
              <SelectList onSelect={onSelectFilter} />
              <TextField
                placeholder="Search (for events)"
                variant="outlined"
                type="text"
                name="search"
                className="search"
                value={searchValue}
                onChange={e => {
                  setEventLoader(true);
                  setSearchValue(e.target.value);
                  setCurrentPage(0);
                  filter === "month"
                    ? fetchMonthlyEvents(
                        0,
                        e.target.value,
                        selectedTeams,
                        selectedVenues,
                        selectedOrg,
                        selectedMonth,
                        moment(selectedMonth)
                          .endOf("month")
                          .format("YYYY-MM-DD"),
                        selectedSports
                      )
                    : fetchEventData(
                        0,
                        e.target.value,
                        selectedTeams,
                        selectedVenues,
                        selectedOrg,
                        filter === "month"
                          ? selectedMonth
                          : filter === "day"
                          ? selectedStartDateDay
                          : selectedStartDate,
                        filter === "month"
                          ? ""
                          : filter === "day"
                          ? selectedStartDateDay
                          : selectedEndDate,
                        selectedSports
                      );
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
              <Box className="event-cal-filter">
                <Box className="filter-sec">
                  <Box className="select-wrap">
                    <Select
                      className="React series-select"
                      value={sports?.find(item => {
                        return item?.value === selectedSports;
                      })}
                      onChange={e => {
                        setSelectedSports(e?.value == 0 ? "" : e?.value);
                        setCurrentPage(0);
                        setSelectedTeams("");
                        setSelectedVenues("");
                        setSelectedOrg("");
                        setTeamsAll([]);
                        setVenAll([]);
                        setOrgAll([]);
                        setHasMore(false);

                        filter === "month"
                          ? fetchMonthlyEvents(
                              0,
                              searchValue,
                              "",
                              "",
                              "",
                              selectedMonth,
                              moment(selectedMonth)
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                              e?.value
                            )
                          : fetchEventData(
                              0,
                              searchValue,
                              "",
                              "",
                              "",
                              filter === "month"
                                ? selectedMonth
                                : filter === "day"
                                ? selectedStartDateDay
                                : selectedStartDate,
                              filter === "month"
                                ? ""
                                : filter === "day"
                                ? selectedStartDateDay
                                : selectedEndDate,
                              e?.value
                            );

                        if (
                          e?.value !== 1 &&
                          e?.value !== 2 &&
                          e?.value !== 3 &&
                          !e?.isAll
                        ) {
                          fetchOrgData(0, e?.value, []);
                          fetchTeamData(0, e?.value, []);
                          fetchVenData(0, e?.value, []);
                        }
                      }}
                      // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                      options={sports}
                      classNamePrefix="select"
                      placeholder="Sports"
                      // isSearchable={false}
                      // onFocus={() => setTournamentSelect(true)}
                      // onBlur={() => setTournamentSelect(false)}
                      components={{ DropdownIndicator }}
                    />
                  </Box>
                  {selectedSports &&
                    selectedSports !== 1 &&
                    selectedSports !== 2 &&
                    selectedSports !== 3 && (
                      <>
                        <Box className="select-wrap">
                          <Select
                            className="React series-select"
                            // options={teamsAll}
                            onMenuScrollToBottom={e =>
                              handleOnScrollBottomTeams(e)
                            }
                            onInputChange={e =>
                              handleTeamInputChange(0, e, selectedSports)
                            }
                            onChange={e => {
                              setSelectedTeams(e?.value);
                              fetchTeamData(e?.value, selectedSports, teamsAll);
                              setTeamApiCount(0);
                              setpageTeam(0);
                              setHasMore(false);
                              setisTeamSelectOpen(false);
                              setCurrentPage(0);
                              filter === "month"
                                ? fetchMonthlyEvents(
                                    0,
                                    searchValue,
                                    e?.value,
                                    selectedVenues,
                                    selectedOrg,
                                    selectedMonth,
                                    moment(selectedMonth)
                                      .endOf("month")
                                      .format("YYYY-MM-DD"),
                                    selectedSports
                                  )
                                : fetchEventData(
                                    0,
                                    searchValue,
                                    e?.value,
                                    selectedVenues,
                                    selectedOrg,
                                    filter === "month"
                                      ? selectedMonth
                                      : filter === "day"
                                      ? selectedStartDateDay
                                      : selectedStartDate,
                                    filter === "month"
                                      ? ""
                                      : filter === "day"
                                      ? selectedStartDateDay
                                      : selectedEndDate,
                                    selectedSports
                                  );
                            }}
                            onFocus={() => setisTeamSelectOpen(true)}
                            onBlur={() => setisTeamSelectOpen(false)}
                            value={
                              selectedTeams !== "" &&
                              (isTeamSearch
                                ? searchTeam?.find(item => {
                                    return item?.value == selectedTeams;
                                  })
                                : teamsAll?.find(item => {
                                    return item?.value == selectedTeams;
                                  }))
                            }
                            options={isTeamSearch ? searchTeam : teamsAll}
                            classNamePrefix="select"
                            placeholder="Teams"
                            isDisabled={
                              selectedSports && selectedSports.length > 0
                                ? true
                                : false
                            }
                            components={{ DropdownIndicator }}
                          />
                        </Box>
                        <Box className="select-wrap">
                          <Select
                            className="React series-select"
                            onMenuScrollToBottom={e =>
                              handleOnScrollBottomVen(e)
                            }
                            onInputChange={e =>
                              handleVenInputChange(0, e, selectedSports)
                            }
                            onChange={e => {
                              setSelectedVenues(e?.value);
                              fetchVenData(e?.value, selectedSports, VenAll);
                              setVenApiCount(0);
                              setpageVen(0);
                              setisVenSelectOpen(false);
                              setHasMore(false);
                              setCurrentPage(0);
                              filter === "month"
                                ? fetchMonthlyEvents(
                                    0,
                                    searchValue,
                                    selectedTeams,
                                    e?.value,
                                    selectedOrg,
                                    selectedMonth,
                                    moment(selectedMonth)
                                      .endOf("month")
                                      .format("YYYY-MM-DD"),
                                    selectedSports
                                  )
                                : fetchEventData(
                                    0,
                                    searchValue,
                                    selectedTeams,
                                    e?.value,
                                    selectedOrg,
                                    filter === "month"
                                      ? selectedMonth
                                      : filter === "day"
                                      ? selectedStartDateDay
                                      : selectedStartDate,
                                    filter === "month"
                                      ? ""
                                      : filter === "day"
                                      ? selectedStartDateDay
                                      : selectedEndDate,
                                    selectedSports
                                  );
                            }}
                            onFocus={() => setisVenSelectOpen(true)}
                            onBlur={() => setisVenSelectOpen(false)}
                            value={
                              selectedVenues !== "" &&
                              (isVenSearch
                                ? searchVen?.find(item => {
                                    return item?.value == selectedVenues;
                                  })
                                : VenAll?.find(item => {
                                    return item?.value == selectedVenues;
                                  }))
                            }
                            options={isVenSearch ? searchVen : VenAll}
                            classNamePrefix="select"
                            placeholder="Venues"
                            isDisabled={
                              selectedSports && selectedSports.length > 0
                                ? true
                                : false
                            }
                            components={{ DropdownIndicator }}
                          />
                        </Box>
                        <Box className="select-wrap">
                          <Select
                            className="React series-select"
                            onMenuScrollToBottom={e =>
                              handleOnScrollBottomOrg(e)
                            }
                            onInputChange={e =>
                              handleOrgInputChange(0, e, selectedSports)
                            }
                            onChange={e => {
                              setSelectedOrg(e?.value);
                              fetchOrgData(e?.value, selectedSports, OrgAll);
                              setOrgApiCount(0);
                              setpageOrg(0);
                              setisOrgSelectOpen(false);
                              setCurrentPage(0);
                              setHasMore(false);
                              filter === "month"
                                ? fetchMonthlyEvents(
                                    0,
                                    searchValue,
                                    selectedTeams,
                                    selectedVenues,
                                    e?.value,
                                    selectedMonth,
                                    moment(selectedMonth)
                                      .endOf("month")
                                      .format("YYYY-MM-DD"),
                                    selectedSports
                                  )
                                : fetchEventData(
                                    0,
                                    searchValue,
                                    selectedTeams,
                                    selectedVenues,
                                    e?.value,
                                    filter === "month"
                                      ? selectedMonth
                                      : filter === "day"
                                      ? selectedStartDateDay
                                      : selectedStartDate,
                                    filter === "month"
                                      ? ""
                                      : filter === "day"
                                      ? selectedStartDateDay
                                      : selectedEndDate,
                                    selectedSports
                                  );
                            }}
                            onFocus={() => setisOrgSelectOpen(true)}
                            onBlur={() => setisOrgSelectOpen(false)}
                            value={
                              selectedOrg !== "" &&
                              (isOrgSearch
                                ? searchOrg?.find(item => {
                                    return item?.value == selectedOrg;
                                  })
                                : OrgAll?.find(item => {
                                    return item?.value == selectedOrg;
                                  }))
                            }
                            options={isOrgSearch ? searchOrg : OrgAll}
                            classNamePrefix="select"
                            placeholder="Organisations"
                            isDisabled={
                              selectedSports && selectedSports.length > 0
                                ? true
                                : false
                            }
                            components={{ DropdownIndicator }}
                          />
                        </Box>
                      </>
                    )}
                </Box>
                <Box
                  className={
                    filter === "month" ? "month-sec date-sec" : "date-sec"
                  }
                >
                  {filter === "day" ? (
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      className="date-picker"
                    >
                      <KeyboardDatePicker
                        clearable
                        autoOk
                        clearLabel="Reset"
                        // disableToolbar
                        // variant="inline"
                        format="dd/MM/yyyy"
                        placeholder="DD/MM/YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        inputVariant="outlined"
                        value={selectedStartDateDay}
                        onChange={e => handleDateChangeDay(e)}
                        keyboardIcon={<Datepicker className="keyboard-icon" />}
                        views={["year", "month", "date"]}
                        onClear={clearDate}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        // disableFuture={value == 8}
                        // disablePast={value == 7}
                        // style={{ marginRight: 5 }}
                        className="details-date-picker details-day-date-picker"
                      />
                    </MuiPickersUtilsProvider>
                  ) : filter === "month" ? (
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      className="date-picker"
                    >
                      <DatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        // format="MM"
                        placeholder="Select month"
                        // openTo="month"
                        views={["year", "month"]}
                        margin="normal"
                        id="date-picker-inline"
                        inputVariant="outlined"
                        value={selectedMonth}
                        onChange={e => handleMonthChange(e)}
                        showYearDropdown
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <Datepicker />
                            </IconButton>
                          )
                        }}
                        // disableFuture={value == 8}
                        // disablePast={value == 7}
                        // style={{ marginRight: 5 }}
                        className="details-date-picker details-day-date-picker"
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    // <DatePicker
                    //   selected={endDate}
                    //   dateFormat="dd MMM yyyy"
                    //   onChange={handleEndDateChange}
                    //   className="date-picker-input"
                    // />
                    <>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        className="date-picker"
                      >
                        <KeyboardDatePicker
                          autoOk
                          clearable
                          clearLabel="Reset"
                          // disableToolbar
                          // variant="inline"
                          format="dd/MM/yyyy"
                          placeholder="Date range (from)"
                          margin="normal"
                          id="date-picker-inline"
                          inputVariant="outlined"
                          value={selectedStartDate}
                          onChange={e => handleDateChange(e)}
                          keyboardIcon={
                            <Datepicker className="keyboard-icon" />
                          }
                          views={["year", "month", "date"]}
                          onClear={clearStartDate}
                          open={open}
                          onOpen={() => setOpen(true)}
                          onClose={() => setOpen(false)}
                          // disableFuture={value == 8}
                          // disablePast={value == 7}
                          // style={{ marginRight: 5 }}
                          className="details-date-picker"
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        className="date-picker"
                      >
                        <KeyboardDatePicker
                          autoOk
                          clearable
                          clearLabel="Reset"
                          // disableToolbar
                          // variant="inline"
                          format="dd/MM/yyyy"
                          placeholder="Date range (to)"
                          margin="normal"
                          id="date-picker-inline"
                          inputVariant="outlined"
                          value={selectedEndDate}
                          onChange={e => handleEndDateChange(e)}
                          keyboardIcon={
                            <Datepicker className="keyboard-icon" />
                          }
                          views={["year", "month", "date"]}
                          onClear={clearEndDate}
                          open={endOpen}
                          onOpen={() => setEndOpen(true)}
                          onClose={() => setEndOpen(false)}
                          // disableFuture={value == 8}
                          // disablePast={value == 7}
                          // style={{ marginRight: 5 }}
                          className="details-date-picker"
                        />
                      </MuiPickersUtilsProvider>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="event-calendar-bottom">
              {filter === "day" ? (
                <EventDay
                  eventData={eventData}
                  eventListData={eventListData}
                  limit={limit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  eventLoader={eventLoader}
                  fetchEventData={fetchEventData}
                  selectedStartDateDay={selectedStartDateDay}
                  selectedTeams={selectedTeams}
                  selectedVenues={selectedVenues}
                  selectedOrg={selectedOrg}
                  selectedSports={selectedSports}
                  searchValue={searchValue}
                  setselectedStartDateDay={setselectedStartDateDay}
                />
              ) : filter === "month" ? (
                <EventMonth
                  eventData={eventData}
                  selectedMonth={selectedMonth}
                  fetchEventData={fetchMonthlyEvents}
                  currentPage={currentPage}
                  selectedTeams={selectedTeams}
                  selectedVenues={selectedVenues}
                  selectedOrg={selectedOrg}
                  selectedSports={selectedSports}
                  searchValue={searchValue}
                  onchangeSelectedMonth={onchangeSelectedMonth}
                  eventLoader={eventLoader}
                  filter={filter}
                  hasMore={hasMore}
                  setHasMore={setHasMore}
                />
              ) : (
                <EventData
                  eventData={eventData}
                  fetchEventData={fetchEventData}
                  searchValue={searchValue}
                  eventListData={eventListData}
                  limit={limit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  selectedStartDate={selectedStartDate}
                  selectedTeams={selectedTeams}
                  selectedVenues={selectedVenues}
                  selectedOrg={selectedOrg}
                  selectedEndDate={selectedEndDate}
                  selectedSports={selectedSports}
                  previousEvents={previousEvents}
                  nextEvents={nextEvents}
                  eventLoader={eventLoader}
                />
              )}
            </Box>
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default EventsCalendarPage;
