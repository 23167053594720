import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context";
import MainMenubar from "./MainMenuBar";
import "src/layouts/layout.scss";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "rgb(0 0 0 / 60%)",
    display: "flex",
    minHeight: "100vh",
    overflow: "hidden",
    width: "100%",
    alignItems: "center",
    "@media(max-width: 600px)": {
      background: "#F5F5F5",
      flexDirection: "column"
    }
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    background: "#ffffff"
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto"
  },
  boxContainer: {
    margin: "0 auto",
    "@media(max-width: 1280px)": {
      width: "100%",
      margin: "27px 12px"
    },
    "@media(max-width: 600px)": {
      margin: "0"
    }
    // "@media(max-width: 479px)": {
    //   margin: "180px 12px 27px 12px"
    // }
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const redux_auth = useSelector(state => state?.reduxData?.authData);
  const statics = useSelector(state => state?.reduxData?.user_statics);
  const user_statics = statics ? statics : fetchFromStorage("user_statics");
  const auth = redux_auth ? redux_auth : fetchFromStorage("authData");
  const verify_count = useSelector(
    state => state?.reduxData?.user_profile_count
  );

  useEffect(() => {
    const popUpIntervalId = fetchFromStorage("popup_intervalId");
    clearTimeout(popUpIntervalId);
    localStorage.removeItem("popup_intervalId");
  }, []);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <ToastContainer style={{ padding: "5px" }} limit={1} />
      <>
        <MainMenubar
          auth={auth}
          user_statics={user_statics}
          verify_count={verify_count}
          onMobileNavOpen={() => setMobileNavOpen(true)}
        />
      </>
      <div className={classes.boxContainer}>
        {/* <div className={classes.contentContainer}>
          <div className={classes.content} > */}
        <Outlet />
        {/* </div>
        </div> */}
      </div>
    </div>
  );
};

export default DashboardLayout;
