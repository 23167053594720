import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Placeholder from "../../../../../assets/images/placeOrder.png";
import NewaSlider1 from "../../../../../assets/images/newsSlider1.png";
import NewaSlider2 from "../../../../../assets/images/newsSlider2.png";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import OurPartner from "src/views/component/ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import BelowSlider from "../../../../../assets/images/ad-placeholder/sports2.webp";
import AboveOurPartner from "../../../../../assets/images/ad-placeholder/sports5.webp";
import BelowOurPartner from "../../../../../assets/images/ad-placeholder/homebelow-ourpartner.webp";
import BannerIndia from "../../../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.png";
import DefaultImg from "../../../../../assets/images/smartb_default.png";
import Loader from "src/components/Loader";
import moment from "moment-timezone";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NoDataComp from "src/views/component/UI/NoData";
import he from "he";
import { Config } from "src/helpers/context";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CategoryNews = ({
  categoryData,
  isCategoryLoading,
  fetchNewsCategoryData,
  categoryList,
  searchValue
}) => {
  const params = useParams();
  const silderRef = useRef(null);
  const URL = window?.location?.origin;
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [AdData, setAdData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [offset, setoffset] = useState(0);
  const [categoryCount, setcategoryCount] = useState(0);
  const [SliderData, setSliderData] = useState([]);
  const [isSliderLoading, setIsSliderLoading] = useState(false);
  const rowPerPage = 5;
  const [visibleAds, setVisibleAds] = useState([]);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
  }, []);

  useEffect(() => {
    if (categoryList?.length > 0) {
      fetchCategoryName(categoryList);
    }
  }, [categoryList, params?.categoryId]);

  useEffect(() => {
    if (categoryData) {
      setcategoryCount(categoryData?.count);
    }
  }, [categoryData]);
  useEffect(() => {
    fetchAllSliderData();
    fetchNewsCategoryData(
      params?.categoryId === "1000" ? 0 : params?.categoryId,
      0,
      searchValue
    );
    setcurrentPage(1);
  }, [params]);

  const fetchCategoryName = categoryList => {
    let categoryName = categoryList?.filter(
      item => item?.value == params?.categoryId
    );
    setCategoryName(categoryName?.[0]?.label);
  };

  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} hours ago`;
      }
    } else {
      return `${timeDiffMinutes} minutes ago`;
    }
  };
  const handlePaginationClick = (event, page) => {
    setcurrentPage(Number(page));
    setoffset((Number(page) - 1) * rowPerPage);
    fetchNewsCategoryData(
      params?.categoryId === "1000" ? 0 : params?.categoryId,
      (Number(page) - 1) * rowPerPage,
      searchValue
    );
  };

  const pageNumbers = [];

  if (categoryCount > 0) {
    for (let i = 1; i <= Math.ceil(categoryCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }
  const fetchAllSliderData = async () => {
    try {
      setIsSliderLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/feature/articles?NewsCategoryId=${params?.categoryId}`
      );
      if (status === 200) {
        setSliderData(data?.result);
        setIsSliderLoading(false);
      } else {
        setIsSliderLoading(false);
      }
    } catch (err) {
      setIsSliderLoading(false);
    }
  };
  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) {}
  // };

  const fetchAdsData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=14&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: SliderData?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 610,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const fetchNewsSubtitle = data => {
    const parseHTMLContent = body => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = content => {
      return he.decode(content);
    };
    const removeHtmlTags = htmlString => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = content => {
      return (
        <Typography className="sub-details">
          {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)}
          <span className="read-more">...read more</span>
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        item =>
          item?.type === "editor_block" && item?.data?.type === "paragraph"
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };
  return (
    <>
      <Box className="all-news category-news">
        <Box className="news-slider">
          {isSliderLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <Slider {...settings} ref={silderRef}>
              {SliderData.length > 0 &&
                SliderData?.map((item, index) => (
                  <Grid
                    key={index}
                    container
                    className="slide-cont"
                    // onClick={() =>
                    //   // navigate(
                    //   //   `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`
                    //   // )
                    // }
                  >
                    <NavLink
                      to={`/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticleId}`}
                    >
                      <Grid
                        item
                        lg={6}
                        key={item?.NewsArticle?.id}
                        className="slide-single-box"
                      >
                        <Box
                          className={
                            SliderData?.length === 1
                              ? "single-silder single-silder-object"
                              : "single-silder"
                          }
                          // style={{
                          //   backgroundImage: `url(${item?.NewsArticle?.mainMedia?.gallery?.url})`
                          // }}
                        >
                          <Box className="slider-img">
                            <img
                              src={
                                item?.NewsArticle?.mainMedia?.gallery
                                  ? item?.NewsArticle?.mainMedia?.gallery
                                      ?.url &&
                                    item?.NewsArticle?.mainMedia?.gallery
                                      ?.url !== ""
                                    ? item?.NewsArticle?.mainMedia?.gallery?.url
                                    : DefaultImg
                                  : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                  ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url &&
                                    item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url !== ""
                                    ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url
                                    : DefaultImg
                                  : DefaultImg
                              }
                              alt={
                                item?.NewsArticle?.mainMedia?.gallery
                                  ? item?.NewsArticle?.mainMedia?.gallery?.alt
                                  : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                  ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      .alt
                                  : ""
                              }
                            />
                          </Box>
                          <span className="news-tag">{categoryName}</span>
                          <Box className="details-back">
                            <Box className="slider-news-deatils">
                              <Link
                                to="#"
                                dangerouslySetInnerHTML={{
                                  __html: item?.NewsArticle?.title
                                }}
                              ></Link>
                              <Typography>
                                {fetchNewsTime(
                                  item?.NewsArticle?.rapidCreatedAt
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </NavLink>
                  </Grid>
                ))}
            </Slider>
          )}
        </Box>
        {fetchAds(
          1,
          "100%",
          Config?.release == "IN" ? BannerIndia : BelowSlider
        )}
        {isCategoryLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : categoryData?.raw?.length > 0 ? (
          <>
            <Typography variant="h4">{categoryName} news</Typography>
            <Grid container className="individual-news-cont">
              {categoryData?.raw?.map(item => (
                <Grid
                  item
                  xs={12}
                  className="individual-single-news"
                  key={item?.id}
                  // onClick={() =>
                  //   // navigate(`/news/${item?.NewsCategoryId}/${item?.id}`)
                  // }
                >
                  <NavLink to={`/news/${item?.NewsCategoryId}/${item?.id}`}>
                    <Box className="news-box">
                      <Box className="news-img">
                        <img
                          src={
                            item?.mainMedia?.gallery
                              ? item?.mainMedia?.gallery?.url &&
                                item?.mainMedia?.gallery?.url !== ""
                                ? item?.mainMedia?.gallery?.url
                                : DefaultImg
                              : item?.mainMedia?.[0]?.gallery
                              ? item?.mainMedia?.[0]?.gallery?.url &&
                                item?.mainMedia?.[0]?.gallery?.url !== ""
                                ? item?.mainMedia?.[0]?.gallery?.url
                                : DefaultImg
                              : DefaultImg
                          }
                          alt="placeholder"
                        />
                      </Box>
                      <Box className="individual-news-details">
                        <Typography
                          className="details"
                          dangerouslySetInnerHTML={{
                            __html: item?.title ? item?.title : item?.subTitle
                          }}
                        ></Typography>

                        <Box className="sub-details-wrap">
                          {fetchNewsSubtitle(item)}
                        </Box>
                        <Box className="tag-time-details">
                          {/* <Box className="news-tags">
                            {screenWidth > 860
                              ? item?.NewsTagRelations?.slice(0, 3).map(
                                  (data, index) => {
                                    return (
                                      <span
                                        className="individual-news-tag"
                                        key={index}
                                      >
                                        {data?.NewsTag?.title}
                                      </span>
                                    );
                                  }
                                )
                              : item?.NewsTagRelations?.slice(0, 1).map(
                                  (data, index) => {
                                    return (
                                      <span
                                        className="individual-news-tag"
                                        key={index}
                                      >
                                        {data?.NewsTag?.title}
                                      </span>
                                    );
                                  }
                                )}
                          </Box> */}
                          <Typography className="stories-time">
                            <span className="watch">
                              <Watch />
                            </span>
                            {fetchNewsTime(item?.rapidCreatedAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </NavLink>
                </Grid>
              ))}
            </Grid>
            <Box className="news-Pagination">
              {/* {params?.categoryId != 1000 ? ( */}
              <Pagination
                // hideNextButton
                // hidePrevButton
                disabled={categoryCount / rowPerPage > 1 ? false : true}
                page={currentPage}
                onChange={(e, value) => handlePaginationClick(e, value)}
                count={pageNumbers[pageNumbers?.length - 1]}
                siblingCount={2}
                boundaryCount={1}
                // count={10}
                size="small"
              />
              {/* ) : (
                <></>
              )} */}
            </Box>
          </>
        ) : (
          <>
            <Box style={{ textAlign: "center" }}>
              {/* No Data Available */}
              <NoDataComp />
            </Box>
          </>
        )}
      </Box>
      {fetchAds(
        2,
        "100%",
        Config?.release == "IN" ? BannerIndia : AboveOurPartner
      )}
      <OurPartner />
      {Config?.release == "AU" && fetchAds(3, "102px", BelowOurPartner)}
    </>
  );
};

export default CategoryNews;
