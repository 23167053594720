import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab } from "@material-ui/core";
import banner from "../../../../assets/images/banner/smart-b-banner-primary.png";
import AdvertisingBannner from "../../advertisingBanner";
import InformationTab from "./InformationTab";
import ReviewTab from "./ReviewTab";
import { useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import OurPartner from "../../ourPartners";
import { Config, fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../../AdBanner/AdBanner";
import "./bookmakersinfo.scss";

const tabsData = [
  { id: 0, name: "InformAtion" },
  { id: 1, name: "Review" }
];

const BookMakersInfoPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);

  const params = useParams();
  const [tabvalue, setTabValue] = useState(0);
  const [EventPage, setEventPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [bookkeeperData, setBookkeeperData] = useState({});
  const [infoTabData, setInfoTabData] = useState({});
  const [reviewTabData, setReviewTabData] = useState({});
  const [loader, setLoader] = useState(false);
  const [reviewUserList, setReviewUserList] = useState([]);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  useEffect(() => {
    if (location?.state?.data) {
      setBookkeeperData(location?.state?.data && location?.state?.data);
      getInfomationTabDataAPI(params?.id);
      getReviewTabDataAPI(params?.id, EventPage);
    } else {
      getBookmakersList();
      getInfomationTabDataAPI(params?.id);
      getReviewTabDataAPI(params?.id, EventPage);
    }
  }, [location?.state?.data, params?.id]);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    // try {
    //   const { status, data } = await axiosInstance.get(
    //     `getAllAds?page_id=1,11`
    //   );
    //   if (status === 200) {
    let AdvtData = ReduxAdsData?.filter(item => item?.page_id === 1);
    setAdData(AdvtData);
    // dispatch(fetchAdvtData(AdvtData?.[0]));
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const getBookmakersList = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setLoader(false);
        if (data?.result && data?.result?.length > 0) {
          const dataR = data?.result?.find(
            item => Number(item?.BookKeeper?.id) === Number(params?.id)
          );
          setBookkeeperData(dataR);
        }
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("error", err);
    }
  };

  const handleTabChange = (event, value) => {
    if (value === 1) {
      if (handleRestrictedUser()) {
        setTabValue(value);
      } else {
        dispatch(fetchRestrictedRoute(true));
        localStorage.setItem("prev_authroute", window.location.pathname);
      }
    } else {
      setTabValue(value);
    }
  };

  const getInfomationTabDataAPI = async id => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/bookkeepercms/bookkeepers/${id}`
      );
      if (status === 200) {
        setLoader(false);
        setInfoTabData(data ? data?.data : {});
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("error", err);
    }
  };
  const getReviewTabDataAPI = async (id, EventPage) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/reviews?bookKeeperId=${id}&page=${EventPage}`
      );
      if (status === 200) {
        setLoader(false);
        setReviewTabData(data);
        setReviewUserList(data?.reviews);
        setEventPage(EventPage);
        setTotalPage(data?.pagination?.totalPages);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("error", err);
    }
  };
  const handleScrollBootamReview = async (id, EventPage) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/reviews?bookKeeperId=${id}&page=${EventPage}`
      );
      if (status === 200) {
        // setReviewTabData(data);
        setReviewUserList([...reviewUserList, ...data?.reviews]);
        setEventPage(EventPage);
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const showLogo = item => {
    var logo = item?.BookKeeper?.logo?.includes("uploads")
      ? Config.mediaURL + item?.BookKeeper?.logo
      : item?.BookKeeper?.long_logo?.includes("uploads")
      ? Config.mediaURL + item?.BookKeeper?.long_logo
      : item?.BookKeeper?.small_logo?.includes("uploads")
      ? Config.mediaURL + item?.BookKeeper?.small_logo
      : "";
    return logo;
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {/* <AdvertisingBannner src={banner} className="banner" /> */}
          {fetchPageHeaderAds("218px", banner)}
        </Box>

        <Box className="bookmakersinfo-data">
          <Typography className="page-title">
            {bookkeeperData?.BookKeeper?.name}
          </Typography>

          <Box
            //  className="bookmakers-info-tab"
            className="teamsport-data-tab"
          >
            <Tabs
              value={tabvalue}
              variant="scrollable"
              scrollButtons="off"
              indicatorColor="primary"
              textColor="primary"
              // className="bookmakersinfo-tab-detail"
              className="teamsport-data-tab-details player-tab-details"
            >
              {tabsData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Tab
                      label={
                        <Box className="tab-label">
                          <span className="label-name">{item?.name}</span>
                        </Box>
                      }
                      value={item?.id}
                      className={item?.id === tabvalue ? "active " : ""}
                      onChange={(event, newValue) =>
                        handleTabChange(event, item?.id)
                      }
                    />
                  </Box>
                );
              })}
            </Tabs>
          </Box>
          {tabvalue === 0 ? (
            <InformationTab
              bookkeeperData={bookkeeperData}
              infoTabData={infoTabData}
              showLogo={showLogo}
            />
          ) : (
            <ReviewTab
              bookkeeperData={bookkeeperData}
              reviewTabData={reviewTabData}
              reviewUserList={reviewUserList}
              getReviewTabDataAPI={getReviewTabDataAPI}
              handleScrollBootamReview={handleScrollBootamReview}
              BookKeeperIdFromData={location?.state?.data?.BookKeeperId}
              EventPage={EventPage}
              totalPage={totalPage}
              loader={loader}
              showLogo={showLogo}
            />
          )}
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default BookMakersInfoPage;
