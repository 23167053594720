import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import { IntlContext } from "src/App";
import AboveTable from "../../../../../../assets/images/ad-placeholder/sports1.webp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment/moment";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";

const horsesUpcomingData = [
  {
    id: 1,
    horseName: "Distrustful Award",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  },
  {
    id: 2,
    horseName: "Magic Chloe",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  }
];
const horsespastData = [
  {
    id: 1,
    horseName: "Murray Factor",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  },
  {
    id: 2,
    horseName: "Billy Mav",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  },
  {
    id: 3,
    horseName: "Magic Chloe",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  },
  {
    id: 4,
    horseName: "Fiorente Spritz",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  }
];
const horsesotherpastData = [
  {
    id: 1,
    horseName: "Hangyourhat",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  },
  {
    id: 2,
    horseName: "Handshake",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  },
  {
    id: 3,
    horseName: "High Ferocity",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  },
  {
    id: 4,
    horseName: "Poison Chalice",
    nextRacetime: "15th Oct 2022,",
    raceTrack: "Caulifield R3",
    horseOtherDetail: "3yoG (Brown) - Shamus Award x Distrustful",
    career: "3 1-0-1",
    win: "33%",
    earning: "$21975",
    place: "67%"
  }
];
function HorsesInfoPage({ trackProfileDetail, fetchAds }) {
  const navigate = useNavigate();
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [upcomingData, setupcomingData] = useState([]);
  const [isUpcomingLoading, setisUpcomingLoading] = useState(false);
  const [UpcomingOffset, setisUpcomingOffset] = useState(0);
  const [UpcomingAddedDataCount, setUpcomingAddedDataCount] = useState(0);
  const [lastMonthData, setlastMonthData] = useState([]);
  const [isLastMonthLoading, setisLastMonthLoading] = useState(false);
  const [LastMonthOffset, setisLastMonthOffset] = useState(0);
  const [LastMonthAddedDataCount, setLastMonthAddedDataCount] = useState(0);
  const [lastSecondMonthData, setlastSecondMonthData] = useState([]);
  const [islastSecondMonthLoading, setislastSecondMonthLoading] = useState(
    false
  );
  const [LastSecondMonthOffset, setisLastSecondMonthOffset] = useState(0);
  const [
    LastSecondMonthAddedDataCount,
    setLastSecondMonthAddedDataCount
  ] = useState(0);
  const [LastThirdMonthData, setLastThirdMonthData] = useState([]);
  const [isLastThirdMonthLoading, setisLastThirdMonthLoading] = useState(false);
  const [LastThirdMonthOffset, setisLastThirdMonthOffset] = useState(0);
  const [
    LastThirdMonthAddedDataCount,
    setLastThirdMonthAddedDataCount
  ] = useState(0);
  const handleNavigate = data => {
    navigate(
      `/statistics/${
        params?.trackId !== "0" ? "trackprofiles" : "runnerprofiles"
      }/${params?.trackId}/trainer/${params?.personId}/${data?.id}`
    );
  };
  useEffect(() => {
    fetchUpcoming(0, []);
    fetchLastMonth(0, []);
    fetchlastSecondMonth(0, []);
    fetchLastThirdMonth(0, []);
  }, []);
  const handleUpcomingMoreData = () => {
    setisUpcomingOffset(UpcomingOffset + 5);
    fetchUpcoming(UpcomingOffset + 5, upcomingData);
  };
  const handleUpcomingLessData = () => {
    setisUpcomingOffset(UpcomingOffset - 5);
    if (upcomingData?.length % 5 === 0) {
      setupcomingData(prevArray => prevArray.slice(0, -5));
    } else {
      setupcomingData(prevArray => prevArray.slice(0, -UpcomingAddedDataCount));
    }
  };
  const handleLastMonthMoreData = () => {
    setisLastMonthOffset(LastMonthOffset + 5);
    fetchLastMonth(LastMonthOffset + 5, lastMonthData);
  };

  const handleLastMonthLessData = () => {
    setisLastMonthOffset(LastMonthOffset - 5);
    if (lastMonthData?.length % 5 === 0) {
      setlastMonthData(prevArray => prevArray.slice(0, -5));
    } else {
      setlastMonthData(prevArray =>
        prevArray.slice(0, -LastMonthAddedDataCount)
      );
    }
  };
  const handleLastSecondMonthMoreData = () => {
    setisLastSecondMonthOffset(LastSecondMonthOffset + 5);
    fetchlastSecondMonth(LastSecondMonthOffset + 5, lastSecondMonthData);
  };
  const handleLastSecondMonthLessData = () => {
    setisLastSecondMonthOffset(LastSecondMonthOffset - 5);
    if (lastSecondMonthData?.length % 5 === 0) {
      setlastSecondMonthData(prevArray => prevArray.slice(0, -5));
    } else {
      setlastSecondMonthData(prevArray =>
        prevArray.slice(0, -LastSecondMonthAddedDataCount)
      );
    }
  };
  const handleLastThirdMonthMoreData = () => {
    setisLastThirdMonthOffset(LastThirdMonthOffset + 5);
    fetchLastThirdMonth(LastThirdMonthOffset + 5, LastThirdMonthData);
  };
  const handleLastThirdMonthLessData = () => {
    setisLastThirdMonthOffset(LastThirdMonthOffset - 5);
    if (LastThirdMonthData?.length % 5 === 0) {
      setLastThirdMonthData(prevArray => prevArray.slice(0, -5));
    } else {
      setLastThirdMonthData(prevArray =>
        prevArray.slice(0, -LastThirdMonthAddedDataCount)
      );
    }
  };
  const fetchUpcoming = async (offset, info) => {
    setisUpcomingLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrainerData/horse/${params?.personId}?type=upcoming&limit=5&offset=${offset}`
      );
      if (status === 200) {
        setisUpcomingLoading(false);

        let runnerInfoData = data?.result?.map(obj => {
          return {
            ...obj,
            RacingParticipants: obj?.RacingParticipants?.map(runner => {
              return {
                ...runner,
                RunnerInfos: runner?.RunnerInfos?.map(item => {
                  return {
                    ...item,
                    info: item?.info ? JSON.parse(item?.info) : ""
                  };
                })
              };
            })
          };
        });
        setUpcomingAddedDataCount(runnerInfoData?.length);
        const horseData = [...info, ...runnerInfoData];
        setupcomingData(horseData);
      } else {
        setisUpcomingLoading(false);
      }
    } catch (error) {
      setisUpcomingLoading(false);
    }
  };
  const fetchLastMonth = async (offset, info) => {
    setisLastMonthLoading(true);
    const currentDate = moment();
    const Month = currentDate.subtract(1, "month").format("MMM YYYY");
    const lastDateOfMonth = currentDate.endOf("month").format("YYYY-MM-DD");
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrainerData/horse/${params?.personId}?startTime=${lastDateOfMonth}&limit=5&offset=${offset}`
      );
      if (status === 200) {
        setisLastMonthLoading(false);
        let runnerInfoData = data?.result?.map(obj => {
          return {
            ...obj,
            RacingParticipants: obj?.RacingParticipants?.map(runner => {
              return {
                ...runner,
                RunnerInfos: runner?.RunnerInfos?.map(item => {
                  return {
                    ...item,
                    info: item?.info ? JSON.parse(item?.info) : ""
                  };
                })
              };
            })
          };
        });
        setLastMonthAddedDataCount(runnerInfoData?.length);
        const horseData = [...info, ...runnerInfoData];
        setlastMonthData(horseData);
      } else {
        setisLastMonthLoading(false);
      }
    } catch (error) {
      setisLastMonthLoading(false);
    }
  };
  const fetchlastSecondMonth = async (offset, info) => {
    setislastSecondMonthLoading(true);
    const currentDate = moment();
    const Month = currentDate.subtract(2, "month").format("MMM YYYY");
    const lastDateOfMonth = currentDate.endOf("month").format("YYYY-MM-DD");
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrainerData/horse/${params?.personId}?startTime=${lastDateOfMonth}&limit=5&offset=${offset}`
      );
      if (status === 200) {
        setislastSecondMonthLoading(false);
        let runnerInfoData = data?.result?.map(obj => {
          return {
            ...obj,
            RacingParticipants: obj?.RacingParticipants?.map(runner => {
              return {
                ...runner,
                RunnerInfos: runner?.RunnerInfos?.map(item => {
                  return {
                    ...item,
                    info: item?.info ? JSON.parse(item?.info) : ""
                  };
                })
              };
            })
          };
        });
        setLastSecondMonthAddedDataCount(runnerInfoData?.length);
        const horseData = [...info, ...runnerInfoData];
        setlastSecondMonthData(horseData);
      } else {
        setislastSecondMonthLoading(false);
      }
    } catch (error) {
      setislastSecondMonthLoading(false);
    }
  };
  const fetchLastThirdMonth = async (offset, info) => {
    setisLastThirdMonthLoading(true);
    const currentDate = moment();
    const Month = currentDate.subtract(3, "month").format("MMM YYYY");
    const lastDateOfMonth = currentDate.endOf("month").format("YYYY-MM-DD");
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrainerData/horse/${params?.personId}?startTime=${lastDateOfMonth}&limit=5&offset=${offset}`
      );
      if (status === 200) {
        setisLastThirdMonthLoading(false);
        let runnerInfoData = data?.result?.map(obj => {
          return {
            ...obj,
            RacingParticipants: obj?.RacingParticipants?.map(runner => {
              return {
                ...runner,
                RunnerInfos: runner?.RunnerInfos?.map(item => {
                  return {
                    ...item,
                    info: item?.info ? JSON.parse(item?.info) : ""
                  };
                })
              };
            })
          };
        });
        setLastThirdMonthAddedDataCount(runnerInfoData?.length);
        const horseData = [...info, ...runnerInfoData];
        setLastThirdMonthData(horseData);
      } else {
        setisLastThirdMonthLoading(false);
      }
    } catch (error) {
      setisLastThirdMonthLoading(false);
    }
  };
  return (
    <>
      {fetchAds(1, "102px", AboveTable)}
      <Box className="horses-info-wrap">
        <Typography variant="h1">{localesData?.sport?.UPCOMING}</Typography>
        {isUpcomingLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : upcomingData?.length > 0 ? (
          <>
            {upcomingData?.map(item => {
              return (
                <Box className="horse-detail-wrap">
                  <Typography variant="h4" onClick={() => handleNavigate(item)}>
                    {item?.name}
                  </Typography>
                  <Box className="horse-detail">
                    <Box className="next-race-detail">
                      <Box className="next-race-details-info">
                        <Box>
                          <span className="detail-label">
                            {localesData?.STATISTICS?.NEXT_RACE} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.RacingParticipants?.[0]?.race?.startDate
                              ? moment(
                                  item?.RacingParticipants?.[0]?.race?.startDate
                                ).format("Do MMM YYYY")
                              : ""}
                            {","}
                          </span>
                        </Box>{" "}
                        <span className="detail-label">
                          {" "}
                          {` ${item?.RacingParticipants?.[0]?.race?.raceName}`}{" "}
                          {`R${item?.RacingParticipants?.[0]?.race?.raceNumber}`}
                        </span>
                      </Box>
                      <Box>
                        <span className="detail-info">
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.age
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.age + "yo"
                            : ""}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.sex
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.sex
                            : ""}{" "}
                          (
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.colour
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.colour
                            : ""}
                          ) -{" "}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.jockey_info?.name
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.jockey_info?.name
                            : ""}{" "}
                          x{" "}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.trainer_info?.name
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.trainer_info?.name
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="horse-performance">
                      <Box className="left-detail">
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.CAREER} :{" "}
                          </span>
                          <span className="detail-info">
                            {" "}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.starts
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.starts
                              : "0"}{" "}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.wins
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.wins
                              : "0"}
                            -
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.seconds
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.seconds
                              : "0"}
                            -
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.thirds
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.thirds
                              : "0"}
                          </span>
                        </Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.EARNINGS} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.runner_info?.prize_money
                              ? "$" +
                                item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.runner_info?.prize_money
                              : "N/A"}
                          </span>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.WIN} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.win ? item?.win : "N/A"}
                          </span>
                        </Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.PLACE} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.place ? item?.place : "N/A"}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
            {upcomingData?.length > 0 ? (
              <Box className="view-more">
                {UpcomingOffset + 5 === upcomingData?.length ? (
                  <span
                    className="view-more-text red"
                    onClick={() => handleUpcomingMoreData()}
                  >
                    Show more
                  </span>
                ) : (
                  <></>
                )}
                {UpcomingOffset >= 5 && upcomingData?.length > 5 ? (
                  <span
                    className="view-more-text view-lese-text red"
                    onClick={() => handleUpcomingLessData()}
                  >
                    View Less
                  </span>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            style={{
              textAlign: "center",
              fontFamily: "sans-serif",
              fontSize: "16px",
              padding: "10px 0px"
            }}
          >
            {/* {" "}
            No Data Available{" "} */}
            <NoDataComp />
          </Box>
        )}
        <Typography variant="h1" className="horsesinfo-header">
          {moment()
            .subtract(1, "month")
            .format("MMM YYYY")}
        </Typography>
        {isLastMonthLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : lastMonthData?.length > 0 ? (
          <>
            {lastMonthData?.map(item => {
              return (
                <>
                  <Box className="horse-detail-wrap">
                    <Typography
                      variant="h4"
                      onClick={() => handleNavigate(item)}
                    >
                      {item?.name}
                    </Typography>
                    <Box className="horse-detail">
                      <Box className="next-race-detail">
                        <Box className="next-race-details-info">
                          <Box>
                            <span className="detail-label">
                              {localesData?.STATISTICS?.NEXT_RACE} :{" "}
                            </span>
                            <span className="detail-info">
                              {item?.RacingParticipants?.[0]?.race?.startDate
                                ? moment(
                                    item?.RacingParticipants?.[0]?.race
                                      ?.startDate
                                  ).format("Do MMM YYYY")
                                : ""}
                              {","}
                            </span>
                          </Box>{" "}
                          <span className="detail-label">
                            {" "}
                            {` ${item?.RacingParticipants?.[0]?.race?.raceName}`}{" "}
                            {`R${item?.RacingParticipants?.[0]?.race?.raceNumber}`}
                          </span>
                        </Box>
                        <Box>
                          <span className="detail-info">
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.runner_info?.age
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.runner_info?.age + "yo"
                              : ""}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.runner_info?.sex
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.runner_info?.sex
                              : ""}{" "}
                            (
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.runner_info?.colour
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.runner_info?.colour
                              : ""}
                            ) -{" "}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.jockey_info?.name
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.jockey_info?.name
                              : ""}{" "}
                            x{" "}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.trainer_info?.name
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.trainer_info?.name
                              : ""}
                          </span>
                        </Box>
                      </Box>
                      <Box className="horse-performance">
                        <Box className="left-detail">
                          <Box>
                            {" "}
                            <span className="detail-label">
                              {localesData?.sport?.CAREER} :{" "}
                            </span>
                            <span className="detail-info">
                              {" "}
                              {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.past_runner_performances?.overall
                                ?.starts
                                ? item?.RacingParticipants?.[0]
                                    ?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.overall?.starts
                                : "0"}{" "}
                              {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.past_runner_performances?.overall?.wins
                                ? item?.RacingParticipants?.[0]
                                    ?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.overall?.wins
                                : "0"}
                              -
                              {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.past_runner_performances?.overall
                                ?.seconds
                                ? item?.RacingParticipants?.[0]
                                    ?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.overall?.seconds
                                : "0"}
                              -
                              {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.past_runner_performances?.overall
                                ?.thirds
                                ? item?.RacingParticipants?.[0]
                                    ?.RunnerInfos?.[0]?.info
                                    ?.past_runner_performances?.overall?.thirds
                                : "0"}
                            </span>
                          </Box>
                          <Box>
                            {" "}
                            <span className="detail-label">
                              {localesData?.sport?.EARNINGS} :{" "}
                            </span>
                            <span className="detail-info">
                              {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.prize_money
                                ? "$" +
                                  item?.RacingParticipants?.[0]
                                    ?.RunnerInfos?.[0]?.info?.runner_info
                                    ?.prize_money
                                : "N/A"}
                            </span>
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            {" "}
                            <span className="detail-label">
                              {localesData?.sport?.WIN} :{" "}
                            </span>
                            <span className="detail-info">
                              {item?.win ? item?.win : "N/A"}
                            </span>
                          </Box>
                          <Box>
                            {" "}
                            <span className="detail-label">
                              {localesData?.sport?.PLACE} :{" "}
                            </span>
                            <span className="detail-info">
                              {" "}
                              {item?.place ? item?.place : "N/A"}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })}
            {lastMonthData?.length > 0 ? (
              <Box className="view-more">
                {LastMonthOffset + 5 === lastMonthData?.length ? (
                  <span
                    className="view-more-text red"
                    onClick={() => handleLastMonthMoreData()}
                  >
                    Show more
                  </span>
                ) : (
                  <></>
                )}
                {LastMonthOffset >= 5 && lastMonthData?.length > 5 ? (
                  <span
                    className="view-more-text view-lese-text red"
                    onClick={() => handleLastMonthLessData()}
                  >
                    View Less
                  </span>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            style={{
              textAlign: "center",
              fontFamily: "sans-serif",
              fontSize: "16px",
              padding: "10px 0px"
            }}
          >
            {/* {" "}
            No Data Available{" "} */}
            <NoDataComp />
          </Box>
        )}
        <Typography variant="h1" className="horsesinfo-header">
          {moment()
            .subtract(2, "month")
            .format("MMM YYYY")}
        </Typography>
        {islastSecondMonthLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : lastSecondMonthData?.length > 0 ? (
          <>
            {lastSecondMonthData?.map(item => {
              return (
                <Box className="horse-detail-wrap">
                  <Typography variant="h4" onClick={() => handleNavigate(item)}>
                    {item?.name}
                  </Typography>
                  <Box className="horse-detail">
                    <Box className="next-race-detail">
                      <Box className="next-race-details-info">
                        <Box>
                          <span className="detail-label">
                            {localesData?.STATISTICS?.NEXT_RACE} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.RacingParticipants?.[0]?.race?.startDate
                              ? moment(
                                  item?.RacingParticipants?.[0]?.race?.startDate
                                ).format("Do MMM YYYY")
                              : ""}
                            {","}
                          </span>
                        </Box>{" "}
                        <span className="detail-label">
                          {" "}
                          {` ${item?.RacingParticipants?.[0]?.race?.raceName}`}{" "}
                          {`R${item?.RacingParticipants?.[0]?.race?.raceNumber}`}
                        </span>
                      </Box>
                      <Box>
                        <span className="detail-info">
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.age
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.age + "yo"
                            : ""}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.sex
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.sex
                            : ""}{" "}
                          (
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.colour
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.colour
                            : ""}
                          ) -{" "}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.jockey_info?.name
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.jockey_info?.name
                            : ""}{" "}
                          x{" "}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.trainer_info?.name
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.trainer_info?.name
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="horse-performance">
                      <Box className="left-detail">
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.CAREER} :{" "}
                          </span>
                          <span className="detail-info">
                            {" "}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.starts
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.starts
                              : "0"}{" "}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.wins
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.wins
                              : "0"}
                            -
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.seconds
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.seconds
                              : "0"}
                            -
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.thirds
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.thirds
                              : "0"}
                          </span>
                        </Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.EARNINGS} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.runner_info?.prize_money
                              ? "$" +
                                item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.runner_info?.prize_money
                              : "N/A"}
                          </span>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.WIN} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.win ? item?.win : "N/A"}
                          </span>
                        </Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.PLACE} :{" "}
                          </span>
                          <span className="detail-info">
                            {" "}
                            {item?.place ? item?.place : "N/A"}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
            {lastSecondMonthData?.length > 0 ? (
              <Box className="view-more">
                {LastSecondMonthOffset + 5 === lastSecondMonthData?.length ? (
                  <span
                    className="view-more-text red"
                    onClick={() => handleLastSecondMonthMoreData()}
                  >
                    Show more
                  </span>
                ) : (
                  <></>
                )}
                {LastSecondMonthOffset >= 5 &&
                lastSecondMonthData?.length > 5 ? (
                  <span
                    className="view-more-text view-lese-text red"
                    onClick={() => handleLastSecondMonthLessData()}
                  >
                    View Less
                  </span>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            style={{
              textAlign: "center",
              fontFamily: "sans-serif",
              fontSize: "16px",
              padding: "10px 0px"
            }}
          >
            {/* {" "}
            No Data Available{" "} */}
            <NoDataComp />
          </Box>
        )}
        <Typography variant="h1" className="horsesinfo-header">
          {moment()
            .subtract(3, "month")
            .format("MMM YYYY")}
        </Typography>
        {isLastThirdMonthLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : LastThirdMonthData?.length > 0 ? (
          <>
            {LastThirdMonthData?.map(item => {
              return (
                <Box className="horse-detail-wrap">
                  <Typography variant="h4" onClick={() => handleNavigate(item)}>
                    {item?.name}
                  </Typography>
                  <Box className="horse-detail">
                    <Box className="next-race-detail">
                      <Box className="next-race-details-info">
                        <Box>
                          <span className="detail-label">
                            {localesData?.STATISTICS?.NEXT_RACE} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.RacingParticipants?.[0]?.race?.startDate
                              ? moment(
                                  item?.RacingParticipants?.[0]?.race?.startDate
                                ).format("Do MMM YYYY")
                              : ""}
                            {","}
                          </span>
                        </Box>{" "}
                        <span className="detail-label">
                          {" "}
                          {` ${item?.RacingParticipants?.[0]?.race?.raceName}`}{" "}
                          {`R${item?.RacingParticipants?.[0]?.race?.raceNumber}`}
                        </span>
                      </Box>
                      <Box>
                        <span className="detail-info">
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.age
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.age + "yo"
                            : ""}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.sex
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.sex
                            : ""}{" "}
                          (
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.runner_info?.colour
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.runner_info?.colour
                            : ""}
                          ) -{" "}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.jockey_info?.name
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.jockey_info?.name
                            : ""}{" "}
                          x{" "}
                          {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]?.info
                            ?.trainer_info?.name
                            ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                ?.info?.trainer_info?.name
                            : ""}
                        </span>
                      </Box>
                    </Box>
                    <Box className="horse-performance">
                      <Box className="left-detail">
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.CAREER} :{" "}
                          </span>
                          <span className="detail-info">
                            {" "}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.starts
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.starts
                              : "0"}{" "}
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.wins
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.wins
                              : "0"}
                            -
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.seconds
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.seconds
                              : "0"}
                            -
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.past_runner_performances?.overall?.thirds
                              ? item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.past_runner_performances?.overall
                                  ?.thirds
                              : "0"}
                          </span>
                        </Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.EARNINGS} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                              ?.info?.runner_info?.prize_money
                              ? "$" +
                                item?.RacingParticipants?.[0]?.RunnerInfos?.[0]
                                  ?.info?.runner_info?.prize_money
                              : "N/A"}
                          </span>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.WIN} :{" "}
                          </span>
                          <span className="detail-info">
                            {item?.win ? item?.win : "N/A"}
                          </span>
                        </Box>
                        <Box>
                          {" "}
                          <span className="detail-label">
                            {localesData?.sport?.PLACE} :{" "}
                          </span>
                          <span className="detail-info">
                            {" "}
                            {item?.place ? item?.place : "N/A"}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
            {LastThirdMonthData?.length > 0 ? (
              <Box className="view-more">
                {LastThirdMonthOffset + 5 === LastThirdMonthData?.length ? (
                  <span
                    className="view-more-text red"
                    onClick={() => handleLastThirdMonthMoreData()}
                  >
                    Show more
                  </span>
                ) : (
                  <></>
                )}
                {LastThirdMonthOffset >= 5 && LastThirdMonthData?.length > 5 ? (
                  <span
                    className="view-more-text view-lese-text red"
                    onClick={() => handleLastThirdMonthLessData()}
                  >
                    View Less
                  </span>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            style={{
              textAlign: "center",
              fontFamily: "sans-serif",
              fontSize: "16px",
              padding: "10px 0px"
            }}
          >
            <NoDataComp />
          </Box>
        )}
      </Box>
    </>
  );
}

export default HorsesInfoPage;
