import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Button,
  Tabs,
  Tab,
  withStyles,
} from "@material-ui/core";
import "./runnerResultTable.scss";
import { IntlContext } from "src/App";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "react-select";

import { RunnerTable } from "./runnerTable";
import Bet365 from "../../../../../assets/images/bookmakers/bet365-thumb.svg";
import UniBet from "../../../../../assets/images/bookmakers/unibet-thumb.svg";
import BookMaker from "../../../../../assets/images/bookmakers/bookmakers-thumb.svg";
import Neds from "../../../../../assets/images/bookmakers/neds-thumb.svg";
import PlayUp from "../../../../../assets/images/bookmakers/playup-thumb.svg";
import BetStar from "../../../../../assets/images/bookmakers/betstar-thumb.svg";
// import ViceBet from "../../../../../assets/images/bookmakers/vice-bet-thumb.svg";
import BlueBet from "../../../../../assets/images/bookmakers/BlueBet-thumb.png";
import TopSport from "../../../../../assets/images/bookmakers/top-sport-thumb.svg";
import betFair from "../../../../../assets/images/bookmakers/betfair-thumb.svg";
import BoomBet from "../../../../../assets/images/bookmakers/BoomBet.png";
import LadBrokes from "../../../../../assets/images/bookmakers/Ladbrokes-thumb.png";
import Draftstars from "../../../../../assets/images/bookmakers/DraftStars.png";
import SouthernCrossBet from "../../../../../assets/images/bookmakers/SouthernCrossBet.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Config } from "src/helpers/context";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import Loader from "src/components/Loader";
import { split } from "lodash";
import NoDataComp from "src/views/component/UI/NoData";

const resultTabData = [
  {
    id: 0,
    name: "Results",
  },
  {
    id: 1,
    name: "Exotics",
  },
  {
    id: 2,
    name: "Dividends",
  },
  {
    id: 3,
    name: "Multiples",
  },
];
const options = [
  // { value: "", label: "ODDS TYPE", disabled: true },
  { value: "1", label: "Win Fixed" },
  { value: "2", label: "Place Fixed" },
  // { value: "3", label: "Win Tote" },
  // { value: "4", label: "Place Tote" },
  // { value: "4", label: "Starting Price" },
];
const RunnerResultTable = ({
  raceId,
  runnerInfo,
  isrunnnerLoading,
  isResult,
  RaceResultData,
  raceTrackdata,
}) => {
  const params = useParams();
  let sportId = params?.sportId;
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [resultTabValue, setResultTabValue] = useState(0);
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [sponsoredId, setSponsoredId] = useState([]);
  const [oddsLoader, setOddsLoader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState("1");
  const [SelectedDividend, setSelectedDividend] = useState("1");
  const [oddsType, setOddsType] = useState({ marketId: 1, oddKey: 1 });
  const [ExoticsData, setExoticsData] = useState([]);
  const [MultipleData, setMultipleData] = useState([]);
  const [ToteWinData, setToteWinData] = useState([]);
  const [TotePlaceData, setTotePlaceData] = useState([]);
  const [DividendData, setDividendData] = useState([]);
  const fetchRank = (rank) => {
    let raceRank = rank === 1 ? "winner" : "not-winner";
    return raceRank;
  };
  const oddsTypeChange = (e) => {
    setSelectedOption(e?.value);
    switch (e?.value) {
      case "1":
        setOddsType({ marketId: 1, oddKey: 1 });
        break;
      case "2":
        setOddsType({ marketId: 1, oddKey: 2 });
        break;
      case "3":
        setOddsType({ marketId: 2, oddKey: 1 });
        break;
      case "4":
        setOddsType({ marketId: 2, oddKey: 2 });
        break;
      default:
        setOddsType({ marketId: 1, oddKey: 1 });
    }
  };
  const oddsicon = (BookKeeperId, type) => {
    const newData = raceTrackdata?.ApiRaceIdentifiers?.map((obj) => {
      const BookkeeperObj = obj?.provider?.BookKeeperProviders?.filter(
        (item) => {
          return item?.BookKeeperId === BookKeeperId;
        }
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null,
      };
    });

    const filteredData = newData?.filter?.(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    return (
      <a
        href={
          filteredData?.length > 0 && filteredData?.[0]?.url
            ? filteredData?.[0]?.url + `?Referrer=SmartB`
            : iconData?.affiliate_link
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="bookmaker-thumb"
          src={
            iconData?.small_logo?.includes("uploads")
              ? Config.mediaURL + iconData?.small_logo
              : iconData?.small_logo
          }
          alt="Odds Icon"
          onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
        />
      </a>
    );
  };
  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };
  const handleBookkeeperCounter = async (BookKeeperId, type) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(sportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const fetchClickableOdds = (odds, BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

    //   const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
    //   return Bet365Data && Bet365Data?.length > 0 ? (
    //     <a
    //       href={
    //         Odds?.providerMarketId && Odds?.providerParticipantId
    //           ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
    //           : `${iconData?.affiliate_link}`
    //       }
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       className='odds-link'
    //     >
    //       {' '}
    //       {odds === 'SP' ? 'SP' : Number(odds).toFixed(2)}{' '}
    //     </a>
    //   ) : (
    //     <></>
    //   );
    // } else {
    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target='_blank'
      //   rel='noopener noreferrer'
      //   className='odds-link'
      // >
      <span
        className="odds-link"
        onClick={() => handleBookkeeperCounter(BookKeeperId, type)}
      >
        {Number(odds).toFixed(2)}
      </span>
      // </a>
    );
    // }
  };
  const fetchBestOpenValue = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    // let newOdds = data?.filter((odds) => {
    //     return odds.ApiProviderId > 5;
    // });
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno == 0) {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          return fetchClickableOdds(maxno, providerid?.[0], "header", data);
        } else {
          return "-";
        }
      } else {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      }
      // return maxno;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchBestOpenIcon = (data) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    // let newOdds = data?.filter((odds) => {
    //     return odds.ApiProviderId > 5;
    // });
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header");
      } else {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map((obj) => {
              if (
                obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === newmaxno
              ) {
                return obj?.BookKeeperId;
              }
            })
            .filter((x) => x !== undefined);
          return oddsicon(providerid?.[0], "header");
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };
  const fetchCurrentBestValue = (data, oddsType) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    // let newOdds = data?.filter((odds) => {
    //     return odds.BookKeeperId > 5;
    // });
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[oddsType]?.intValue > max
        ? (max = obj?.RaceOdds?.[oddsType]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[oddsType]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  const fetchCurrentBestIcon = (data, oddsType) => {
    // let newOdds = data?.filter((odds) => {
    //     return odds.BookKeeperId > 5;
    // });
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[oddsType]?.intValue > max
        ? (max = obj?.RaceOdds?.[oddsType]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map((obj) => {
            if (obj?.RaceOdds?.[oddsType]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter((x) => x !== undefined);
        return oddsicon(providerid?.[0], "header");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const handleResultTabChange = (event, value) => {
    setResultTabValue(value);
  };
  const GetSponsoredOdds = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/sponsor?timeZone=${timezone}&SportId=${sportId}`
      );
      if (status === 200) {
        let providerIDs = [];
        let Ids = data?.result?.map((item) =>
          providerIDs?.push(item?.bookKeepersId)
        );
        setSponsoredId(providerIDs);
      } else {
        setSponsoredId([]);
      }
    } catch (err) {
      setSponsoredId([]);
    }
  };
  const fetchSponsoredOdds = (data) => {
    let newOdds = data
      ?.filter((odds) => {
        return sponsoredId?.includes(odds.BookKeeperId);
      })
      ?.slice(0, 2);
    let firstSponsored = newOdds?.filter(
      (item) => item?.BookKeeperId === sponsoredId?.[0]
    );
    let secondSponsored = newOdds?.filter(
      (item) => item?.BookKeeperId === sponsoredId?.[1]
    );
    let SponsoredOdds =
      sponsoredId?.length > 0 ? (
        <>
          <Box>
            {firstSponsored?.length > 0 ? (
              <>
                <Box>
                  {fetchClickableOdds(
                    firstSponsored?.[0]?.RaceOdds?.[0]?.intValue !== 0
                      ? firstSponsored?.[0]?.RaceOdds?.[0]?.intValue
                      : "SP",
                    firstSponsored?.[0]?.BookKeeperId,
                    "sponsored",
                    data
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box style={{ height: "22.88px" }}></Box>
              </>
            )}
            {oddsicon(sponsoredId?.[0], "sponsored")}
          </Box>
          {sponsoredId?.[1] ? (
            <Box>
              {secondSponsored?.length > 0 ? (
                <>
                  <Box>
                    {fetchClickableOdds(
                      secondSponsored?.[0]?.RaceOdds?.[0]?.intValue !== 0
                        ? secondSponsored?.[0]?.RaceOdds?.[0]?.intValue
                        : "SP",
                      secondSponsored?.[0]?.BookKeeperId,
                      "sponsored",
                      data
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <Box style={{ height: "22.88px" }}></Box>
                </>
              )}
              {oddsicon(sponsoredId?.[1], "sponsored")}
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <TableCell colSpan={2}>
          {" "}
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="no-odds">NOA</span>
          </Tooltip>
        </TableCell>
      );
    return SponsoredOdds;
  };
  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    if (raceId) {
      fetchOddsData(raceId);
      fetchExoticsData();
    }
    fetchBookKeeper();
    GetSponsoredOdds();
  }, []);

  useEffect(() => {
    if (raceId) {
      fetchOddsData(raceId);
      fetchExoticsData();
    }
    setSelectedDividend("1");
  }, [raceId, runnerInfo]);
  useEffect(() => {
    if (raceId) {
      fetchOddsData(raceId);
    }
  }, [selectedOption]);

  const fetchOddsData = async (raceId, isFirstTime) => {
    setOddsLoader(true);
    if (RaceResultData && runnerInfo) {
      let parsedResult = RaceResultData?.summary
        ? JSON.parse(RaceResultData?.summary)
        : [];

      let standingData = parsedResult?.map((item) => {
        return {
          ...item,
          RunnerDetails: runnerInfo?.filter((runner) => {
            return item?.runner_number
              ? runner?.runnerNumber === Number(item?.runner_number)
              : runner?.runnerNumber === Number(item?.RunnerNumber);
          }),
        };
      });
      try {
        const { status, data } = await axiosInstance.get(
          `events/getOddsByrace/${raceId}?marketId=${oddsType?.marketId}&oddKey=${oddsType?.oddKey}`
        );
        if (status === 200) {
          let newData = standingData?.map((obj) => {
            return {
              ...obj,
              oddsData: data?.marketRelation
                ?.map((item) => {
                  return item?.RacingParticipantId ==
                    obj?.RunnerDetails?.[0]?.id
                    ? item
                    : [];
                })
                ?.filter((x) => {
                  return x?.data?.length > 0;
                }),
            };
          });
          setSummary(newData);

          setTimeout(() => {
            setOddsLoader(false);
          }, 1000);
        }
      } catch (err) {}
    }
  };
  const fetchExoticsData = () => {
    if (RaceResultData) {
      let parsedExotics = RaceResultData?.dividends
        ? RaceResultData?.dividends === '""'
          ? []
          : JSON.parse(RaceResultData?.dividends)
        : [];
      let Exacta = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name === "Exacta"
          : item?.ProductName === "Exacta"
      );
      let Trifecta = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name === "Trifecta"
          : item?.ProductName === "Trifecta"
      );

      let ToteWin = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name === "Tote Win"
          : item?.ProductName === "Tote Win"
      );
      let TotePlace = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name === "Tote Place"
          : item?.ProductName === "Tote Place"
      );
      // let FirstFour = parsedExotics?.filter((item) => item?.ProductName === "First Four")
      // let Quinella = parsedExotics?.filter((item) => item?.ProductName === "Quinella")
      // let RunningDouble = parsedExotics?.filter((item) => item?.ProductName === "Running Double")
      let restArray = parsedExotics?.filter((item) =>
        item?.product_name
          ? item?.product_name !== "Exacta" &&
            item?.product_name !== "Trifecta" &&
            item?.product_name !== "Tote Win" &&
            item?.product_name !== "Tote Place"
          : item?.ProductName !== "Exacta" &&
            item?.ProductName !== "Trifecta" &&
            item?.ProductName !== "Tote Win" &&
            item?.ProductName !== "Tote Place"
      );
      const sortedData = restArray?.sort((a, b) => {
        return a?.product_name
          ? a?.product_name.localeCompare(b?.product_name)
          : a?.ProductName.localeCompare(b?.ProductName);
      });
      let Exotics = [...Exacta, ...Trifecta];
      setExoticsData(Exotics);
      setMultipleData(sortedData);
      setToteWinData(ToteWin);
      setTotePlaceData(TotePlace);
      fetchDividendData("1", ToteWin, TotePlace);
    }
  };

  const fetchDividendData = (SelectedDividend, ToteWin, TotePlace) => {
    if (RaceResultData && runnerInfo?.length > 0) {
      let parsedResult = RaceResultData?.summary
        ? JSON.parse(RaceResultData?.summary)
        : [];
      let dividend = SelectedDividend === "1" ? ToteWin : TotePlace;
      let standingData = parsedResult?.map((item) => {
        return {
          ...item,
          RunnerDetails: runnerInfo?.filter((runner) => {
            return item?.runner_number
              ? runner?.runnerNumber === Number(item?.runner_number)
              : runner?.runnerNumber === Number(item?.RunnerNumber);
          }),
        };
      });

      let newData = standingData?.map((obj) => {
        let runnerDividend = dividend?.filter((runner) =>
          runner?.positions?.[0]?.runner_number
            ? Number(runner?.positions?.[0]?.runner_number) ===
              Number(obj?.runner_number)
            : Number(runner?.Positions?.[0]?.RunnerNumber) ===
              Number(obj?.RunnerNumber)
        );

        return {
          ...obj,
          DividendData: runnerDividend ? runnerDividend : [],
        };
      });
      setDividendData(newData);
    }
  };
  const fetchDividendOddsValue = (item, tote) => {
    if (item?.DividendData?.length > 0) {
      let oddsValue = item?.DividendData?.filter((obj) => {
        if (obj?.tote ? obj?.tote === tote : obj?.Tote === tote) {
          return obj?.dividend ? obj?.dividend : obj?.Dividend;
        }
      });
      if (
        oddsValue?.[0]?.dividend
          ? oddsValue?.[0]?.dividend
          : oddsValue?.[0]?.Dividend
      ) {
        return (
          <>
            <Box>
              {fetchClickableOdds(
                oddsValue?.[0]?.dividend
                  ? oddsValue?.[0]?.dividend
                  : oddsValue?.[0]?.Dividend,
                3,
                "header"
              )}
            </Box>
            {oddsicon(3, "header")}
          </>
        );
      } else {
        return (
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="no-odds">NOA</span>
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let Exotics_Arr =
    resultTabValue === 1
      ? ExoticsData
      : resultTabValue === 3
      ? MultipleData
      : [];
  let Divedend_Arr =
    resultTabValue === 0 ? Summary : resultTabValue === 2 ? DividendData : [];
  return (
    <Box className="race-result-wrap">
      <Box className="result-tab">
        <Tabs
          value={resultTabValue}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          className="result-tab-detail"
          disableRipple
          disableFocusRipple
        >
          {resultTabData?.map((item, index) => {
            return (
              <Box key={index}>
                <Tab
                  disableRipple
                  disableFocusRipple
                  label={item?.name}
                  value={item?.id}
                  className={item?.id === resultTabValue ? "active " : ""}
                  onChange={(event, newValue) =>
                    handleResultTabChange(event, item?.id)
                  }
                />
              </Box>
            );
          })}
        </Tabs>
      </Box>
      {resultTabValue === 0 || resultTabValue === 2 ? (
        screenWidth > 799 ? (
          <>
            <Box className="race-card-header">
              <Typography variant="h4">Final race results</Typography>
            </Box>

            <Box className="final-race-result">
              <TableContainer className="race-bookmakersodd">
                {/* {(isrunnnerLoading || oddsLoader) ? (
                    <div className="allsport-loader-center">
                        <Loader />
                    </div>
                ) : ( */}
                {oddsLoader ? (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        columnGap: "18px",
                      }}
                      className="final-result-time"
                    >
                      <span>
                        {RaceResultData
                          ? moment(RaceResultData?.updatedAt)
                              .tz(timezone)
                              .format("DD/MM/YYYY")
                          : ""}
                        ,{" "}
                        {RaceResultData
                          ? moment(RaceResultData?.updatedAt)
                              .tz(timezone)
                              .format("hh:mm A")
                          : ""}
                      </span>
                    </Box>
                    <Table
                      className="racing-data"
                      aria-label="customized table"
                      style={{ minWidth: 700, borderRadius: 5 }}
                    >
                      <TableHead className="racing-track-table-head">
                        <TableRow style={{ fontSize: 12 }} className="head-row">
                          <TableCell
                            className="track-table-th selectbox-wrap"
                            style={{ textAlign: "left" }}
                          >
                            {resultTabValue === 0 ? (
                              <Box className="select-wrap">
                                <Select
                                  className="React"
                                  value={options?.find((item) => {
                                    return item?.value == selectedOption;
                                  })}
                                  onChange={(e) => oddsTypeChange(e)}
                                  options={options}
                                  isOptionDisabled={(option) => option.disabled}
                                  classNamePrefix="select"
                                  isSearchable={false}
                                />
                              </Box>
                            ) : (
                              <Box className="select-wrap">
                                <Select
                                  className="React"
                                  value={options?.find((item) => {
                                    return item?.value == SelectedDividend;
                                  })}
                                  onChange={(e) => {
                                    setSelectedDividend(e?.value);
                                    fetchDividendData(
                                      e?.value,
                                      ToteWinData,
                                      TotePlaceData
                                    );
                                  }}
                                  options={options}
                                  isOptionDisabled={(option) => option.disabled}
                                  classNamePrefix="select"
                                  isSearchable={false}
                                />
                              </Box>
                            )}
                          </TableCell>
                          {resultTabValue === 0 ? (
                            <>
                              <TableCell
                                className="track-tabel-odds-th table-head"
                                style={{ width: "180px", textAlign: "center" }}
                              >
                                {localesData?.RUNNER_TABLE?.BEST_AT_OPEN}
                              </TableCell>
                              <TableCell
                                className="track-tabel-odds-th table-head current-best"
                                style={{ width: "180px", textAlign: "center" }}
                              >
                                {localesData?.RUNNER_TABLE?.BEST_AT_CLOSE}
                              </TableCell>
                              {sponsoredId?.length > 0 ? (
                                <TableCell
                                  colSpan={2}
                                  className="track-tabel-odds-th table-head sponsored-header"
                                  style={{
                                    width: "180px",
                                    textAlign: "center",
                                  }}
                                >
                                  <span className="sponsored">
                                    {localesData?.sport?.SPONSORED}
                                  </span>
                                </TableCell>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              <TableCell
                                className="track-tabel-odds-th table-head"
                                style={{ width: "180px", textAlign: "center" }}
                              >
                                VIC
                              </TableCell>
                              <TableCell
                                className="track-tabel-odds-th table-head"
                                style={{ width: "180px", textAlign: "center" }}
                              >
                                NSW
                              </TableCell>
                              <TableCell
                                className="track-tabel-odds-th table-head"
                                style={{ width: "180px", textAlign: "center" }}
                              >
                                QLD
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {Divedend_Arr?.length > 0 ? (
                          <>
                            {Divedend_Arr?.slice(0, 4)?.map((obj, index) => {
                              let item = obj?.RunnerDetails?.[0];
                              return (
                                <>
                                  <TableRow className="racing-track-data-row">
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                      className="result-racer-detail border-right"
                                    >
                                      {obj?.RunnerDetails?.length > 0 ? (
                                        <Box className="racer-detail-wrap">
                                          <Typography variant="h6">
                                            {item?.runnerNumber}
                                            {"."} {item?.animal?.name} (
                                            {item?.barrierNumber})
                                            <span
                                              className={`rank-badge ${fetchRank(
                                                obj?.position
                                                  ? Number(obj?.position)
                                                  : Number(obj?.Position)
                                              )}`}
                                            >
                                              {" "}
                                              {obj?.position
                                                ? Number(obj?.position) === 1
                                                  ? "1st"
                                                  : Number(obj?.position) === 2
                                                  ? "2nd"
                                                  : Number(obj?.position) === 3
                                                  ? "3rd"
                                                  : `${Number(obj?.position)}th`
                                                : Number(obj?.Position) === 1
                                                ? "1st"
                                                : Number(obj?.Position) === 2
                                                ? "2nd"
                                                : Number(obj?.Position) === 3
                                                ? "3rd"
                                                : `${Number(obj?.Position)}th`}
                                            </span>
                                          </Typography>
                                          <Box className="player-wrap">
                                            {sportId === "1" ? (
                                              <Box className="player-detail">
                                                <span>
                                                  {
                                                    localesData?.RUNNER_TABLE
                                                      ?.WEIGHT
                                                  }
                                                  :
                                                </span>
                                                <span>
                                                  {" "}
                                                  {Number(
                                                    item?.JockeyWeight
                                                  ).toFixed(2) + "Kg"}
                                                </span>
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                            <Box className="player-detail">
                                              {item?.Jockey ? (
                                                <>
                                                  <span>
                                                    {sportId === "2"
                                                      ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                                                      : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                                                  </span>
                                                  <span>
                                                    {item?.Jockey?.name}
                                                  </span>
                                                </>
                                              ) : item?.Trainer ? (
                                                <>
                                                  <span>
                                                    {
                                                      localesData?.RUNNER_TABLE
                                                        ?.TRAINER
                                                    }
                                                    :{" "}
                                                  </span>
                                                  <span>
                                                    {item?.Trainer?.name}
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Box>
                                          </Box>
                                          <Box className="player-wrap">
                                            {/* <Box className="player-detail">
                                                                      <span>Form:</span>
                                                              <span> {}</span>
                                                                      </Box> */}
                                            {sportId === "1" ||
                                            sportId === "2" ? (
                                              <Box className="player-detail">
                                                {item?.Trainer ? (
                                                  <>
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE
                                                          ?.TRAINER
                                                      }
                                                      :{" "}
                                                    </span>
                                                    <span>
                                                      {item?.Trainer?.name}
                                                    </span>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                          </Box>
                                        </Box>
                                      ) : (
                                        <>-</>
                                      )}
                                    </TableCell>
                                    {resultTabValue === 0 ? (
                                      <>
                                        <TableCell className="border-right odds-column">
                                          {obj?.oddsData?.[0]?.data ? (
                                            <>
                                              <Box>
                                                {fetchBestOpenValue(
                                                  obj?.oddsData?.[0]?.data
                                                )}
                                              </Box>
                                              {fetchBestOpenIcon(
                                                obj?.oddsData?.[0]?.data
                                              )}
                                            </>
                                          ) : (
                                            <Tooltip
                                              title="No odds available"
                                              className="odds-tooltip"
                                              placement="top"
                                            >
                                              <span className="no-odds">
                                                NOA
                                              </span>
                                            </Tooltip>
                                          )}
                                        </TableCell>
                                        <TableCell className="border-right current-best-odds-column">
                                          {obj?.oddsData?.[0]?.data ? (
                                            <>
                                              <Box>
                                                {fetchCurrentBestValue(
                                                  obj?.oddsData?.[0]?.data,
                                                  0
                                                )}
                                              </Box>
                                              {fetchCurrentBestIcon(
                                                obj?.oddsData?.[0]?.data,
                                                0
                                              )}
                                            </>
                                          ) : (
                                            <Tooltip
                                              title="No odds available"
                                              className="odds-tooltip"
                                              placement="top"
                                            >
                                              <span className="no-odds">
                                                NOA
                                              </span>
                                            </Tooltip>
                                          )}
                                        </TableCell>
                                        {sponsoredId?.length > 0 ? (
                                          <>
                                            <TableCell
                                              colSpan={2}
                                              className="next5odds-icon border-right odds-column"
                                            >
                                              <Box className="sponsored-odds">
                                                {fetchSponsoredOdds(
                                                  obj?.oddsData?.[0]?.data
                                                )}
                                              </Box>
                                            </TableCell>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <TableCell className="border-right">
                                          {fetchDividendOddsValue(obj, "VIC")}
                                        </TableCell>
                                        <TableCell className="border-right">
                                          {fetchDividendOddsValue(obj, "NSW")}
                                        </TableCell>
                                        <TableCell className="border-right">
                                          {fetchDividendOddsValue(obj, "QLD")}
                                        </TableCell>
                                      </>
                                    )}
                                  </TableRow>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <TableRow style={{ border: "transparent" }}>
                            <TableCell
                              style={{
                                textAlign: "center",
                                border: "transparent",
                              }}
                              colSpan={21}
                            >
                              {/* {localesData?.sport?.NO_DATA} */}
                              <NoDataComp />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </>
                )}
              </TableContainer>
            </Box>
          </>
        ) : (
          <>
            <Box className="race-card-header">
              <Typography variant="h4">Final race results</Typography>
            </Box>
            <Box className="final-race-result mobile-race-result">
              {oddsLoader ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : (
                <>
                  <Typography className="race-result-time">
                    {RaceResultData
                      ? moment(RaceResultData?.updatedAt)
                          .tz(timezone)
                          .format("DD/MM/YYYY")
                      : ""}
                    ,{" "}
                    {RaceResultData
                      ? moment(RaceResultData?.updatedAt)
                          .tz(timezone)
                          .format("hh:mm A")
                      : ""}
                  </Typography>
                  {resultTabValue === 0 ? (
                    <Box className="select-wrap">
                      <Select
                        className="React"
                        value={options?.find((item) => {
                          return item?.value == selectedOption;
                        })}
                        onChange={(e) => oddsTypeChange(e)}
                        options={options}
                        isOptionDisabled={(option) => option.disabled}
                        classNamePrefix="select"
                        isSearchable={false}
                      />
                    </Box>
                  ) : (
                    <Box className="select-wrap">
                      <Select
                        className="React"
                        value={options?.find((item) => {
                          return item?.value == SelectedDividend;
                        })}
                        onChange={(e) => {
                          setSelectedDividend(e?.value);
                          fetchDividendData(
                            e?.value,
                            ToteWinData,
                            TotePlaceData
                          );
                        }}
                        options={options}
                        isOptionDisabled={(option) => option.disabled}
                        classNamePrefix="select"
                        isSearchable={false}
                      />
                    </Box>
                  )}

                  <Box className="race-bookmakersodd">
                    {/* {(isrunnnerLoading || oddsLoader) ? (
                                        <div className="allsport-loader-center">
                                            <Loader />
                                        </div>
                                    ) : ( */}
                    <>
                      <Box className="racing-data">
                        {Divedend_Arr?.length > 0 ? (
                          <>
                            {Divedend_Arr?.map((obj, index) => {
                              let item = obj?.RunnerDetails?.[0];
                              return (
                                <Box key={index}>
                                  <Box className="racer-detail-wrap">
                                    {obj?.RunnerDetails?.length > 0 ? (
                                      <>
                                        <Typography
                                          variant="h6"
                                          className="mobile-racer-info"
                                        >
                                          {item?.runnerNumber}
                                          {"."} {item?.animal?.name} (
                                          {item?.barrierNumber})
                                          <span
                                            className={`rank-badge ${fetchRank(
                                              obj?.position
                                                ? Number(obj?.position)
                                                : Number(obj?.Position)
                                            )}`}
                                          >
                                            {" "}
                                            {obj?.position
                                              ? Number(obj?.position) === 1
                                                ? "1st"
                                                : Number(obj?.position) === 2
                                                ? "2nd"
                                                : Number(obj?.position) === 3
                                                ? "3rd"
                                                : `${Number(obj?.position)}th`
                                              : Number(obj?.Position) === 1
                                              ? "1st"
                                              : Number(obj?.Position) === 2
                                              ? "2nd"
                                              : Number(obj?.Position) === 3
                                              ? "3rd"
                                              : `${Number(obj?.Position)}th`}
                                          </span>
                                        </Typography>
                                        <Box className="player-detail-container">
                                          <Box className="player-wrap">
                                            {sportId === "1" ? (
                                              <Box className="player-detail">
                                                <span>
                                                  {
                                                    localesData?.RUNNER_TABLE
                                                      ?.WEIGHT
                                                  }
                                                  :
                                                </span>
                                                <span>
                                                  {Number(
                                                    item?.JockeyWeight
                                                  ).toFixed(2) + "Kg"}{" "}
                                                </span>
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                            <Box className="player-detail">
                                              {item?.Jockey ? (
                                                <>
                                                  <span>
                                                    {sportId === "2"
                                                      ? `${localesData?.RUNNER_TABLE?.DRIVER}: `
                                                      : `${localesData?.RUNNER_TABLE?.JOCKEY}: `}
                                                  </span>
                                                  <span>
                                                    {item?.Jockey?.name}
                                                  </span>
                                                </>
                                              ) : item?.Trainer ? (
                                                <>
                                                  <span>
                                                    {
                                                      localesData?.RUNNER_TABLE
                                                        ?.TRAINER
                                                    }
                                                    :{" "}
                                                  </span>
                                                  <span>
                                                    {item?.Trainer?.name}
                                                  </span>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Box>
                                          </Box>
                                          <Box className="player-wrap">
                                            {sportId === "1" ||
                                            sportId === "2" ? (
                                              <Box className="player-detail">
                                                {item?.Trainer ? (
                                                  <>
                                                    <span>
                                                      {
                                                        localesData
                                                          ?.RUNNER_TABLE
                                                          ?.TRAINER
                                                      }
                                                      :{" "}
                                                    </span>
                                                    <span>
                                                      {item?.Trainer?.name}
                                                    </span>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                          </Box>
                                        </Box>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Box>
                                  <TableContainer className="race-result-table-container">
                                    <Table>
                                      <TableHead className="mobile-data-head">
                                        {resultTabValue === 0 ? (
                                          <>
                                            <TableCell
                                              className="mobile-table-data "
                                              width="33.33%"
                                            >
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.BEST_AT_OPEN
                                              }
                                            </TableCell>
                                            <TableCell
                                              className="mobile-table-data current-best"
                                              width="33.33%"
                                            >
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.BEST_AT_CLOSE
                                              }
                                            </TableCell>
                                            {sponsoredId?.length > 0 ? (
                                              <TableCell
                                                className="mobile-table-data sponsored-header"
                                                width="33.33%"
                                              >
                                                <span className="sponsored">
                                                  {
                                                    localesData?.RUNNER_TABLE
                                                      ?.SPONSORED
                                                  }
                                                </span>
                                              </TableCell>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <TableCell
                                              className="mobile-table-data "
                                              width="33.33%"
                                            >
                                              VIC
                                            </TableCell>
                                            <TableCell
                                              className="mobile-table-data "
                                              width="33.33%"
                                            >
                                              NSW
                                            </TableCell>
                                            <TableCell
                                              className="mobile-table-data "
                                              width="33.33%"
                                            >
                                              QLD
                                            </TableCell>
                                          </>
                                        )}
                                      </TableHead>
                                      <TableBody className="mobile-table-body">
                                        {resultTabValue === 0 ? (
                                          <>
                                            <TableCell className="mobile-table-data odds-column">
                                              {obj?.oddsData?.[0]?.data ? (
                                                <>
                                                  <Box>
                                                    {fetchBestOpenValue(
                                                      obj?.oddsData?.[0]?.data
                                                    )}
                                                  </Box>
                                                  {fetchBestOpenIcon(
                                                    obj?.oddsData?.[0]?.data
                                                  )}
                                                </>
                                              ) : (
                                                <Tooltip
                                                  title="No odds available"
                                                  className="odds-tooltip"
                                                  placement="top"
                                                >
                                                  <span className="no-odds">
                                                    NOA
                                                  </span>
                                                </Tooltip>
                                              )}
                                            </TableCell>
                                            <TableCell className="mobile-table-data current-best-odds-column">
                                              {obj?.oddsData?.[0]?.data ? (
                                                <>
                                                  <Box>
                                                    {fetchCurrentBestValue(
                                                      obj?.oddsData?.[0]?.data,
                                                      0
                                                    )}
                                                  </Box>
                                                  {fetchCurrentBestIcon(
                                                    obj?.oddsData?.[0]?.data,
                                                    0
                                                  )}
                                                </>
                                              ) : (
                                                <Tooltip
                                                  title="No odds available"
                                                  className="odds-tooltip"
                                                  placement="top"
                                                >
                                                  <span className="no-odds">
                                                    NOA
                                                  </span>
                                                </Tooltip>
                                              )}
                                            </TableCell>
                                            {sponsoredId?.length > 0 ? (
                                              <>
                                                <TableCell className="next5odds-icon odds-column">
                                                  <Box className="sponsored-odds">
                                                    {fetchSponsoredOdds(
                                                      obj?.oddsData?.[0]?.data
                                                    )}
                                                  </Box>
                                                </TableCell>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <TableCell className="mobile-table-data">
                                              {fetchDividendOddsValue(
                                                obj,
                                                "VIC"
                                              )}
                                            </TableCell>
                                            <TableCell className="mobile-table-data">
                                              {fetchDividendOddsValue(
                                                obj,
                                                "NSW"
                                              )}
                                            </TableCell>
                                            <TableCell className="mobile-table-data">
                                              {fetchDividendOddsValue(
                                                obj,
                                                "QLD"
                                              )}
                                            </TableCell>
                                          </>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Box>
                              );
                            })}
                          </>
                        ) : (
                          <Box style={{ border: "transparent" }}>
                            <Box
                              style={{
                                textAlign: "center",
                                border: "transparent",
                              }}
                              colSpan={21}
                            >
                              {/* {localesData?.sport?.NO_DATA} */}
                              <NoDataComp />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </>
                    {/* )} */}
                  </Box>
                </>
              )}
            </Box>
          </>
        )
      ) : screenWidth > 799 ? (
        <>
          <Box className="race-card-header">
            <Typography variant="h4">Final race results</Typography>
          </Box>
          <Box className="final-race-result exotics-race-result">
            <TableContainer className="race-bookmakersodd">
              {/* {(isrunnnerLoading || oddsLoader) ? (
                <div className="allsport-loader-center">
                    <Loader />
                </div>
            ) : ( */}
              <Table
                className="racing-data"
                aria-label="customized table"
                style={{ minWidth: 700, borderRadius: 5, marginTop: "24px" }}
              >
                <TableHead className="racing-track-table-head">
                  <TableRow style={{ fontSize: 12 }} className="head-row">
                    <TableCell
                      className="track-table-th exotics-th"
                      style={{ textAlign: "left" }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          columnGap: "18px",
                          margin: "0px",
                        }}
                        className="final-result-time"
                      >
                        <span>
                          {RaceResultData
                            ? moment(RaceResultData?.updatedAt)
                                .tz(timezone)
                                .format("DD/MM/YYYY")
                            : ""}
                          ,{" "}
                          {RaceResultData
                            ? moment(RaceResultData?.updatedAt)
                                .tz(timezone)
                                .format("hh:mm A")
                            : ""}
                        </span>
                      </Box>
                    </TableCell>
                    <TableCell
                      className="track-tabel-odds-th table-head"
                      style={{ width: "20%", textAlign: "center" }}
                    >
                      {localesData?.sport?.BET_TYPE}
                    </TableCell>
                    <TableCell
                      className="track-tabel-odds-th table-head"
                      style={{ width: "20%", textAlign: "center" }}
                    >
                      {localesData?.sport?.DIVIDEND}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {runnerInfoData?.length > 0 ? (
                                        <>
                                            {runnerInfoData?.map((item, index) => {
                                                return (
                                                    <> */}

                  {Exotics_Arr?.length > 0 ? (
                    Exotics_Arr?.map((item) => {
                      const positionsArray = item?.positions
                        ? Array.isArray(item?.positions)
                          ? item?.positions
                          : [item?.Positions]
                        : Array.isArray(item?.Positions)
                        ? item?.Positions
                        : [item?.Positions];
                      return (
                        <TableRow className="racing-track-data-row">
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            className="result-racer-detail exotics-cell border-right"
                          >
                            <Box className="racer-detail-wrap">
                              {positionsArray?.map((obj) => {
                                return (
                                  <Box className="rank-card-wrap">
                                    <span className="rank-card">
                                      {obj?.runner_number
                                        ? obj?.runner_number
                                        : obj?.RunnerNumber}
                                    </span>

                                    <span
                                      className={`rank-badge ${fetchRank(
                                        obj?.position
                                          ? Number(obj?.position)
                                          : Number(obj?.Position)
                                      )}`}
                                    >
                                      {" "}
                                      {obj?.position
                                        ? Number(obj?.position) === 1
                                          ? "1st"
                                          : Number(obj?.position) === 2
                                          ? "2nd"
                                          : Number(obj?.position) === 3
                                          ? "3rd"
                                          : `${Number(obj?.position)}th`
                                        : Number(obj?.Position) === 1
                                        ? "1st"
                                        : Number(obj?.Position) === 2
                                        ? "2nd"
                                        : Number(obj?.Position) === 3
                                        ? "3rd"
                                        : `${Number(obj?.Position)}th`}
                                    </span>
                                  </Box>
                                );
                              })}
                            </Box>
                          </TableCell>
                          <TableCell className="border-right">
                            {/* {item?.oddsData?.[0]?.data ? (
                                                <> */}
                            <Box>
                              {item?.product_name
                                ? item?.product_name
                                : item?.ProductName}{" "}
                              ({item?.tote ? item?.tote : item?.Tote})
                            </Box>
                            {oddsicon(3, "header")}
                          </TableCell>
                          <TableCell className="border-right">
                            <Box>
                              {fetchClickableOdds(
                                item?.dividend
                                  ? item?.dividend
                                  : item?.Dividend,
                                3,
                                "header"
                              )}
                            </Box>
                            {oddsicon(3, "header")}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <>
                      <TableRow style={{ border: "transparent" }}>
                        <TableCell
                          style={{
                            textAlign: "center",
                            border: "transparent",
                          }}
                          colSpan={21}
                        >
                          {/* {localesData?.sport?.NO_DATA} */}
                          <NoDataComp />
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
              {/* )} */}
            </TableContainer>
          </Box>
        </>
      ) : (
        <>
          <Box className="race-card-header">
            <Typography variant="h4">Final race results</Typography>
          </Box>
          <Box className="final-race-result mobile-race-result">
            <Typography className="race-result-time">
              {RaceResultData
                ? moment(RaceResultData?.updatedAt)
                    .tz(timezone)
                    .format("DD/MM/YYYY")
                : ""}
              ,{" "}
              {RaceResultData
                ? moment(RaceResultData?.updatedAt)
                    .tz(timezone)
                    .format("hh:mm A")
                : ""}
            </Typography>
            <Box className="race-bookmakersodd">
              {/* {(isrunnnerLoading || oddsLoader) ? (
                                    <div className="allsport-loader-center">
                                        <Loader />
                                    </div>
                                ) : ( */}
              <>
                <Box className="racing-data">
                  {/* {runnerInfoData?.length > 0 ? (
                                                    <>
                                                        {runnerInfoData?.map((item, index) => {
                                                            return ( */}
                  {Exotics_Arr?.length > 0 ? (
                    Exotics_Arr?.map((item) => {
                      const positionsArray = item?.positions
                        ? Array.isArray(item?.positions)
                          ? item?.positions
                          : [item?.Positions]
                        : Array.isArray(item?.Positions)
                        ? item?.Positions
                        : [item?.Positions];
                      return (
                        <Box className="exotics-row">
                          <Box className="rank-card-container">
                            {positionsArray?.map((obj) => {
                              return (
                                <Box className="rank-card-wrap">
                                  <span
                                    className={`rank-badge ${fetchRank(
                                      obj?.position
                                        ? Number(obj?.position)
                                        : Number(obj?.Position)
                                    )}`}
                                  >
                                    {" "}
                                    {obj?.position
                                      ? Number(obj?.position) === 1
                                        ? "1st"
                                        : Number(obj?.position) === 2
                                        ? "2nd"
                                        : Number(obj?.position) === 3
                                        ? "3rd"
                                        : `${Number(obj?.position)}th`
                                      : Number(obj?.Position) === 1
                                      ? "1st"
                                      : Number(obj?.Position) === 2
                                      ? "2nd"
                                      : Number(obj?.Position) === 3
                                      ? "3rd"
                                      : `${Number(obj?.Position)}th`}
                                  </span>
                                  <Box className="rank-card">
                                    {" "}
                                    {obj?.runner_number
                                      ? obj?.runner_number
                                      : obj?.RunnerNumber}
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                          <TableContainer>
                            <Table>
                              <TableHead className="mobile-data-head">
                                <TableCell
                                  className="mobile-table-data"
                                  width="50%"
                                >
                                  {" "}
                                  {localesData?.sport?.BET_TYPE}
                                </TableCell>
                                <TableCell
                                  className="mobile-table-data"
                                  width="50%"
                                >
                                  {" "}
                                  {localesData?.sport?.DIVIDEND}
                                </TableCell>
                              </TableHead>
                              <TableBody className="mobile-table-body">
                                <TableCell className="mobile-table-data">
                                  <Box>
                                    {" "}
                                    {item?.product_name
                                      ? item?.product_name
                                      : item?.ProductName}{" "}
                                    ({item?.tote ? item?.tote : item?.Tote})
                                  </Box>
                                  {oddsicon(3, "header")}
                                </TableCell>
                                <TableCell className="mobile-table-data">
                                  <Box>
                                    {fetchClickableOdds(
                                      item?.dividend
                                        ? item?.dividend
                                        : item?.Dividend,
                                      3,
                                      "header"
                                    )}
                                  </Box>
                                  {oddsicon(3, "header")}
                                </TableCell>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      );
                    })
                  ) : (
                    <>
                      <Box style={{ border: "transparent" }}>
                        <Box
                          style={{
                            textAlign: "center",
                            border: "transparent",
                          }}
                        >
                          {/* {localesData?.sport?.NO_DATA} */}
                          <NoDataComp />
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </>
              {/* )} */}
            </Box>
          </Box>
        </>
      )}
      <Box className="race-card-wrap">
        <Box className="race-card-header">
          <Typography variant="h4">Race Card</Typography>
        </Box>
        {/* <Box className="race-player-detail-wrap side-space"> */}
        <RunnerTable
          raceId={raceId}
          runnerInfo={runnerInfo}
          isrunnnerLoading={isrunnnerLoading}
          isResult={isResult}
          raceTrackdata={raceTrackdata}
        />
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default RunnerResultTable;
