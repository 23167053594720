import React, { useState, useContext } from "react";
// import { Link, withRouter } from "react-router-dom";
import {
  ClickAwayListener,
  Checkbox,
  FormControl,
  Box,
} from "@material-ui/core";
import { IntlContext } from "src/App";
import UpcommingCarousel from "./UpcommingCarousel";
import Horses from "../../../assets/images/filter1.svg";
import Greys from "../../../assets/images/filter2.svg";
import Harnes from "../../../assets/images/filter3.svg";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/smartb-check.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./headertopbar.scss";

const HeaderTopBar = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [filtermenu, setfiltermenu] = useState("none");
  const [filter, setfilter] = useState("Horse Racing");
  const [checkedFilter, setcheckedFilter] = useState("Horse Racing");
  const [filterRaceType, setfilterRaceType] = useState([
    {
      id: 1,
      value: "Horse Racing",
      isChecked: true,
      icon: (
        <span>
          <img src={Horses} alt="horse-race" />
        </span>
      ),
    },
    {
      id: 3,
      value: "Greyhound Racing",
      isChecked: true,
      icon: (
        <span>
          <img src={Greys} alt="greyhound-race" />
        </span>
      ),
    },
    {
      id: 2,
      value: "Harness Racing",
      isChecked: true,
      icon: (
        <span>
          <img src={Harnes} alt="harness-race" />
        </span>
      ),
    },
  ]);
  const [filterCountry, setfilterCountry] = useState([
    {
      id: 1,
      value: "Aus/NZ",
      name: "Aus/NZ",
      isChecked: true,
    },
    {
      id: 2,
      value: "Intl",
      name: "Int'l",
      isChecked: true,
    },
  ]);
  const [collepseRace, setcollepseRace] = useState([1, 2, 3]);
  const [collepseCountry, setcollepseCountry] = useState(["Aus/NZ", "Intl"]);
  const handleFiltermenu = () => {
    setfiltermenu(filtermenu === "none" ? "block" : "none");
  };
  const handleFiltermenuClose = () => {
    setfiltermenu("none");
  };
  const handleFilter = (e) => {
    setfilter(filter === e.target.value ? "" : e.target.value);
    setcheckedFilter(checkedFilter === e.target.value ? "" : e.target.value);
  };

  // Change Racing Filter

  const ChangeRaceFilter = (event, item) => {
    const flag = event.target.checked;
    if (flag) {
      const newItem = [...collepseRace, item];
      setcollepseRace(newItem);
    } else {
      if (collepseRace?.length >= 2) {
        const removeItem = collepseRace?.filter((eItem) => eItem !== item);
        setcollepseRace(removeItem);
      }
    }
  };

  // Change Timezone Filter
  const ChangeCountryFilter = (event, item) => {
    const flag = event.target.checked;
    if (flag) {
      const newItem = [...collepseCountry, item];
      setcollepseCountry(newItem);
    } else {
      if (collepseCountry.length >= 2) {
        const removeItem = collepseCountry.filter((eItem) => eItem !== item);
        setcollepseCountry(removeItem);
      }
    }
  };

  return (
    <>
      <Box className="bg-blue top-bar ">
        <Box className="header-bar">
          <div className="NextoJump-wrap ">
            <div className="NextoJump-Item NextoJump-Title">
              <span>{localesData.next_to_jump}</span>
            </div>
            <ClickAwayListener onClickAway={handleFiltermenuClose}>
              <div className="NextoJump-Item NextoJump-Filteritem">
                <p className="innerTitle" onClick={handleFiltermenu}>
                  Filter
                </p>
                <div
                  style={{ display: filtermenu }}
                  className="NextoJump-Filteritemlist"
                >
                  <ul className="NextoJump-Filteritemlist-ul">
                    {filterRaceType?.length > 0 &&
                      filterRaceType?.map((filter, i) => (
                        <li key={filter?.id}>
                          <FormControl>
                            <label>
                              <Checkbox
                                icon={<Unchecked />}
                                checkedIcon={<Checked />}
                                name="filter"
                                value={filter?.id}
                                onChange={(event) => {
                                  ChangeRaceFilter(event, filter?.id);
                                }}
                                checked={collepseRace.includes(filter?.id)}
                              />
                              {filter?.icon}
                            </label>
                          </FormControl>
                        </li>
                      ))}
                  </ul>
                  <ul>
                    {filterCountry?.length > 0 &&
                      filterCountry?.map((countryItem, i) => (
                        <li key={countryItem?.id}>
                          <label>
                            <Checkbox
                              icon={<Unchecked />}
                              checkedIcon={<Checked />}
                              name="filtercountry"
                              value={countryItem?.value}
                              onChange={(event) => {
                                ChangeCountryFilter(event, countryItem?.value);
                              }}
                              checked={collepseCountry.includes(
                                countryItem?.value
                              )}
                            />

                            {countryItem?.name}
                          </label>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </ClickAwayListener>

            <UpcommingCarousel
              filter={collepseRace}
              country={collepseCountry}
              // search={searchrace}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default HeaderTopBar;
