import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Breadcrumbs,
  Collapse,
  ClickAwayListener
  // Grid
} from "@material-ui/core";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Search } from "../../../../assets/images/icons/search.svg";
import AllNews from "./allNews";
import CategoryNews from "./categoryNews";
import IndividualNews from "./individualNews";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "../../../../components/Loader";

const newsTitle = [
  { id: 0, label: "All" },
  { id: 1, label: "football" },
  { id: 2, label: "Predictions" },
  { id: 3, label: "Champions League" },
  { id: 4, label: "Women's Football" },
  { id: 5, label: "Match Reports" },
  { id: 6, label: "Transfer news" },
  { id: 7, label: "Cricket" },
  { id: 8, label: "Tennis" },
  { id: 9, label: "Rugby Union" },
  { id: 10, label: "Baseball" }
];

const LeftNewsSiderBar = () => {
  // const silderRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [todayCategoryList, setTodayCategoryList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [moreTabData, setMoreTabData] = useState(false);
  const [newsActiveTab, setNewsActiveTab] = useState(0);
  const tabsRef = useRef(null);
  //   const [tabData, setTabData] = useState(datas);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    // window.addEventListener("resize", function() {
    //   setScreenWidth(window.innerWidth);
    // });

    let box = document.querySelector(".custom-news-wrap");
    let width = box.offsetWidth;

    // let scrolltab = document.getElementById("news-tab");
    // console.log("scrolltab", width);

    fetchNewsCategoryList();
    fetchTodayNewsCategoryList();
    if (params?.categoryId && params?.categoryId != 0) {
      fetchNewsCategoryData(params?.categoryId, 0, searchValue);
    }
    setTimeout(() => {
      const activeTab = document.querySelector(".custom-news-wrap");
      if (activeTab) {
        activeTab.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      setNewsActiveTab(Number(params?.categoryId));
    }, 100);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const activeTab = document.querySelector(".custom-news-wrap");
      if (activeTab) {
        activeTab.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      setNewsActiveTab(Number(params?.categoryId));
    }, 100);
  }, [newsActiveTab, params]);

  const fetchNewsCategoryData = async (id, page, searchValue) => {
    try {
      setIsCategoryLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/articles?NewsCategoryId=${
          id == 0 ? "" : id
        }&limit=5&offset=${page}&search=${
          searchValue && searchValue !== "" ? searchValue : ""
        }`
      );
      if (status === 200) {
        setCategoryData(data?.result);
        setIsCategoryLoading(false);
        // setSearchValue("");
      } else {
        setIsCategoryLoading(false);
      }
    } catch (err) {
      setIsCategoryLoading(false);
    }
  };
  const handleSearch = searchValue => {
    navigate(`/news/1000`);
    // fetchNewsCategoryData(0, 0, searchValue);
    searchValue != "" ? setIsSearch(true) : setIsSearch(false);
  };
  const handleSearchKeyPress = e => {
    if (e.key === "Enter") {
      handleSearch(e.target.value);
    }
  };

  const fetchNewsCategoryList = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `v2/news/category?All=0`
      );
      if (status === 200) {
        setLoading(false);
        let response = data?.result;
        let newdata = [];
        let FinalData = response?.map(item => {
          newdata.push({
            label: item?.title?.includes("-")
              ? item?.title?.replace("-", " ")
              : item?.title,
            value: item?.id
          });
        });

        const sortedData = newdata?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        let alldatas = sortedData?.unshift({
          label: "all",
          value: 0
        });
        setCategoryList(sortedData);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchTodayNewsCategoryList = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `v2/news/category?All=0&timezone=${timezone}`
      );
      if (status === 200) {
        let response = data?.result;
        let newdata = [];
        let FinalData = response?.map(item => {
          newdata.push({
            label: item?.title?.includes("-")
              ? item?.title?.replace("-", " ")
              : item?.title,
            value: item?.id
          });
        });

        const sortedData = newdata?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
        setTodayCategoryList(sortedData);
      } else {
      }
    } catch (error) {}
  };

  const handleTabClick = index => {
    navigate(`/news/${index}`);
    setNewsActiveTab(index);
    setMoreTabData(false);
    if (index) {
      fetchNewsCategoryData(index, 0, searchValue);
    }
  };

  const handleOutsideClick = () => {
    if (moreTabData) {
      setMoreTabData(false);
    }
  };
  return (
    <>
      {/* {isLoading ? (
        <div className="app-loader">
          <Loader />
        </div>
      ) : ( */}
      <Box className="left-section-wrap">
        <Box className="news-header">
          <Box className="bredcrumn-wrap">
            <Breadcrumbs
              separator="/"
              aria-label="breadcrumb"
              className="breadcrumb"
            >
              <Link underline="hover" color="inherit" to="/">
                {/* {localesData?.MENU?.HOME} */}
                Home
              </Link>

              <Typography>News</Typography>
            </Breadcrumbs>
          </Box>
          <Typography variant="h1">News</Typography>
        </Box>
        <Box className="news-details">
          <TextField
            placeholder="Search"
            variant="outlined"
            type="text"
            name="search"
            className="search_input"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={e => handleSearchKeyPress(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search onClick={() => handleSearch(searchValue)} />
                </InputAdornment>
              )
            }}
          />
          <ClickAwayListener onClickAway={() => handleOutsideClick()}>
            {params?.id ? (
              <Box className="custom-news-wrap">
                <Box className="custom-news-tabs">
                  {categoryList?.slice(0, 8)?.map(item => (
                    <Box
                      key={item?.value}
                      className={`tab ${
                        newsActiveTab === item?.value ? "active" : ""
                      }`}
                      onClick={() => {
                        handleTabClick(item?.value);
                      }}
                    >
                      {item?.label}
                    </Box>
                  ))}
                  {categoryList?.length > 8 ? (
                    <Box
                      className="tab tab-more"
                      onClick={() => setMoreTabData(!moreTabData)}
                    >
                      more <SelectIndicator />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                <Collapse in={moreTabData} className="more-news-tab">
                  {categoryList?.slice(8)?.map(item => {
                    return (
                      <>
                        <Box
                          key={item?.value}
                          className={`tab ${
                            newsActiveTab === item?.value
                              ? categoryList?.slice(8)?.length % 2 === 0
                                ? "active last-active"
                                : "active last-second"
                              : ""
                          }`}
                          onClick={() => handleTabClick(item?.value)}
                        >
                          {item?.label}
                        </Box>
                      </>
                    );
                  })}
                </Collapse>
              </Box>
            ) : (
              <Box className="custom-news-wrap">
                <Box className="custom-news-tabs">
                  {categoryList?.slice(0, 10)?.map(item => (
                    <Box
                      key={item?.value}
                      className={`tab ${
                        newsActiveTab === item?.value ? "active" : ""
                      }`}
                      onClick={() => {
                        handleTabClick(item?.value);
                      }}
                    >
                      {item?.label}
                    </Box>
                  ))}
                  {categoryList?.length > 10 ? (
                    <Box
                      className="tab tab-more"
                      onClick={() => setMoreTabData(!moreTabData)}
                    >
                      more <SelectIndicator />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                <Collapse in={moreTabData} className="more-news-tab">
                  {categoryList?.slice(10)?.map(item => {
                    return (
                      <>
                        <Box
                          key={item?.value}
                          className={`tab ${
                            newsActiveTab === item?.value
                              ? categoryList?.slice(10)?.length % 2 === 0
                                ? "active last-active"
                                : "active last-second"
                              : ""
                          }`}
                          onClick={() => handleTabClick(item?.value)}
                        >
                          {item?.label}
                        </Box>
                      </>
                    );
                  })}
                </Collapse>
              </Box>
            )}
          </ClickAwayListener>
        </Box>
        <Box
          className={`${
            !params?.id ? "news-layout-section" : "individual-news-layout"
          }`}
        >
          {!params?.id ? (
            params?.categoryId == 0 ? (
              <AllNews categoryList={todayCategoryList} />
            ) : (
              <CategoryNews
                categoryData={categoryData}
                isCategoryLoading={isCategoryLoading}
                fetchNewsCategoryData={fetchNewsCategoryData}
                categoryList={categoryList}
                searchValue={searchValue}
              />
            )
          ) : (
            <IndividualNews categoryList={categoryList} />
          )}
        </Box>
      </Box>
      {/* )} */}
    </>
  );
};

export default LeftNewsSiderBar;
