import React from "react";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../helpers/context/config";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
import { ReactComponent as Delete } from "src/assets/images/icons/delete.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as EditIcon } from "src/assets/images/icons/editIcon.svg";
import moment from "moment";
import OurPartner from "../ourPartners";
import Loader from "src/components/Loader";
import { useState } from "react";
import { useEffect } from "react";
import DialogBox from "../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import SmartBookModal from "../UI/smartbookModal";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import NoDataComp from "../UI/NoData";
import { ReactComponent as AmericanFootball } from "../../../assets/images/SportIcon/outlineSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../../assets/images/SportIcon/outlineSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../../assets/images/SportIcon/outlineSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../../assets/images/SportIcon/outlineSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../../assets/images/SportIcon/outlineSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../../assets/images/SportIcon/outlineSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../../assets/images/SportIcon/outlineSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../../assets/images/SportIcon/outlineSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../../assets/images/SportIcon/outlineSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../../assets/images/SportIcon/outlineSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../../assets/images/SportIcon/outlineSportIcon/Tennis.svg";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import { release } from "src/helpers/context/release";

const UpcomingRunner = ({
  upcomingData,
  isUpcomingLoading,
  fetchUpcomingBlackBook,
  selectedOrg,
  selectedTeams,
  handlePaginationClick,
  upcomingCount,
  upcomingPage,
  pageNumbers,
  isToggle
}) => {
  const navigate = useNavigate();
  const [BookkeeperData, setBookKeeperData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [dataId, setDataId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [editComment, setEditComment] = useState("");
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const fetchBestOpenValue = (data, OddsFluc) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })

    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno == 0) {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map(obj => {
              if (obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter(x => x !== undefined);
          return fetchClickableOdds(maxno, providerid?.[0], "header", data);
        } else {
          return "-";
        }
      } else {
        let providerid = data
          ?.map(obj => {
            if (
              obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue === maxno
            ) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter(x => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      }
      // return maxno;
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
          style={{ margin: "0px auto" }}
        >
          <span className="no-odds">NOA</span>
        </Tooltip>
      );
    }
  };
  const fetchBestOpenIcon = (data, OddsFluc) => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (
              obj?.RaceOdds?.[0]?.RaceOddFlucs?.[OddsFluc]?.intValue === maxno
            ) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        let newmaxno = data?.reduce((max, obj) => {
          obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue > max
            ? (max = obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue)
            : (max = max);
          return max;
        }, -1);
        if (newmaxno !== -1) {
          let providerid = data
            ?.map(obj => {
              if (
                obj?.RaceOdds?.[0]?.RaceOddFlucs?.[1]?.intValue === newmaxno
              ) {
                return obj?.BookKeeperId;
              }
            })
            .filter(x => x !== undefined);
          return oddsicon(providerid?.[0], "header", data);
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  };
  const fetchCurrentBestValue = data => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return fetchClickableOdds(maxno, providerid?.[0], "header", data);
      } else {
        return "SP";
      }
    } else {
      return "SP";
    }
  };
  const fetchCurrentBestIcon = data => {
    // let marketData = data?.map((obj) => {
    //   return {
    //     ...obj, RaceOdds: obj?.RaceOdds?.filter((item) => {
    //       return item?.RaceOddKeyId == selectedOption
    //     })
    //   }
    // })
    let maxno = data?.reduce((max, obj) => {
      obj?.RaceOdds?.[0]?.intValue > max
        ? (max = obj?.RaceOdds?.[0]?.intValue)
        : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno !== 0) {
        let providerid = data
          ?.map(obj => {
            if (obj?.RaceOdds?.[0]?.intValue === maxno) {
              return obj?.BookKeeperId;
            }
          })
          .filter(x => x !== undefined);
        return oddsicon(providerid?.[0], "header", data);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const fetchClickableOdds = (odds, BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.[0]?.RaceOdds?.[0];
      return Bet365Data && Bet365Data?.length > 0 ? (
        <a
          href={
            Odds?.providerMarketId && Odds?.providerParticipantId
              ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.providerMarketId}-${Odds?.providerParticipantId}~${Odds?.intValue}~1&bet=1`
              : `${iconData?.affiliate_link}`
          }
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          {" "}
          {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
        </a>
      ) : (
        <></>
      );
    } else {
      return (
        <a
          href={iconData?.affiliate_link}
          target="_blank"
          rel="noopener noreferrer"
          className="odds-link"
        >
          <span onClick={() => handleBookkeeperCounter(BookKeeperId, type)}>
            {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
          </span>
        </a>
      );
    }
  };
  const handleBookkeeperCounter = async (BookKeeperId, type, item) => {
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(item?.SportId)
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const oddsicon = (BookKeeperId, type, item) => {
    let icon = BookkeeperData?.filter(
      obj => obj?.BookKeeperId === BookKeeperId
    );
    let iconData = icon?.[0]?.BookKeeper;

    return (
      <a
        href={iconData?.affiliate_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="bookmaker-thumb"
          src={
            iconData?.small_logo?.includes("uploads")
              ? Config.mediaURL + iconData?.small_logo
              : iconData?.small_logo
          }
          alt="Odds Icon"
          onClick={() => handleBookkeeperCounter(BookKeeperId, type, item)}
        />
      </a>
    );
  };

  const fetchBookKeeper = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setBookKeeperData(data?.result);
      } else {
      }
    } catch (err) {}
  };

  const handleDelete = (type, id) => {
    setSelectedType(type);
    setSelectedId(id);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedType("");
    setSelectedId("");
  };
  const handleuserDelete = async (type, id) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `blackbook/favoriteData/${id}?type=${type}`
      );
      if (status === 200) {
        toast.success("Deleted Successfully", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });

        handleDeleteClose();

        fetchUpcomingBlackBook(selectedOrg, selectedTeams, upcomingPage);
      } else {
      }
    } catch (error) {}
  };
  const handleEdit = async (type, id, item) => {
    setSelectedType(type);
    setTypeId(item?.SportId);
    setSelectedId(id);
    setDataId(item?.dataId);
    const comment = item?.comment;
    setEditComment(comment);
    const title =
      type === "Tournament"
        ? item?.tournamentName
        : item?.dataId === item?.homeTeamId
        ? item?.homeTeamName
        : item?.awayTeamName;

    setSelectedTitle(title);
    handleModal();
  };
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedType("");
    setSelectedId("");
  };

  useEffect(() => {
    fetchBookKeeper();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const sportIcon = sportId => {
    if (sportId === 15) {
      return <AmericanFootball />;
    } else if (sportId === 9) {
      return <AustralianRules />;
    } else if (sportId === 11) {
      return <Baseball />;
    } else if (sportId === 10) {
      return <Basketball />;
    } else if (sportId === 6) {
      return <Boxing />;
    } else if (sportId === 4) {
      return <Cricket />;
    } else if (sportId === 16) {
      return <Golf />;
    } else if (sportId === 17) {
      return <IceHockey />;
    } else if (sportId === 5) {
      return <MixedMartialArts />;
    } else if (sportId === 12) {
      return <RugbyLeague />;
    } else if (sportId === 13) {
      return <RugbyUnion />;
    } else if (sportId === 8) {
      return <Soccer />;
    } else if (sportId === 7) {
      return <Tennis />;
    }
  };

  const sportName = sportId => {
    if (sportId === 15) {
      return "American Football";
    } else if (sportId === 9) {
      return "Australian Rules";
    } else if (sportId === 11) {
      return "Baseball";
    } else if (sportId === 10) {
      return "Basketball";
    } else if (sportId === 6) {
      return "Boxing";
    } else if (sportId === 4) {
      return "Cricket";
    } else if (sportId === 16) {
      return "Golf";
    } else if (sportId === 17) {
      return "Ice Hockey";
    } else if (sportId === 5) {
      return "Mixed Martial Arts";
    } else if (sportId === 12) {
      return "Rugby League";
    } else if (sportId === 13) {
      return "Rugby Union";
    } else if (sportId === 8) {
      return "Soccer";
    } else if (sportId === 7) {
      return "Tennis";
    }
  };

  const handleNavigate = item => {
    const sportId = item?.SportId;
    let sportType =
      sportId === 4
        ? "cricket"
        : sportId === 12
        ? "rugbyleague"
        : sportId === 13
        ? "rugbyunion"
        : sportId === 10
        ? "basketball"
        : sportId === 15
        ? "americanfootball"
        : sportId === 9
        ? "australianrules"
        : sportId === 16
        ? "golf"
        : sportId === 7
        ? "tennis"
        : sportId === 11
        ? "baseball"
        : sportId === 17
        ? "icehockey"
        : sportId === 6
        ? "boxing"
        : sportId === 5
        ? "mma"
        : sportId === 8
        ? "soccer"
        : "cricket";
    sportId === 16
      ? navigate(`/teamsports/${sportType}/outrights/${item?.eventId}/true`)
      : navigate(`/teamsports/${sportType}/odds/${item?.eventId}/true`);
  };

  const fetchTeamScore = (score, sportId) => {
    const parsedScore = score
      ? typeof score === "string"
        ? JSON.parse(score)
        : score
      : {};
    // const cricketScore =
    //   (parsedScore?.innings?.inning1
    //     ? parsedScore?.innings?.inning1?.score +
    //       "/" +
    //       parsedScore?.innings?.inning1?.wickets
    //     : "-") +
    //   " " +
    //   (parsedScore?.innings?.inning1?.overs
    //     ? "(" + parsedScore?.innings?.inning1?.overs + ")"
    //     : "") +
    //   (parsedScore?.innings?.inning2
    //     ? ` & ${parsedScore?.innings?.inning2?.score}/${
    //         parsedScore?.innings?.inning2?.wickets
    //       } ${
    //         parsedScore?.innings?.inning2?.overs
    //           ? "(" + parsedScore?.innings?.inning2?.overs + ")"
    //           : ""
    //       })`
    //     : "");
    const scoreData = parsedScore?.current;
    return scoreData;
  };

  const fetchCricketScore = (teamScore, teamType) => {
    return teamType === "hometeam" ? (
      teamScore?.Exd > "1" ? (
        <Typography className="team-score">
          {teamScore?.Tr1C1 ?? "-"}
          {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
            ? ""
            : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
              `/${teamScore?.Tr1CW1}${
                teamScore?.Tr1CD1 && teamScore?.Tr1CD1 === 1 ? "d" : ""
              }`}{" "}
          {(teamScore?.Tr1C2 || teamScore?.Tr1C2 === 0) &&
            `& ${teamScore?.Tr1C2}`}
          {teamScore?.Tr1CW2 && teamScore?.Tr1CW2 === 10
            ? ""
            : (teamScore?.Tr1CW2 || teamScore?.Tr1CW2 === 0) &&
              `/${teamScore?.Tr1CW2}${
                teamScore?.Tr1CD2 && teamScore?.Tr1CD2 === 1 ? "d" : ""
              }`}{" "}
        </Typography>
      ) : (
        <Typography className="team-score">
          {teamScore?.Tr1C1 ?? "-"}
          {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
            ? ""
            : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
              `/ ${teamScore?.Tr1CW1}`}{" "}
          <span className="f-white">
            {" "}
            {teamScore?.Tr1CO1 ? `(${teamScore?.Tr1CO1})` : ""}
          </span>{" "}
        </Typography>
      )
    ) : teamScore?.Exd > "1" ? (
      <Typography className="team-score">
        {teamScore?.Tr2C1 ?? "-"}
        {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
          ? ""
          : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
            `/${teamScore?.Tr2CW1}${
              teamScore?.Tr2CD1 && teamScore?.Tr2CD1 === 1 ? "d" : ""
            }`}{" "}
        {(teamScore?.Tr2C2 || teamScore?.Tr2C2 === 0) &&
          `& ${teamScore?.Tr2C2}`}
        {teamScore?.Tr2CW2 && teamScore?.Tr2CW2 === 10
          ? ""
          : (teamScore?.Tr2CW2 || teamScore?.Tr2CW2 === 0) &&
            `/${teamScore?.Tr2CW2}${
              teamScore?.Tr2CD2 && teamScore?.Tr2CD2 === 1 ? "d" : ""
            }`}{" "}
      </Typography>
    ) : (
      <Typography className="team-score">
        {teamScore?.Tr2C1 ?? "-"}
        {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
          ? ""
          : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
            `/ ${teamScore?.Tr2CW1}`}{" "}
        <span className="f-white">
          {teamScore?.Tr2CO1 ? `(${teamScore?.Tr2CO1})` : ""}
        </span>
      </Typography>
    );
  };

  const fetchSoccerScore = (teamScore, teamType) => {
    return teamType === "hometeam" ? (
      <Typography className="team-score"> {teamScore?.Tr1 ?? "-"}</Typography>
    ) : (
      <Typography className="team-score">{teamScore?.Tr2 ?? "-"}</Typography>
    );
  };

  return (
    <>
      <Box className="page-content-wrap">
        {isUpcomingLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : upcomingData && upcomingData?.length > 0 ? (
          upcomingData?.map((item, index) => {
            return (
              <>
                <Box
                  className="team-sport-table-wrap blackbook-runner-details"
                  key={index}
                >
                  <Box className="smart-top-header-details bottom-border">
                    <Box className="header-details title-bottom-border">
                      <Typography
                        variant="h6"
                        className="day-time-details flex"
                      >
                        <span className="dark-color">
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span className="sport-icon">
                              {sportIcon(item?.SportId)}
                            </span>
                            <p className="m-0">
                              <span className="title-txt">
                                {sportName(item?.SportId)}
                              </span>
                            </p>
                          </Box>
                        </span>
                      </Typography>
                      <Typography>
                        {screenWidth < 940 ? (
                          <span className="dark-color chip chip-txt">
                            {item?.tournamentName}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </Box>
                    <Box className="Updated-date">
                      <Typography className="date">
                        Updated:{" "}
                        {item?.updatedAt
                          ? moment
                              .utc(item?.updatedAt)
                              .local()
                              .format("DD/MM/YYYY")
                          : ""}
                      </Typography>
                      <Box
                        className="delete-box cursor-pointer"
                        onClick={() => handleDelete(item?.type, item?.tableId)}
                      >
                        <Delete />
                      </Box>
                    </Box>
                  </Box>
                  <Box className="smart-top-header-details">
                    <Box className="header-details">
                      <Typography variant="h6" className="day-time-details">
                        <span className="dark-color">
                          {item?.startTime ? fetchDayName(item?.startTime) : ""}{" "}
                          {item?.startTime
                            ? moment
                                .utc(item?.startTime)
                                .local()
                                .format("DD/MM/YYYY")
                            : ""}{" "}
                        </span>
                        |{" "}
                        <span className="dark-color">
                          {item?.startTime
                            ? moment
                                .utc(item?.startTime)
                                .local()
                                .format("hh:mm A")
                            : ""}{" "}
                        </span>
                        |{" "}
                        <span className="dark-color">
                          {item?.marketLength
                            ? item?.marketLength
                            : item?.marketLength === 0
                            ? item?.marketLength
                            : ""}{" "}
                          {item?.marketLength
                            ? item?.marketLength === 1
                              ? "Market"
                              : "Markets"
                            : item?.marketLength === 0
                            ? "Market"
                            : ""}{" "}
                        </span>
                        {screenWidth >= 940 ? (
                          <span className="dark-color chip chip-txt">
                            {item?.tournamentName}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </Box>
                    {release[Config.release]?.oddsCheck && (
                      <Box className="see-all-box">
                        {/* <Box className="see-all-btn"> */}
                        <Typography
                          className="btn-txt"
                          onClick={() => handleNavigate(item)}
                        >
                          See all odds
                        </Typography>
                        {screenWidth >= 940 ? <RightArrow /> : <></>}
                        {/* </Box> */}
                      </Box>
                    )}
                    {screenWidth < 940 ? (
                      <Box className="live-box">
                        {(item?.status === "inprogress" || item?.Epr === 1) && (
                          <span className="live-indicator">LIVE</span>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>

                  {/* {item?.favoriteData?.type !== "Track" ? ( */}
                  <Box className="runner-deatils">
                    <Table className="runner-info">
                      <TableHead>
                        {screenWidth >= 940 ? (
                          <TableRow>
                            <TableCell className="runner-tc">
                              {(item?.status === "inprogress" ||
                                item?.Epr === 1) && (
                                <span className="live-indicator">LIVE</span>
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )}
                      </TableHead>
                      <TableBody>
                        {screenWidth >= 940 ? (
                          <>
                            <TableRow>
                              <TableCell className="runner-tc">
                                <Box className="team-img-wrap">
                                  <img
                                    className="team-img"
                                    src={
                                      item?.homeFlag
                                        ? Config.mediaURL + item?.homeFlag
                                        : Brisbane
                                    }
                                    alt="team"
                                  />
                                </Box>
                                <Typography className="title-txt">
                                  {item?.homeTeamName}
                                </Typography>
                              </TableCell>

                              <TableCell className="runner-tc">
                                <Box className="team-img-wrap">
                                  <img
                                    className="team-img"
                                    src={
                                      item?.awayFlag
                                        ? Config.mediaURL + item?.awayFlag
                                        : Melbourne
                                    }
                                    alt="team"
                                  />
                                </Box>
                                <Typography className="title-txt">
                                  {item?.awayTeamName}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            {/* <TableRow>
                              <TableCell
                                style={{
                                  display: "flex",
                                  columnGap: "9px",
                                  alignItems: "center"
                                }}
                                className="runner-tc"
                              >
                                <img src={item?.image1} />
                                <Typography>{item?.team1}</Typography>
                              </TableCell>

                              <TableCell
                                style={{
                                  display: "flex",
                                  columnGap: "9px",
                                  alignItems: "center"
                                }}
                                className="runner-tc"
                              >
                                <img src={item?.image2} />
                                <Typography>{item?.team2}</Typography>
                              </TableCell>
                            </TableRow> */}
                          </>
                        ) : (
                          <></>
                        )}

                        {screenWidth < 940 ? (
                          <Box className="detail-row1">
                            <Box className="left-box">
                              <Box class="team-content">
                                <Box className="team-img-wrap">
                                  <img
                                    alt="team-img"
                                    src={
                                      item?.homeFlag
                                        ? Config.mediaURL + item?.homeFlag
                                        : Brisbane
                                    }
                                    className="flag"
                                  />
                                </Box>
                                <Typography className="team-name">
                                  {item?.homeTeamName}
                                </Typography>
                              </Box>
                              <Box className="team-name size-22">V/S</Box>
                              <Box className="team-content">
                                <Box className="team-img-wrap">
                                  <img
                                    alt="flag team-img"
                                    src={
                                      item?.awayFlag
                                        ? Config.mediaURL + item?.awayFlag
                                        : Melbourne
                                    }
                                    className="flag"
                                  />
                                </Box>
                                <Typography className="team-name">
                                  {item?.awayTeamName}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </TableBody>
                      {/* <Typography className="runner-name">
                        {item?.runner?.[0]?.runnerNumber}
                        {"."} {item?.runner?.[0]?.animal?.name} (
                        {item?.runner?.[0]?.barrierNumber})
                      </Typography>
                      <Box className="info-details">
                        <Box>
                          <Typography className="runner-info-details">
                            {item?.runner?.[0]?.JockeyWeight
                              ? `Weight:${Number(
                                  item?.runner?.[0]?.JockeyWeight
                                ).toFixed(2) + "Kg"}`
                              : ""}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className="runner-info-details color-purple">
                            {item?.runner?.[0]?.Jockey?.name
                              ? `Jockey: ${item?.runner?.[0]?.Jockey?.name}`
                              : ""}
                          </Typography>
                          <Typography className="runner-info-details color-purple">
                            {item?.runner?.[0]?.Trainer?.name
                              ? `Trainer: ${item?.runner?.[0]?.Trainer?.name} `
                              : ""}
                          </Typography>
                        </Box>
                      </Box> */}
                    </Table>
                    <Box className="race-deatils">
                      {/* <Box className="race-wrap">
                        <Typography className="race-name">
                          {item?.raceNumber ? "R" + item?.raceNumber : ""}{" "}
                          {item?.raceName}
                        </Typography>
                        <Typography className="race-time">
                          {item?.startTimeDate
                            ? moment
                                .utc(item?.startTimeDate)
                                .local()
                                .format("hh:mm A")
                            : ""}{" "}
                          <RightArrow />
                        </Typography>
                      </Box> */}
                      {screenWidth >= 940 ? (
                        <Box
                          className={
                            (item?.homeScore !== null &&
                              item?.awayScore !== null) ||
                            item?.Epr === 1 ||
                            item?.Epr === 2
                              ? "odds-deatils max-width"
                              : "odds-deatils"
                          }
                        >
                          <Table className="odds-table">
                            <TableHead>
                              <TableRow>
                                {(item?.homeScore !== null &&
                                  item?.awayScore !== null &&
                                  item?.status !== null) ||
                                item?.Epr === 1 ||
                                item?.Epr === 2 ? (
                                  <TableCell className="sport-score w-89 border-bottom border-right">
                                    {item?.SportId === 4
                                      ? item?.Epr === 1
                                        ? "SCORES"
                                        : "RESULTS"
                                      : item?.status == "inprogress"
                                      ? "SCORES"
                                      : "RESULTS"}
                                  </TableCell>
                                ) : release[Config.release]?.oddsCheck ? (
                                  <TableCell className="best-at-open border-right">
                                    BEST AT OPEN
                                  </TableCell>
                                ) : (
                                  <></>
                                )}
                                {release[Config.release]?.oddsCheck && (
                                  <TableCell className="current-best">
                                    CURRENT BEST
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            {/*  */}
                            <TableBody>
                              <TableRow>
                                {item?.homeScore !== null ||
                                item?.Epr === 1 ||
                                item?.Epr === 2 ? (
                                  <TableCell className="sport-score border-right">
                                    {item?.homeScore ||
                                    item?.Epr === 1 ||
                                    item?.Epr === 2 ? (
                                      <Box className="odds-wrap">
                                        <span className="f-white ft-txt">
                                          {item?.SportId === 4
                                            ? fetchCricketScore(
                                                item,
                                                "hometeam"
                                              )
                                            : item?.SportId === 8
                                            ? fetchSoccerScore(item, "hometeam")
                                            : fetchTeamScore(
                                                item?.homeScore,
                                                item?.SportId
                                              )}
                                          {/* {" "}
                                    {fetchBestOpenValue(
                                      item?.runner?.[0]?.MarketRelations,
                                      0
                                    )} */}
                                        </span>
                                      </Box>
                                    ) : (
                                      <Box>-</Box>
                                    )}
                                  </TableCell>
                                ) : release[Config.release]?.oddsCheck ? (
                                  <TableCell className="best-at-open-cell border-right">
                                    {item?.homeBestOpen ? (
                                      <Box className="odds-wrap">
                                        <span>
                                          {Number(item?.homeBestOpen).toFixed(
                                            2
                                          )}

                                          {/* {" "}
                                    {fetchBestOpenValue(
                                      item?.runner?.[0]?.MarketRelations,
                                      0
                                    )} */}
                                        </span>

                                        {/* {fetchBestOpenIcon(
                                    item?.runner?.[0]?.MarketRelations,
                                    0
                                  )} */}
                                        {item?.homeBestBookKeepertId
                                          ? oddsicon(
                                              item?.homeBestBookKeepertId,
                                              "header",
                                              item
                                            )
                                          : ""}
                                      </Box>
                                    ) : (
                                      <Tooltip
                                        title="No odds available"
                                        className="odds-tooltip"
                                        placement="top"
                                      >
                                        <span className="odds-btn">
                                          <span className="no-odds">NOA</span>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                ) : (
                                  <></>
                                )}
                                {release[Config.release]?.oddsCheck && (
                                  <TableCell className="current-best-cell">
                                    {" "}
                                    {item?.homeCurrentBestOdd ? (
                                      <Box className="odds-wrap current-best-cell">
                                        <span>
                                          {/* 1.00 */}
                                          {Number(
                                            item?.homeCurrentBestOdd
                                          ).toFixed(2)}
                                          {/* {fetchCurrentBestValue(
                                      item?.runner?.[0]?.MarketRelations
                                    )} */}
                                        </span>
                                        {/* {fetchCurrentBestIcon(
                                    item?.runner?.[0]?.MarketRelations
                                  )} */}
                                        {item?.homeCurrentBookKeeperId
                                          ? oddsicon(
                                              item?.homeCurrentBookKeeperId,
                                              "header",
                                              item
                                            )
                                          : "-"}
                                      </Box>
                                    ) : (
                                      <Tooltip
                                        title="No odds available"
                                        className="odds-tooltip"
                                        placement="top"
                                      >
                                        <span className="odds-btn">
                                          <span className="no-odds">NOA</span>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                              {/*  */}
                              <TableRow>
                                {item?.awayScore !== null ||
                                item?.Epr === 1 ||
                                item?.Epr === 2 ? (
                                  <TableCell className="sport-score border-right">
                                    {item?.awayScore ||
                                    item?.Epr === 1 ||
                                    item?.Epr === 2 ? (
                                      <Box className="odds-wrap">
                                        <span className="f-white ft-txt">
                                          {item?.SportId === 4
                                            ? fetchCricketScore(
                                                item,
                                                "awayteam"
                                              )
                                            : item?.SportId === 8
                                            ? fetchSoccerScore(item, "awayteam")
                                            : fetchTeamScore(
                                                item?.awayScore,
                                                item?.SportId
                                              )}
                                        </span>
                                      </Box>
                                    ) : (
                                      <Box>-</Box>
                                    )}
                                  </TableCell>
                                ) : release[Config.release]?.oddsCheck ? (
                                  <TableCell className=" best-at-open-cell border-right">
                                    {item?.awayBestOpen ? (
                                      <Box className="odds-wrap">
                                        <span>
                                          {Number(item?.awayBestOpen).toFixed(
                                            2
                                          )}
                                          {/* {" "}
                                    {fetchBestOpenValue(
                                      item?.runner?.[0]?.MarketRelations,
                                      0
                                    )} */}
                                        </span>
                                        {/* {fetchBestOpenIcon(
                                    item?.runner?.[0]?.MarketRelations,
                                    0
                                  )} */}
                                        {item?.awayBestBookKeeperId
                                          ? oddsicon(
                                              item?.awayBestBookKeeperId,
                                              "header",
                                              item
                                            )
                                          : "-"}
                                      </Box>
                                    ) : (
                                      <Tooltip
                                        title="No odds available"
                                        className="odds-tooltip"
                                        placement="top"
                                      >
                                        <span className="odds-btn">
                                          <span className="no-odds">NOA</span>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                ) : (
                                  <></>
                                )}
                                {release[Config.release]?.oddsCheck && (
                                  <TableCell className="current-best-cell">
                                    {" "}
                                    {item?.awayCurrentBestOdd ? (
                                      <Box className="odds-wrap current-best-cell">
                                        <span>
                                          {Number(
                                            item?.awayCurrentBestOdd
                                          ).toFixed(2)}
                                          {/* {fetchCurrentBestValue(
                                      item?.runner?.[0]?.MarketRelations
                                    )} */}
                                        </span>
                                        {/* {fetchCurrentBestIcon(
                                    item?.runner?.[0]?.MarketRelations
                                  )} */}
                                        {item?.awayCurrentBookKeeperId
                                          ? oddsicon(
                                              item?.awayCurrentBookKeeperId,
                                              "header",
                                              item
                                            )
                                          : "-"}
                                      </Box>
                                    ) : (
                                      <Tooltip
                                        title="No odds available"
                                        className="odds-tooltip"
                                        placement="top"
                                      >
                                        <span className="odds-btn">
                                          <span className="no-odds">NOA</span>
                                        </span>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      ) : (
                        <>
                          {/*  */}
                          <Box className="mobileview-smartbook-table-wrap">
                            <Box className="team-match-wrap">
                              {(item?.status === "inprogress" &&
                                item?.homeScore &&
                                item?.awayScore) ||
                              item?.Epr === 1 ||
                              item?.Epr === 2 ? (
                                <>
                                  {/* <Typography className="des-label des-head">
                                    SCORE
                                  </Typography> */}
                                  <Box className="odds-detail score score-padding-mobile">
                                    <Box className="odds-body">
                                      <Box>
                                        {item?.homeScore ||
                                        item?.Epr === 1 ||
                                        item?.Epr === 2 ? (
                                          <Box className="odds-wrap">
                                            <span>
                                              <Box className="f-white points ft-txt">
                                                {item?.SportId === 4
                                                  ? fetchCricketScore(
                                                      item,
                                                      "hometeam"
                                                    )
                                                  : item?.SportId === 8
                                                  ? fetchSoccerScore(
                                                      item,
                                                      "hometeam"
                                                    )
                                                  : fetchTeamScore(
                                                      item?.homeScore,
                                                      item?.SportId
                                                    )}
                                                {/* {fetchBestAtOpenOdds(
                                        item,
                                        "points",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "over"
                                          : "hometeam"
                                      )} */}
                                              </Box>
                                              <span className="odds">
                                                {/* 1.00 */}
                                                {/* {fetchBestAtOpenOdds(
                                        item,
                                        "odds",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "over"
                                          : "hometeam"
                                      )} */}
                                              </span>
                                            </span>
                                          </Box>
                                        ) : (
                                          <Box>-</Box>
                                        )}
                                      </Box>
                                      <Box>
                                        {item?.awayScore ||
                                        item?.Epr === 1 ||
                                        item?.Epr === 2 ? (
                                          <Box className="odds-wrap">
                                            <span>
                                              <Box className="f-white points ft-txt">
                                                {item?.SportId === 4
                                                  ? fetchCricketScore(
                                                      item,
                                                      "awayteam"
                                                    )
                                                  : item?.SportId === 8
                                                  ? fetchSoccerScore(
                                                      item,
                                                      "awayteam"
                                                    )
                                                  : fetchTeamScore(
                                                      item?.awayScore,
                                                      item?.SportId
                                                    )}
                                                {/* {fetchBestAtOpenOdds(
                                        item,
                                        "points",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "under"
                                          : "awayteam"
                                      )} */}
                                              </Box>
                                              <span className="odds odds-left">
                                                {/* 1.00 */}
                                                {/* {" "}
                                      {fetchBestAtOpenOdds(
                                        item,
                                        "odds",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "under"
                                          : "awayteam"
                                      )} */}
                                              </span>
                                            </span>
                                          </Box>
                                        ) : (
                                          <Box>-</Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                </>
                              ) : release[Config.release]?.oddsCheck ? (
                                <>
                                  <Typography className="des-label des-head">
                                    BEST AT OPEN
                                  </Typography>
                                  <Box className="odds-detail">
                                    <Box className="odds-body">
                                      {item?.homeBestOpen ? (
                                        <Box>
                                          <Box className="odds-wrap">
                                            <span className="bookmaker-long odds-left">
                                              {item?.homeBestBookKeepertId
                                                ? oddsicon(
                                                    item?.homeBestBookKeepertId,
                                                    "header",
                                                    item
                                                  )
                                                : ""}
                                              {/* {fetchBestAtOpenIconOdds(
                                      item,
                                      "odds",
                                      MarketName?.toLowerCase()?.includes(
                                        "total"
                                      )
                                        ? "over"
                                        : "hometeam"
                                    )} */}
                                            </span>
                                            <span>
                                              <Box className="points">
                                                {/* {fetchBestAtOpenOdds(
                                        item,
                                        "points",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "over"
                                          : "hometeam"
                                      )} */}
                                              </Box>

                                              <span className="odds">
                                                {Number(
                                                  item?.homeBestOpen
                                                ).toFixed(2)}
                                                {/* {fetchBestAtOpenOdds(
                                        item,
                                        "odds",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "over"
                                          : "hometeam"
                                      )} */}
                                              </span>
                                            </span>
                                          </Box>
                                        </Box>
                                      ) : (
                                        <Tooltip
                                          title="No odds available"
                                          className="odds-tooltip"
                                          placement="top"
                                        >
                                          <span
                                            className="odds-btn"
                                            style={{
                                              display: "block",
                                              textAlign: "center",
                                              width: "97px"
                                            }}
                                          >
                                            <span className="no-odds">NOA</span>
                                          </span>
                                        </Tooltip>
                                      )}
                                      {item?.awayBestOpen ? (
                                        <Box>
                                          <Box className="odds-wrap">
                                            <span>
                                              <Box className="points">
                                                {/* 1.00 */}
                                                {/* {fetchBestAtOpenOdds(
                                        item,
                                        "points",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "under"
                                          : "awayteam"
                                      )} */}
                                              </Box>
                                              <span className="odds odds-left">
                                                {Number(
                                                  item?.awayBestOpen
                                                ).toFixed(2)}
                                                {/* {" "}
                                      {fetchBestAtOpenOdds(
                                        item,
                                        "odds",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "under"
                                          : "awayteam"
                                      )} */}
                                              </span>
                                            </span>
                                            <span className="bookmaker-long">
                                              {item?.awayBestBookKeeperId
                                                ? oddsicon(
                                                    item?.awayBestBookKeeperId,
                                                    "header",
                                                    item
                                                  )
                                                : "-"}
                                              {/* {fetchBestAtOpenIconOdds(
                                      item,
                                      "odds",
                                      MarketName?.toLowerCase()?.includes(
                                        "total"
                                      )
                                        ? "under"
                                        : "awayteam"
                                    )} */}
                                            </span>
                                          </Box>
                                        </Box>
                                      ) : (
                                        <Tooltip
                                          title="No odds available"
                                          className="odds-tooltip"
                                          placement="top"
                                        >
                                          <span
                                            className="odds-btn"
                                            style={{
                                              display: "block",
                                              textAlign: "center",
                                              width: "97px"
                                            }}
                                          >
                                            <span className="no-odds">NOA</span>
                                          </span>
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <></>
                              )}
                              {release[Config.release]?.oddsCheck && (
                                <>
                                  <Typography className="des-label des-head des-current-best">
                                    CURRENT BEST
                                  </Typography>
                                  <Box className="odds-detail odds-details-best">
                                    <Box className="odds-body">
                                      {item?.homeCurrentBestOdd ? (
                                        <Box>
                                          <Box className="odds-wrap">
                                            <span className="bookmaker-long odds-left">
                                              {item?.homeCurrentBookKeeperId
                                                ? oddsicon(
                                                    item?.homeCurrentBookKeeperId,
                                                    "header",
                                                    item
                                                  )
                                                : "-"}
                                              {/* {fetchCurrentBestsOddsIcon(
                                      item,
                                      "odds",
                                      MarketName?.toLowerCase()?.includes(
                                        "total"
                                      )
                                        ? "over"
                                        : "hometeam"
                                    )} */}
                                            </span>
                                            <span>
                                              <Box className="points">
                                                {/* {fetchCurrentBestOdds(
                                        item,
                                        "points",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "over"
                                          : "hometeam"
                                      )} */}
                                              </Box>
                                              <span className="odds">
                                                {Number(
                                                  item?.homeCurrentBestOdd
                                                ).toFixed(2)}
                                                {/* 1.00 */}
                                                {/* {fetchCurrentBestOdds(
                                        item,
                                        "odds",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "over"
                                          : "hometeam"
                                      )} */}
                                              </span>
                                            </span>
                                          </Box>
                                        </Box>
                                      ) : (
                                        <Tooltip
                                          title="No odds available"
                                          className="odds-tooltip"
                                          placement="top"
                                        >
                                          <span
                                            className="odds-btn"
                                            style={{
                                              display: "block",
                                              textAlign: "center",
                                              width: "97px"
                                            }}
                                          >
                                            <span className="no-odds">NOA</span>
                                          </span>
                                        </Tooltip>
                                      )}
                                      {item?.awayCurrentBestOdd ? (
                                        <Box>
                                          {/* <Box className="odds-label">
                                      {" "}
                                      {localesData?.sport?.WIN}{" "}
                                    </Box> */}
                                          <Box className="odds-wrap">
                                            <span>
                                              <Box className="points">
                                                {/* {fetchCurrentBestOdds(
                                        item,
                                        "points",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "under"
                                          : "awayteam"
                                      )} */}
                                              </Box>
                                              <span className="odds odds-left">
                                                {Number(
                                                  item?.awayCurrentBestOdd
                                                ).toFixed(2)}
                                                {/* {fetchCurrentBestOdds(
                                        item,
                                        "odds",
                                        MarketName?.toLowerCase()?.includes(
                                          "total"
                                        )
                                          ? "under"
                                          : "awayteam"
                                      )} */}
                                              </span>
                                            </span>
                                            <span className="bookmaker-long">
                                              {item?.awayCurrentBookKeeperId
                                                ? oddsicon(
                                                    item?.awayCurrentBookKeeperId,
                                                    "header",
                                                    item
                                                  )
                                                : "-"}
                                              {/* 1.00 */}
                                              {/* {fetchCurrentBestsOddsIcon(
                                      item,
                                      "odds",
                                      MarketName?.toLowerCase()?.includes(
                                        "total"
                                      )
                                        ? "under"
                                        : "awayteam"
                                    )} */}
                                            </span>
                                          </Box>
                                        </Box>
                                      ) : (
                                        <Tooltip
                                          title="No odds available"
                                          className="odds-tooltip"
                                          placement="top"
                                        >
                                          <span
                                            className="odds-btn"
                                            style={{
                                              display: "block",
                                              textAlign: "center",
                                              width: "97px"
                                            }}
                                          >
                                            <span className="no-odds">NOA</span>
                                          </span>
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </Box>{" "}
                                </>
                              )}
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                  {isToggle ? (
                    <Box className="comment-section">
                      <Box className="runner-comment width-86">
                        <Typography className="runner">
                          {" "}
                          {item?.type}:
                        </Typography>
                        <Typography className="runner comment">
                          {item?.comment}
                        </Typography>
                      </Box>
                      <Box className="width-14">
                        <Typography
                          className="edit-comment cursor-pointer"
                          onClick={() =>
                            handleEdit(item?.type, item?.tableId, item)
                          }
                        >
                          <EditIcon />
                          Edit comment
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            );
          })
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              <Box
                style={{
                  textAlign: "center",
                  border: "transparent",
                  fontFamily: "sans-serif"
                }}
              >
                {/* No Data Available */}
                <NoDataComp />
              </Box>
            </Box>
          </>
        )}
        {!isUpcomingLoading && upcomingData && upcomingData?.length > 0 ? (
          <Box className="pagination-section">
            <Pagination
              disabled={upcomingCount / 10 > 1 ? false : true}
              page={upcomingPage}
              onChange={(e, value) => handlePaginationClick(e, value)}
              count={pageNumbers[pageNumbers?.length - 1]}
              siblingCount={2}
              boundaryCount={1}
              size="small"
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <OurPartner />
      <DialogBox
        open={deleteOpen}
        onClose={handleDeleteClose}
        handleClose={handleDeleteClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                Are you sure you want to delete?
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={() => {
                    handleuserDelete(selectedType, selectedId);
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleDeleteClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <SmartBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        tableId={selectedId}
        dataId={dataId}
        sportId={typeId}
        editComment={editComment}
        isEdit={true}
        tabType="Upcoming"
        listingFunction={() =>
          fetchUpcomingBlackBook(selectedOrg, selectedTeams, upcomingPage)
        }
      />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default UpcomingRunner;
