import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { fetchFromStorage } from "src/helpers/context";
import banner from "../../../assets/images/banner/smart-b-banner-primary.png";
import AdvertisingBannner from "../advertisingBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import { Config } from "src/helpers/context";
import DialogBox from "../UI/dialogBox";
import { Rating } from "@material-ui/lab";
import OurPartner from "../ourPartners";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch, useSelector } from "react-redux";
import AdBannner from "../AdBanner/AdBanner";
import "./bookmakers.scss";

const BookMakersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);

  const [BookkeeperData, setBookkeeperData] = useState([]);
  const [BookkeeperID, setBookkeeperID] = useState(1);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [ratingError, setRatingError] = useState("");
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  useEffect(() => {
    getBookmakersList();
  }, []);

  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = ReduxAdsData => {
    // try {
    //   const { status, data } = await axiosInstance.get(
    //     `getAllAds?page_id=1,11`
    //   );
    //   if (status === 200) {
    let AdvtData = ReduxAdsData?.filter(item => item?.page_id === 1);
    setAdData(AdvtData);
    // dispatch(fetchAdvtData(AdvtData?.[0]));
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    var payload = {
      bookKeeperId: BookkeeperID,
      review: review,
      rating: rating
    };

    if (rating === 0) {
      setRatingError("Please select a rating.");
    } else {
      setRatingError("");
      if (rating !== null || rating > 0) {
        try {
          const { status, data } = await axiosInstance.post(
            `/reviews`,
            payload
          );
          if (status === 201) {
            setOpen(false);
            setReview("");
            setRating(0);
          }
        } catch (err) {
          console.log("err", err);
        }
      }
    }
  };
  const oncloseDialog = () => {
    setOpen(false);
    setReview("");
    setRating(0);
  };

  const getBookmakersList = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders`
      );
      if (status === 200) {
        setLoader(false);
        setBookkeeperData(data?.result);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log("error", err);
    }
  };

  const handleNavigate = data => {
    navigate(`/bookmaker/bookmakers-info/${data?.BookKeeper?.id}`, {
      state: { data: data }
    });
  };

  const showLogo = item => {
    var logo = item?.BookKeeper?.logo?.includes("uploads")
      ? Config.mediaURL + item?.BookKeeper?.logo
      : item?.BookKeeper?.long_logo?.includes("uploads")
      ? Config.mediaURL + item?.BookKeeper?.long_logo
      : item?.BookKeeper?.small_logo?.includes("uploads")
      ? Config.mediaURL + item?.BookKeeper?.small_logo
      : "";
    return logo;
  };

  const handleReviewChange = event => {
    setReview(event.target.value);
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleReviewSection = data => {
    if (handleRestrictedUser()) {
      setOpen(true);
      setBookkeeperID(data?.BookKeeper?.id);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="mobile-banner">
          {/* <AdvertisingBannner src={banner} className="banner" /> */}
          {fetchPageHeaderAds("218px", banner)}
        </Box>

        <Box className="bookmakers-data">
          <Typography className="page-title">Bookmakers</Typography>

          <Box className="bookmakers-list-box">
            {loader ? (
              <div className="allsport-loader-center">
                <Loader />
              </div>
            ) : BookkeeperData?.length > 0 ? (
              BookkeeperData?.map((item, i) => {
                return (
                  <Box className="bookmakers-list" key={i}>
                    <Box className="image-title-box">
                      <Box
                        className="img-box"
                        onClick={() => handleNavigate(item)}
                      >
                        <img
                          className="img"
                          src={showLogo(item)}
                          alt={item?.BookKeeper?.key}
                        />
                      </Box>

                      <Typography
                        className="title"
                        onClick={() => handleNavigate(item)}
                      >
                        {item?.BookKeeper?.name}
                      </Typography>
                    </Box>
                    <Box className="review-box">
                      <Button
                        className="review-txt"
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        onClick={() => {
                          handleReviewSection(item);
                        }}
                      >
                        Review
                      </Button>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box className="no-data-wrap-bookmakers">
                <Typography className="text-align no-data">
                  No Data Available
                </Typography>
              </Box>
            )}
          </Box>
          <OurPartner />
        </Box>

        <DialogBox
          open={open}
          onClose={oncloseDialog}
          handleClose={oncloseDialog}
          title={false}
          className="dialog-box"
          content={
            <Box className="smartb-dialog">
              <Typography className="title">Write a review</Typography>
              <Rating
                value={rating}
                name="half-rating"
                className="rating"
                onChange={handleRatingChange}
              />
              {ratingError && !rating && (
                <div className="text-danger ml-4">{ratingError}</div>
              )}
              <TextField
                id="review"
                variant="outlined"
                className="txt-fld"
                placeholder="Write a review"
                multiline
                rows={8}
                fullWidth
                value={review}
                onChange={handleReviewChange}
              />

              <Box className="submit-btn-box">
                <Button className="submit-btn" onClick={e => handleSubmit(e)}>
                  Submit
                </Button>
              </Box>
            </Box>
          }
          closeIcon={true}
        />
      </Box>
    </>
  );
};

export default BookMakersPage;
