import React from "react";
import { Box } from "@material-ui/core";
import Page from "src/components/Page";
import FuturesTablePage from "src/views/component/allsports/racing/futuresTable/futuresTablePage";

const FuturesTablePageView = () => {
  return (
    <Page title="Racing">
      <Box className="content-wrap">
        <FuturesTablePage />
      </Box>
    </Page>
  );
};

export default FuturesTablePageView;
