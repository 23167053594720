import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { IntlContext } from "src/App";
import DefaultImg from "../../../../../assets/images/smartb_default.png";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import moment from "moment-timezone";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";
import { NavLink } from "react-router-dom";
import he from "he";

const categoryData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  }
];

const TeamNewsPage = ({ teamDetails }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentPage, setcurrentPage] = useState(1);
  const [categoryCount, setcategoryCount] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [offset, setoffset] = useState(0);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [tagsToPass, setTagsToPass] = useState("");
  const rowPerPage = 10;

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    const tagsToPass = teamDetails?.name ? teamDetails?.name : "";
    fetchNewsCategoryData(0, tagsToPass.trim());
    setTagsToPass(tagsToPass.trim());
  }, []);
  const fetchNewsCategoryData = async (page, tag) => {
    const SportId = teamDetails?.SportId;
    try {
      setIsCategoryLoading(true);
      const { status, data } = await axiosInstance.get(
        `v2/news/articles?SportId=${SportId}&limit=${rowPerPage}&offset=${page}&tag=${tag}`
      );
      if (status === 200) {
        setCategoryData(data?.result);
        setIsCategoryLoading(false);
        setcategoryCount(data?.result?.count);
        // setSearchValue("");
      } else {
        setIsCategoryLoading(false);
      }
    } catch (err) {
      setIsCategoryLoading(false);
    }
  };
  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} hours ago`;
      }
    } else {
      return `${timeDiffMinutes} minutes ago`;
    }
  };

  const scrollToTopSection = () => {
    const targetSection = document.getElementById("news-section");

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth"
      });
    }
  };
  const handlePaginationClick = (event, page) => {
    setcurrentPage(Number(page));
    setoffset((Number(page) - 1) * rowPerPage);
    fetchNewsCategoryData((Number(page) - 1) * rowPerPage, tagsToPass);
    scrollToTopSection();
  };
  const pageNumbers = [];

  if (categoryCount > 0) {
    for (let i = 1; i <= Math.ceil(categoryCount / rowPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const fetchNewsSubtitle = data => {
    const parseHTMLContent = body => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = content => {
      return he.decode(content);
    };
    const removeHtmlTags = htmlString => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = content => {
      return (
        <Typography className="sub-details">
          {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)}
          <span className="read-more">...read more</span>
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        item =>
          item?.type === "editor_block" && item?.data?.type === "paragraph"
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };

  return (
    <>
      <Box className="fixture-wrap">
        <Box className="all-news category-news" id="news-section">
          {isCategoryLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : categoryData?.raw?.length > 0 ? (
            <>
              <Typography variant="h4" className="fixture-heading">
                {localesData?.FIXTURE_INFO?.NEWS}
              </Typography>
              <Grid container className="individual-news-cont">
                {categoryData?.raw?.map(item => (
                  <Grid
                    item
                    xs={12}
                    className="individual-single-news"
                    key={item?.id}
                    //   onClick={() =>
                    //     navigate(`/news/${item?.NewsCategoryId}/${item?.id}`)
                    //   }
                  >
                    <NavLink to={`/news/${item?.NewsCategoryId}/${item?.id}`}>
                      <Box className="news-box">
                        <Box className="news-tag-relative">
                          <Box className="news-img">
                            <img
                              src={
                                item?.mainMedia?.gallery
                                  ? item?.mainMedia?.gallery?.url &&
                                    item?.mainMedia?.gallery?.url !== ""
                                    ? item?.mainMedia?.gallery?.url
                                    : DefaultImg
                                  : item?.mainMedia?.[0]?.gallery
                                  ? item?.mainMedia?.[0]?.gallery?.url &&
                                    item?.mainMedia?.[0]?.gallery?.url !== ""
                                    ? item?.mainMedia?.[0]?.gallery?.url
                                    : DefaultImg
                                  : DefaultImg
                              }
                              alt="placeholder"
                            />
                          </Box>
                          <span className="news-tag">
                            {item?.NewsCategory?.initialTitle}
                          </span>
                        </Box>
                        <Box className="individual-news-details">
                          <Typography
                            className="details"
                            dangerouslySetInnerHTML={{
                              __html: item?.title ? item?.title : item?.subTitle
                            }}
                          >
                            {/* {item?.title} */}
                          </Typography>
                          <Box className="sub-details-wrap">
                            {fetchNewsSubtitle(item)}
                          </Box>
                          <Box className="tag-time-details">
                            {/* <Box className="news-tags">
                              {screenWidth > 860
                                ? item?.NewsTagRelations?.slice(0, 3).map(
                                    (data, index) => {
                                      return data?.NewsTag ? (
                                        <span
                                          className="individual-news-tag"
                                          key={index}
                                        >
                                          {data?.NewsTag?.title}
                                        </span>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )
                                : item?.NewsTagRelations?.slice(0, 1).map(
                                    (data, index) => {
                                      return data?.NewsTag ? (
                                        <span
                                          className="individual-news-tag"
                                          key={index}
                                        >
                                          {data?.NewsTag?.title}
                                        </span>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )}
                            </Box> */}
                            <Typography className="stories-time">
                              <span className="watch">
                                <Watch />
                              </span>
                              {fetchNewsTime(item?.rapidCreatedAt)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </NavLink>
                  </Grid>
                ))}
              </Grid>
              <Box className="news-Pagination">
                {/* {params?.categoryId != 100 ? ( */}
                <Pagination
                  // hideNextButton
                  // hidePrevButton
                  disabled={categoryCount / rowPerPage > 1 ? false : true}
                  page={currentPage}
                  onChange={(e, value) => handlePaginationClick(e, value)}
                  count={pageNumbers[pageNumbers?.length - 1]}
                  siblingCount={2}
                  boundaryCount={1}
                  // count={10}
                  size="small"
                />
                {/* ) : (
                <></>
              )} */}
              </Box>
            </>
          ) : (
            <>
              <Box style={{ textAlign: "center" }}>
                {/* No Data Available */}
                <NoDataComp />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TeamNewsPage;
