import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tab,
  Tabs
} from "@material-ui/core";
import { Config } from "../../../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useParams } from "react-router";
import moment from "moment";
import Loader from "src/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";

const H2HInfoData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  }
];

const tableTabData = [
  {
    id: 1,
    name: "H2H"
  },
  {
    id: 2,
    name: "Home"
  },
  {
    id: 3,
    name: "Away"
  }
];

const InfoH2H = ({ fixtureInfoData, singleMatchData }) => {
  const params = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tabvalue, setTabValue] = useState(1);
  const [h2hLoading, seth2hLoading] = useState(false);
  const [h2hData, seth2hData] = useState([]);
  const [matchCount, setMatchCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const rowPerPage = 20;

  const handleTabChange = (event, value) => {
    setTabValue(value);
    setOffset(0);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (singleMatchData) {
      fetchH2HData(0);
    }
  }, [params, tabvalue, singleMatchData]);

  const fetchH2HData = async offset => {
    seth2hLoading(true);
    const h2hQuary =
      tabvalue === 1
        ? `awayTeamId=${singleMatchData?.awayTeamId ??
            ""}&homeTeamId=${singleMatchData?.homeTeamId ?? ""}`
        : tabvalue === 2
        ? `homeTeamId=${singleMatchData?.homeTeamId ?? ""}`
        : tabvalue === 3
        ? `awayTeamId=${singleMatchData?.awayTeamId ?? ""}`
        : "";
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event?${h2hQuary}&limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        let count = data?.result?.count / 20;
        setMatchCount(Math.ceil(count));
        seth2hData(data?.result?.result);
        seth2hLoading(false);
      } else {
        seth2hLoading(false);
      }
    } catch (err) {
      seth2hLoading(false);
    }
  };

  const handleOnScrollBottomMatch = async offset => {
    const h2hQuary =
      tabvalue === 1
        ? `awayTeamId=${singleMatchData?.awayTeamId ??
            ""}&homeTeamId=${singleMatchData?.homeTeamId ?? ""}`
        : tabvalue === 2
        ? `homeTeamId=${singleMatchData?.homeTeamId ?? ""}`
        : tabvalue === 3
        ? `awayTeamId=${singleMatchData?.awayTeamId ?? ""}`
        : "";
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/statistics/soccer/event?${h2hQuary}&limit=${rowPerPage}&offset=${offset}`
      );
      if (status === 200) {
        setOffset(offset);
        seth2hData([...h2hData, ...data?.result?.result]);
      } else {
      }
    } catch (err) {}
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const fetchDayName = date => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };

  const getMatchStatus = status => {
    if (status == "FT") {
      return "Full Time";
    } else if (status == "HT") {
      return "Half Time";
    } else return status;
  };
  return (
    <>
      <Box className="info-table-wrap h2h-wrap">
        <Tabs
          value={tabvalue}
          variant="scrollable"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          className="scorecard-tab-detail"
        >
          {tableTabData?.map((item, index) => {
            return (
              <Box key={index}>
                <Tab
                  label={
                    <Box className="d-flex align-center team-wrap">
                      <span>{item?.name}</span>
                    </Box>
                  }
                  value={item?.id}
                  className={item?.id === tabvalue ? "active " : ""}
                  onChange={(event, newValue) =>
                    handleTabChange(event, item?.id)
                  }
                />
              </Box>
            );
          })}
        </Tabs>
        {h2hLoading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={h2hData?.length}
            next={() => handleOnScrollBottomMatch(offset + 20)}
            hasMore={
              matchCount !== 0 && matchCount !== Math.ceil(offset / 20 + 1)
            }
            loader={
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            }
          >
            <Box>
              {h2hData?.length > 0 ? (
                h2hData?.map((item, index) => {
                  return (
                    <>
                      <Box className="h2h-sport-table-wrap" key={index}>
                        <Box className="table-header-wrap">
                          {item?.SoccerTournament && (
                            <Box className="table-indicator-wrap">
                              <span>{item?.SoccerTournament?.name}</span>
                            </Box>
                          )}
                        </Box>
                        {screenWidth > 1023 ? (
                          <Table className="h2h-sport-table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Box className="h2h-sport-table-indicator">
                                    <Box>
                                      <span className="start-time-date">
                                        {fetchDayName(item?.startTime)}{" "}
                                        {moment
                                          .utc(item?.startTime)
                                          .local()
                                          .format("DD/MM/YYYY")}{" "}
                                        |{" "}
                                        {moment
                                          .utc(item?.startTime)
                                          .local()
                                          .format("hh:mm A")}{" "}
                                      </span>
                                    </Box>
                                  </Box>
                                </TableCell>
                                {item?.ScoreBoard && (
                                  <TableCell className="table-head">
                                    {getMatchStatus(item?.ScoreBoard?.Eps)}
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow className="teamsports-tablerow hometeam-row">
                                <TableCell className="team-wrap">
                                  <Box className="d-flex align-center team-wrap-box">
                                    <span className="team-img-wrap">
                                      {fetchTeamlogo(item, "hometeam")}
                                    </span>
                                    <span className="team-name">
                                      {item?.homeTeam?.name}
                                    </span>
                                  </Box>
                                </TableCell>
                                {item?.ScoreBoard && (
                                  <TableCell className="td-cell border-bottom">
                                    <Box className="odds-wrap-info d-flex align-center">
                                      <Box className="points">
                                        <span>
                                          {item?.ScoreBoard?.Tr1 ?? ""}
                                        </span>
                                      </Box>
                                      {item?.ScoreBoard?.Tr1 ==
                                      item?.ScoreBoard?.Tr2 ? (
                                        <Box className="win-loss draw">
                                          <span>D</span>
                                        </Box>
                                      ) : item?.ScoreBoard?.Tr1 <
                                        item?.ScoreBoard?.Tr2 ? (
                                        <Box className="win-loss loss">
                                          <span>L</span>
                                        </Box>
                                      ) : item?.ScoreBoard?.Tr1 >
                                        item?.ScoreBoard?.Tr2 ? (
                                        <Box className="win-loss win">
                                          <span>W</span>
                                        </Box>
                                      ) : null}
                                    </Box>
                                  </TableCell>
                                )}
                              </TableRow>
                              <TableRow className="awayteam-row">
                                <TableCell className="team-wrap">
                                  <Box className="d-flex align-center team-wrap-box">
                                    <span className="team-img-wrap">
                                      {fetchTeamlogo(item, "awayteam")}
                                    </span>
                                    <span className="team-name mr-6">
                                      {item?.awayTeam?.name}
                                    </span>
                                  </Box>
                                </TableCell>
                                {item?.ScoreBoard && (
                                  <TableCell className="td-cell">
                                    <Box className="odds-wrap-info d-flex align-center">
                                      <Box className="points">
                                        <span>
                                          {item?.ScoreBoard?.Tr2 ?? ""}
                                        </span>
                                      </Box>
                                      {item?.ScoreBoard?.Tr1 ==
                                      item?.ScoreBoard?.Tr2 ? (
                                        <Box className="win-loss draw">
                                          <span>D</span>
                                        </Box>
                                      ) : item?.ScoreBoard?.Tr2 <
                                        item?.ScoreBoard?.Tr1 ? (
                                        <Box className="win-loss loss">
                                          <span>L</span>
                                        </Box>
                                      ) : item?.ScoreBoard?.Tr2 >
                                        item?.ScoreBoard?.Tr1 ? (
                                        <Box className="win-loss win">
                                          <span>W</span>
                                        </Box>
                                      ) : null}
                                    </Box>
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableBody>
                          </Table>
                        ) : (
                          <Box className="mobileview-h2h-table-wrap">
                            <Box className="match-time-date">
                              <>
                                <Typography className="h2h-des-label">
                                  {fetchDayName(item?.startTime)}{" "}
                                  {moment
                                    .utc(item?.startTime)
                                    .local()
                                    .format("DD/MM/YYYY")}{" "}
                                  |{" "}
                                  {moment
                                    .utc(item?.startTime)
                                    .local()
                                    .format("hh:mm A")}{" "}
                                </Typography>
                              </>
                            </Box>
                            <Box className="teams-details d-flex align-center">
                              <Box className="team-wrap">
                                <span className="team-img-wrap">
                                  {fetchTeamlogo(item, "hometeam")}
                                </span>
                                <Box className="teams-details-title">
                                  <span className="team-name">
                                    {" "}
                                    {item?.homeTeam?.name}
                                  </span>
                                </Box>
                              </Box>
                              <Box className="vs"> V/S </Box>
                              <Box className="team-wrap">
                                <span className="team-img-wrap">
                                  {fetchTeamlogo(item, "awayteam")}
                                </span>
                                <Box className="teams-details-title">
                                  <span className="team-name">
                                    {" "}
                                    {item?.awayTeam?.name}
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                            {item?.ScoreBoard && (
                              <Box className="h2h-full-time-wrap">
                                <Box className="full-time-wrap">
                                  <span>
                                    {getMatchStatus(item?.ScoreBoard?.Eps)}
                                  </span>
                                </Box>
                                <Box className="d-flex align-center mobile-h2h-odds">
                                  <Box className="d-flex align-center h2h-odds-left">
                                    <Box className="points">
                                      <span>{item?.ScoreBoard?.Tr1 ?? ""}</span>
                                    </Box>
                                    {item?.ScoreBoard?.Tr1 ==
                                    item?.ScoreBoard?.Tr2 ? (
                                      <Box className="win-loss draw">
                                        <span>D</span>
                                      </Box>
                                    ) : item?.ScoreBoard?.Tr1 <
                                      item?.ScoreBoard?.Tr2 ? (
                                      <Box className="win-loss loss">
                                        <span>L</span>
                                      </Box>
                                    ) : item?.ScoreBoard?.Tr1 >
                                      item?.ScoreBoard?.Tr2 ? (
                                      <Box className="win-loss win">
                                        <span>W</span>
                                      </Box>
                                    ) : null}
                                  </Box>
                                  <Box className="d-flex align-center h2h-odds-right">
                                    <Box className="points">
                                      <span>{item?.ScoreBoard?.Tr2 ?? ""}</span>
                                    </Box>
                                    {item?.ScoreBoard?.Tr1 ==
                                    item?.ScoreBoard?.Tr2 ? (
                                      <Box className="win-loss draw">
                                        <span>D</span>
                                      </Box>
                                    ) : item?.ScoreBoard?.Tr2 <
                                      item?.ScoreBoard?.Tr1 ? (
                                      <Box className="win-loss loss">
                                        <span>L</span>
                                      </Box>
                                    ) : item?.ScoreBoard?.Tr2 >
                                      item?.ScoreBoard?.Tr1 ? (
                                      <Box className="win-loss win">
                                        <span>W</span>
                                      </Box>
                                    ) : null}
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    </>
                  );
                })
              ) : (
                <Box className="no-match"> No Stats Available </Box>
              )}
            </Box>
          </InfiniteScroll>
        )}
      </Box>
    </>
  );
};

export default InfoH2H;
