import React, { useState, useEffect, useContext } from "react";
import { IntlContext } from "src/App";
import "./trackprofile.scss";
import {
  Box,
  Breadcrumbs,
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import AboveTable from "../../../../assets/images/ad-placeholder/sports1.webp";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import AboveOurPartner from "../../../../assets/images/ad-placeholder/sports2.webp";
import BelowOurPartner from "../../../../assets/images/ad-placeholder/sports5.webp";
import Horse from "../../../../assets/images/dark_horse.svg";
import Greys from "../../../../assets/images/dar-greyhound.svg";
import Harnes from "../../../../assets/images/dark_harness.svg";
import { ReactComponent as Unchecked } from "../../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../../assets/images/icons/smartb-check.svg";
import { ReactComponent as Plus } from "../../../../assets/images/icons/plus.svg";
import { ReactComponent as Right } from "../../../../assets/images/icons/right.svg";
import { ReactComponent as BlackbookRight } from "../../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../../assets/images/icons/blackbookPlus.svg";
import Loader from "src/components/Loader";
import moment from "moment/moment";
import _ from "lodash";
// import axiosInstance from "src/helpers/Axios/axiosInstance";
// import Loader from "../../../../components/Loader";
import { ReactComponent as Search } from "../../../../assets/images/icons/search.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectboxicon.svg";
import OurPartner from "../../ourPartners";
import ScriptLoader from "../../scriptLoader/ScriptLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import BlackBookModal from "../../UI/blackBookModal";
import NoDataComp from "../../UI/NoData";

const trackplace = [
  { id: 0, label: "ACT" },
  { id: 1, label: "NSW" },
  { id: 2, label: "NT" },
  { id: 3, label: "QLD" },
  { id: 4, label: "SA" },
  { id: 5, label: "TAS" },
  { id: 6, label: "VIC" },
  { id: 7, label: "WA" }
];
const datas = [
  { id: 0, label: "Canberra, ACT, Australia" },
  { id: 1, label: "Canberra Acton, ACT, Australia" }
];
const datas2 = [
  { id: 0, label: "Adaminaby, NSW, Australia" },
  { id: 1, label: "Albury, NSW, Australia" },
  { id: 2, label: "Ardlethan, NSW, Australia" }
];
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};
function Trackprofiles() {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  let navigate = useNavigate();
  const params = useParams();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [value, setValue] = useState("ACT");
  const [tabData, setTabData] = useState(datas);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState("");
  const [state, setState] = useState([]);
  const [count, setcount] = useState(0);
  const [pageCountry, setpageCountry] = useState(0);
  const [statecount, setstatecount] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [searchCountryCount, setsearchCountryCount] = useState("");
  const [searchCountry, setsearchCountry] = useState([]);
  const [isCountrySearch, setisCountrySearch] = useState("");
  const [SearchCountrypage, setSearchCountrypage] = useState(0);
  const [searchStateCount, setsearchStateCount] = useState("");
  const [searchState, setsearchState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStatePage, setsearchStatePage] = useState(0);
  const [isCountrySelectOpen, setisCountrySelectOpen] = useState(false);
  const [countryApiCount, setCountryApiCount] = useState(0);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [stateApiCount, setStateApiCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [TrackListData, setTrackListData] = useState([]);
  const [EventPage, setEventPage] = useState(0);
  const [EventCount, setEventCount] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [collepseRace, setcollepseRace] = useState([1, 2, 3]);
  const [filterRaceType, setfilterRaceType] = useState([
    {
      id: 1,
      Racevalue: "Horse Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Horse} alt="horse" />
        </span>
      )
    },
    {
      id: 3,
      Racevalue: "Greyhound Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Greys} alt="greyhound" />
        </span>
      )
    },
    {
      id: 2,
      Racevalue: "Harness Racing",
      isRaceChecked: true,
      icon: (
        <span>
          <img src={Harnes} alt="harness" />
        </span>
      )
    }
  ]);
  const alpha = [];
  for (let i = 65; i <= 90; i++) {
    alpha.push({
      letter: String.fromCharCode(i),
      isSelected: false
    });
  }
  const [letters, setLetters] = useState(alpha);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [isViewAll, setIsViewAll] = useState(false);
  const [viewAllAlphabet, setViewAllAlphabet] = useState("");
  const [viewAllCount, setViewAllCount] = useState(0);
  const [viewAllOffset, setViewAllOffset] = useState(0);
  const [BBopen, setBBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");

  const lettersCount = [];
  for (let i = 65; i <= 90; i++) {
    lettersCount.push(String.fromCharCode(i));
  }
  const handleChange = (id, value) => {
    setValue(value);
    setTabData(value === "ACT" ? datas : datas2);
  };
  const handleNavigate = id => {
    {
      params?.profileType === "trackprofiles"
        ? navigate(`/statistics/trackprofiles/${id}`)
        : params?.profileType === "jockeyprofiles"
        ? navigate(`/statistics/jockeyprofiles/0/jockey/${id}`)
        : params?.profileType === "trainerprofiles"
        ? navigate(`/statistics/trainerprofiles/0/trainer/${id}`)
        : navigate(`/statistics/runnerprofiles/0/runner/0/${id}`);
    }
  };

  const fetchCountry = async page => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/country?limit=20&offset=${page}`
      );
      if (status === 200) {
        setCountryApiCount(countryApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.country,
            value: item?.id
          });
        });
        let alldatas = newdata?.unshift({
          label: "All Countries",
          value: 0
        });
        let unionData = _.unionBy(country, newdata);
        let finalData = _.uniqBy(unionData, function(e) {
          return e.value;
        });
        setCountry(finalData);
      }
    } catch (err) {}
  };
  const handleOnScrollBottomCountry = () => {
    if (
      isCountrySearch !== "" &&
      searchCountryCount !== Math.ceil(SearchCountrypage / 20)
    ) {
      handleCountryInputChange(SearchCountrypage + 20, isCountrySearch);

      setSearchCountrypage(SearchCountrypage + 20);
    } else {
      if (count !== Math.ceil(pageCountry / 20)) {
        fetchCountry(pageCountry + 20);
        setpageCountry(pageCountry + 20);
      }
    }
  };
  const fetchState = async (id, page, type, statecount) => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${id}?limit=20&offset=${page}`
      );
      if (status === 200) {
        setpageState(page);
        setStateApiCount(statecount + 1);
        setstatecount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.state,
            value: item?.id
          });
        });

        let newStateData = _.unionBy(newdata)?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });

        newStateData.unshift({
          label: "All States",
          value: 0
        });
        let finalStateData = newStateData;
        if (type) {
          setState(finalStateData);
        } else {
          // setState(
          //   _.unionBy(state, newdata)
          //     ?.sort((a, b) => {
          //       return a?.label.localeCompare(b?.label);
          //     })
          //     ?.unshift({
          //       label: "All States",
          //       value: 0
          //     })
          // );
        }
      }
    } catch (err) {}
  };
  const handleOnScrollBottomState = () => {
    // if (statecount !== Math.ceil(pageState / 20)) {
    //   fetchState(countryId, pageState + 20, false);
    //   setpageState(pageState + 20);
    // }
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(searchStatePage / 20)
    ) {
      handleStateInputChange(searchStatePage + 20, isStateSearch);

      setsearchStatePage(searchStatePage + 20);
    } else {
      if (statecount !== Math.ceil(pageState / 20)) {
        fetchState(countryId, pageState + 20, false, stateApiCount);
        setpageState(pageState + 20);
      }
    }
  };
  const handleCountryInputChange = (page, value) => {
    axiosInstance
      .get(`public/country?limit=20&offset=${page}&search=${value}`)
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchCountryCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.country,
              value: item?.id
            });
          });
          let filterData = _.unionBy(searchCountry, newdata);
          setsearchCountry(
            _.uniqBy(filterData, function(e) {
              return e.value;
            })
          );
          setisCountrySearch(value);
        }
      });
  };
  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${countryId}?limit=20&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchStateCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.state,
              value: item?.id
            });
          });
          const finalStateData = newdata.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          let filterData = _.unionBy(searchState, finalStateData);
          setsearchState(
            _.uniqBy(filterData, function(e) {
              return e.value;
            })
          );
          setisStateSearch(value);
        }
      });
  };

  const fetchTrackList = async (
    country,
    state,
    inputValue,
    collepseRace,
    datacount,
    selectedLetters,
    searchcount
  ) => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrackList?countryId=${country ? country : ""}&stateId=${
          state ? state : ""
        }&name=${inputValue}&sportId=${collepseRace?.join(",")}&limit=${
          searchcount ? 50 : 10
        }&offset=0&alphabet=${
          selectedLetters?.length > 0
            ? selectedLetters
            : lettersCount?.[datacount] +
              "," +
              lettersCount?.[datacount + 1] +
              "," +
              lettersCount?.[datacount + 2] +
              "," +
              lettersCount?.[datacount + 3]
        }`
      );
      if (status === 200) {
        setEventCount(data?.count);
        if (searchcount) {
          setViewAllCount(Math.ceil(searchcount / 50));
        }
        // sortedData old data

        // const sortedData = data?.result?.sort((a, b) => {
        //   return a?.name?.trim().localeCompare(b?.name?.trim());
        // });
        // setTrackListData(sortedData);

        // new Data

        setTrackListData(data?.result);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };
  const handleOnScrollBottomViewAll = async offset => {
    // setisLoading(true);
    const passApi =
      params?.profileType === "trackprofiles"
        ? `statistics/getTrackList?countryId=${
            countryId ? countryId : ""
          }&stateId=${
            stateId ? stateId : ""
          }&name=${searchInput}&sportId=${collepseRace?.join(
            ","
          )}&limit=50&offset=${offset}&alphabet=${selectedLetters}`
        : params?.profileType === "jockeyprofiles"
        ? `statistics/getJockeyList?name=${searchInput}&limit=50&offset=${offset}&alphabet=${selectedLetters}`
        : params?.profileType === "trainerprofiles"
        ? `statistics/getTrainerList?name=${searchInput}&limit=50&offset=${offset}&alphabet=${selectedLetters}`
        : `statistics/getRunnerProfile?name=${searchInput}&limit=50&offset=${offset}&alphabet=${selectedLetters}`;
    try {
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setViewAllOffset(offset);
        let mergedData = {
          [selectedLetters]: [
            ...TrackListData?.[selectedLetters],
            ...data?.result?.[selectedLetters]
          ]
        };
        // sortedData old data

        // const sortedData = data?.result?.sort((a, b) => {
        //   return a?.name?.trim().localeCompare(b?.name?.trim());
        // });
        // setTrackListData(sortedData);

        // new Data

        setTrackListData(mergedData);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };
  const handleViewAll = async (key, count) => {
    setIsViewAll(true);
    setViewAllAlphabet(key);
    setSelectedLetters([key]);
    setListCount(0);
    const updatedAlphabet = letters?.map(letterInfo => {
      if (letterInfo.letter === key) {
        return { ...letterInfo, isSelected: true };
      }
      if (letterInfo.letter !== key) {
        return { ...letterInfo, isSelected: false };
      }
      return letterInfo;
    });
    setLetters(updatedAlphabet);
    if (params?.profileType === "trackprofiles") {
      fetchTrackList(countryId, stateId, "", [1, 2, 3], 0, key, count);
    } else if (params?.profileType === "jockeyprofiles") {
      fetchJockeyList("", 0, key, count);
    } else if (params?.profileType === "trainerprofiles") {
      fetchTrainerList("", 0, key, count);
    } else if (params?.profileType === "runnerprofiles") {
      fetchRunnerList("", 0, key, count);
    }
  };
  const handleOnScrollBottomEvents = async datacount => {
    if (datacount < 26 && selectedLetters?.length === 0 && searchInput === "") {
      const passApiCount =
        datacount < 24
          ? lettersCount?.[datacount] +
            "," +
            lettersCount?.[datacount + 1] +
            "," +
            lettersCount?.[datacount + 2] +
            "," +
            lettersCount?.[datacount + 3]
          : lettersCount?.[datacount] + "," + lettersCount?.[datacount + 1];
      setListCount(datacount);
      try {
        const passApi =
          params?.profileType === "trackprofiles"
            ? `statistics/getTrackList?countryId=${
                countryId ? countryId : ""
              }&stateId=${
                stateId ? stateId : ""
              }&name=${searchInput}&sportId=${collepseRace?.join(
                ","
              )}&limit=10&offset=0&alphabet=${passApiCount}`
            : params?.profileType === "jockeyprofiles"
            ? `statistics/getJockeyList?name=${searchInput}&limit=10&offset=0&alphabet=${passApiCount}`
            : params?.profileType === "trainerprofiles"
            ? `statistics/getTrainerList?name=${searchInput}&limit=10&offset=0&alphabet=${passApiCount}`
            : `statistics/getRunnerProfile?name=${searchInput}&limit=10&offset=0&alphabet=${passApiCount}`;
        const { status, data } = await axiosInstance.get(passApi);
        if (status === 200) {
          setEventCount({ ...EventCount, ...data?.count });
          setEventPage(EventPage);
          setTrackListData({ ...TrackListData, ...data?.result });
        } else {
        }
      } catch (err) {}
    }
  };
  const fetchJockeyList = async (
    inputValue,
    datacount,
    selectedLetters,
    searchcount
  ) => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getJockeyList?name=${inputValue}&limit=${
          searchcount ? 50 : 10
        }&offset=0&alphabet=${
          selectedLetters?.length > 0
            ? selectedLetters
            : lettersCount?.[datacount] +
              "," +
              lettersCount?.[datacount + 1] +
              "," +
              lettersCount?.[datacount + 2] +
              "," +
              lettersCount?.[datacount + 3]
        }`
      );
      if (status === 200) {
        setEventCount(data?.count);
        if (searchcount) {
          setViewAllCount(Math.ceil(searchcount / 50));
        }
        // const sortedData = data?.result?.sort((a, b) => {
        //   return a?.name?.trim().localeCompare(b?.name?.trim());
        // });
        setTrackListData(data?.result);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };
  const fetchTrainerList = async (
    inputValue,
    datacount,
    selectedLetters,
    searchcount
  ) => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getTrainerList?name=${inputValue}&limit=${
          searchcount ? 50 : 10
        }&offset=0&alphabet=${
          selectedLetters?.length > 0
            ? selectedLetters
            : lettersCount?.[datacount] +
              "," +
              lettersCount?.[datacount + 1] +
              "," +
              lettersCount?.[datacount + 2] +
              "," +
              lettersCount?.[datacount + 3]
        }`
      );
      if (status === 200) {
        setEventCount(data?.count);
        if (searchcount) {
          setViewAllCount(Math.ceil(searchcount / 50));
        }
        // const sortedData = data?.result?.sort((a, b) => {
        //   return a?.name?.trim()?.localeCompare(b?.name?.trim());
        // });
        setTrackListData(data?.result);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };
  const fetchRunnerList = async (
    inputValue,
    datacount,
    selectedLetters,
    searchcount
  ) => {
    setisLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `statistics/getRunnerProfile?name=${inputValue}&limit=${
          searchcount ? 50 : 10
        }&offset=0&alphabet=${
          selectedLetters?.length > 0
            ? selectedLetters
            : lettersCount?.[datacount] +
              "," +
              lettersCount?.[datacount + 1] +
              "," +
              lettersCount?.[datacount + 2] +
              "," +
              lettersCount?.[datacount + 3]
        }`
      );
      if (status === 200) {
        setEventCount(data?.count);
        if (searchcount) {
          setViewAllCount(Math.ceil(searchcount / 50));
        }
        // const sortedData = data?.result?.sort((a, b) => {
        //   return a?.name?.trim()?.localeCompare(b?.name?.trim());
        // });
        setTrackListData(data?.result);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };

  const handleSearch = e => {
    setSearchInput(e.target.value);
    setListCount(0);
    setViewAllOffset(0);
    const passAlphabet =
      selectedLetters?.length > 0 ? selectedLetters : lettersCount;
    if (params?.profileType === "trackprofiles") {
      fetchTrackList(
        countryId,
        stateId,
        e.target.value,
        collepseRace,
        0,
        passAlphabet
      );
    } else if (params?.profileType === "jockeyprofiles") {
      fetchJockeyList(e.target.value, 0, passAlphabet);
    } else if (params?.profileType === "trainerprofiles") {
      fetchTrainerList(e.target.value, 0, passAlphabet);
    } else if (params?.profileType === "runnerprofiles") {
      fetchRunnerList(e.target.value, 0, passAlphabet);
    }
  };

  const handleBlackBookModal = (title, id, type) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    handleModal();
  };
  const handleModal = () => {
    setBBOpen(true);
  };

  const handleClose = () => {
    setBBOpen(false);
  };
  const handleListingApi = () => {
    setListCount(0);
    const passAlphabet = searchInput !== "" ? lettersCount : selectedLetters;
    if (params?.profileType === "trackprofiles") {
      fetchTrackList(
        countryId,
        stateId,
        searchInput,
        collepseRace,
        0,
        passAlphabet
      );
    } else if (params?.profileType === "jockeyprofiles") {
      fetchJockeyList(searchInput, 0, passAlphabet);
    } else if (params?.profileType === "trainerprofiles") {
      fetchTrainerList(searchInput, 0, passAlphabet);
    } else if (params?.profileType === "runnerprofiles") {
      fetchRunnerList(searchInput, 0, passAlphabet);
    }
  };

  const handleNavigateToBlackBook = () => {
    navigate("/blackbook");
  };
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isCountrySelectOpen) {
          if (isCountrySearch === "" && countryApiCount !== count) {
            fetchCountry(pageCountry + 20);
            setpageCountry(pageCountry + 20);
          }
        }
      }, 2000);
    }
  }, [isCountrySelectOpen, countryApiCount, isCountrySearch]);

  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && stateApiCount !== statecount) {
            fetchState(countryId, pageState + 20, false, stateApiCount);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
  }, []);
  useEffect(() => {
    if (params?.profileType) {
      if (params?.profileType === "trackprofiles") {
        fetchCountry(0);
        fetchTrackList(0, 0, "", [1, 2, 3], 0, []);
      } else if (params?.profileType === "jockeyprofiles") {
        fetchJockeyList("", 0, []);
      } else if (params?.profileType === "trainerprofiles") {
        fetchTrainerList("", 0, []);
      } else if (params?.profileType === "runnerprofiles") {
        fetchRunnerList("", 0, []);
      }
    }
    setTrackListData({});
    setLetters(alpha);
    setSelectedLetters([]);
    setListCount(0);
    setViewAllOffset(0);
    setIsViewAll(false);
    setSearchInput("");
  }, [params?.profileType]);

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchAdsData = async () => {
    try {
      let pageId = 24;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${pageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const scriptCode = `
  rdt('track', 'ViewContent', {
    "products": [
      {
        "id": "product id 1",
        "name": "product name 1",
        "category": "product category 1"
      },
      // additional products can be added here
    ]
  });
`;
  const ChangeRaceFilter = (event, item) => {
    const flag = event.target.checked;
    setListCount(0);
    setViewAllOffset(0);
    const passAlphabet =
      searchInput !== "" || countryId || stateId
        ? lettersCount
        : selectedLetters;
    if (flag) {
      const newItem = [...collepseRace, item];
      fetchTrackList(countryId, stateId, searchInput, newItem, 0, passAlphabet);
      setcollepseRace(newItem);
      setEventPage(0);
    } else {
      if (collepseRace?.length >= 2) {
        const removeItem = collepseRace?.filter(eItem => eItem !== item);
        setcollepseRace(removeItem);
        fetchTrackList(
          countryId,
          stateId,
          searchInput,
          removeItem,
          0,
          passAlphabet
        );
        setEventPage(0);
      }
    }
  };
  const fetchSportIcon = sportId => {
    const sportIcon = sportId === 1 ? Horse : sportId === 3 ? Greys : Harnes;
    return (
      <span className="sport-icon-img">
        <img
          src={sportIcon}
          alt={
            sportId === 1 ? "Horse" : sportId === 3 ? "Greyhound" : "Harness"
          }
        />
      </span>
    );
  };
  useEffect(() => {
    const generateLetters = () => {
      const lettersArray = [];
      for (let i = 65; i <= 90; i++) {
        lettersArray.push({
          letter: String.fromCharCode(i),
          isSelected: false
        });
      }
      return lettersArray;
    };

    setLetters(generateLetters());
  }, []);
  const toggleLetter = index => {
    setIsViewAll(false);
    setViewAllAlphabet("");
    setListCount(0);
    setViewAllOffset(0);
    const updatedLetters = [...letters];
    updatedLetters[index].isSelected = !updatedLetters[index].isSelected;
    setLetters(updatedLetters);

    if (updatedLetters[index].isSelected) {
      const filteredData = [...selectedLetters, updatedLetters[index]?.letter];
      if (params?.profileType === "trackprofiles") {
        fetchCountry(0);
        fetchTrackList(countryId, stateId, "", collepseRace, 0, filteredData);
      } else if (params?.profileType === "jockeyprofiles") {
        fetchJockeyList("", 0, filteredData);
      } else if (params?.profileType === "trainerprofiles") {
        fetchTrainerList("", 0, filteredData);
      } else if (params?.profileType === "runnerprofiles") {
        fetchRunnerList("", 0, filteredData);
      }

      setSelectedLetters(prevSelected => [
        ...prevSelected,
        updatedLetters[index]?.letter
      ]);
    } else {
      setSelectedLetters(prevSelected =>
        prevSelected?.filter(letter => letter !== updatedLetters[index].letter)
      );
      if (params?.profileType === "trackprofiles") {
        fetchCountry(0);
        fetchTrackList(
          countryId,
          stateId,
          "",
          collepseRace,
          0,
          selectedLetters?.filter(
            letter => letter !== updatedLetters[index]?.letter
          )
        );
      } else if (params?.profileType === "jockeyprofiles") {
        fetchJockeyList(
          "",
          0,
          selectedLetters?.filter(
            letter => letter !== updatedLetters[index]?.letter
          )
        );
      } else if (params?.profileType === "trainerprofiles") {
        fetchTrainerList(
          "",
          0,
          selectedLetters?.filter(
            letter => letter !== updatedLetters[index]?.letter
          )
        );
      } else if (params?.profileType === "runnerprofiles") {
        fetchRunnerList(
          "",
          0,
          selectedLetters?.filter(
            letter => letter !== updatedLetters[index]?.letter
          )
        );
      }
    }
  };

  return (
    <>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds("218px", banner)}
        </Box>
        <Box className="trackprofile-wrap">
          <Box className="trackprofile-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  {localesData?.MENU?.HOME}
                </Link>
                <Link underline="hover" color="inherit" to="">
                  {localesData?.MENU?.STATISTICS}
                </Link>

                <Typography>
                  {" "}
                  {params?.profileType === "trackprofiles"
                    ? "Track Profiles"
                    : params?.profileType === "jockeyprofiles"
                    ? "Jockey Profiles"
                    : params?.profileType === "trainerprofiles"
                    ? "Trainer Profiles"
                    : "Runner Profiles"}
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">
              {params?.profileType === "trackprofiles"
                ? "Track Profiles"
                : params?.profileType === "jockeyprofiles"
                ? "Jockey Profiles"
                : params?.profileType === "trainerprofiles"
                ? "Trainer Profiles"
                : "Runner Profiles"}
            </Typography>
          </Box>

          <Box className="trackprofile-detail">
            <Box className="select-search-container">
              {params?.profileType === "trackprofiles" ? (
                <Box className="select-wrap statistics-select-wrap">
                  <Select
                    className="select statistics-select"
                    classNamePrefix="select"
                    onMenuScrollToBottom={e => handleOnScrollBottomCountry(e)}
                    onInputChange={e => handleCountryInputChange(0, e)}
                    placeholder="COUNTRY"
                    value={
                      isCountrySearch
                        ? searchCountry?.find(item => {
                            return item?.value == countryId;
                          })
                        : country?.find(item => {
                            return item?.value == countryId;
                          })
                    }
                    onChange={e => {
                      // setFieldValue("country", e?.value);
                      fetchState(e?.value, 0, true, 0);
                      setCountryId(e?.value);
                      // setFieldValue("state", "");
                      setState([]);
                      setStateId("");
                      setListCount(0);
                      setViewAllOffset(0);
                      // setStateApiCount(0);
                      // setpageState(0);
                      setisCountrySelectOpen(false);
                      // setstateValue("");
                      fetchTrackList(
                        e?.value,
                        0,
                        "",
                        collepseRace,
                        0,
                        selectedLetters?.length > 0
                          ? selectedLetters
                          : lettersCount
                      );
                    }}
                    onFocus={() => setisCountrySelectOpen(true)}
                    onBlur={() => setisCountrySelectOpen(false)}
                    options={isCountrySearch ? searchCountry : country}
                    components={{ DropdownIndicator }}
                  />
                  <Select
                    className="select statistics-select"
                    classNamePrefix="select"
                    onMenuScrollToBottom={e => handleOnScrollBottomState(e)}
                    onInputChange={e => handleStateInputChange(0, e)}
                    placeholder="STATE"
                    isDisabled={countryId === 0}
                    value={
                      isStateSearch
                        ? searchState?.find(item => {
                            return item?.value == stateId;
                          })
                        : stateId !== "" &&
                          state?.find(item => {
                            return item?.value == stateId;
                          })
                    }
                    onChange={e => {
                      // setFieldValue("state", e?.value);
                      setStateId(e?.value);
                      setisStateSelectOpen(false);
                      // setStateApiCount(0);
                      // setstateValue(e?.value);
                      setListCount(0);
                      setViewAllOffset(0);
                      fetchTrackList(
                        countryId,
                        e?.value,
                        "",
                        collepseRace,
                        0,
                        selectedLetters?.length > 0
                          ? selectedLetters
                          : lettersCount
                      );
                    }}
                    onFocus={() => setisStateSelectOpen(true)}
                    onBlur={() => setisStateSelectOpen(false)}
                    options={isStateSearch ? searchState : state}
                    components={{ DropdownIndicator }}
                  />
                </Box>
              ) : (
                <></>
              )}
              <TextField
                placeholder={
                  params?.profileType === "trackprofiles"
                    ? "Search (by name)"
                    : params?.profileType === "jockeyprofiles"
                    ? "Search (by jockey name)"
                    : params?.profileType === "trainerprofiles"
                    ? "Search (by trainer name)"
                    : "Search (by runner name)"
                }
                variant="outlined"
                type="text"
                name="search"
                className="search_input"
                onChange={e => {
                  handleSearch(e);
                }}
                value={searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
              <Box className="track-filter-section">
                {params?.profileType === "trackprofiles" ? (
                  <Box>
                    <ul className="Filteritemlist-racing">
                      {filterRaceType?.length > 0 &&
                        filterRaceType?.map((filter, i) => (
                          <li key={i}>
                            <FormControl>
                              <label>
                                <Checkbox
                                  className="filter-racing"
                                  icon={<Unchecked />}
                                  checkedIcon={<Checked />}
                                  name="filter"
                                  value={filter?.id}
                                  onChange={event => {
                                    ChangeRaceFilter(event, filter?.id);
                                  }}
                                  checked={collepseRace.includes(filter?.id)}
                                />
                                {filter?.icon}
                              </label>
                            </FormControl>
                          </li>
                        ))}
                    </ul>
                  </Box>
                ) : (
                  <></>
                )}

                <Box
                  className={
                    params?.profileType !== "trackprofiles"
                      ? `alphabet-filter nosport-filter`
                      : `alphabet-filter`
                  }
                >
                  {letters?.map((item, index) => (
                    <Box
                      key={index}
                      className={`letters-alphabet ${
                        item.isSelected ? "selected" : ""
                      }`}
                      onClick={() => toggleLetter(index)}
                    >
                      {item.letter}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            {fetchAds(1, "102px", AboveTable)}
            {isLoading ? (
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            ) : !Object.values(TrackListData).every(arr => arr.length === 0) ? (
              <Box>
                <InfiniteScroll
                  dataLength={Object.keys(TrackListData)?.length}
                  next={() => handleOnScrollBottomEvents(listCount + 4)}
                  hasMore={listCount + 4 < 26}
                  loader={
                    selectedLetters?.length === 0 && searchInput === "" ? (
                      <div className="allsport-loader-center ">
                        <Loader />
                      </div>
                    ) : (
                      <></>
                    )
                  }
                  // scrollableTarget="scrollableDiv"
                >
                  {Object.entries(TrackListData)?.map(([key, item], index) => {
                    return (
                      <>
                        {EventCount?.[key] > 0 && (
                          <Box
                            className={
                              params?.profileType === "trackprofiles"
                                ? "track-accordion track-profile-accordian"
                                : "track-accordion"
                            }
                          >
                            <Accordion key={index} defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{key}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <ul className="trackprofilelist-wrap">
                                  {!isViewAll ? (
                                    <Box>
                                      {item?.length > 0 ? (
                                        item?.map(obj => {
                                          return (
                                            <>
                                              <li
                                                className="trackprofile-list"
                                                // style={{
                                                //   paddingBottom:
                                                //     params?.profileType !==
                                                //     "trackprofiles"
                                                //       ? "10px"
                                                //       : "4px"
                                                // }}
                                              >
                                                <Box
                                                  display="flex"
                                                  alignItems="center"
                                                >
                                                  {params?.profileType ===
                                                  "trackprofiles"
                                                    ? fetchSportIcon(
                                                        obj?.sportId
                                                      )
                                                    : ""}
                                                  <span
                                                    className="statistics-name"
                                                    onClick={() =>
                                                      handleNavigate(obj?.id)
                                                    }
                                                  >
                                                    {obj?.name}
                                                    {obj?.State
                                                      ? `, ${obj?.State?.state}`
                                                      : ""}
                                                    {obj?.Country
                                                      ? `, ${obj?.Country?.country}`
                                                      : ""}
                                                  </span>
                                                </Box>
                                                {screenWidth > 799 ? (
                                                  <span>
                                                    {obj?.isBlackBook ? (
                                                      <span
                                                        className="add-to-blackbook view-Blackbook"
                                                        onClick={() =>
                                                          handleNavigateToBlackBook()
                                                        }
                                                      >
                                                        <Right />
                                                        {
                                                          localesData
                                                            ?.STATISTICS
                                                            ?.VIEW_BLACKBOOK
                                                        }
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="add-to-blackbook"
                                                        onClick={() =>
                                                          handleBlackBookModal(
                                                            obj?.name,
                                                            obj?.id,
                                                            params?.profileType ===
                                                              "trackprofiles"
                                                              ? "Track"
                                                              : params?.profileType ===
                                                                "jockeyprofiles"
                                                              ? "Jockey"
                                                              : params?.profileType ===
                                                                "trainerprofiles"
                                                              ? "Trainer"
                                                              : "Runner"
                                                          )
                                                        }
                                                      >
                                                        <Plus />
                                                        {
                                                          localesData
                                                            ?.STATISTICS
                                                            ?.ADD_TO_MY_BLACKBOOK
                                                        }
                                                      </span>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <>
                                                    {obj?.isBlackBook ? (
                                                      <Box
                                                        onClick={() =>
                                                          handleNavigateToBlackBook()
                                                        }
                                                      >
                                                        <BlackbookRight />
                                                      </Box>
                                                    ) : (
                                                      <Box
                                                        onClick={() =>
                                                          handleBlackBookModal(
                                                            obj?.name,
                                                            obj?.id,
                                                            params?.profileType ===
                                                              "trackprofiles"
                                                              ? "Track"
                                                              : params?.profileType ===
                                                                "jockeyprofiles"
                                                              ? "Jockey"
                                                              : params?.profileType ===
                                                                "trainerprofiles"
                                                              ? "Trainer"
                                                              : "Runner"
                                                          )
                                                        }
                                                      >
                                                        <BlackbookPlus />
                                                      </Box>
                                                    )}
                                                  </>
                                                )}
                                              </li>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <>
                                          <Box
                                            style={{ border: "transparent" }}
                                          >
                                            <Box
                                              style={{
                                                textAlign: "center",
                                                border: "transparent"
                                              }}
                                            >
                                              No Data Available
                                            </Box>
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                  ) : (
                                    <InfiniteScroll
                                      dataLength={item?.length}
                                      next={() =>
                                        handleOnScrollBottomViewAll(
                                          viewAllOffset + 50
                                        )
                                      }
                                      hasMore={
                                        viewAllCount !==
                                        Math.ceil(viewAllOffset / 50 + 1)
                                      }
                                      loader={
                                        <div className="allsport-loader-center ">
                                          <Loader />
                                        </div>
                                      }
                                      // scrollableTarget="scrollableDiv"
                                    >
                                      {item?.length > 0 ? (
                                        item?.map(obj => {
                                          return (
                                            <>
                                              <li
                                                className="trackprofile-list"
                                                // style={{
                                                //   paddingBottom:
                                                //     params?.profileType !==
                                                //     "trackprofiles"
                                                //       ? "10px"
                                                //       : "4px"
                                                // }}
                                              >
                                                <Box
                                                  display="flex"
                                                  alignItems="center"
                                                >
                                                  {params?.profileType ===
                                                  "trackprofiles"
                                                    ? fetchSportIcon(
                                                        obj?.sportId
                                                      )
                                                    : ""}
                                                  <span
                                                    className="statistics-name"
                                                    onClick={() =>
                                                      handleNavigate(obj?.id)
                                                    }
                                                  >
                                                    {obj?.name}
                                                    {obj?.State
                                                      ? `, ${obj?.State?.state}`
                                                      : ""}
                                                    {obj?.Country
                                                      ? `, ${obj?.Country?.country}`
                                                      : ""}
                                                  </span>
                                                </Box>
                                                {screenWidth > 799 ? (
                                                  <span>
                                                    {obj?.isBlackBook ? (
                                                      <span
                                                        className="add-to-blackbook view-Blackbook"
                                                        onClick={() =>
                                                          handleNavigateToBlackBook()
                                                        }
                                                      >
                                                        <Right />
                                                        {
                                                          localesData
                                                            ?.STATISTICS
                                                            ?.VIEW_BLACKBOOK
                                                        }
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="add-to-blackbook"
                                                        onClick={() =>
                                                          handleBlackBookModal(
                                                            obj?.name,
                                                            obj?.id,
                                                            params?.profileType ===
                                                              "trackprofiles"
                                                              ? "Track"
                                                              : params?.profileType ===
                                                                "jockeyprofiles"
                                                              ? "Jockey"
                                                              : params?.profileType ===
                                                                "trainerprofiles"
                                                              ? "Trainer"
                                                              : "Runner"
                                                          )
                                                        }
                                                      >
                                                        <Plus />
                                                        {
                                                          localesData
                                                            ?.STATISTICS
                                                            ?.ADD_TO_MY_BLACKBOOK
                                                        }
                                                      </span>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <>
                                                    {obj?.isBlackBook ? (
                                                      <Box
                                                        onClick={() =>
                                                          handleNavigateToBlackBook()
                                                        }
                                                      >
                                                        <BlackbookRight />
                                                      </Box>
                                                    ) : (
                                                      <Box
                                                        onClick={() =>
                                                          handleBlackBookModal(
                                                            obj?.name,
                                                            obj?.id,
                                                            params?.profileType ===
                                                              "trackprofiles"
                                                              ? "Track"
                                                              : params?.profileType ===
                                                                "jockeyprofiles"
                                                              ? "Jockey"
                                                              : params?.profileType ===
                                                                "trainerprofiles"
                                                              ? "Trainer"
                                                              : "Runner"
                                                          )
                                                        }
                                                      >
                                                        <BlackbookPlus />
                                                      </Box>
                                                    )}
                                                  </>
                                                )}
                                              </li>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <>
                                          <Box
                                            style={{ border: "transparent" }}
                                          >
                                            <Box
                                              style={{
                                                textAlign: "center",
                                                border: "transparent"
                                              }}
                                            >
                                              No Data Available
                                            </Box>
                                          </Box>
                                        </>
                                      )}
                                    </InfiniteScroll>
                                  )}
                                </ul>
                                {!isViewAll && EventCount?.[key] > 10 && (
                                  <Box className="view-all">
                                    <span
                                      className="view-all-text"
                                      onClick={() =>
                                        handleViewAll(key, EventCount?.[key])
                                      }
                                    >
                                      View all
                                    </span>
                                  </Box>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        )}
                      </>
                    );
                  })}
                </InfiniteScroll>
              </Box>
            ) : (
              <>
                <Box style={{ border: "transparent" }}>
                  <Box
                    style={{
                      textAlign: "center",
                      border: "transparent"
                    }}
                  >
                    {/* No Data Available */}
                    <NoDataComp />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {fetchAds(2, "102px", AboveOurPartner)}
        <OurPartner />
        {fetchAds(3, "102px", BelowOurPartner)}
      </Box>
      <BlackBookModal
        open={BBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        listingFunction={() => handleListingApi()}
      />
    </>
  );
}
export default Trackprofiles;
