import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  FormControlLabel,
  FormLabel,
  Checkbox,
  TextareaAutosize,
  Button
} from "@material-ui/core";
import { IntlContext } from "src/App";
import "./advertisingEnquiries.scss";
import { ReactComponent as Download } from "src/assets/images/downloadIcon.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import _ from "lodash";
import Loader from "../../../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import OurPartner from "../ourPartners";
import { Config } from "src/helpers/context";

const InterestedIn = [
  { id: 1, name: "Website Banners" },
  { id: 2, name: "Newsletter Banners" },
  { id: 3, name: "In Venue Screen Advertising" },
  { id: 4, name: "Mobile App Banners" },
  { id: 5, name: "News Editorials" },
  { id: 6, name: "Event Advertising" },
  { id: 7, name: "Social Media Shout Outs" }
];

const AdvertisingEnquiriesPage = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formvalues, setformvalues] = useState();
  const [interestedData, setInterestedData] = useState([]);
  const [stateApiCount, setStateApiCount] = useState(0);
  const [count, setcount] = useState(0);
  const [states, setState] = useState([]);
  const [isStateSearch, setisStateSearch] = useState("");
  const [searchStateCount, setsearchStateCount] = useState("");
  const [SearchStatepage, setSearchStatepage] = useState(0);
  const [pageState, setpageState] = useState(0);
  const [searchState, setsearchState] = useState([]);
  const [isStateSelectOpen, setisStateSelectOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isLoading, setLoading] = useState(false);

  const fullNameRegExp = /^[\w\d\s]*[^\W\.][\w\d\s]*$/;
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const handleinterestedIn = e => {
    const { value, checked } = e.target;
    if (checked) {
      let checkedData = [...interestedData, value];
      setInterestedData(checkedData);
    } else {
      let checkboxdata = interestedData?.filter(element => element !== value);
      setInterestedData(checkboxdata);
    }
  };

  useEffect(() => {
    fetchState(0);
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchState = async page => {
    try {
      const { status, data } = await axiosInstance.get(
        `public/state/country/${
          Config.release == "AU" ? 13 : 101
        }?limit=20&offset=${page}`
      );
      if (status === 200) {
        setStateApiCount(stateApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.state,
            value: item?.id
          });
        });
        let sortData = newdata?.sort((a, b) => {
          return a.label > b.label ? 1 : -1;
        });
        setState(_.unionBy(states, sortData));
      }
    } catch (err) {}
  };

  const handleOnScrollBottomState = () => {
    if (
      isStateSearch !== "" &&
      searchStateCount !== Math.ceil(SearchStatepage / 20)
    ) {
      handleStateInputChange(SearchStatepage + 20, isStateSearch);

      setSearchStatepage(SearchStatepage + 20);
    } else {
      if (count !== Math.ceil(pageState / 20)) {
        fetchState(pageState + 20);
        setpageState(pageState + 20);
      }
    }
  };

  const handleStateInputChange = (page, value) => {
    axiosInstance
      .get(
        `public/state/country/${
          Config.release == "AU" ? 13 : 101
        }?limit=20&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchStateCount(res?.data?.result?.count / 20);

          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.state,
              value: item?.id
            });
          });
          let filterData = _.unionBy(searchState, newdata);
          let sortData = filterData?.sort((a, b) => {
            return a.label > b.label ? 1 : -1;
          });
          setsearchState(
            _.uniqBy(sortData, function(e) {
              return e.value;
            })
          );
          setisStateSearch(value);
        }
      });
  };
  useEffect(() => {
    if (screenWidth < 1025) {
      setTimeout(() => {
        if (isStateSelectOpen) {
          if (isStateSearch === "" && setStateApiCount !== count) {
            fetchState(pageState + 20);
            setpageState(pageState + 20);
          }
        }
      }, 2000);
    }
  }, [isStateSelectOpen, stateApiCount, isStateSearch]);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="enquiries-wrap">
          <Typography variant="h4" className="advertising-head">
            {localesData?.ADVERTISING_ENQUIRIES?.TITLE}
          </Typography>
          <Typography
            className="advertising-contact"
            dangerouslySetInnerHTML={{
              __html: localesData?.ADVERTISING_ENQUIRIES?.ENQUIRIES_DETAILS
            }}
          ></Typography>
          <Box className="general-box">
            <Typography className="advertising-contact general-advertising">
              {localesData?.ADVERTISING_ENQUIRIES?.GENERAL_ADVERTISING}
            </Typography>
            <Box className="contact-link">
              <span className="email advertising-contact">
                <a
                  href={
                    Config.release == "AU"
                      ? "mailto:sales@smartb.com.au"
                      : "mailto:sales@smartb.co.in"
                  }
                >
                  {Config.release == "AU"
                    ? "sales@smartb.com.au,"
                    : "sales@smartb.co.in,"}
                </a>
              </span>
              <span className="phone advertising-contact">
                <a href="tel:1300065591">1300 065 591</a>
              </span>
            </Box>
          </Box>
          {/* <ul className="advertising-link">
            <li>
              <span className="download-icon">
                <a href="#">
                  <Download />
                </a>
              </span>
              {localesData?.ADVERTISING_ENQUIRIES?.MEDIA_PACK}
            </li>
            <li>
              <span className="download-icon">
                <a href="#">
                  <Download />
                </a>
              </span>
              {localesData?.ADVERTISING_ENQUIRIES?.RATE_CARD}
            </li>
            <li>
              <span className="download-icon">
                <a href="#">
                  <Download />
                </a>
              </span>
              {localesData?.ADVERTISING_ENQUIRIES?.DIGITAL_MEDIA_SPACS}
            </li>
          </ul> */}
        </Box>
        <Formik
          initialValues={{
            fullname: "",
            companyname: "",
            abnacn: "",
            phonenumber: "",
            email: "",
            states: "",
            message: "",
            interestedin: []
          }}
          validationSchema={Yup.object().shape({
            fullname: Yup.string()
              .nullable()
              .matches(fullNameRegExp, localesData?.validation?.fullname_valid)
              .required(localesData?.validation?.required_message),
            companyname: Yup.string()
              .nullable()
              .required(localesData?.validation?.required_message),
            // abnacn: Yup.string()
            //   .nullable()
            //   .required(localesData?.validation?.required_message),
            phonenumber: Yup.string()
              .nullable()
              // .matches(phoneRegExp, localesData?.validation?.number_valid)
              .required(localesData?.validation?.required_message),
            // .min(10, localesData?.validation?.NUMBER_MIN_MAX_LENGTH)
            // .max(12, localesData?.validation?.NUMBER_MIN_MAX_LENGTH),
            email: Yup.string()
              .nullable()
              .email(localesData?.validation?.valid_email)
              .max(255)
              .required(localesData?.validation?.required_message),
            states: Yup.string()
              .nullable()
              .required(localesData?.validation?.required_message)
          })}
          validate={values => {
            if (!values.interestedin.length) {
              return {
                interestedin: "Please choose atleast one Interested in"
              };
            }
            return {};
          }}
          // onSubmit={() => handleSave()}
          onSubmit={async (formvalues, { resetForm }) => {
            setLoading(true);
            let payload = {
              fullName: formvalues?.fullname,
              companyName: formvalues?.companyname,
              abnacn: formvalues?.abnacn,
              phoneNumber: formvalues?.phonenumber,
              email: formvalues?.email,
              state: formvalues?.states,
              message: formvalues?.message,
              interestedIn: interestedData
            };
            try {
              const { status, data } = await axiosInstance.post(
                "/public/advertiseHubSpot",
                payload
              );
              if (status === 200) {
                setLoading(false);
                toast.success(data?.message, {
                  position: "bottom-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  theme: "colored"
                });

                let timer = setTimeout(
                  () => resetForm(formvalues),
                  setInterestedData([]),

                  3000
                );
              } else {
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
              toast.error(error?.response?.data?.message, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored"
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className="advertising-with-us-wrap">
                {isLoading && (
                  <div className="loader_center">
                    <Loader />
                  </div>
                )}
                <Typography variant="h4" className="with-us-title">
                  {localesData?.ADVERTISING_ENQUIRIES?.ADVERTISE_WITH_US_NOW}
                </Typography>
                <Box className="details">
                  <Typography className="textfield-text">
                    {localesData?.ADVERTISING_ENQUIRIES?.FULL_NAME}
                    <span className="star-red">*</span>:
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="fullname"
                    error={Boolean(touched?.fullname && errors?.fullname)}
                    helperText={touched?.fullname ? errors?.fullname : ""}
                    onChange={handleChange}
                    value={values?.fullname}
                  />
                </Box>
                <Box className="details">
                  <Typography className="textfield-text">
                    {localesData?.ADVERTISING_ENQUIRIES?.COMPANY_NAME}
                    <span className="star-red">*</span>:
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="companyname"
                    error={Boolean(touched?.companyname && errors?.companyname)}
                    helperText={touched?.companyname ? errors?.companyname : ""}
                    onChange={handleChange}
                    value={values?.companyname}
                  />
                </Box>
                <Box className="details">
                  <Typography className="textfield-text">
                    {localesData?.ADVERTISING_ENQUIRIES?.ABNACN}
                    {/* <span className="star-red">*</span> */}:
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="number"
                    name="abnacn"
                    // error={Boolean(touched?.abnacn && errors?.abnacn)}
                    // helperText={touched?.abnacn ? errors?.abnacn : ""}
                    onChange={handleChange}
                    value={values?.abnacn}
                  />
                </Box>
                <Box className="details">
                  <Typography className="textfield-text">
                    {localesData?.ADVERTISING_ENQUIRIES?.PHONE_NUMBER}
                    <span className="star-red">*</span>:
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="number"
                    name="phonenumber"
                    error={Boolean(touched?.phonenumber && errors?.phonenumber)}
                    helperText={touched?.phonenumber ? errors?.phonenumber : ""}
                    onChange={handleChange}
                    value={values?.phonenumber}
                  />
                </Box>
                <Box className="details">
                  <Typography className="textfield-text">
                    {localesData?.ADVERTISING_ENQUIRIES?.EMAIL}
                    <span className="star-red">*</span>:
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textfield"
                    type="text"
                    name="email"
                    error={Boolean(touched?.email && errors?.email)}
                    helperText={touched?.email ? errors?.email : ""}
                    onChange={handleChange}
                    value={values?.email}
                  />
                </Box>
                <Box className="details">
                  <Typography className="textfield-text">
                    {localesData?.ADVERTISING_ENQUIRIES?.STATE}
                    <span className="star-red">*</span>:
                  </Typography>
                  <Box className="select-state">
                    <Select
                      className="select state-field"
                      classNamePrefix="select"
                      placeholder="-Select-"
                      components={{ DropdownIndicator }}
                      onMenuScrollToBottom={e => handleOnScrollBottomState(e)}
                      onInputChange={e => handleStateInputChange(0, e)}
                      isLoading={isLoading}
                      value={
                        isStateSearch
                          ? searchState?.find(item => {
                              return item?.label == states;
                            })
                          : values?.states !== "" &&
                            states?.find(item => {
                              return item?.label == values?.states;
                            })
                      }
                      name="states"
                      onChange={e => {
                        setFieldValue("states", e?.label);
                        setisStateSelectOpen(false);
                        setStateApiCount(0);
                      }}
                      onFocus={() => setisStateSelectOpen(true)}
                      onBlur={() => setisStateSelectOpen(false)}
                      options={isStateSearch ? searchState : states}
                    />
                    <span className="text-danger">
                      {touched?.states && errors?.states ? errors?.states : ""}
                    </span>
                  </Box>
                </Box>
                <Box className="interested-wrap">
                  <FormLabel component="legend" className="interested-label">
                    {localesData?.ADVERTISING_ENQUIRIES?.INTERESTED_IN}
                    <span className="star-red">*</span>:
                  </FormLabel>
                  <Box className="checkbox-list">
                    {InterestedIn?.map(element => (
                      <FormControlLabel
                        className="interested-check"
                        key={element.id}
                        onChange={e => {
                          handleChange(e);
                          handleinterestedIn(e);
                        }}
                        control={<Checkbox className="interested-checkbox" />}
                        label={element?.name}
                        value={element?.name}
                        name="interestedin"
                        checked={
                          interestedData.includes(element?.name) ? true : false
                        }
                      />
                    ))}
                    <span className="text-danger">
                      {touched?.interestedin && errors?.interestedin
                        ? errors?.interestedin
                        : ""}
                    </span>
                  </Box>
                </Box>
                <Box className="details details-textarea">
                  <Typography className="textfield-text">
                    {" "}
                    {localesData?.ADVERTISING_ENQUIRIES?.MESSAGE}
                  </Typography>
                  <TextareaAutosize
                    id="outlined-basic"
                    variant="outlined"
                    className="details-textarea-field"
                    multiline
                    maxRows={6}
                    name="message"
                    style={{ width: "71%", height: "202px" }}
                    // error={Boolean(touched?.message && errors?.message)}
                    // helperText={touched?.message ? errors?.message : ""}
                    onChange={handleChange}
                    value={values?.message}
                  />
                </Box>
                <Box className="advertise-btn">
                  <Button
                    variant="contained"
                    className="btn-save"
                    type="submit"
                    // onClick={() => handleSave()}
                  >
                    {localesData?.ADVERTISING_ENQUIRIES?.SEND}
                  </Button>
                </Box>
              </Box>
              {/* {setformvalues(values)} */}
            </form>
          )}
        </Formik>
      </Box>
      <OurPartner />
      <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
    </>
  );
};

export default AdvertisingEnquiriesPage;
