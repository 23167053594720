import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  // Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
// import AdvertisingBannner from "../advertisingBanner";
// import OurPartner from "src/views/component/ourPartners";
// import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import "./teamSportData.scss";
import NoDataComp from "../UI/NoData";

// const accordionData = [
//   {
//     id: 1,
//     title: "Latest Rugby League Competitions Tables, Fixtures & Results",
//     expanded: true,
//     accordionDatails: [
//       {
//         id: 1,
//         parentId: 1,
//         title: "2023 Rep Matches"
//       },
//       {
//         id: 2,
//         parentId: 1,
//         title: "NRL 2023"
//       },
//       {
//         id: 3,
//         parentId: 1,
//         title: "Super League XXVIII"
//       },
//       {
//         id: 4,
//         parentId: 1,
//         title: "NRLW 2023"
//       },
//       {
//         id: 5,
//         parentId: 1,
//         title: "WSL 2023"
//       },
//       {
//         id: 6,
//         parentId: 1,
//         title: "2023 Championship"
//       },
//       {
//         id: 7,
//         parentId: 1,
//         title: "2023 League 1"
//       },
//       {
//         id: 8,
//         parentId: 1,
//         title: "NSW Cup 2023"
//       },
//       {
//         id: 9,
//         parentId: 1,
//         title: "QLD Cup 2023"
//       }
//     ]
//   },
//   {
//     id: 2,
//     title: "Australia",
//     expanded: true,
//     subaccordiondata: [
//       {
//         id: 1,
//         parentId: 2,
//         title: "League Competitions",
//         expanded: true,
//         list: [
//           {
//             id: 1,
//             parentId: 2,
//             childId: 1,
//             name: "National Rugby League ",
//             leagueName:
//               "(NSWRL 1908-94, ARL 1995-97, Super League 1997, NRL 1998-)"
//           },
//           {
//             id: 2,
//             parentId: 2,
//             childId: 1,
//             name: "NRLW - Women's Rugby League",
//             leagueName: "(NRLW 2018-)"
//           },
//           {
//             id: 3,
//             parentId: 2,
//             childId: 1,
//             name: "NRL Finals ",
//             leagueName: "(1908-)"
//           }
//         ]
//       },
//       {
//         id: 2,
//         parentId: 2,
//         title: "Representative History"
//       },
//       {
//         id: 3,
//         parentId: 2,
//         title: "Cup Competitions"
//       }
//     ]
//   },
//   {
//     id: 3,
//     title: "International",
//     expanded: true,
//     accordionDatails: [
//       {
//         id: 1,
//         parentId: 3,
//         title: "Tour Archive"
//       },
//       {
//         id: 2,
//         parentId: 3,
//         title: "Test Matches"
//       },
//       {
//         id: 3,
//         parentId: 3,
//         title: "Other Internationals"
//       },
//       {
//         id: 4,
//         parentId: 3,
//         title: "Test Series List",
//         leagueName: "(1908-)"
//       },
//       {
//         id: 5,
//         parentId: 3,
//         title: "European Championship"
//       },
//       {
//         id: 6,
//         parentId: 3,
//         title: "Four Nations (2009-)",
//         leagueName: "(2009-)"
//       },
//       {
//         id: 7,
//         parentId: 3,
//         title: "Tri-Nations ",
//         leagueName: "(1999-2006)"
//       },
//       {
//         id: 8,
//         parentId: 3,
//         title: "World Club Challenge ",
//         leagueName: "(1976, 1987-94, 2000-)"
//       },
//       {
//         id: 9,
//         parentId: 3,
//         title: "1997 World Club Challenge Tournament"
//       }
//     ]
//   },
//   {
//     id: 4,
//     title: "International - Women's",
//     expanded: true,
//     accordionDatails: [
//       {
//         id: 1,
//         parentId: 4,
//         title: "Test Matches"
//       },
//       {
//         id: 2,
//         parentId: 4,
//         title: "Other Internationals"
//       }
//     ]
//   },
//   {
//     id: 5,
//     title: "England"
//   }
// ];

const TeamSportsDataPage = ({ teamSportType, searchInput }) => {
  const navigate = useNavigate();
  const [tournamentData, setTournamentData] = useState([]);
  const [tournamentCount, setTournamentCount] = useState(0);
  const [tournamentOffset, setTournamentOffset] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchAllTournament(0, [], searchInput);
  }, []);

  useEffect(() => {
    fetchAllTournament(0, [], searchInput);
  }, [searchInput]);

  const fetchAllTournament = async (offset, tournamentDatas, searchInput) => {
    offset === 0 && setLoader(true);
    try {
      let SportId =
        teamSportType === "cricket"
          ? 4
          : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "rugbyunion"
          ? 13
          : teamSportType === "basketball"
          ? 10
          : teamSportType === "americanfootball"
          ? 15
          : teamSportType === "australianrules"
          ? 9
          : teamSportType === "golf"
          ? 16
          : teamSportType === "tennis"
          ? 7
          : teamSportType === "baseball"
          ? 11
          : teamSportType === "icehockey"
          ? 17
          : teamSportType === "boxing"
          ? 6
          : teamSportType === "mma"
          ? 5
          : teamSportType === "soccer"
          ? 8
          : 14;
      let passApi =
        teamSportType === "cricket"
          ? `allsport/tournament?SportId=${SportId}&search=${
              searchInput && searchInput !== "" ? searchInput : ""
            }&limit=20&offset=${offset}&Sport=true&unique=true`
          : `allsport/tournament?SportId=${SportId}&search=${
              searchInput && searchInput !== "" ? searchInput : ""
            }&limit=20&offset=${offset}`;

      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        // setTournamentData(data?.result?.rows);
        // let count = data?.result?.count / 20;
        // setTournamentCount(Math.ceil(count));
        setLoader(false);
        setTournamentCount(data?.result?.rows?.length);
        const tournamentDetailsData = [
          ...tournamentDatas,
          ...data?.result?.rows
        ];
        setTournamentData(tournamentDetailsData);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const scrollToTopSection = () => {
    const targetSection = document.getElementById("view-less");
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const handleMoreData = () => {
    setTournamentOffset(tournamentOffset + 20);
    fetchAllTournament(tournamentOffset + 20, tournamentData, searchInput);
  };

  const handleLessData = () => {
    scrollToTopSection();
    setTournamentOffset(tournamentOffset - 20);
    if (tournamentData?.length % 20 === 0) {
      setTournamentData(prevArray => prevArray.slice(0, -20));
    } else {
      setTournamentData(prevArray => prevArray.slice(0, -tournamentCount));
    }
  };

  // const fetchScrollAllTournament = async offset => {
  //   try {
  //     let SportId =
  //       teamSportType === "cricket"
  //         ? 4
  //         : teamSportType === "rugbyleague"
  //         ? 12
  //         : teamSportType === "rugbyunion"
  //         ? 13
  //         : teamSportType === "basketball"
  //         ? 10
  //         : teamSportType === "americanfootball"
  //         ? 15
  //         : teamSportType === "australianrules"
  //         ? 9
  //         : teamSportType === "golf"
  //         ? 16
  //         : teamSportType === "tennis"
  //         ? 7
  //         : teamSportType === "baseball"
  //         ? 11
  //         : teamSportType === "icehockey"
  //         ? 17
  //         : teamSportType === "boxing"
  //         ? 6
  //         : teamSportType === "mma"
  //         ? 5
  //         : teamSportType === "soccer"
  //         ? 8
  //         : 14;

  //     const { status, data } = await axiosInstance.get(
  //       `allsport/tournament?SportId=${SportId}&search=&limit=20&offset=${offset}`
  //     );
  //     if (status === 200) {
  //       setTournamentData(prevData => [
  //         ...tournamentData,
  //         ...data?.result?.rows
  //       ]);
  //       setTournamentOffset(offset);
  //     } else {
  //     }
  //   } catch (err) {}
  // };

  const handleNavigate = data => {
    if (teamSportType === "cricket" || teamSportType === "soccer") {
      const tournamentSlug = data?.Scd ? data?.Scd : "tournament";
      const type =
        teamSportType === "cricket"
          ? "cricket"
          : teamSportType === "soccer"
          ? "soccer"
          : "";
      navigate(
        `/teamsports/${type}/statistics/${tournamentSlug}/info/${data?.SportId}/${data?.id}`
      );
    } else {
      navigate(`/sport/statistics/${data?.SportId}/${data?.id}/league-data`);
    }
  };

  const sportName =
    teamSportType === "cricket"
      ? "Cricket"
      : teamSportType === "rugbyleague"
      ? "Rugby League"
      : teamSportType === "rugbyunion"
      ? "Rugby Union"
      : teamSportType === "basketball"
      ? "Basketball"
      : teamSportType === "americanfootball"
      ? "American Football"
      : teamSportType === "australianrules"
      ? "Australian Rules"
      : teamSportType === "golf"
      ? "Golf"
      : teamSportType === "tennis"
      ? "Tennis"
      : teamSportType === "baseball"
      ? "Baseball"
      : teamSportType === "icehockey"
      ? "Ice Hockey"
      : teamSportType === "boxing"
      ? "Boxing"
      : teamSportType === "mma"
      ? "Mixed Martial Arts"
      : teamSportType === "soccer"
      ? "Soccer"
      : "";

  return (
    <>
      <Box className="teamsport-data-wrap">
        <Box className="teamsport-data-accordian mt-18">
          <Box className="teamsport-section-details">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Latest {sportName} Competitions Tables, Fixtures & Results
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className="accordion-teamsport-details">
                  {loader ? (
                    <div className="allsport-loader-center">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {/* <InfiniteScroll
                        dataLength={tournamentData?.length}
                        next={() =>
                          fetchScrollAllTournament(tournamentOffset + 20)
                        }
                        // hasMore={tournamentCount > tournamentOffset}
                        hasMore={
                          tournamentCount !==
                          Math.ceil(tournamentOffset / 20 + 1)
                        }
                        loader={
                          <div className="allsport-loader-center ">
                            <Loader />
                          </div>
                        }
                      > */}
                      {tournamentData?.length > 0 ? (
                        tournamentData?.map((obj, id) => {
                          return (
                            <>
                              <Box
                                className="d-flex text-align content-space-betwen league-list"
                                key={id}
                                onClick={() => handleNavigate(obj)}
                              >
                                <Box className="d-flex text-align name-wrap">
                                  <Typography className="league-name">
                                    {teamSportType === "cricket"
                                      ? obj?.uniqueTournament
                                        ? obj?.uniqueTournament?.name
                                        : obj?.name
                                      : obj?.name}
                                  </Typography>
                                  {teamSportType === "cricket" ? (
                                    <Typography className="league-sub-name">
                                      {obj?.CricketCategory?.name}
                                    </Typography>
                                  ) : teamSportType === "soccer" ? (
                                    obj?.SoccerCategory ? (
                                      <Typography className="league-sub-name">
                                        {obj?.SoccerCategory?.name}
                                      </Typography>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </Box>
                                <Box className="arrow">
                                  <KeyboardArrowRightIcon fontSize="small" />
                                </Box>
                              </Box>
                            </>
                          );
                        })
                      ) : (
                        <Box className="no-data-wrap">
                          <Typography className="text-align no-data">
                            {/* No Data Available */}
                            <NoDataComp />
                          </Typography>
                        </Box>
                      )}
                      {/* </InfiniteScroll> */}
                    </>
                  )}
                  {tournamentData?.length > 0 ? (
                    <Box className="view-more">
                      {tournamentOffset + 20 === tournamentData?.length ? (
                        <span
                          className="view-more-text"
                          onClick={() => handleMoreData()}
                        >
                          View More
                        </span>
                      ) : (
                        <></>
                      )}
                      {tournamentOffset >= 20 && tournamentData?.length > 20 ? (
                        <span
                          className="view-more-text view-lese-text"
                          onClick={() => handleLessData()}
                        >
                          View Less
                        </span>
                      ) : (
                        <></>
                      )}
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TeamSportsDataPage;
