import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  TextareaAutosize,
  Typography,
  Button
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "src/assets/images/icons/closeicon.svg";
import Loader from "src/components/Loader";
import { useLocation } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "./ui.scss";
import FileUploader from "./fileUploader";
import CropImage from "./cropImage";

const UploadImageModal = ({
  handleClose,
  open,
  title,
  closeIcon,
  getImage,
  id,
  isEdit,
  tabType,
  listingFunction
}) => {
  const location = useLocation();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  useEffect(() => {
    setImage(null);
  }, [open]);

  const handleFileUpload = files => {
    setImage(
      Object.assign(files[0], {
        preview: URL.createObjectURL(files[0])
      })
    );
  };
  const getCropData = async cropper => {
    var baseText = cropper.getCroppedCanvas().toDataURL();
    const blob = await (await fetch(baseText)).blob();
    const files = new File([blob], image?.preview, {
      type: image?.type,
      lastModified: new Date()
    });
    const formData = new FormData();
    formData.append("image", files);

    // setLoader(true);
    try {
      const { status, data } = await axiosInstance.post(
        "/public/media",
        formData
      );
      if (status === 200) {
        // setApiMessage("success", data?.message);
        // setProfileImg(data?.data?.name);
        // setProfileImgPreview(data?.data?.link);
        // setLoader(false);
        // onhandleclose()
        getImage(data?.image);
        handleClose();
        setImage(null);
        console.log("data1234", data?.image);
      }
    } catch (err) {
      //   setApiMessage("error", err?.response?.data?.message);
      //   setLoader(false);
    }
  };

  //   useEffect(() => {
  //     if (open) {
  //       GetBlackBook();
  //       const comment = isEdit ? editComment : "";
  //       setComment(comment);
  //     }
  //   }, [open]);

  const outSideCloseModal = () => {
    handleClose();
    setImage(null);
  };
  return (
    <Dialog
      className="uploadimg-dialog"
      // maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
      scroll={"paper"}
    >
      <Box className="dialog-content-box">
        {title && (
          <DialogTitle>
            <Box className="custom-dialog-title">
              <Typography variant="h3" className="dialog-title">
                {title}
                {closeIcon !== false && (
                  <IconButton
                    className="dialog-close"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
          </DialogTitle>
        )}
        <Box className="uploadimg-content-wrap">
          {!image ? (
            <>
              <Box className="dropzone-wrap">
                <FileUploader onDrop={image => handleFileUpload(image)} />
              </Box>
              <Box className="modal-btn-wrap">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="modal-btn"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </>
          ) : (
            <CropImage
              file={image}
              isProfile={true}
              getCropData={getCropData}
              onhandleclose={outSideCloseModal}
              setImage={setImage}
            />
          )}
        </Box>
      </Box>
      <Box>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Box>
    </Dialog>
  );
};

export default UploadImageModal;
