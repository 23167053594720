import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment";
import { Config } from "src/helpers/context";

const StickyTableCell = withStyles(theme => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 99,
    background: "#FFFFFF",
    // minWidth: "165px",
    boxShadow: "inset -2px 0px 0px 0px #ebebeb",
    "@media(max-width: 799px)": {
      //   maxWidth: "133px",
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  },
  body: {
    left: 0,
    position: "sticky",
    background: "#FFFFFF",
    boxShadow: "inset -2px 0px 0px 0px #ebebeb",
    "@media(max-width: 799px)": {
      boxShadow: "inset -2px 0px 0px 0px #ebebeb"
    }
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  head: {},
  cell: {}
}));

const LeagueVenuesDetails = ({ headerData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SeasonId = searchParams.get("seasonId");
  const [loader, setLoader] = useState(false);
  const [stadiumDetails, setStadiumDetails] = useState([]);
  const [offset, setOffset] = useState(0);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    fetchStadiumDetails(0, []);
  }, [params, SeasonId]);

  const fetchStadiumDetails = async (offset, stadiumData) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/stadium/?SportId=${params?.sportId}&tournamentId=${
          params?.tournamentId
        }&limit=10&offset=${offset}&seasonId=${SeasonId ? SeasonId : ""}`
      );
      if (status === 200) {
        setLoader(false);
        setDataCount(data?.result?.rows?.length);
        const stadiumDetailsData = [...stadiumData, ...data?.result?.rows];
        setStadiumDetails(stadiumDetailsData);
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const handleMoreData = () => {
    setOffset(offset + 10);
    fetchStadiumDetails(offset + 10, stadiumDetails);
  };
  const handleLessData = () => {
    setOffset(offset - 10);
    if (stadiumDetails?.length % 10 === 0) {
      setStadiumDetails(prevArray => prevArray.slice(0, -10));
    } else {
      setStadiumDetails(prevArray => prevArray.slice(0, -dataCount));
    }
  };

  const handleNavigate = data => {
    navigate(
      `/sport/statistics/${params?.sportId}/${
        params?.tournamentId
      }/league-data/${data?.id}/stadium-data?seasonId=${
        SeasonId ? SeasonId : ""
      }`
    );
  };

  return (
    <>
      <Box className="teamsport-data-accordian league-data-details">
        <Box className="teamsport-section-details">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {SeasonId
                  ? headerData?.season?.name
                  : headerData?.tournament?.name}{" "}
                - Venues
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="league-accordion-details">
              {/* <Box className="short-name-details">
                <Typography className="short-name">
                  P - <span>Played</span> , W - <span>Win</span>, L -{" "}
                  <span>Loss</span>, D - <span>Draw</span>, F - , A -{" "}
                  <span>Away</span>, PD - <span>Point Differential</span>, FPG -{" "}
                  <span>Fantasy Points per Game</span>, APG -{" "}
                  <span>Assists Points per Game</span>, Att% -{" "}
                  <span>Attempts</span> , Def% - <span>Defeated</span>{" "}
                </Typography>
              </Box> */}
              <Box className="teamsport-header-details">
                <Typography className="heading-title">Venues</Typography>
              </Box>
              <Box className="table-overlay">
                {loader && (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                )}
                {!loader && (
                  <>
                    <TableContainer
                      id="details-table"
                      className="teamsport-data-table team-sport-tables"
                    >
                      {/* <Box className="overlay"></Box> */}
                      <Table className="data-table" style={{ minWidth: 940 }}>
                        <TableHead className="table-header">
                          <TableRow className="table-head-row ">
                            <TableCell className={`${classes.head} first-cell`}>
                              Venues
                            </TableCell>
                            <TableCell className={classes.head}>
                              Country
                            </TableCell>
                            <TableCell className={classes.head}>City</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                          {stadiumDetails?.length > 0 ? (
                            <>
                              {stadiumDetails?.map((item, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      className="table-body-row "
                                    >
                                      <TableCell
                                        className={`${classes.cell} first-cell light-blue cursor-pointer`}
                                        onClick={() => handleNavigate(item)}
                                      >
                                        {item?.name}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.Country
                                          ? item?.Country?.country
                                          : "-"}
                                      </TableCell>
                                      <TableCell className={classes.cell}>
                                        {item?.City ? item?.City?.name : "-"}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <TableRow>
                              <TableCell colSpan={100} className="no-data-td">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {stadiumDetails?.length > 0 ? (
                      <Box className="view-more">
                        {offset + 10 === stadiumDetails?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => handleMoreData()}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {offset >= 10 && stadiumDetails?.length > 10 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => handleLessData()}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default LeagueVenuesDetails;
